import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { useMemo, useRef, useState, VFC } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { ChevronDown, ChevronRight } from '../../components/icons';
import SortableTable from '../../components/SortableTable';
import { useAlert } from '../../context/AlertContext';
import { useAuth } from '../../context/AuthContext';
import { useLayout } from '../../context/LayoutContext';
import { resetAlertQueryParams } from '../../helpers';
import { formatNumber } from '../../helpers/i18n';
import useQueryParams from '../../hooks/queryParams';
import { useAlertGroupOrganizationList } from '../../queries/alertGroupOrganizations';
import { Alert, AlertGroupOrganization, AlertTypeOrganization, TableTemplateKey, User } from '../../queries/api/types';
import { useTableTemplateUpdate } from '../../queries/tableTemplates';

const getTableTemplateFromKey = (user: User | undefined, key: TableTemplateKey | string) => {
    return user?.tableTemplates?.find((tableTemplate) => tableTemplate.key === key);
};

const sortAlertGroups = (
    alertGroups: AlertGroupOrganization[] | undefined,
    user: User | undefined,
    tableTemplateKey: TableTemplateKey
) => {
    if (!alertGroups || !user?.tableTemplates) {
        return undefined;
    }

    const tableTemplateItems = getTableTemplateFromKey(user, tableTemplateKey)?.items;

    return [...alertGroups].sort(
        (a, b) =>
            (tableTemplateItems?.find((item) => item.key === a.id)?.index ?? 0) -
            (tableTemplateItems?.find((item) => item.key === b.id)?.index ?? 0)
    );
};

const sortAlertTypes = (
    alertTypes: AlertTypeOrganization[] | undefined,
    user: User | undefined,
    tableTemplateKey: string
) => {
    if (!alertTypes || !user?.tableTemplates) {
        return undefined;
    }

    const tableTemplateItems = getTableTemplateFromKey(user, tableTemplateKey)?.items;

    return [...alertTypes].sort(
        (a, b) =>
            (tableTemplateItems?.find((item) => item.key === a.alertType.id)?.index ?? 0) -
            (tableTemplateItems?.find((item) => item.key === b.alertType.id)?.index ?? 0)
    );
};

const AlertsWrapper: VFC = () => {
    const { user } = useAuth();
    const { organizationId } = useLayout();
    const { setAlert } = useAlert();
    const [queryParams, setQueryParams] = useQueryParams('alerts');
    const alertTypeId = queryParams.get('alertTypeId');
    const alertGroupIndex = queryParams.get('alertGroupIndex') ? parseInt(queryParams.get('alertGroupIndex')!) : 0;
    const [, setAlertIdsToIgnore] = useLocalStorage<Array<Alert['id']> | undefined>('massIgnoreAlertIds', undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[] | undefined>(
        alertGroupIndex ? [alertGroupIndex] : undefined
    );
    const setExpandedRowKeysRef = useRef(setExpandedRowKeys);
    const { data: alertGroupOrganizationsList, isLoading } = useAlertGroupOrganizationList(
        { organization: organizationId, page: 0, pageSize: 4 },
        {
            onSuccess: (data) => {
                const sortedAlertGroups = sortAlertGroups(data?.items, user, TableTemplateKey.alertGroups);
                setAlertGroupOrganizations(sortedAlertGroups);
                setAlertTypeOrganizations(
                    data?.items.reduce<{ [alertGroupId: string]: AlertTypeOrganization[] | undefined }>(
                        (alertTypes, alertGroupOrganization) => ({
                            ...alertTypes,
                            [alertGroupOrganization.alertGroup.id]: sortAlertTypes(
                                alertGroupOrganization.alertTypesOrganization,
                                user,
                                alertGroupOrganization.alertGroup.id
                            ),
                        }),
                        {}
                    )
                );
                if (!expandedRowKeys) {
                    setExpandedRowKeys([0]);
                }
            },
            staleTime: 0,
            refetchInterval: 60000,
            refetchIntervalInBackground: true,
        }
    );
    const [alertGroupOrganizations, setAlertGroupOrganizations] = useState(
        sortAlertGroups(alertGroupOrganizationsList?.items, user, TableTemplateKey.alertGroups)
    );
    const [alertTypeOrganizations, setAlertTypeOrganizations] = useState(
        alertGroupOrganizationsList?.items.reduce<{ [alertGroupId: string]: AlertTypeOrganization[] | undefined }>(
            (alertTypes, alertGroupOrganization) => ({
                ...alertTypes,
                [alertGroupOrganization.alertGroup.id]: sortAlertTypes(
                    alertGroupOrganization.alertTypesOrganization,
                    user,
                    alertGroupOrganization.alertGroup.id
                ),
            }),
            {}
        )
    );
    const { mutate: updateTemplate } = useTableTemplateUpdate();
    const onSortAlertGroups = (items: AlertGroupOrganization[]) => {
        updateTemplate({
            id: getTableTemplateFromKey(user, TableTemplateKey.alertGroups)?.id,
            items: items.map((item, index) => ({ key: item.id, index })),
        });

        setAlertGroupOrganizations(items);
    };
    const onSortAlertTypes = (tableTemplateKey: string, items: AlertTypeOrganization[]) => {
        updateTemplate({
            id: getTableTemplateFromKey(user, tableTemplateKey)?.id,
            items: items.map((item, index) => ({ key: item.alertType.id, index })),
        });

        setAlertTypeOrganizations((value) => ({
            ...value,
            [tableTemplateKey]: items,
        }));
    };
    const parentColumns: ColumnsType<AlertGroupOrganization> = useMemo(
        () => [
            {
                key: 'reference',
                dataIndex: ['alertGroup', 'reference'],
                title: 'Code',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold',
                }),
                width: 190,
                shouldCellUpdate: () => false,
            },
            {
                key: 'type',
                title: 'Type',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold',
                }),
                width: 353,
                shouldCellUpdate: () => false,
            },
            {
                key: 'received',
                title: 'Reçu',
                render: (_, record) => formatNumber(record.received),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 97,
                shouldCellUpdate: () => false,
            },
            {
                key: 'pending',
                title: 'Attente',
                render: (_, record) => formatNumber(record.pending),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 119,
                shouldCellUpdate: () => false,
            },
            {
                key: 'ignored',
                title: 'Ignoré',
                render: (_, record) => formatNumber(record.ignored),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 110,
                shouldCellUpdate: () => false,
            },
            {
                key: 'routed',
                title: 'Routé',
                render: (_, record) => formatNumber(record.routed),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 106,
                shouldCellUpdate: () => false,
            },
            {
                key: 'treated',
                title: 'Traité',
                render: (_, record) => formatNumber(record.treated),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 107,
                shouldCellUpdate: () => false,
            },
            {
                key: 'registered',
                title: 'Enregistré',
                render: (_, record) => formatNumber(record.registered),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 139,
                shouldCellUpdate: () => false,
            },
            {
                key: 'total',
                title: 'Total',
                render: (_, record) =>
                    formatNumber(
                        (record.received ?? 0) +
                            (record.pending ?? 0) +
                            (record.ignored ?? 0) +
                            (record.routed ?? 0) +
                            (record.treated ?? 0) +
                            (record.registered ?? 0)
                    ),
                className: 'text-center',
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold text-center',
                }),
                width: 129,
                shouldCellUpdate: () => false,
            },
        ],
        []
    );
    const expandedRowRender: ExpandableConfig<AlertGroupOrganization>['expandedRowRender'] = (record, index) => {
        const childColumns: ColumnsType<AlertTypeOrganization> = [
            {
                key: 'reference',
                title: 'Code',
                render: (_, record) => record.alertType.reference ?? '-',
                width: 190,
                shouldCellUpdate: () => false,
            },
            {
                key: 'type',
                title: 'Type',
                render: (_, record) => record.alertType.name ?? '-',
                width: 353,
                shouldCellUpdate: () => false,
            },
            {
                key: 'received',
                title: 'Reçu',
                render: (_, record) => formatNumber(record.received),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 97,
                shouldCellUpdate: () => false,
            },
            {
                key: 'pending',
                title: 'Attente',
                render: (_, record) => formatNumber(record.pending),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 119,
                shouldCellUpdate: () => false,
            },
            {
                key: 'ignored',
                title: 'Ignoré',
                render: (_, record) => formatNumber(record.ignored),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 110,
                shouldCellUpdate: () => false,
            },
            {
                key: 'routed',
                title: 'Routé',
                render: (_, record) => formatNumber(record.routed),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 106,
                shouldCellUpdate: () => false,
            },
            {
                key: 'treated',
                title: 'Traité',
                render: (_, record) => formatNumber(record.treated),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 107,
                shouldCellUpdate: () => false,
            },
            {
                key: 'registered',
                title: 'Enregistré',
                render: (_, record) => formatNumber(record.registered),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 139,
                shouldCellUpdate: () => false,
            },
            {
                key: 'total',
                title: 'Total',
                render: (_, record) =>
                    formatNumber(
                        (record.received ?? 0) +
                            (record.pending ?? 0) +
                            (record.ignored ?? 0) +
                            (record.routed ?? 0) +
                            (record.treated ?? 0) +
                            (record.registered ?? 0)
                    ),
                className: 'text-center',
                onCell: () => ({
                    className: 'text-center',
                }),
                width: 129,
                shouldCellUpdate: () => false,
            },
        ];

        return (
            <SortableTable<AlertTypeOrganization>
                columns={childColumns}
                dataSource={alertTypeOrganizations?.[record.alertGroup.id]}
                onSort={onSortAlertTypes.bind(null, record.alertGroup.id)}
                size="small"
                showHeader={false}
                onRow={(record) => ({
                    onClick: () => {
                        setAlert(undefined);
                        setAlertIdsToIgnore(undefined);
                        resetAlertQueryParams(setQueryParams, {
                            alertTypeId: record.alertType.id,
                            alertGroupIndex: index,
                        });
                    },
                })}
                rowClassName={(record) =>
                    record.alertType.id === alertTypeId ? 'cursor-pointer table-row-highlighted' : 'cursor-pointer'
                }
                className="expandable"
            />
        );
    };

    return (
        <SortableTable<AlertGroupOrganization>
            columns={parentColumns}
            loading={isLoading}
            dataSource={alertGroupOrganizations}
            onSort={onSortAlertGroups}
            onSortStart={() => {
                setExpandedRowKeys(undefined);
                setExpandedRowKeysRef.current?.(undefined);
            }}
            onHandleMouseDown={() => {
                setExpandedRowKeys(undefined);
                setExpandedRowKeysRef.current?.(undefined);
            }}
            size="small"
            className="expandable"
            dragHandleColumnProps={{
                onCell: () => ({
                    className: 'bg-taupe-lighter font-semibold',
                }),
            }}
            onRow={(record, index) => ({
                onClick: () => {
                    setExpandedRowKeys((value) => (value?.[0] === index ? undefined : [index ?? 0]));
                },
            })}
            rowClassName={() => 'cursor-pointer'}
            expandable={{
                expandedRowRender,
                expandedRowKeys,
                columnWidth: 30,
                indentSize: 220,
                defaultExpandedRowKeys: ['0'],
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <ChevronDown
                            onClick={(e) => {
                                onExpand(record, e);
                                e.stopPropagation();
                            }}
                            className="font-10"
                        />
                    ) : (
                        <ChevronRight
                            onClick={(e) => {
                                onExpand(record, e);
                                e.stopPropagation();
                            }}
                            className="font-10"
                        />
                    ),
                expandRowByClick: true,
            }}
            scroll={{ x: 1280 }}
        />
    );
};

export default AlertsWrapper;

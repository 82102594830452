import * as auth from './auth';

export type ApiResponse = Record<string, any>;

export interface RequestError {
    data?: any;
    headers?: any;
    message: string;
    status: number;
}

export interface PaginationQuery {
    page?: number;
    pageSize?: number;
    sort?: string;
    sortOrder?: string;
}

export interface SearchQuery {
    search?: string;
    throttling?: number;
}

export type SearchPaginationQuery = PaginationQuery & SearchQuery;

export default {
    auth,
};

export enum FileType {
    EXCEL = 'excel',
    PDF = 'pdf',
}

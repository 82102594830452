import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    ErrorCodeRecommendationCreatePayload,
    ErrorCodeRecommendationIdPayload,
    ErrorCodeRecommendationListPayload,
    ErrorCodeRecommendationListResponse,
    ErrorCodeRecommendationUpdatePayload,
    remove,
    update,
} from './api/errorCodeRecommendations';
import { ErrorCodeRecommendation } from './api/types';

export const errorCodeRecommendationsKeys = {
    all: ['errorCodeRecommendations'],
    lists: () => [...errorCodeRecommendationsKeys.all, 'list'],
    list: (params?: ErrorCodeRecommendationListPayload) => [...errorCodeRecommendationsKeys.lists(), params],
    details: () => [...errorCodeRecommendationsKeys.all, 'details'],
    detail: (id?: ErrorCodeRecommendationIdPayload) => [...errorCodeRecommendationsKeys.details(), id],
};

export const useErrorCodeRecommendationList = <TData = ErrorCodeRecommendationListResponse>(
    params?: ErrorCodeRecommendationListPayload,
    options?: UseQueryOptions<ErrorCodeRecommendationListResponse, AxiosError, TData>
) => {
    return useQuery<ErrorCodeRecommendationListResponse, AxiosError, TData>(
        errorCodeRecommendationsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useErrorCodeRecommendationDetails = <TData = ErrorCodeRecommendation>(
    id?: ErrorCodeRecommendationIdPayload,
    options?: UseQueryOptions<ErrorCodeRecommendation, AxiosError, TData>
) => {
    return useQuery<ErrorCodeRecommendation, AxiosError, TData>(
        errorCodeRecommendationsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useErrorCodeRecommendationCreate = (
    options?: UseMutationOptions<ErrorCodeRecommendation, AxiosError, ErrorCodeRecommendationCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ErrorCodeRecommendation, AxiosError, ErrorCodeRecommendationCreatePayload>(
        async (params) => await create(params),
        {
            ...options,
            onSuccess: (...args) => {
                options?.onSuccess?.(...args);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(errorCodeRecommendationsKeys.lists());
            },
        }
    );
};

export const useErrorCodeRecommendationUpdate = (
    options?: UseMutationOptions<ErrorCodeRecommendation, AxiosError, ErrorCodeRecommendationUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ErrorCodeRecommendation, AxiosError, ErrorCodeRecommendationUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(errorCodeRecommendationsKeys.detail(variables.id));
            },
        }
    );
};

export const useErrorCodeRecommendationRemove = (
    options?: UseMutationOptions<undefined, AxiosError, ErrorCodeRecommendationIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ErrorCodeRecommendationIdPayload>(
        async (params) => await remove(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query since we deleted the item
                queryClient.invalidateQueries(errorCodeRecommendationsKeys.detail(variables));

                // invalidate list queries to refetch for refreshing the list views
                queryClient.invalidateQueries(errorCodeRecommendationsKeys.lists());
            },
        }
    );
};

import { useEffect, useRef, Ref, useCallback, useState, useLayoutEffect } from 'react';
import { useCurrentBreakpointName } from 'react-socks';

export const usePrevious = <T extends unknown>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useShareForwardedRef = <T extends unknown>(forwardedRef: Ref<T>) => {
    // final ref that will share value with forward ref. this is the one we will attach to components
    const innerRef = useRef<T>(null);

    useEffect(() => {
        // after every render - try to share current ref value with forwarded ref
        if (!forwardedRef) {
            return;
        }
        if (typeof forwardedRef === 'function') {
            forwardedRef(innerRef.current);
        } else {
            // @ts-expect-error
            // by default forwardedRef.current is readonly. Let's ignore it
            forwardedRef.current = innerRef.current;
        }
    });

    return innerRef;
};

// Tablet is considered mobile here, customize as needed
export const useIsMobile = () => {
    const breakpoint = useCurrentBreakpointName();

    return breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' || breakpoint === 'lg';
};

export const useIsMounted = (): (() => boolean) => {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return function cleanup(): void {
            isMounted.current = false;
        };
    }, []);
    const checker = useCallback((): boolean => {
        return isMounted.current;
    }, []);
    return checker;
};

export const useDebounce = (value: any, delay: number) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
};

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function useCopyToClipboard(): [CopiedValue, CopyFn] {
    const [copiedText, setCopiedText] = useState<CopiedValue>(null);

    const copy: CopyFn = async (text) => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');
            return false;
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        } catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(null);
            return false;
        }
    };

    return [copiedText, copy];
}

export default useCopyToClipboard;

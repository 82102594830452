/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 2a1 1 0 0 1 1-1c6.075 0 11 4.925 11 11a1 1 0 0 1-1 1H12a1 1 0 0 1-1-1V2Zm2 1.055V11h7.945A9.004 9.004 0 0 0 13 3.055Zm-3.904-.68a1 1 0 0 1-.54 1.307 9.014 9.014 0 0 0-2.92 1.954A8.969 8.969 0 0 0 3 12a9 9 0 0 0 9 9 8.968 8.968 0 0 0 6.364-2.636 9.013 9.013 0 0 0 1.953-2.92 1 1 0 1 1 1.848.767 11.014 11.014 0 0 1-2.387 3.567m0 0A10.969 10.969 0 0 1 12 23C5.925 23 1 18.075 1 12c0-3.037 1.232-5.789 3.222-7.778a11.015 11.015 0 0 1 3.567-2.387 1 1 0 0 1 1.307.54"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgChartProportion: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-chart-proportion', props.className)} />
));
export default SvgChartProportion;

import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Layout, Menu, MenuProps, message, Typography } from 'antd';
import { show } from '@ebay/nice-modal-react';
import { CloudUploadOutlined } from '@ant-design/icons';

import '../assets/styles/MainMenu.less';

import constants from '../config/constants';
import { getRoute, RoutePathName } from '../routes';
import {
    Alarm,
    Artis,
    ChartProportion,
    Connect,
    Message,
    NetworkTree,
    Printer,
    SettingTwo,
    Star,
    Tool,
    Truck,
} from './icons';
import { hasPermission, hasRole } from '../helpers/security';
import { useAuth } from '../context/AuthContext';
import { Permission, RoleName, User } from '../queries/api/types';
import MailTemplatesDrawer from './drawers/MailTemplatesDrawer';
import PrivilegeCodesDrawer from './drawers/PrivilegeCodesDrawer';
import ErrorCodesDrawer from './drawers/ErrorCodesDrawer';
import { useNationalReporting } from '../queries/nationalReportings';
import { errorMessage, successMessage } from '../helpers/message';
import CountersUploadModal from './modals/CountersUploadModal';

const errorCodesDrawerId = 'errorCodesDrawer';
const privilegeCodesDrawerId = 'privilegeCodesDrawer';
export const mailTemplatesDrawerId = 'mailTemplatesDrawer';

const checkMenuItemPermissionAndRole = (item: MenuItem, user: User | undefined) => {
    if (Array.isArray(item.permission)) {
        return item.permission.some((perm) => hasPermission(user, perm));
    } else {
        return hasPermission(user, item.permission) && (item.role ? hasRole(user, item.role) : true);
    }
};

interface MenuItem {
    key: string;
    to?: string;
    locationState?: string;
    href?: string;
    target?: string;
    onClick?: () => void;
    label: ReactNode;
    icon?: any;
    children?: MenuItem[];
    disabled?: boolean;
    permission?: Permission | Permission[];
    role?: RoleName[];
}

const MainMenu: FC = () => {
    const { user } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { mutate: generateNationalReporting } = useNationalReporting({
        onError: (error) => {
            if (error?.response?.status === 400 && error?.response?.data?.fileGenerationRequestInProgressId) {
                errorMessage({
                    key: 'reporting',
                    content: "Une demande d'export est déjà en cours, veuillez patienter quelques instants",
                });
            } else {
                errorMessage({
                    key: 'reporting',
                    content: 'Une erreur est survenue pendant la génération du reporting',
                });
            }
        },
        onSuccess: () => {
            successMessage({
                key: 'reporting',
                content: 'Génération du reporting débutée avec succès, vous recevrez le résultat par email',
                duration: 4,
            });
        },
    });
    const onClickReporting = useCallback(() => {
        message.loading({
            key: 'reporting',
            content: 'Chargement...',
            duration: 0,
        });
        generateNationalReporting();
    }, [generateNationalReporting]);
    const onMenuSelect: MenuProps['onSelect'] = ({ key }) => {
        const item = menuItems.find((menuItem) =>
            menuItem.children ? menuItem.children.find((child) => child.key === key) : menuItem.key === key
        );

        if (item) {
            if (item.href) {
                if (item.target) {
                    window.open(item.href, item.target);
                } else {
                    window.location.href = item.href;
                }
            } else if (item.to) {
                history.push(item.to, item.locationState);
            }
        }
    };
    const menuItems = useMemo<MenuItem[]>(
        () => [
            {
                key: getRoute(RoutePathName.alerts),
                to: getRoute(RoutePathName.alerts),
                label: 'Alertes',
                icon: <Alarm />,
            },
            {
                key: getRoute(RoutePathName.orders),
                to: getRoute(RoutePathName.orders),
                label: 'Livraisons',
                icon: <Truck />,
                permission: Permission.orders,
            },
            {
                key: getRoute(RoutePathName.devices),
                to: getRoute(RoutePathName.devices),
                label: 'Parc',
                icon: <Printer />,
                permission: Permission.devices,
            },
            {
                key: getRoute(RoutePathName.subscriptions),
                to: getRoute(RoutePathName.subscriptions),
                label: 'Abonnements',
                icon: <NetworkTree />,
                permission: Permission.subscriptions,
            },
            {
                key: 'admin',
                label: 'Admin',
                icon: <SettingTwo />,
                role: [RoleName.admin, RoleName.dsc, RoleName.grc],
                children: [
                    {
                        key: 'reporting',
                        label: 'Reporting national',
                        icon: <ChartProportion />,
                        role: [RoleName.admin, RoleName.dsc],
                        onClick: onClickReporting,
                    },
                    {
                        key: 'errorCodes',
                        onClick: () => {
                            show(errorCodesDrawerId);
                        },
                        label: 'Codes pannes',
                        icon: <Tool />,
                        permission: Permission.errorCodes,
                    },
                    {
                        key: 'privilegeCodes',
                        onClick: () => {
                            show(privilegeCodesDrawerId);
                        },
                        label: 'Codes privilèges',
                        icon: <Star />,
                        permission: Permission.privilegeCodes,
                    },
                    {
                        key: 'mailTemplates',
                        onClick: () => {
                            show(mailTemplatesDrawerId);
                        },
                        label: 'Modèles de réponse par email ',
                        icon: <Message />,
                        permission: Permission.mailTemplates,
                    },
                    {
                        key: 'countersUpload',
                        onClick: () => {
                            show(CountersUploadModal);
                        },
                        label: 'import des compteurs externes',
                        icon: <CloudUploadOutlined />,
                        role: [RoleName.admin, RoleName.grc, RoleName.dsc],
                    },
                ],
            },
            {
                key: 'artis',
                href: constants.LINKS.ARTIS,
                target: '_blank',
                label: 'Artis',
                icon: <Artis />,
            },
            {
                key: 'connect',
                href: constants.LINKS.CONNECT,
                target: '_blank',
                label: 'Connect',
                icon: <Connect />,
            },
        ],
        [onClickReporting]
    );

    return (
        <Layout.Sider collapsible collapsed trigger={null} theme="light" id="sidebar" collapsedWidth={58}>
            <div id="sidebar-wrapper">
                <Menu
                    mode="inline"
                    selectedKeys={[
                        [
                            getRoute(RoutePathName.alertStats),
                            getRoute(RoutePathName.alerts, { param: 'surveillance-niveaux-toner' }),
                            getRoute(RoutePathName.alertHistory),
                            getRoute(RoutePathName.deviceSearch),
                        ].includes(location.pathname)
                            ? getRoute(RoutePathName.alerts)
                            : location.pathname,
                    ]}
                    onSelect={onMenuSelect}
                >
                    {menuItems
                        .filter((item) => checkMenuItemPermissionAndRole(item, user))
                        .map((item) => {
                            if (!item.children) {
                                return (
                                    <Menu.Item
                                        key={item.key}
                                        icon={item.icon}
                                        disabled={item.disabled}
                                        onClick={item.onClick}
                                    >
                                        {item.label}
                                    </Menu.Item>
                                );
                            } else {
                                return (
                                    <Menu.SubMenu
                                        key={item.key}
                                        disabled={item.disabled}
                                        title={item.label}
                                        icon={item.icon}
                                    >
                                        {item.children
                                            .filter((subItem) => checkMenuItemPermissionAndRole(subItem, user))
                                            .map((subItem) => {
                                                if (!subItem.children) {
                                                    return (
                                                        <Menu.Item
                                                            key={subItem.key}
                                                            disabled={subItem.disabled}
                                                            icon={subItem.icon}
                                                            onClick={subItem.onClick}
                                                        >
                                                            {subItem.label}
                                                        </Menu.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Menu.SubMenu
                                                            key={subItem.key}
                                                            disabled={subItem.disabled}
                                                            title={subItem.label}
                                                            icon={subItem.icon}
                                                        >
                                                            {subItem.children
                                                                .filter((subSubItem) =>
                                                                    Array.isArray(subSubItem.permission)
                                                                        ? subSubItem.permission.some((perm) =>
                                                                              hasPermission(user, perm)
                                                                          )
                                                                        : hasPermission(user, subSubItem.permission) &&
                                                                          (subSubItem.role
                                                                              ? hasRole(user, subSubItem.role)
                                                                              : true)
                                                                )
                                                                .map((subSubItem) => (
                                                                    <Menu.Item
                                                                        key={subSubItem.key}
                                                                        disabled={subSubItem.disabled}
                                                                        icon={subSubItem.icon}
                                                                        onClick={subSubItem.onClick}
                                                                    >
                                                                        {subSubItem.label}
                                                                    </Menu.Item>
                                                                ))}
                                                        </Menu.SubMenu>
                                                    );
                                                }
                                            })}
                                    </Menu.SubMenu>
                                );
                            }
                        })}
                </Menu>
                <Typography.Text className="text-white">v{constants.VERSION}</Typography.Text>
            </div>
            <ErrorCodesDrawer id={errorCodesDrawerId} />
            <PrivilegeCodesDrawer id={privilegeCodesDrawerId} />
            <MailTemplatesDrawer id={mailTemplatesDrawerId} />
        </Layout.Sider>
    );
};

export default MainMenu;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4.51A.998.998 0 0 1 2 3.5h20a.999.999 0 0 1 1 1.01V12a1 1 0 0 1-2 0V6.5l-8.4 6.3a1 1 0 0 1-1.2 0L3 6.5v12h9a1 1 0 0 1 0 2H2a1 1 0 0 1-1-1V4.51Zm4 .99 7 5.25 7-5.25H5Z"
            fill="currentColor"
        />
        <path
            d="M14 17a1 1 0 0 1 1-1h4.586l-.793-.793a1 1 0 0 1 1.414-1.414l2.5 2.5a.999.999 0 0 1 .293.698v.018a.997.997 0 0 1-.293.698l-2.5 2.5a1 1 0 0 1-1.414-1.414l.793-.793H15a1 1 0 0 1-1-1Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgSendEmail: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-send-email', props.className)} />
));
export default SvgSendEmail;

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import api from './apiClient';
import { FileType, SearchPaginationQuery } from '.';
import {
    Color,
    Device,
    ListResponse,
    Organization,
    TonerMonitoring,
    TonerMonitoringStatus,
    Subscription,
    FileGenerationRequestSlug,
    DacDeviceCounters,
} from './types';

// Controller Interface
export type DeviceUpdatePayload = Partial<Pick<Device, 'id' | 'thresholdColorLevels'>> & {
    erpDeviceInfo?: {
        customer: {
            mailTicketRecipient: string;
        };
    };
    subscriptionReference?: string;
    tonerMonitoring?: Partial<TonerMonitoring>;
};

export type SubscriptionFileGenerationPayload = {
    subscriptionId?: Subscription['id'];
    type: FileType;
};

export type DeviceIdPayload = Device['id'];

export type DeviceListPayload = SearchPaginationQuery & {
    serials?: Array<Device['serial']>;
    organization?: Organization['id'];
    subscription?: Subscription['id'];
    color?: Color;
    colorLevel?: number;
    tonerMonitoringStatus?: TonerMonitoringStatus[];
    notified?: boolean;
    eccAndAutoNotFalse?: boolean;
    isFromSelectedDevices?: boolean;
    fileGenerationRequestSlug?: FileGenerationRequestSlug;
};
export type DeviceListResponse = ListResponse<Device>;
export type DeviceExportResponse = AxiosResponse;

export interface DeviceAlertHistoryFilterStatsResponse {
    all: number;
    counter: number;
    blackColorLevel: number;
    tne: number;
    cno: number;
    nonIgnored: number;
}

// Routes
export const list = async (payload?: DeviceListPayload, axiosOptions?: AxiosRequestConfig) => {
    return await api.get<DeviceListResponse>(`/devices`, { params: payload, ...axiosOptions });
};

export const alertHistoryFilterStats = async (id?: DeviceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .get<DeviceAlertHistoryFilterStatsResponse>(`/devices/${id}/alertHistoryFilterStats`)
        .then((response) => response?.data);
};

export const update = async ({ id, ...payload }: DeviceUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Device>(`/devices/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: DeviceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Device>(`/devices/${id}`).then((response) => response?.data);
};

export const remove = async (id?: DeviceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/devices/${id}`).then((response) => response?.data);
};

export const generation = async (url: string) => {
    if (!url) {
        throw new Error('missing url');
    }
    return await api
        .get<undefined>('/pdfGeneration', {
            params: { url },
            headers: {
                'Content-Type': 'application/octet-stream',
            },
            responseType: 'blob',
        })
        .then((response) => response?.data);
};

const getFileAcceptHeader = (fileType: FileType | undefined) => {
    switch (fileType) {
        case FileType.EXCEL:
            return 'application/vnd.ms-excel';
        case FileType.PDF:
            return 'application/pdf';
    }
    return '';
};
export const subscriptionFileGeneration = async (params?: SubscriptionFileGenerationPayload) => {
    if (!params?.subscriptionId) {
        throw new Error('missing subscription identifier');
    }
    return await api
        .get<Blob>(`subscriptions/${params.subscriptionId}/generate`, {
            headers: {
                'Content-Type': 'application/octet-stream',
                Accept: getFileAcceptHeader(params.type),
            },
            responseType: 'blob',
        })
        .then((response) => response?.data);
};

export const dacDeviceCounters = async (id?: DeviceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<DacDeviceCounters>(`/devices/${id}/dacDeviceCounters`).then((response) => response?.data);
};

import { Button, Form, Typography, Empty, Card, Input, Tag } from 'antd';
import { useCallback, useMemo, useState, VFC } from 'react';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SortingState } from '@tanstack/react-table';

import { CalendarThree, Printer, Truck } from '../../components/icons';
import { getOrderStatusColor } from '../../helpers';
import { formatDate, formatNumber, formatRelativeTime, translateOrderStatus } from '../../helpers/i18n';
import { Order } from '../../queries/api/types';
import DatePicker from '../../components/DatePicker';
import { useOrderCount, useOrderList } from '../../queries/orders';
import NoData from '../../components/NoData';
import useQueryParams, { useStoredQueryParams } from '../../hooks/queryParams';
import { useLayout } from '../../context/LayoutContext';
import { getRoute, RoutePathName } from '../../routes';
import { alertQueryParamsKey } from '../alerts/AlertsList';
import Seo from '../../components/Seo';
import ResizableTable, {
    ResizableTableColumn,
    ResizableTableProps,
    getDefaultSort,
} from '../../components/ResizableTable';
import { truthy } from '../../types';

const Orders: VFC = () => {
    const { withBackButton } = useLocation<{ withBackButton?: boolean }>()?.state || {};
    const alertQueryParams = useStoredQueryParams(alertQueryParamsKey);
    const { organizationId } = useLayout();
    const [queryParams, setQueryParams] = useQueryParams('Orders');
    const orderedAt = queryParams.get('orderedAt') ?? dayjs().endOf('day').toISOString();
    const deviceSerial = queryParams.get('deviceSerial') ?? undefined;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const sort = queryParams.get('sort') ?? 'orderedAt';
    const sortOrder = queryParams.get('sortOrder') ?? 'desc';
    const [lastDateOfMonth, setLastDateOfMonth] = useState<string>(dayjs().endOf('month').toISOString());
    const [deviceValue, setDeviceValue] = useState<string | undefined>(deviceSerial);
    const {
        data: orders,
        isLoading,
        isFetching,
        isError,
        error,
    } = useOrderList({
        orderedAt: deviceSerial ? undefined : orderedAt,
        deviceSerial: deviceSerial,
        organization: organizationId,
        sort,
        sortOrder,
        page,
    });
    const { data: orderCounts } = useOrderCount({
        lastDateOfMonth,
    });
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const onSubmit = () => {
        setQueryParams({
            deviceSerial: deviceValue ?? undefined,
            sort: 'orderedAt',
            sortOrder: 'desc',
        });
    };
    const onReset = () => {
        setQueryParams({
            deviceSerial: undefined,
            sort: undefined,
            sortOrder: undefined,
            page: undefined,
        });
        setDeviceValue(undefined);
    };

    const columns = useMemo<Array<ResizableTableColumn<Order>>>(
        () =>
            (
                [
                    !!deviceSerial && {
                        id: 'orderedAt',
                        header: 'Date',
                        accessorKey: 'orderedAt',
                        cell: (info) => (info.getValue<string>() ? formatDate(info.getValue<string>()) : <NoData />),
                        enableSorting: true,
                    },
                    {
                        id: 'serial',
                        header: 'N° de série',
                        accessorKey: 'device.serial',
                        cell: (info) => info.getValue<string>() ?? <NoData />,
                    },
                    {
                        id: 'status',
                        header: 'Statut',
                        accessorKey: 'status',
                        cell: (info) => (
                            <Tag
                                color={getOrderStatusColor(info.getValue<Order['status']>())}
                                className="uppercase font-bold"
                            >
                                {translateOrderStatus(info.getValue<Order['status']>())}
                            </Tag>
                        ),
                    },
                    {
                        id: 'reference',
                        header: 'N° cmd client',
                        accessorKey: 'reference',
                        cell: (info) => info.getValue<Order['reference']>() ?? <NoData />,
                    },
                    {
                        id: 'referenceFrs',
                        header: 'N° cmd frs',
                        accessorKey: 'referenceFrs',
                        cell: (info) => info.getValue<Order['referenceFrs']>() ?? <NoData />,
                    },
                    {
                        id: 'editedAt',
                        header: 'Date édition BL',
                        accessorKey: 'editedAt',
                        cell: (info) =>
                            info.getValue<Order['editedAt']>() ? (
                                `${formatDate(info.getValue<Order['editedAt']>())} (${formatRelativeTime(
                                    info.getValue<Order['editedAt']>()
                                )})`
                            ) : (
                                <NoData />
                            ),
                        enableSorting: true,
                    },
                    {
                        id: 'itemCode',
                        header: 'Code article',
                        accessorKey: 'itemCode',
                        cell: (info) => info.getValue<Order['itemCode']>() ?? <NoData />,
                    },
                    {
                        id: 'name',
                        header: 'Libéllé',
                        accessorKey: 'name',
                        cell: (info) => info.getValue<Order['name']>() ?? <NoData />,
                    },
                ] as Array<ResizableTableColumn<Order>>
            ).filter(truthy),
        [deviceSerial]
    );

    const defaultSortingState = useMemo(() => getDefaultSort(sort, sortOrder), [sort, sortOrder]);

    const pagination = useMemo<ResizableTableProps<Order>['pagination']>(
        () => ({
            current: page + 1,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            pageSize: orders?.pageSize ?? 50,
            showSizeChanger: false,
        }),
        [orders?.pageSize, page, setQueryParams]
    );

    return (
        <div className="flex flex-col min-h-full">
            <Seo title="Commandes et livraisons de toner" />
            <div className="-mt-16 -mx-16 p-16">
                {withBackButton && (
                    <Link
                        to={{
                            pathname: getRoute(RoutePathName.alerts),
                            search: new URLSearchParams(alertQueryParams).toString(),
                        }}
                    >
                        <Button icon={<ArrowLeftOutlined />} type="link" className="pl-0 font-semibold">
                            Retour à l&rsquo;alerte
                        </Button>
                    </Link>
                )}
                <Typography.Title className="mb-16">Commandes et livraisons de toner</Typography.Title>
                <Card>
                    <div className="flex space-x-3">
                        <Form.Item label="N° de série de la machine" className="inline mb-0">
                            <Input
                                placeholder="Saisir un n° de série"
                                prefix={<Printer />}
                                onChange={(e) => setDeviceValue(e.target.value)}
                                value={deviceValue}
                            />
                        </Form.Item>
                        <Button type="primary" onClick={onSubmit}>
                            Rechercher
                        </Button>
                        <Button onClick={onReset}>Effacer</Button>
                    </div>
                </Card>
            </div>
            <div className="bg-grey-darker -mx-16 -mb-16 p-16 space-y-4 flex-auto">
                <div className="flex justify-between items-center">
                    <Typography.Title level={2} className="mb-0">
                        {formatNumber(orders?.totalCount)} commande{orders?.totalCount === 1 ? '' : 's'} passée
                        {orders?.totalCount === 1 ? '' : 's'}{' '}
                        {deviceSerial
                            ? ` pour le numéro de série « ${deviceSerial} »`
                            : dayjs(orderedAt).isSame(dayjs(), 'day')
                            ? 'aujourd’hui'
                            : `le ${formatDate(orderedAt)}`}
                    </Typography.Title>
                    <Form.Item label="Date de commande" className="inline mb-0">
                        <DatePicker
                            defaultValue={dayjs(orderedAt)}
                            onChange={(value) => setQueryParams({ orderedAt: value?.toISOString(), page: undefined })}
                            format="DD/MM/YYYY"
                            style={{ width: 210 }}
                            onPanelChange={(value, mode) => {
                                if (mode === 'date') {
                                    setLastDateOfMonth(value.endOf('month').toISOString());
                                }
                            }}
                            suffixIcon={<CalendarThree />}
                            dateRender={(current) => {
                                const count = orderCounts?.items.find((d) =>
                                    dayjs(d.date).isSame(current, 'day')
                                )?.count;

                                return (
                                    <div className="ant-picker-cell-inner" key={`${current.date()}${count ?? ''}`}>
                                        {count !== undefined ? (
                                            <>
                                                <span className="cell-count-date">{current.date()}</span>
                                                <span className="cell-count-value">{formatNumber(count)}</span>
                                            </>
                                        ) : (
                                            current.date()
                                        )}
                                    </div>
                                );
                            }}
                            disabled={!!deviceSerial}
                        />
                    </Form.Item>
                </div>
                <ResizableTable<Order>
                    data={orders?.items}
                    columns={columns}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    error={error}
                    defaultSortingState={defaultSortingState}
                    onSortChange={onSortChange}
                    pagination={pagination}
                    emptyContent={
                        <Empty
                            className="mb-32 text-taupe"
                            image={<Truck className="font-28 mt-56" />}
                            description="Aucun résultat"
                        />
                    }
                    enableColumnResizing
                />
            </div>
        </div>
    );
};

export default Orders;

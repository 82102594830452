import { memo, useCallback, VFC } from 'react';
import { Card, Tooltip, Pagination, PaginationProps, Empty } from 'antd';
import { SortingState } from '@tanstack/react-table';

import useQueryParams from '../../hooks/queryParams';
import { Alert, AlertStatus, SortOrder } from '../../queries/api/types';
import { useAlertDetails, useAlertList } from '../../queries/alerts';
import { formatDate } from '../../helpers/i18n';
import { isDeviceUsingLowColor } from '../../helpers';
import AlertStatusTag from '../../components/AlertStatusTag';
import { useAlertTypeList } from '../../queries/alertTypes';
import constants from '../../config/constants';
import NoData from '../../components/NoData';
import { useLayout } from '../../context/LayoutContext';
import ResizableTable, { ResizableTableColumn } from '../../components/ResizableTable';
import { Alarm } from '../../components/icons';

const AlertDetailsHistory: VFC = memo(() => {
    const { organizationId } = useLayout();
    const [queryParams, setQueryParams] = useQueryParams('alerts');
    const alertId = queryParams.get('alertId') ?? undefined;
    const deviceId = queryParams.get('deviceId') ?? undefined;
    const page = queryParams.get('historyPage') !== null ? parseInt(queryParams.get('historyPage')!, 10) || 0 : 0;
    const pageSize =
        queryParams.get('historyPageSize') !== null
            ? parseInt(queryParams.get('historyPageSize')!, 10) || constants.PAGE_SIZE
            : constants.PAGE_SIZE;
    const sort = queryParams.get('historySort') ?? 'alertSortableProperties.dateFromBody';
    const sortOrder = queryParams.get('historySortOrder') ?? SortOrder.desc;
    const { data: alertTypeList, isLoading: isLoadingAlertTypes } = useAlertTypeList();
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                historySort: sortingState[0]?.id,
                historySortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
        setQueryParams({
            historyPage: (page ?? 1) - 1,
            historyPageSize: pageSize,
        });
    };
    const alertTypeIdsToIgnore = (
        [
            alertTypeList?.items.find((alertType) => alertType.reference === 'DCC')?.id,
            alertTypeList?.items.find((alertType) => alertType.reference === 'ECR')?.id,
            alertTypeList?.items.find((alertType) => alertType.reference === 'CNO')?.id,
            alertTypeList?.items.find((alertType) => alertType.reference === 'DAC')?.id,
        ] as string[]
    ).filter(Boolean);

    const {
        data: alerts,
        isLoading: isLoadingAlerts,
        isError: isErrorAlerts,
        error: errorAlerts,
        isFetching,
    } = useAlertList(
        {
            status: [
                AlertStatus.received,
                AlertStatus.inProgress,
                AlertStatus.treated,
                AlertStatus.registered,
                AlertStatus.pending,
            ],
            devices: deviceId,
            ignoreAlertTypeIds: alertTypeIdsToIgnore,
            organization: organizationId,
            sort,
            sortOrder,
            page,
            pageSize,
        },
        {
            enabled: alertId !== undefined && alertTypeIdsToIgnore.length === 4,
        }
    );
    const { data: alert } = useAlertDetails(alertId, { enabled: !!alertId });
    const isUsingLowColor = isDeviceUsingLowColor(alert?.device);

    const isLoading = isLoadingAlertTypes || isLoadingAlerts;

    const tableColumns: Array<ResizableTableColumn<Alert>> = [
        {
            id: 'alertSortableProperties.dateFromBody',
            accessorFn: (info: Alert) => info.alertSortableProperties?.dateFromBody,
            header: 'Date & heure',
            cell: (info) => {
                return formatDate(info.getValue?.() as Date, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                });
            },
            size: 140,
            enableSorting: true,
        },
        {
            accessorKey: 'object',
            header: 'Objet',
            cell: (info) =>
                info.getValue() ? (
                    <Tooltip title={info.getValue() as string} placement="topLeft">
                        {info.getValue<string>()}
                    </Tooltip>
                ) : (
                    '-'
                ),
            size: 186,
            ellipsis: true,
            enableSorting: true,
        },
        {
            id: 'errorCode',
            accessorFn: (info) => info.errorCode?.reference,
            header: 'Panne',
            cell: (info) => info.getValue() ?? <NoData />,
            size: 70,
        },
        {
            id: 'status',
            header: 'Statut',
            accessorFn: (info) => info,
            cell: (info) => {
                return <AlertStatusTag alert={info.getValue() as Alert} isList />;
            },
            size: 146,
        },
        {
            id: 'operator',
            header: 'Opérateur',
            accessorFn: (info) => info.operator?.username,
            cell: (info) => info.getValue() ?? <NoData />,
            size: 165,
        },
        {
            accessorKey: 'treatedAt',
            header: 'Traitement',
            cell: (info) =>
                formatDate(info.getValue() as Date, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
            size: 156,
        },
    ];

    const hasPagination = (alerts?.pageCount ?? 0) > 1;

    return (
        <Card
            title="Dernières alertes machine"
            bodyStyle={{
                padding: 0,
                margin: 0,
                height: isUsingLowColor ? 422 : 378,
            }}
            className="flex-1"
            actions={
                hasPagination
                    ? [
                          <div key="pagination" className="flex justify-end p-8">
                              <Pagination
                                  total={alerts?.totalCount}
                                  current={page + 1}
                                  pageSize={alerts?.pageSize ?? 50}
                                  onChange={onPaginationChange}
                                  size="small"
                              />
                          </div>,
                      ]
                    : undefined
            }
        >
            <ResizableTable<Alert>
                data={alerts?.items}
                columns={tableColumns}
                isLoading={isLoading || isFetching}
                isError={isErrorAlerts}
                error={errorAlerts}
                onSortChange={onSortChange}
                className="alert-details-history"
                emptyContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={<Alarm className="font-28 mt-56" />}
                        description={alertId ? 'Aucun résultat' : 'Aucune alerte sélectionnée'}
                    />
                }
                enableColumnResizing
                isFullHeight
            />
        </Card>
    );
});

export default AlertDetailsHistory;

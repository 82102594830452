export default Object.freeze({
    PAGE_SIZE: 100,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL || '/api',
        KEY: process.env.REACT_APP_API_KEY || '',
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Kairos',
        DEFAULT_TITLE: 'Kairos',
        DEFAULT_DESCRIPTION: 'Kairos',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    VERSION: process.env.REACT_APP_VERSION || '',
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '',
    LINKS: Object.freeze({
        ARTIS: 'https://toshibatectfis.sharepoint.com/sites/informatique/artis/SitePages/Accueil.aspx',
        CONNECT: 'https://connect.toshibatec.fr/',
    }),
});

import { Table } from '@tanstack/react-table';
import { Empty } from 'antd';

import ResizableTableCell from './ResizableTableCell';
import { classNames } from '../../helpers';
import { ResizableTableDefaultError, ResizableTableProps } from './ResizableTable';
import ApiResult from '../ApiResult';

interface ResizableTableBodyProps<TData, TError extends ResizableTableDefaultError = ResizableTableDefaultError>
    extends Pick<
        ResizableTableProps<TData, TError>,
        | 'isError'
        | 'error'
        | 'errorContent'
        | 'emptyContent'
        | 'onRowHighlightChange'
        | 'isRowHighlighted'
        | 'rowClassName'
    > {
    table: Table<TData>;
    dataLength?: number;
    columnCount: number;
    availableWidth: number;
}

const ResizableTableBody = <
    TData extends unknown,
    TError extends ResizableTableDefaultError = ResizableTableDefaultError
>({
    table,
    dataLength,
    emptyContent,
    columnCount,
    availableWidth,
    isError,
    error,
    errorContent,
    isRowHighlighted,
    onRowHighlightChange,
    rowClassName,
}: ResizableTableBodyProps<TData, TError>) => {
    if (isError) {
        return (
            <tbody>
                <tr>
                    <td colSpan={columnCount}>
                        <div className="sticky left-0 overflow-hidden" style={{ width: `${availableWidth}px` }}>
                            {errorContent ?? <ApiResult status={error?.response?.status} />}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    if (!dataLength) {
        return (
            <tbody>
                <tr>
                    <td colSpan={columnCount}>
                        <div className="sticky left-0 overflow-hidden" style={{ width: `${availableWidth}px` }}>
                            {emptyContent ?? <Empty />}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody>
            {table.getRowModel().rows.map((row) => (
                <tr
                    key={row.id}
                    className={classNames(
                        row.getIsSelected() && 'ts-tr-selected',
                        isRowHighlighted?.(row.original, row.index) && 'ts-tr-highlighted',
                        rowClassName?.(row.original, row.index)
                    )}
                >
                    {row.getVisibleCells().map((cell, cellIndex) => (
                        <ResizableTableCell<TData>
                            table={table}
                            key={cell.id}
                            cell={cell}
                            index={cellIndex}
                            row={row}
                            onRowHighlightChange={onRowHighlightChange}
                        />
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

export default ResizableTableBody;

import { CSSProperties, forwardRef, ReactNode, Ref, useEffect } from 'react';
import { Button } from 'antd';
import { Transform } from '@dnd-kit/utilities';
import { DraggableSyntheticListeners } from '@dnd-kit/core';

import '../../assets/styles/List.less';

import { Drag } from '../icons';
import { classNames } from '../../helpers';

export interface ListRowProps<RecordType> {
    record: RecordType;
    renderItem: (record: RecordType, index: number) => ReactNode;
    isSortable?: boolean;
    index: number;

    // Sortable list row props
    dragOverlay?: boolean;
    isDragDisabled?: boolean;
    dragging?: boolean;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: CSSProperties;
    transition?: string | null;
    wrapperStyle?: CSSProperties;
    isSuccess?: boolean;
}

function ListRow<RecordType extends Record<string, any>>(
    {
        record,
        renderItem,
        isSortable,

        dragOverlay,
        isDragDisabled,
        dragging,
        fadeIn,
        index,
        listeners,
        sorting,
        style,
        transition,
        transform,
        wrapperStyle,
        ...props
    }: ListRowProps<RecordType>,
    ref: Ref<HTMLLIElement>
) {
    useEffect(() => {
        if (!dragOverlay) {
            return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
            document.body.style.cursor = '';
        };
    }, [dragOverlay]);

    return (
        <li
            {...props}
            className={classNames(
                'list-row',
                fadeIn && 'list-row-fade-in',
                sorting && 'list-row-sorting',
                dragOverlay && 'list-row-drag-overlay',
                dragging && 'list-row-dragging'
            )}
            style={
                {
                    ...wrapperStyle,
                    transition,
                    '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                    '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                    '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                    '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                    '--index': index,
                } as React.CSSProperties
            }
            ref={ref}
        >
            <div
                className={classNames(
                    'list-row-wrapper',
                    dragging && 'list-row-wrapper-dragging',
                    dragOverlay && 'list-row-wrapper-drag-overlay'
                )}
                style={style}
                data-cypress="draggable-item"
            >
                {(!!isSortable || dragOverlay) && (
                    <div className="list-row-handle">
                        <Button type="link" disabled={isDragDisabled} {...listeners}>
                            <Drag />
                        </Button>
                    </div>
                )}
                <div className="list-row-content">{renderItem(record, index)}</div>
            </div>
        </li>
    );
}

export default forwardRef(ListRow);

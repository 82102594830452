import { FC } from 'react';
import { Button, Form, Input, Modal, Radio, Space, Typography } from 'antd';
import { PauseCircleOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';

import { useAlertUpdate } from '../../queries/alerts';
import { Alert, AlertPendingReason, AlertStatus } from '../../queries/api/types';
import { errorMessage, successMessage } from '../../helpers/message';
import { translateAlertPendingReason } from '../../helpers/i18n';
import { useAlert } from '../../context/AlertContext';
import { alertModalId } from '../../App';

interface AlertPendingButtonProps {
    alertId?: Alert['id'];
    alertStatus?: Alert['status'];
}

const AlertPendingButton: FC<AlertPendingButtonProps> = ({ alertId, alertStatus }) => {
    const { alert } = useAlert();
    const id = alertId || alert?.id;
    const status = alertStatus || alert?.status;
    const [pendingForm] = Form.useForm();
    const { mutateAsync: updateAlert } = useAlertUpdate();
    const alertModal = useModal(alertModalId);
    const onPendingModalOk =
        (pendingStatusReason: AlertPendingReason, pendingStatusReasonComment?: string) => async () =>
            await updateAlert(
                { id, status: AlertStatus.pending, pendingStatusReason, pendingStatusReasonComment },
                {
                    onSuccess: () => {
                        successMessage({ content: 'Alerte mise en attente avec succès' });
                        alertModal.hide();
                    },
                    onError: (error) => {
                        if (error?.response?.status !== 409) {
                            errorMessage({
                                content: `Une erreur est survenue pendant la mise en attente (${
                                    error?.response?.status ?? 0
                                })`,
                            });
                        }
                    },
                }
            );
    const onClickPending = () => {
        pendingForm.resetFields();
        const pendingModal = Modal.confirm({
            width: 480,
            centered: true,
            title: 'Mettre en attente l’alerte',
            icon: <PauseCircleOutlined className="text-warning" />,
            content: (
                <>
                    <Typography.Paragraph>
                        Veuillez choisir ci-dessous un motif de mise en attente :
                    </Typography.Paragraph>
                    <Form
                        form={pendingForm}
                        onValuesChange={(changedValues, values) =>
                            pendingModal.update({
                                onOk: onPendingModalOk(values.reason, values.comment),
                            })
                        }
                        initialValues={{
                            reason: AlertPendingReason.accountingAlert,
                        }}
                    >
                        <Form.Item name="reason">
                            <Radio.Group>
                                <Space direction="vertical">
                                    {Object.values(AlertPendingReason).map((reason) => (
                                        <Radio value={reason} key={reason}>
                                            {translateAlertPendingReason(reason)}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Commentaire (facultatif)"
                            name="comment"
                            labelCol={{ span: 24 }}
                            className="mb-0"
                        >
                            <Input placeholder="Saisir un commentaire" />
                        </Form.Item>
                    </Form>
                </>
            ),
            okText: 'Mettre en attente',
            onOk: onPendingModalOk(AlertPendingReason.accountingAlert),
        });
    };
    return (
        <Button
            className="btn-warning"
            icon={<PauseCircleOutlined />}
            onClick={onClickPending}
            disabled={!status || [AlertStatus.pending, AlertStatus.treated, AlertStatus.registered].includes(status)}
        >
            Mettre en attente
        </Button>
    );
};

export default AlertPendingButton;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75 1.577c0 .595-.448 1.077-1 1.077s-1-.482-1-1.077S1.198.5 1.75.5s1 .482 1 1.077Zm-1 4.577c.552 0 1-.482 1-1.077S2.302 4 1.75 4s-1 .482-1 1.077.448 1.077 1 1.077Zm0 3.5c.552 0 1-.482 1-1.077S2.302 7.5 1.75 7.5s-1 .482-1 1.077.448 1.077 1 1.077ZM7.25 1.577c0 .595-.448 1.077-1 1.077s-1-.482-1-1.077S5.698.5 6.25.5s1 .482 1 1.077Zm-1 4.577c.552 0 1-.482 1-1.077S6.802 4 6.25 4s-1 .482-1 1.077.448 1.077 1 1.077Zm0 3.5c.552 0 1-.482 1-1.077S6.802 7.5 6.25 7.5s-1 .482-1 1.077.448 1.077 1 1.077Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgDragHandle: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-drag-handle', props.className)} />
));
export default SvgDragHandle;

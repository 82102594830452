/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 12.5a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1ZM10 15.5a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8ZM5 12.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1ZM6 15.5a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2H6Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 5a2.5 2.5 0 0 1 2.5-2.5h17A2.5 2.5 0 0 1 23 5v15a2.5 2.5 0 0 1-2.5 2.5h-17A2.5 2.5 0 0 1 1 20V5Zm2 0a.5.5 0 0 1 .5-.5h17a.5.5 0 0 1 .5.5v1.5H3V5Zm18 3.5V20a.5.5 0 0 1-.5.5h-17A.5.5 0 0 1 3 20V8.5h18Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgListView: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-list-view', props.className)} />
));
export default SvgListView;

import { Typography, Button, Card, Form, Input } from 'antd';
import { useCallback, useMemo, useState, VFC } from 'react';
import { FileExcelOutlined, LineChartOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { show } from '@ebay/nice-modal-react';
import { SortingState } from '@tanstack/react-table';

import { defaultErrorMessageWithStatus, formatNumber } from '../../helpers/i18n';
import { Device, FileGenerationRequestSlug, RoleName } from '../../queries/api/types';
import useQueryParams from '../../hooks/queryParams';
import { useLayout } from '../../context/LayoutContext';
import DevicesList from '../../components/DevicesList';
import Seo from '../../components/Seo';
import { DocSearchTwo, Printer } from '../../components/icons';
import { deviceDrawerId } from '../../App';
import { getRoute, RoutePathName } from '../../routes';
import { useDeviceExport } from '../../queries/devices';
import { errorMessage, successMessage } from '../../helpers/message';
import constants from '../../config/constants';
import { useAuth } from '../../context/AuthContext';
import { hasRole } from '../../helpers/security';
import { ResizableTableProps } from '../../components/ResizableTable';

const Devices: VFC = () => {
    const { organizationId } = useLayout();
    const { user } = useAuth();
    const [queryParams, setQueryParams] = useQueryParams('Devices');
    const [selectedDevice, setSelectedDevice] = useState<Device>();
    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const pageSize =
        queryParams.get('pageSize') !== null
            ? parseInt(queryParams.get('pageSize')!, 10) || constants.PAGE_SIZE
            : constants.PAGE_SIZE;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const deviceSerial = queryParams.get('deviceSerial') ?? undefined;
    const [deviceValue, setDeviceValue] = useState<string | undefined>(deviceSerial);
    const { isLoading: isExporting } = useDeviceExport(
        {
            organization: organizationId,
            serials: deviceSerial ? [deviceSerial] : undefined,
            notified: true,
            fileGenerationRequestSlug: FileGenerationRequestSlug.devices,
        },
        {
            enabled: isExportEnabled,
            onSettled: () => {
                setIsExportEnabled(false);
            },
            onError: (error) => {
                if (error?.response?.status === 400 && error?.response?.data?.fileGenerationRequestInProgressId) {
                    errorMessage({
                        content: "Une demande d'export est déjà en cours, veuillez patienter quelques instants",
                    });
                } else {
                    errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                }
            },
            onSuccess: () => {
                successMessage({
                    content: "Demande d'export envoyée avec succès, vous recevrez le fichier par e-mail",
                });
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );
    const deviceQueryPayload = useMemo(
        () => ({
            organization: organizationId,
            serials: deviceSerial ? [deviceSerial] : undefined,
            notified: true,
        }),
        [organizationId, deviceSerial]
    );
    const onClickExport = () => {
        setIsExportEnabled(true);
    };
    const deviceRenderHeader = useCallback(
        (devices) => (
            <div className="flex justify-between items-center">
                <Typography.Title level={2} className="mb-0">
                    {formatNumber(devices?.totalCount)} machine{devices?.totalCount === 1 ? '' : 's'} trouvée
                    {devices?.totalCount === 1 ? '' : 's'}
                </Typography.Title>
                <div className="space-x-2">
                    <Button
                        icon={<UserOutlined className="text-primary" />}
                        disabled={!selectedDevice}
                        loading={isExporting}
                        onClick={async () =>
                            await show(deviceDrawerId, {
                                id: selectedDevice?.id,
                                tab: 'artis',
                            })
                        }
                    >
                        Fiche parc
                    </Button>
                    <Link
                        to={{
                            pathname: getRoute(RoutePathName.alertHistory),
                            search: new URLSearchParams({ deviceSerial: selectedDevice?.serial ?? '' }).toString(),
                        }}
                    >
                        <Button icon={<DocSearchTwo className="text-primary" />} disabled={!selectedDevice?.serial}>
                            Historique alertes
                        </Button>
                    </Link>
                    <Link
                        to={{
                            pathname: getRoute(RoutePathName.alertStats),
                            search: new URLSearchParams({ deviceSerial: selectedDevice?.serial ?? '' }).toString(),
                        }}
                    >
                        <Button
                            icon={<LineChartOutlined className="text-primary" />}
                            disabled={!selectedDevice?.serial}
                        >
                            Compteurs
                        </Button>
                    </Link>
                </div>
            </div>
        ),
        [isExporting, selectedDevice]
    );
    const onSubmit = () => {
        setQueryParams({
            deviceSerial: deviceValue ?? undefined,
            sort: 'orderedAt',
            sortOrder: 'desc',
        });
    };
    const onReset = () => {
        setQueryParams({
            deviceSerial: undefined,
            sort: undefined,
            sortOrder: undefined,
            page: undefined,
        });
        setDeviceValue(undefined);
    };

    const pagination = useMemo<ResizableTableProps<Device>['pagination']>(
        () => ({
            current: page + 1,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            pageSize: pageSize,
            showSizeChanger: false,
        }),
        [page, pageSize, setQueryParams]
    );
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const isRowHighlighted = useCallback<(row: Device, index: number) => boolean>(
        (row) => row.id === selectedDevice?.id,
        [selectedDevice?.id]
    );

    return (
        <div className="flex flex-col min-h-full">
            <Seo title="Parc machines en notification" />
            <div className="-mt-16 -mx-16 p-16">
                <div className="flex justify-between">
                    <Typography.Title className="mb-24">Parc machines en notification</Typography.Title>
                    {!hasRole(user, [RoleName.sav]) && (
                        <Button
                            icon={<FileExcelOutlined className="text-primary" />}
                            onClick={onClickExport}
                            loading={isExporting}
                        >
                            Excel
                        </Button>
                    )}
                </div>
                <Card>
                    <div className="flex space-x-3">
                        <Form.Item label="N° de série de la machine" className="inline mb-0">
                            <Input
                                placeholder="Saisir un n° de série"
                                prefix={<Printer />}
                                onChange={(e) => setDeviceValue(e.target.value)}
                                value={deviceValue}
                            />
                        </Form.Item>
                        <Button type="primary" onClick={onSubmit}>
                            Rechercher
                        </Button>
                        <Button onClick={onReset}>Effacer</Button>
                    </div>
                </Card>
            </div>
            <div className="bg-grey-darker -mx-16 -mb-16 mt-16 p-16 space-y-4 flex-auto">
                <DevicesList
                    sort={sort}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    pagination={pagination}
                    queryPayload={deviceQueryPayload}
                    renderHeader={deviceRenderHeader}
                    selectedDeviceId={selectedDevice?.id}
                    maxHeight="calc(100vh - 343px)"
                    onRowHighlightChange={setSelectedDevice}
                    isRowHighlighted={isRowHighlighted}
                    enableColumnResizing
                />
            </div>
        </div>
    );
};

export default Devices;

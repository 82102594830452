import { Button, DrawerProps, Form, Tabs } from 'antd';
import { antdDrawer, useModal, create as createModal } from '@ebay/nice-modal-react';
import { useCallback, useState } from 'react';

import CustomDrawer from '../CustomDrawer';
import { Device } from '../../queries/api/types';
import DeviceDrawerArtis from './DeviceDrawerArtis';
import DeviceDrawerDevice from './DeviceDrawerDevice';

interface DeviceDrawerProps extends DrawerProps, Record<string, unknown> {
    id?: Device['id'];
    serial?: Device['serial'];
    tab?: 'artis' | 'device';
}

const DeviceDrawer = createModal<DeviceDrawerProps>(({ tab, ...props }) => {
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const [activeTab, setActiveTab] = useState<string | undefined>(tab);
    const [form] = Form.useForm();
    const modal = useModal();
    const onClose = useCallback(() => {
        modal.hide();
    }, [modal]);
    const renderFooter = () => {
        if (activeTab === 'device') {
            return (
                <div className="flex gap-16">
                    <Button size="large" onClick={() => form.resetFields()} disabled={!hasFormChanged} block>
                        Annuler
                    </Button>
                    <Button size="large" type="primary" onClick={() => form.submit()} disabled={!hasFormChanged} block>
                        Mettre à jour la machine
                    </Button>
                </div>
            );
        }

        return undefined;
    };

    return (
        <CustomDrawer
            width={1080}
            title="Informations matériel"
            {...props}
            {...antdDrawer(modal)}
            onClose={onClose}
            footer={renderFooter()}
        >
            <Tabs activeKey={activeTab} onChange={setActiveTab} className="-mx-16">
                <Tabs.TabPane tab="Fiche Parc" key="artis" className="px-16">
                    <DeviceDrawerArtis {...props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Fiche Machine" key="device" className="px-16">
                    <DeviceDrawerDevice
                        form={form}
                        setHasFormChanged={setHasFormChanged}
                        onCloseDrawer={onClose}
                        {...props}
                    />
                </Tabs.TabPane>
            </Tabs>
        </CustomDrawer>
    );
});

export default DeviceDrawer;

import { useMemo, VFC } from 'react';
import { Empty, Spin, Typography } from 'antd';
import { PieChart, PieChartProps } from '@ezeedev/react-charts';

import { formatNumber, translateTonerColor } from '../../helpers/i18n';
import { Color, Counter, Device, MonthlyAverageCounter, Subscription } from '../../queries/api/types';
import {
    classNames,
    getCounterColorPercentage,
    isDevice,
    isDeviceUsingColors,
    isDeviceUsingErasableBlue,
    isDeviceUsingLowColor,
    isNotUndefinedNorNull,
} from '../../helpers';

interface Datum {
    label: string;
    value: number;
    total?: number;
    monthlyAverageCounter?: number;
    color: string;
    backgroundColor: string;
}

const valueAccessor = (d: Datum) => d.value;
const labelValueAccessor = (d: Datum) => formatNumber(d.value / 100, { style: 'percent' });
const labelTitleAccessor = (d: Datum) => translateTonerColor(d.label as Color);

interface ColorUsagePieChartProps extends Partial<PieChartProps<Datum>> {
    height?: number;
    record?: Device | Subscription;
    isLoading?: boolean;
    legendPosition?: 'top' | 'left';
    small?: boolean;
}

const ColorUsagePieChart: VFC<ColorUsagePieChartProps> = ({
    height,
    record,
    isLoading,
    theme,
    legendPosition = 'top',
    small,
    ...props
}) => {
    const pieData = useMemo(
        () => {
            let counter: Counter['counter'];
            let monthlyAverageCounter: MonthlyAverageCounter | undefined;

            // check if the record is a Device or a Subscription
            if (isDevice(record)) {
                counter = record?.lastCounter?.counter;
                monthlyAverageCounter = record?.monthlyAverageCounter;
            } else {
                counter = record?.metrics?.counter;
                monthlyAverageCounter = record?.metrics?.monthlyAverageCounter;
            }

            const hasBlack = !!counter?.black || isNotUndefinedNorNull(monthlyAverageCounter?.black);

            const hasColor =
                (isDevice(record) &&
                    isDeviceUsingColors(record) &&
                    (!!counter?.color || isNotUndefinedNorNull(monthlyAverageCounter?.color))) ||
                (!isDevice(record) && (!!counter?.color || isNotUndefinedNorNull(monthlyAverageCounter?.color)));

            const hasErasableBlue =
                (isDevice(record) &&
                    isDeviceUsingErasableBlue(record) &&
                    (!!counter?.erasableBlue || isNotUndefinedNorNull(monthlyAverageCounter?.erasableBlue))) ||
                (!isDevice(record) &&
                    (!!counter?.erasableBlue || isNotUndefinedNorNull(monthlyAverageCounter?.erasableBlue)));

            const hasLowColor =
                (isDevice(record) &&
                    isDeviceUsingLowColor(record) &&
                    (!!counter?.lowColor || isNotUndefinedNorNull(monthlyAverageCounter?.lowColor))) ||
                (!isDevice(record) && (!!counter?.lowColor || isNotUndefinedNorNull(monthlyAverageCounter?.lowColor)));

            const data = [
                hasColor && {
                    label: 'colors',
                    value: getCounterColorPercentage(counter, 'color'),
                    total: counter?.color,
                    monthlyAverageCounter: monthlyAverageCounter?.color,
                    color: '#E61E1E',
                    backgroundColor: '#FFF1F0',
                },
                hasBlack && {
                    label: 'black',
                    value: getCounterColorPercentage(counter, 'black'),
                    total: counter?.black,
                    monthlyAverageCounter: monthlyAverageCounter?.black,
                    color: '#4D4D4D',
                    backgroundColor: '#F0F0F0',
                },
                hasLowColor && {
                    label: 'lowColor',
                    value: getCounterColorPercentage(counter, 'lowColor'),
                    total: counter?.lowColor,
                    monthlyAverageCounter: monthlyAverageCounter?.lowColor,
                    color: '#000091',
                    backgroundColor: '#EBEBFF',
                },
                hasErasableBlue && {
                    label: 'erasableBlue',
                    value: getCounterColorPercentage(counter, 'erasableBlue'),
                    total: counter?.erasableBlue,
                    monthlyAverageCounter: monthlyAverageCounter?.erasableBlue,
                    color: '#2F54EB',
                    backgroundColor: '#F0F5FF',
                },
            ].filter(Boolean) as Datum[];

            return data.length ? data : undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record?.id]
    );

    return (
        <>
            <Typography.Title level={3} className="text-taupe font-12 font-bold leading-4.5">
                Utilisation du noir et de la couleur
            </Typography.Title>
            <div className={classNames(legendPosition === 'left' ? 'flex gap-16' : '')}>
                <div
                    className={classNames('flex gap-8', legendPosition === 'top' ? 'mb-16' : 'flex-col justify-center')}
                >
                    {pieData
                        ?.filter(
                            (datum) =>
                                isNotUndefinedNorNull(datum.total) || isNotUndefinedNorNull(datum.monthlyAverageCounter)
                        )
                        .map((datum) => (
                            <div
                                className={classNames(
                                    'rounded gap-8',
                                    small ? 'py-4 px-8' : 'py-8 px-12',
                                    legendPosition === 'left' ? 'flex' : 'flex-col flex-1'
                                )}
                                style={{ backgroundColor: datum.backgroundColor }}
                                key={datum.label}
                            >
                                <Typography.Paragraph
                                    className={classNames(
                                        'flex items-center font-12 font-bold gap-8',
                                        legendPosition === 'left' ? 'flex-row-reverse justify-end mb-0' : 'flex'
                                    )}
                                    style={{ width: 76 }}
                                >
                                    {labelTitleAccessor(datum)}{' '}
                                    <span
                                        className={classNames('inline-block w-12 h-12 rounded')}
                                        style={{ backgroundColor: datum.color }}
                                        aria-hidden
                                    />
                                </Typography.Paragraph>
                                <table className={classNames(small && 'leading-3')}>
                                    <tbody>
                                        {isNotUndefinedNorNull(datum.total) && (
                                            <tr>
                                                <th className="font-12 text-dark-grey font-medium px-4">Total</th>
                                                <td className="font-12 font-bold px-4">{formatNumber(datum.total)}</td>
                                            </tr>
                                        )}
                                        {isNotUndefinedNorNull(datum.monthlyAverageCounter) && (
                                            <tr>
                                                <th className="font-12 text-dark-grey font-medium px-4">VMM</th>
                                                <td className="font-12 font-bold px-4">
                                                    {formatNumber(datum.monthlyAverageCounter)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                </div>
                <div className={classNames(legendPosition === 'left' && 'flex-1')} style={{ height }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center w-full h-full">
                            <Spin />
                        </div>
                    ) : pieData ? (
                        <PieChart
                            {...props}
                            data={pieData}
                            valueAccessor={valueAccessor}
                            labelValueAccessor={labelValueAccessor}
                            labelTitleAccessor={labelTitleAccessor}
                            showLabelTitle={false}
                            theme={{
                                margin: theme?.margin || {
                                    left: 112,
                                    top: 16,
                                    right: 112,
                                    bottom: 0,
                                },
                                pie: {
                                    valueLabel: {
                                        fill: '#fff',
                                    },
                                    titleLabel: {
                                        fill: '#010101',
                                        fontWeight: 700,
                                        tickStrokeColor: '#E9E2E2',
                                    },
                                },
                            }}
                        />
                    ) : (
                        <Empty className="text-taupe" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </div>
        </>
    );
};

export default ColorUsagePieChart;

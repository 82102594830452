import { FC } from 'react';
import { Button, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';

import { useAlertUpdate } from '../../queries/alerts';
import { Alert, AlertStatus } from '../../queries/api/types';
import { errorMessage, successMessage } from '../../helpers/message';
import { useAlert } from '../../context/AlertContext';
import { alertModalId } from '../../App';

interface AlertIgnoreButtonProps {
    alertId?: Alert['id'];
    alertStatus?: Alert['status'];
}

const AlertIgnoreButton: FC<AlertIgnoreButtonProps> = ({ alertId, alertStatus }) => {
    const { alert } = useAlert();
    const id = alertId || alert?.id;
    const status = alertStatus || alert?.status;
    const { mutateAsync: updateAlert } = useAlertUpdate();
    const alertModal = useModal(alertModalId);
    const onClickIgnore = () => {
        Modal.confirm({
            width: 410,
            centered: true,
            title: 'Ignorer l’alerte',
            icon: <CloseCircleOutlined className="text-red" />,
            content: 'Êtes-vous sûr de vouloir ignorer cette alerte ?',
            okText: 'Ignorer',
            onOk: async () =>
                await updateAlert(
                    { id, status: AlertStatus.ignored },
                    {
                        onSuccess: () => {
                            successMessage({ content: 'Alerte ignorée avec succès' });
                            alertModal.hide();
                        },
                        onError: (error) => {
                            if (error?.response?.status !== 409) {
                                errorMessage({
                                    content: `Une erreur est survenue pendant la mise à jour (${
                                        error?.response?.status ?? 0
                                    })`,
                                });
                            }
                        },
                    }
                ),
        });
    };
    return (
        <Button
            type="primary"
            danger
            icon={<CloseCircleOutlined />}
            onClick={onClickIgnore}
            disabled={!status || [AlertStatus.ignored, AlertStatus.treated, AlertStatus.registered].includes(status)}
        >
            Ignorer
        </Button>
    );
};

export default AlertIgnoreButton;

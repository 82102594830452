import { ReactNode, useState, VFC } from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { errorMessage } from '../../helpers/message';
import { AlertType } from '../../queries/api/types';
import { useAlertTypeList } from '../../queries/alertTypes';
import { AlertTypeListPayload } from '../../queries/api/alertTypes';
import { defaultErrorMessage } from '../../helpers/i18n';
import { useDebounce } from '../../hooks';
import { classNames } from '../../helpers';

interface AlertTypeFormItemProps extends FormItemProps {
    selectProps?: SelectProps<AlertType['id']>;
    queryPayload?: AlertTypeListPayload;
    optionLabelRender?: (record: AlertType) => ReactNode;
    optionFilter?: (record: AlertType) => boolean;
    customOptions?: DefaultOptionType[];
    isWhite?: boolean;
}

const AlertTypeFormItem: VFC<AlertTypeFormItemProps> = ({
    selectProps,
    queryPayload,
    optionLabelRender = (record) => record.name,
    optionFilter = () => true,
    customOptions,
    isWhite,
    ...props
}) => {
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: alertTypes, isLoading } = useAlertTypeList(
        { pageSize: 20, search: debouncedSearch || undefined, ...queryPayload },
        {
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    return (
        <Form.Item {...props}>
            <Select
                {...selectProps}
                placeholder={selectProps?.placeholder ?? 'Choisir un type'}
                className={classNames(isWhite && 'select-white', selectProps?.className)}
                filterOption={false}
                loading={isLoading}
                options={(
                    alertTypes?.items?.filter(optionFilter).map((alertType) => ({
                        label: optionLabelRender(alertType),
                        value: alertType.id,
                    })) as unknown as DefaultOptionType
                )?.concat(customOptions ?? [])}
                onSearch={setSearch}
                defaultActiveFirstOption
                showSearch
            />
        </Form.Item>
    );
};

export default AlertTypeFormItem;

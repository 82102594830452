import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
    ColumnDef as ReactTableColumnDef,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    ColumnSizingState,
    SortingState,
    useReactTable,
    CoreOptions,
    Row,
} from '@tanstack/react-table';
import { Pagination, PaginationProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useElementSize } from 'usehooks-ts';
import { AxiosError } from 'axios';

import '../../assets/styles/ResizableTable.less';

import { classNames, debug } from '../../helpers';
import ResizableTableHeader from './ResizableTableHeader';
import ResizableTableBody from './ResizableTableBody';
import useScrollInfo from '../../hooks/useScrollInfo';
import { addColumnResizing, addRowSelectionColumn, selectedRowKeysToObject } from './helpers';
import { scrollTableToTop } from '.';

const defaultPageSize = 10;
let resizeOnChangeTimeout: number;

export type ResizableTableDefaultError = AxiosError<any> | null;

export interface ResizableTablePaginationProps extends PaginationProps {
    /**
     * Client side pagination handled by react-table
     */
    isAuto?: boolean;
    /**
     * Renders Antd Pagination component inside the table footer.
     */
    inTableFooter?: boolean;
    /**
     * Scrolls table to top on pagination change
     */
    isScrollingToTopOnChange?: boolean;
}

export type ResizableTableColumn<TData> = ReactTableColumnDef<TData>;

export interface ResizableTableProps<TData, TError extends ResizableTableDefaultError = ResizableTableDefaultError> {
    /**
     * Array of items to display in the table
     */
    data?: TData[];
    /**
     * Array of table columns
     */
    columns: Array<ResizableTableColumn<TData>>;
    /**
     * Key of a row (id of row in react-table)
     */
    rowKey?: string | CoreOptions<TData>['getRowId'];
    /**
     * Displays a spinner over the table
     */
    isLoading?: boolean;
    /**
     * Displays an <ApiResult /> with error response status or 500
     */
    isError?: boolean;
    /**
     * Axios API error
     */
    error?: TError;
    /**
     * Customize table empty state content
     */
    errorContent?: ReactNode;
    /**
     * Enables column resizing. If true will add resizing to all columns.
     * Will omit columns with a set `column.enableResizing` value
     */
    enableColumnResizing?: boolean;
    /**
     * Callback called after resizing a column (300ms after end of resizing)
     */
    onColumnResizeEnd?: (columnSizingState: ColumnSizingState) => void;
    /**
     * Initial sorting state value
     */
    defaultSortingState?: SortingState;
    /**
     * Callback called when sorting has changed
     */
    onSortChange?: (sortingState: SortingState) => void;
    /**
     * Client side sorting handled by react-table
     */
    enableAutoSorting?: boolean;
    /**
     * Pagination props
     */
    pagination?: ResizableTablePaginationProps;
    /**
     * Controlled value of row selection using row keys
     */
    selectedRowKeys?: string[];
    /**
     * Enable/disable selection for a specific row, it will enable/disable the selection checkbox
     */
    isRowSelectable?: (original: TData) => boolean;
    /**
     * Called when row selection value changes
     */
    onRowSelectionChange?: (selectedRowKeys?: string[]) => void;
    /**
     * Customize row class name
     */
    rowClassName?: (original: TData, rowIndex: number) => string | undefined;
    /**
     * Enables single row highlighting by clicking on a row. The callback passes the selected row original data
     */
    onRowHighlightChange?: (original: TData, rowIndex: number) => void;
    /**
     * Determines if row is highlighted
     */
    isRowHighlighted?: (original: TData, rowIndex: number) => boolean;
    /**
     * Max height of the table, will add vertical scrollbar if container height is smaller
     */
    maxHeight?: number | string;
    /**
     * Min width of the table, will add horizontal scrollbar if container width is smaller
     */
    minWidth?: number | string;
    /**
     * Sets 100% height to table container
     */
    isFullHeight?: boolean;
    /**
     * Class name set on the container
     */
    className?: string;
    /**
     * Customize table empty state content
     */
    emptyContent?: ReactNode;
    /**
     * Adds a footer to the table. If `pagination.inTableFooter` is true, the footer content will include the pagination,
     * otherwise the pagination will be displayed below the footer.
     */
    footer?: () => ReactNode;
}

const ResizableTable = <TData extends unknown, TError extends AxiosError<any> | null = AxiosError<any> | null>({
    data: dataProp,
    columns: columnsProp,
    rowKey,
    pagination: paginationProp,
    defaultSortingState,
    onSortChange,
    enableAutoSorting,
    enableColumnResizing,
    onColumnResizeEnd,
    onRowHighlightChange,
    isRowHighlighted,
    isRowSelectable,
    selectedRowKeys,
    onRowSelectionChange,
    rowClassName,
    isLoading,
    isError,
    error,
    errorContent,
    maxHeight,
    minWidth,
    isFullHeight,
    className,
    emptyContent,
    footer,
}: ResizableTableProps<TData, TError>) => {
    /* ---- STATE MANAGERS ---- */
    const [sorting, setSorting] = useState<SortingState>(defaultSortingState ?? []);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: paginationProp?.current ?? 0,
        pageSize: paginationProp?.pageSize ?? defaultPageSize,
    });
    const data = useMemo(() => dataProp ?? [], [dataProp]);
    const hasPagination = !!paginationProp;
    const hasRowHighlighting = !!onRowHighlightChange;
    const hasRowSelection = !!onRowSelectionChange;
    const hasFooter = !!footer || !!paginationProp?.inTableFooter;
    const hasSomeRightFixedColumns = useMemo(
        () => columnsProp.some((column) => column.fixed === 'right'),
        [columnsProp]
    );
    const hasSomeLeftFixedColumns = useMemo(() => columnsProp.some((column) => column.fixed === 'left'), [columnsProp]);
    const pageCount = Math.ceil((paginationProp?.total ?? 0) / pagination.pageSize);
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});

    /* ---- SCROLL MANAGEMENT ---- */
    const [scrollInfo, setScrollRef, scrollRef] = useScrollInfo<HTMLDivElement>();
    const scrollX = scrollInfo.x.value ?? 0;
    const scrollXMax = scrollInfo.x.total ?? 0;
    const isXScrolled = scrollX > 0;
    const hasXScroll = scrollXMax > 0;
    const isXMaxScrolled = scrollX > 0 && scrollX >= scrollXMax;
    const hasYScroll = (scrollInfo.y.total ?? 0) > 0;
    const scrollbarWidth = (scrollRef.current?.offsetWidth ?? 0) - (scrollRef.current?.clientWidth ?? 0) - 1;

    const getRowId = useMemo<CoreOptions<TData>['getRowId']>(() => {
        if (!rowKey) {
            return undefined;
        } else if (typeof rowKey === 'string') {
            return (originalRow, index) =>
                (originalRow[rowKey as keyof typeof originalRow] as unknown as string) ?? index;
        } else {
            return rowKey;
        }
    }, [rowKey]);

    /* ----- ROW SELECTION ----- */
    if (process.env.NODE_ENV !== 'production') {
        if (getRowId === undefined && hasRowSelection && !paginationProp?.isAuto) {
            debug.warn(
                '[ResizableTable] you enabled row selection but have manual pagination and did not provide a custom rowKey, thus indexes will be used for selected row keys, if you change page with manual pagination, old rows will be replaced with new rows but with the same keys (index based)'
            );
        }
    }

    const onClickRowSelectionCheckbox = useCallback(
        (rowId: string, checked: boolean) => {
            let newSelection: string[] | undefined;

            if (checked) {
                newSelection = [...(selectedRowKeys ?? []), rowId];
            } else {
                newSelection = selectedRowKeys?.filter((key) => key !== rowId);
            }

            onRowSelectionChange?.(newSelection);
        },
        [onRowSelectionChange, selectedRowKeys]
    );

    const onClickPageSelectionCheckbox = useCallback(
        (checked: boolean, rows: Array<Row<TData>>) => {
            if (checked) {
                onRowSelectionChange?.([...(selectedRowKeys ?? []), ...rows.map((row) => row.id)]);
            } else {
                onRowSelectionChange?.(selectedRowKeys?.filter((key) => !rows.some((row) => row.id === key)));
            }
        },
        [onRowSelectionChange, selectedRowKeys]
    );

    /* ----- SORTING ----- */
    const onTableSortingChange = useCallback(
        (sortingState: SortingState | ((old: SortingState) => SortingState)) => {
            setSorting(sortingState);
            onSortChange?.(typeof sortingState === 'function' ? sortingState(sorting) : sortingState);
        },
        [onSortChange, sorting]
    );

    /* ----- COLUMN RESIZING ----- */
    const onColumnSizingChange = useCallback(
        (columnSizingState: ColumnSizingState | ((old: ColumnSizingState) => ColumnSizingState)) => {
            setColumnSizing(columnSizingState);
            if (resizeOnChangeTimeout) {
                window.clearTimeout(resizeOnChangeTimeout);
            }
            resizeOnChangeTimeout = window.setTimeout(() => {
                onColumnResizeEnd?.(
                    typeof columnSizingState === 'function' ? columnSizingState(columnSizing) : columnSizingState
                );
            }, 300);
        },
        [onColumnResizeEnd, columnSizing]
    );

    const columns = useMemo(() => {
        let newColumns = [...columnsProp];

        if (enableColumnResizing) {
            newColumns = addColumnResizing<TData>(columnsProp);
        }

        if (hasRowSelection) {
            newColumns = addRowSelectionColumn<TData>({
                columns: newColumns,
                dataLength: data.length,
                hasSomeLeftFixedColumns,
                onClickRowSelectionCheckbox,
                onClickPageSelectionCheckbox,
            });
        }

        return newColumns;
    }, [
        columnsProp,
        enableColumnResizing,
        hasRowSelection,
        data.length,
        hasSomeLeftFixedColumns,
        onClickRowSelectionCheckbox,
        onClickPageSelectionCheckbox,
    ]);

    const table = useReactTable<TData>({
        data: data ?? [],
        columns,
        state: { sorting, columnSizing, pagination },
        getCoreRowModel: getCoreRowModel(),
        getRowId,

        getPaginationRowModel: hasPagination ? getPaginationRowModel() : undefined,
        onPaginationChange: setPagination,
        manualPagination: !paginationProp?.isAuto,

        getSortedRowModel: getSortedRowModel(),
        sortDescFirst: false,
        onSortingChange: onTableSortingChange,
        manualSorting: !enableAutoSorting,

        enableRowSelection: isRowSelectable ? (row) => isRowSelectable(row.original) : !!hasRowSelection,
        enableMultiRowSelection: !!hasRowSelection,

        enableColumnResizing,
        onColumnSizingChange,
        columnResizeMode: 'onChange',
    });

    /* ----- PAGINATION ----- */
    if (process.env.NODE_ENV !== 'production') {
        if (!isLoading && paginationProp && !paginationProp?.isAuto && paginationProp.total === undefined) {
            debug.warn('[ResizableTable] you provided non-auto pagination settings without `total`');
        }
    }
    const onAutoPaginationChange = useCallback<(current: number, size: number) => void>(
        (current, pageSize) => {
            scrollTableToTop({
                isScrollingToTopOnChange: paginationProp?.isScrollingToTopOnChange,
                scrollRef,
                hasYScroll,
            });
            table.setPageSize(pageSize);
            table.setPageIndex(current - 1);
        },
        [hasYScroll, paginationProp?.isScrollingToTopOnChange, scrollRef, table]
    );

    const paginationElement = useMemo(() => {
        if (paginationProp && (pageCount ?? 0) > 0 && !(paginationProp.hideOnSinglePage && pageCount === 1)) {
            let props: PaginationProps = {
                ...paginationProp,
                onChange: (page, pageSize) => {
                    scrollTableToTop({
                        isScrollingToTopOnChange: paginationProp?.isScrollingToTopOnChange,
                        scrollRef,
                        hasYScroll,
                    });
                    paginationProp?.onChange?.(page, pageSize);
                },
            };

            if (paginationProp?.isAuto) {
                props = {
                    ...props,
                    current: pagination.pageIndex + 1,
                    pageSize: pagination.pageSize,
                    total: data?.length,
                    onShowSizeChange: onAutoPaginationChange,
                    onChange: onAutoPaginationChange,
                };
            }

            return <Pagination {...props} />;
        }

        return null;
    }, [
        data?.length,
        hasYScroll,
        onAutoPaginationChange,
        pageCount,
        pagination.pageIndex,
        pagination.pageSize,
        paginationProp,
        scrollRef,
    ]);

    const [containerRef, { width: containerWidth }] = useElementSize();
    const availableTableWidth = Math.floor(containerWidth) - 2 - (hasYScroll ? scrollbarWidth : 0); // -2 is border left + right

    // Controlled value of row selection
    useEffect(() => {
        table.setRowSelection(selectedRowKeysToObject(selectedRowKeys));
    }, [selectedRowKeys, table]);

    return (
        <div
            className={classNames(
                'ts-table-container',
                hasFooter && 'ts-table-with-footer',
                hasRowHighlighting && 'ts-table-with-row-highlighting',
                hasYScroll && 'ts-table-with-y-scroll',
                isFullHeight && 'h-full',
                className
            )}
            ref={containerRef}
        >
            <div className={classNames('relative', isLoading && 'opacity-70', isFullHeight && 'h-full')}>
                {isLoading && (
                    <div className="absolute w-full h-full flex items-center justify-center">
                        <LoadingOutlined style={{ fontSize: 24, zIndex: 100, color: '#E61E1E' }} spin />
                    </div>
                )}
                {!hasSomeLeftFixedColumns && (
                    <span
                        aria-hidden="true"
                        className="ts-table-x-scroll-left-shadow"
                        style={{
                            bottom: scrollbarWidth,
                            ...(!isXScrolled ? { boxShadow: 'none' } : {}),
                        }}
                    />
                )}
                {!hasSomeRightFixedColumns && (
                    <span
                        aria-hidden="true"
                        className="ts-table-x-scroll-right-shadow"
                        style={{
                            transform: hasXScroll ? `translateX(-${scrollbarWidth}px)` : undefined,
                            bottom: scrollbarWidth,
                            ...(!hasXScroll || (hasXScroll && isXMaxScrolled) ? { boxShadow: 'none' } : {}),
                        }}
                    />
                )}
                <div
                    style={{
                        maxHeight,
                    }}
                    className={classNames(
                        'ts-table-wrapper relative overflow-auto',
                        hasXScroll && 'x-scroll',
                        isXScrolled && 'x-scrolled',
                        isXMaxScrolled && 'x-scrolled-max',
                        isFullHeight && 'h-full'
                    )}
                    ref={setScrollRef}
                >
                    <table
                        style={{
                            width: availableTableWidth,
                            minWidth,
                        }}
                    >
                        <ResizableTableHeader table={table} sorting={sorting} />
                        <ResizableTableBody<TData, TError>
                            table={table}
                            dataLength={data?.length}
                            emptyContent={emptyContent}
                            isError={isError}
                            error={error}
                            errorContent={errorContent}
                            columnCount={columns?.length ?? 0}
                            availableWidth={availableTableWidth}
                            onRowHighlightChange={onRowHighlightChange}
                            isRowHighlighted={isRowHighlighted}
                            rowClassName={rowClassName}
                        />
                    </table>
                </div>
            </div>
            {hasFooter && (
                <div className={classNames('ts-table-footer', !footer && 'only-pagination')}>
                    {paginationProp?.inTableFooter ? (
                        <div className="flex justify-between items-center">
                            {footer?.()}
                            {paginationElement}
                        </div>
                    ) : (
                        footer?.()
                    )}
                </div>
            )}
            {!paginationProp?.inTableFooter && <div className="flex justify-end py-8">{paginationElement}</div>}
        </div>
    );
};

export default ResizableTable;

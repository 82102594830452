/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.498 2.844H5.962c-1.975 0-3.392 1.87-2.76 3.743 1.264 3.745 2.963 7.063 5.178 9.277 2.214 2.215 5.532 3.914 9.277 5.178 1.874.632 3.744-.784 3.744-2.76v-2.536a2 2 0 0 0-1.029-1.748l-2.21-1.229-.16-.08a2 2 0 0 0-1.706.04l-2.021 1.009-.048-.015a5.985 5.985 0 0 1-1.74-.899 5.317 5.317 0 0 1-1.073-1.075 6.033 6.033 0 0 1-.803-1.472l-.095-.268-.016-.05 1.01-2.017a2 2 0 0 0-.04-1.866l-1.223-2.203a2 2 0 0 0-1.749-1.03Zm-2.536 2h2.536l1.223 2.203-1.178 2.357a1 1 0 0 0-.087.638c.147.76.553 1.83 1.356 2.904a7.333 7.333 0 0 0 1.476 1.478c1.076.808 2.15 1.213 2.91 1.359a1 1 0 0 0 .636-.088l2.357-1.178 2.21 1.229v2.536c0 .633-.553 1.051-1.105.865-3.478-1.174-6.556-2.75-8.502-4.697-1.946-1.946-3.523-5.024-4.697-8.502-.186-.552.232-1.104.865-1.104Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgPhoneTelephone: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-phone-telephone', props.className)} />
));
export default SvgPhoneTelephone;

import { memo } from 'react';
import { Cell, flexRender, Row, Table } from '@tanstack/react-table';

import { classNames } from '../../helpers';
import { getCellFixedSettings } from './helpers';
import { ResizableTableDefaultError, ResizableTableProps } from './ResizableTable';

interface ResizableTableCellProps<TData, TError extends ResizableTableDefaultError = ResizableTableDefaultError>
    extends Pick<ResizableTableProps<TData, TError>, 'onRowHighlightChange'> {
    table: Table<TData>;
    cell: Cell<TData, unknown>;
    index: number;
    row: Row<TData>;
}

const ResizableTableCell = <
    TData extends unknown,
    TError extends ResizableTableDefaultError = ResizableTableDefaultError
>({
    cell,
    index,
    row,
    onRowHighlightChange,
}: ResizableTableCellProps<TData, TError>) => {
    const fixedSettings = getCellFixedSettings<TData>(cell, index);
    const hasRowHighlighting = !!onRowHighlightChange;
    return (
        // using onClick on td is bad practice accessibility wise, but the current design for row highlighting forces us to do so.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <td
            onClick={
                hasRowHighlighting
                    ? () => {
                          onRowHighlightChange?.(row.original, row.index);
                      }
                    : undefined
            }
            className={classNames(
                fixedSettings.isFixedLeft && 'fixed-left',
                fixedSettings.isLastLeftFixed && 'fixed-left-last',
                fixedSettings.isFixedRight && 'fixed-right',
                fixedSettings.isFirstRightFixed && 'fixed-right-first',
                cell.column.columnDef.ellipsis && 'ellipsis',
                cell.column.columnDef.meta?.isSelectionColumn && 'ts-td-selection'
            )}
            style={{
                width: cell.column.getSize(),
                maxWidth: cell.column.columnDef.ellipsis ? cell.column.getSize() || undefined : undefined,
                left: fixedSettings.left,
                right: fixedSettings.right,
            }}
        >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    );
};

export default memo(ResizableTableCell, (prevProps, nextProps) => {
    const prevFixedSettings = getCellFixedSettings<unknown>(prevProps.cell, prevProps.index);
    const nextFixedSettings = getCellFixedSettings<unknown>(nextProps.cell, nextProps.index);
    const hasLeftChanged = prevFixedSettings.left !== nextFixedSettings.left;

    if (nextProps.cell.column.id === 'row-selection') {
        return nextProps.row.getIsSelected() !== prevProps.row.getIsSelected() || hasLeftChanged;
    } else {
        if (
            nextProps.row.getIsSelected() !== prevProps.row.getIsSelected() ||
            prevProps.row.original !== nextProps.row.original ||
            hasLeftChanged
        ) {
            return false;
        }
        return true;
    }
}) as typeof ResizableTableCell; // cast needed to use a generic with memo, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087

// export default ResizableTableCell;

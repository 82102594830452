import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { reporting } from './api/nationalReportings';

export const nationalReportingsKeys = {
    all: ['nationalReportings'],
};

export const useNationalReporting = (options?: UseMutationOptions<undefined, AxiosError>) => {
    return useMutation<undefined, AxiosError>(async () => await reporting(), options);
};

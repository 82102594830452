import { Divider, Menu, Typography } from 'antd';
import { VFC } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

import { ListView, WaterLevel } from '../../components/icons';
import { getRoute, RoutePathName } from '../../routes';
import AlertsTonerLevels from './AlertsTonerLevels';
import AlertsWrapper from './AlertsWrapper';

const Alerts: VFC = () => {
    const location = useLocation();

    return (
        <>
            <div className="flex items-center -mt-8">
                <Typography.Title className="mb-0 font-bold">Visualisation des alertes</Typography.Title>
                <Divider type="vertical" className="h-24 mx-24" />
                <Menu selectedKeys={[location.pathname]} mode="horizontal" disabledOverflow>
                    <Menu.Item key={getRoute(RoutePathName.alerts)} icon={<ListView />}>
                        <Link to={getRoute(RoutePathName.alerts)}>Synthèse des alertes</Link>
                    </Menu.Item>
                    <Menu.Item
                        key={getRoute(RoutePathName.alerts, { param: 'surveillance-niveaux-toner' })}
                        icon={<WaterLevel />}
                    >
                        <Link to={getRoute(RoutePathName.alerts, { param: 'surveillance-niveaux-toner' })}>
                            Surveillance des niveaux de Toner
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>
            <Divider className="-mx-16 w-auto mt-8 mb-16" />
            <Route path={getRoute(RoutePathName.alerts)} exact>
                <AlertsWrapper />
            </Route>
            <Route path={getRoute(RoutePathName.alerts, { param: 'surveillance-niveaux-toner' })} exact>
                <AlertsTonerLevels />
            </Route>
        </>
    );
};

export default Alerts;

import { useEffect, useRef, useState, useCallback, MutableRefObject, LegacyRef, useMemo } from 'react';

// Edge has a bug where scrollHeight is 1px bigger than clientHeight when there's no scroll.
const isEdge = typeof navigator !== 'undefined' && /Edge\/\d./i.test(window.navigator.userAgent);

// Small hook to use ResizeOberver if available. This fixes some issues when the component is resized.
// This needs a polyfill to work on all browsers. The polyfill is not included in order to keep the package light.
function useResizeObserver(ref: MutableRefObject<Element | null>, callback: (rect: DOMRectReadOnly) => void) {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                // Wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    callback(entries[0].contentRect);
                });
            });

            if (ref.current) {
                resizeObserver.observe(ref.current);
            }

            return () => {
                resizeObserver.disconnect();
            };
        }

        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

function throttle(func: () => void, wait: number) {
    let context: unknown, args: any, result: unknown;
    let timeout: NodeJS.Timeout | null = null;
    let previous = 0;
    const later = function () {
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) {
            context = args = null;
        }
    };
    return function (this: any) {
        const now = Date.now();
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) {
                context = args = null;
            }
        } else if (!timeout) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}

type ScrollInfos = {
    percentage?: number | null;
    value?: number;
    total?: number;
    direction?: number;
};

type ScrollInfo = {
    x: ScrollInfos;
    y: ScrollInfos;
};

function useScrollInfo<TElement extends Element>(
    throttleTime = 50
): [scrollInfo: ScrollInfo, setRef: LegacyRef<TElement> | undefined, ref: MutableRefObject<TElement | null>] {
    const [scroll, setScroll] = useState<ScrollInfo>({ x: {}, y: {} });
    const ref = useRef<TElement | null>(null);
    const previousScroll = useRef<ScrollInfo | null>(null);

    useResizeObserver(ref, () => {
        update();
    });

    const update = useCallback(() => {
        const element = ref.current;
        if (element) {
            let maxY = element.scrollHeight - element.clientHeight;
            const maxX = element.scrollWidth - element.clientWidth;

            // Edge has a bug where scrollHeight is 1px bigger than clientHeight when there's no scroll.
            if (isEdge && maxY === 1 && element.scrollTop === 0) {
                maxY = 0;
            }

            const percentageY = maxY !== 0 ? element.scrollTop / maxY : null;
            const percentageX = maxX !== 0 ? element.scrollLeft / maxX : null;

            const previous = previousScroll.current;

            const scrollInfo = {
                x: {
                    percentage: percentageX,
                    value: element?.scrollLeft,
                    total: maxX,
                    direction: previous && element ? Math.sign(element.scrollLeft - (previous?.x.value ?? 0)) : 0,
                },
                y: {
                    percentage: percentageY,
                    value: element?.scrollTop,
                    total: maxY,
                    direction: previous && element ? Math.sign(element.scrollTop - (previous?.y.value ?? 0)) : 0,
                },
            };
            previousScroll.current = scrollInfo;
            setScroll(scrollInfo);
        }
    }, []);

    const throttledUpdate = useMemo(() => throttle(update, throttleTime), [throttleTime, update]);

    const setRef = useCallback(
        (node) => {
            if (node) {
                // When the ref is first set (after mounting)
                node.addEventListener('scroll', throttledUpdate);
                if (!window.ResizeObserver) {
                    window.addEventListener('resize', throttledUpdate); // Fallback if ResizeObserver is not available
                }
                ref.current = node;
                throttledUpdate(); // initialization
            } else if (ref.current) {
                // When unmounting
                ref.current.removeEventListener('scroll', throttledUpdate);
                if (!window.ResizeObserver) {
                    window.removeEventListener('resize', throttledUpdate);
                }
            }
        },
        [throttledUpdate]
    );

    return [scroll, setRef, ref];
}

export default useScrollInfo;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.636 1.587a6.475 6.475 0 0 1 4.85 1.928 6.476 6.476 0 0 1 1.927 4.85c-.05 1.579-.658 3.073-1.574 3.989l-7.07 7.07-.708-.706-.707-.707 7.07-7.071c.5-.498.953-1.48.99-2.64a4.476 4.476 0 0 0-1.343-3.37 4.476 4.476 0 0 0-3.37-1.344c-1.161.038-2.142.491-2.64.99l-9.546 9.546c-.177.177-.526.749-.595 1.504-.063.697.112 1.548.949 2.385.837.837 1.687 1.012 2.384.948.756-.068 1.328-.418 1.505-.595l9.192-9.192c.148-.148.34-.52.372-.995.03-.448-.092-.847-.372-1.126-.28-.28-.678-.403-1.127-.373-.474.032-.846.224-.994.373L8.11 13.768l-1.414-1.414 6.718-6.718c.558-.558 1.423-.896 2.275-.953.877-.059 1.893.172 2.674.953.781.782 1.012 1.797.954 2.674-.057.852-.395 1.717-.954 2.276L9.172 19.78c-.53.53-1.55 1.064-2.738 1.172-1.248.114-2.695-.242-3.98-1.526C1.17 18.14.814 16.693.928 15.445c.108-1.189.643-2.207 1.173-2.738l9.546-9.546c.916-.916 2.41-1.523 3.99-1.574ZM6.697 12.354a1 1 0 1 0 1.414 1.414l-1.414-1.414Zm5.657 7.07a1 1 0 0 1 0-1.413l.707.707.707.707a1 1 0 0 1-1.414 0Z"
            fill="currentColor"
            fillOpacity={0.85}
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgPaperclip: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-paperclip', props.className)} />
));
export default SvgPaperclip;

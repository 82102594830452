import { createContext, useState, FC, useMemo, useContext, Dispatch, SetStateAction, useEffect } from 'react';

import { hasRole } from '../helpers/security';
import { usePrevious } from '../hooks';
import { Organization, RoleName } from '../queries/api/types';
import { useAuth } from './AuthContext';

interface LayoutContextTypes {
    organizationId?: Organization['id'];
    setOrganizationId: Dispatch<SetStateAction<Organization['id'] | undefined>>;
}

export const LayoutContext = createContext<LayoutContextTypes | null>(null);
LayoutContext.displayName = 'LayoutContext';

export const LayoutProvider: FC = ({ children }) => {
    const { user } = useAuth();
    const isAdmin = hasRole(user, [RoleName.admin, RoleName.dsc]);
    const [organizationId, setOrganizationId] = useState<Organization['id'] | undefined>(
        isAdmin ? user?.organization?.id : undefined
    );
    const previousOrgId = usePrevious(user?.organization?.id);

    useEffect(() => {
        // set organizationId value for admin if not already set && after receiving user info
        if (isAdmin && !organizationId && user?.organization?.id && !previousOrgId) {
            setOrganizationId(user?.organization?.id);
        }
    }, [isAdmin, organizationId, previousOrgId, user?.organization?.id]);

    const layoutContext = useMemo(
        () => ({
            organizationId,
            setOrganizationId,
        }),
        [organizationId, setOrganizationId]
    );

    return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => {
    const context = useContext(LayoutContext);

    if (!context) {
        throw new Error('useLayout must be used within an LayoutProvider');
    }

    return context;
};

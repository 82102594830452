import { FC } from 'react';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';

interface DeletePopConfirmProps extends Omit<PopconfirmProps, 'title'> {
    title?: PopconfirmProps['title'];
}

const DeletePopConfirm: FC<DeletePopConfirmProps> = ({ children, ...props }) => (
    <Popconfirm
        title="Confirmez-vous la suppression ?"
        okText="Confirmer"
        cancelText="Annuler"
        okButtonProps={{
            size: 'small',
            danger: true,
        }}
        cancelButtonProps={{
            size: 'small',
        }}
        {...props}
    >
        {children ?? 'Supprimer'}
    </Popconfirm>
);

export default DeletePopConfirm;

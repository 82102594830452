import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { AlertType, ListResponse } from './types';

// Controller Interface

export type AlertTypeIdPayload = AlertType['id'];

export type AlertTypeListPayload = SearchPaginationQuery & {
    registeredMail?: 'false';
};
export type AlertTypeListResponse = ListResponse<AlertType>;

// Routes
export const list = async (payload?: AlertTypeListPayload) => {
    return await api.get<AlertTypeListResponse>(`/alertTypes`, { params: payload }).then((response) => response?.data);
};

export const details = async (id?: AlertTypeIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<AlertType>(`/alertTypes/${id}`).then((response) => response?.data);
};

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.375 30H32l-.055-17.823C31.872 5.6 28.532 2 22.15 2H3.767v3.471l17.482.014c.914.005 1.826.096 2.724.272 2.337.465 3.518 2.076 4.135 4.595l-18.41.092c-5.846.061-9.611 3.713-9.696 9.29-.098 6.549 4.287 10.253 9.773 10.226l12.791-.052v-3.555l-13.27.083c-3.264.027-5.648-2.795-5.648-6.065 0-3.765 2.265-6.219 5.826-6.232l18.901-.21"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgArtis: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-artis', props.className)} />
));
export default SvgArtis;

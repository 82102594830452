import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    MailTemplateCreatePayload,
    MailTemplateIdPayload,
    MailTemplateListPayload,
    MailTemplateListResponse,
    MailTemplateUpdatePayload,
    remove,
    update,
} from './api/mailTemplates';
import { MailTemplate } from './api/types';

export const mailTemplatesKeys = {
    all: ['mailTemplates'],
    lists: () => [...mailTemplatesKeys.all, 'list'],
    list: (params?: MailTemplateListPayload) => [...mailTemplatesKeys.lists(), params],
    details: () => [...mailTemplatesKeys.all, 'details'],
    detail: (id?: MailTemplateIdPayload) => [...mailTemplatesKeys.details(), id],
};

export const useMailTemplateList = <TData = MailTemplateListResponse>(
    params?: MailTemplateListPayload,
    options?: UseQueryOptions<MailTemplateListResponse, AxiosError, TData>
) => {
    return useQuery<MailTemplateListResponse, AxiosError, TData>(
        mailTemplatesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useMailTemplateDetails = <TData = MailTemplate>(
    id?: MailTemplateIdPayload,
    options?: UseQueryOptions<MailTemplate, AxiosError, TData>
) => {
    return useQuery<MailTemplate, AxiosError, TData>(
        mailTemplatesKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useMailTemplateCreate = (
    options?: UseMutationOptions<MailTemplate, AxiosError, MailTemplateCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<MailTemplate, AxiosError, MailTemplateCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(mailTemplatesKeys.lists());
        },
    });
};

export const useMailTemplateUpdate = (
    options?: UseMutationOptions<MailTemplate, AxiosError, MailTemplateUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<MailTemplate, AxiosError, MailTemplateUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(mailTemplatesKeys.detail(variables.id));
        },
    });
};

export const useMailTemplateRemove = (options?: UseMutationOptions<undefined, AxiosError, MailTemplateIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, MailTemplateIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(mailTemplatesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(mailTemplatesKeys.lists());
        },
    });
};

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Order, OrderStatus, ListResponse, Device, Organization } from './types';

// Controller Interface

export interface OrderUpdatePayload extends Omit<Order, 'id'> {
    id?: Order['id'];
}
export interface OrderCountPayload {
    id?: Order['id'];
    lastDateOfMonth?: string;
    organization?: Organization['id'];
}

export type OrderIdPayload = Order['id'];

export type OrderListPayload = SearchPaginationQuery & {
    status?: OrderStatus;
    deviceSerial?: Device['serial'];
    organization?: Organization['id'];
    orderedAt?: string;
};
export type OrderListResponse = ListResponse<Order>;
export interface OrderCountResponse {
    items: Array<{
        date: string;
        count: number;
    }>;
}

// Routes
export const list = async (payload?: OrderListPayload) => {
    return await api.get<OrderListResponse>(`/orders`, { params: payload }).then((response) => response?.data);
};
export const count = async (payload?: OrderCountPayload) => {
    return await api.get<OrderCountResponse>(`/orders/count`, { params: payload }).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: OrderUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Order>(`/orders/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: OrderIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Order>(`/orders/${id}`).then((response) => response?.data);
};

export const remove = async (id?: OrderIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/orders/${id}`).then((response) => response?.data);
};

import {
    DndContext,
    DropAnimation,
    KeyboardSensor,
    Modifiers,
    MouseSensor,
    PointerActivationConstraint,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
    closestCenter,
    defaultDropAnimation,
    DragOverlay,
} from '@dnd-kit/core';
import {
    AnimateLayoutChanges,
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Empty, Skeleton } from 'antd';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import '../../assets/styles/List.less';

import { classNames } from '../../helpers';
import BasicList from '../BasicList';
import { ListProps } from './List';
import ListRow from './ListRow';
import SortableListRow from './SortableListRow';

const defaultDropAnimationConfig: DropAnimation = {
    ...defaultDropAnimation,
    dragSourceOpacity: 0.5,
};

export interface SortableListColumn<RecordType> {
    dataIndex?: string;
    key?: string;
    render?: (record: RecordType) => ReactNode;
    hidden?: (record: RecordType) => boolean;
    title?: ReactNode;
    style?: CSSProperties;
    cellStyle?: CSSProperties;
    flex: string;
}

interface SortableListProps<RecordType> extends ListProps<RecordType> {
    activationConstraint?: PointerActivationConstraint;
    animateLayoutChanges?: AnimateLayoutChanges;
    adjustScale?: boolean;
    dropAnimation?: DropAnimation | null;
    itemCount?: number;
    items?: string[];
    modifiers?: Modifiers;
    useDragOverlay?: boolean;
    getItemStyles?: (args: {
        id: UniqueIdentifier;
        index: number;
        isSorting: boolean;
        isDragOverlay: boolean;
        overIndex: number;
        isDragging: boolean;
    }) => CSSProperties;
    wrapperStyle?: (args: { index: number; isDragging: boolean; id: string }) => CSSProperties;
    isDragDisabled?: (item: RecordType) => boolean;
    onOrderChange: (items: RecordType[]) => void;
}

function SortableList<RecordType extends Record<string, any> & { id: string }>({
    data,
    className,
    renderItem,
    rowKey,
    isLoading,
    loadingRowsCount = 4,
    emptyDescription,

    activationConstraint,
    animateLayoutChanges,
    adjustScale = false,
    dropAnimation = defaultDropAnimationConfig,
    getItemStyles = () => ({}),
    modifiers,
    useDragOverlay = true,
    wrapperStyle = () => ({}),
    isDragDisabled,
    onOrderChange,
    ...props
}: SortableListProps<RecordType>) {
    const [items, setItems] = useState<RecordType[]>(data ?? []);
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint,
        }),
        useSensor(TouchSensor, {
            activationConstraint,
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const activeIndex = activeId ? items.findIndex((item) => item.id === activeId) : -1;

    useEffect(() => {
        if (data) {
            setItems(data);
        }
    }, [data]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({ active }) => {
                if (!active) {
                    return;
                }

                setActiveId(active.id);
            }}
            onDragEnd={({ over }) => {
                setActiveId(null);

                if (over) {
                    const overIndex = items.findIndex((item) => item.id === over.id);
                    if (activeIndex !== overIndex) {
                        const newItems = arrayMove(items, activeIndex, overIndex);
                        onOrderChange(newItems);
                        setItems(newItems);
                    }
                }
            }}
            onDragCancel={() => setActiveId(null)}
            modifiers={modifiers}
        >
            <div className={classNames('list-wrapper', className)} {...props}>
                {isLoading && (
                    <BasicList>
                        {Array.from({ length: loadingRowsCount }, (_, index) => (
                            <li key={index}>
                                <Skeleton paragraph={false} title active />
                            </li>
                        ))}
                    </BasicList>
                )}
                {!isLoading && !data?.length && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />
                )}
                {!isLoading && !!data?.length && (
                    <>
                        <SortableContext
                            items={
                                items as unknown as Array<{
                                    id: UniqueIdentifier;
                                }>
                            }
                            strategy={verticalListSortingStrategy}
                        >
                            <BasicList className="list">
                                {items.map((record) => (
                                    <SortableListRow<RecordType>
                                        {...props}
                                        record={record}
                                        renderItem={renderItem}
                                        index={record.index}
                                        key={rowKey(record, record.index)}
                                        id={record.id}
                                        style={getItemStyles}
                                        wrapperStyle={wrapperStyle}
                                        animateLayoutChanges={animateLayoutChanges}
                                        useDragOverlay={useDragOverlay}
                                        isDragDisabled={isDragDisabled?.(record)}
                                    />
                                ))}
                            </BasicList>
                        </SortableContext>
                        {createPortal(
                            <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
                                {activeId !== null && activeIndex !== -1 ? (
                                    <ListRow<RecordType>
                                        record={items[activeIndex]}
                                        renderItem={renderItem}
                                        index={-1}
                                        wrapperStyle={wrapperStyle({
                                            index: activeIndex,
                                            isDragging: true,
                                            id: items[activeIndex].id,
                                        })}
                                        style={getItemStyles({
                                            id: items[activeIndex].id,
                                            index: activeIndex,
                                            isSorting: activeId !== null,
                                            isDragging: true,
                                            overIndex: -1,
                                            isDragOverlay: true,
                                        })}
                                        dragOverlay
                                    />
                                ) : null}
                            </DragOverlay>,
                            document.body
                        )}
                    </>
                )}
            </div>
        </DndContext>
    );
}

export default SortableList;

import { Button, Empty, Input, Typography, DrawerProps, Select } from 'antd';
import { antdDrawer, create, useModal } from '@ebay/nice-modal-react';
import { useCallback, useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { SortingState } from '@tanstack/react-table';

import CustomDrawer from '../CustomDrawer';
import { Device, Organization, Subscription } from '../../queries/api/types';
import { useDebounce } from '../../hooks';
import NoData from '../NoData';
import { devicesKeys, useDeviceList } from '../../queries/devices';
import { useSubscriptionUpdate } from '../../queries/subscriptions';
import { errorMessage, successMessage } from '../../helpers/message';
import { formatNumber } from '../../helpers/i18n';
import ResizableTable, { ResizableTableColumn, ResizableTableProps } from '../ResizableTable';

interface SubscriptionLinkDevicesDrawerProps extends DrawerProps, Record<string, unknown> {
    subscriptionId?: Subscription['id'];
    organizationId?: Organization['id'];
}

const SubscriptionLinkDevicesDrawer = create<SubscriptionLinkDevicesDrawerProps>((props) => {
    const { subscriptionId, organizationId } = props;
    const queryClient = useQueryClient();
    const modal = useModal();
    const [selectValue, setSelectValue] = useState<'serial' | 'domain'>('serial');
    const [page, setPage] = useState<number>();
    const [sort, setSort] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>();
    const [selectedDeviceIds, setSelectedDeviceIds] = useState<Array<Device['id']>>();
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const {
        data: devices,
        isLoading,
        isError,
        error,
    } = useDeviceList(
        {
            page,
            sort,
            sortOrder,
            organization: organizationId,
            [selectValue === 'serial' ? 'serials' : 'search']: debouncedSearch
                ? selectValue === 'serial'
                    ? [debouncedSearch]
                    : debouncedSearch
                : undefined,
        },
        { enabled: !!debouncedSearch, refetchOnWindowFocus: false }
    );
    const { mutateAsync: updateSubscription, isLoading: isUpdatingSubscription } = useSubscriptionUpdate();
    const onSubmit = () => {
        const isMultiple = (selectedDeviceIds?.length ?? 0) > 1;

        updateSubscription(
            {
                id: subscriptionId,
                devices: selectedDeviceIds,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: `${isMultiple ? 'Machines associées' : 'Machine associée'} avec succès`,
                    });
                    queryClient.invalidateQueries(devicesKeys.lists());
                    setSelectedDeviceIds(undefined);
                    modal.resolve();
                    modal.hide();
                },
                onError: (error) => {
                    errorMessage({
                        content: `Une erreur est survenue pendant l'association ${
                            isMultiple ? 'des machines' : 'de la machine'
                        } (${error?.response?.status ?? 0})`,
                    });
                },
            }
        );
    };
    const onSortChange = useCallback((sortingState: SortingState) => {
        setSort(sortingState[0]?.id);
        setSortOrder(sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined);
    }, []);
    const pagination = useMemo<ResizableTableProps<Device>['pagination']>(
        () => ({
            current: (page ?? 0) + 1,
            total: devices?.totalCount ?? 0,
            onChange: (current) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setPage(newPage);
            },
            size: 'small',
            pageSize: devices?.pageSize ?? 50,
            showSizeChanger: false,
        }),
        [devices?.pageSize, devices?.totalCount, page]
    );
    const columns = useMemo<Array<ResizableTableColumn<Device>>>(
        () => [
            {
                id: 'serial',
                header: 'N° de série',
                accessorKey: 'serial',
                cell: (info) => info.getValue<Device['serial']>() || <NoData />,
                enableSorting: true,
                fixed: 'left',
            },
            {
                id: 'customerReference',
                header: 'Code client',
                accessorFn: (row) => row,
                cell: (info) =>
                    info.getValue<Device>().hardCodedDeviceInfo?.customer?.reference ||
                    info.getValue<Device>().erpDeviceInfo?.customer?.reference || <NoData />,
            },
            {
                id: 'customerName',
                header: 'Nom client',
                accessorFn: (row) => row,
                cell: (info) =>
                    info.getValue<Device>().erpDeviceInfo?.customer?.name ||
                    info.getValue<Device>().hardCodedDeviceInfo?.customer?.name || <NoData />,
            },
            {
                id: 'model',
                header: 'Matériel',
                accessorFn: (row) => row,
                cell: (info) =>
                    info.getValue<Device>().hardCodedDeviceInfo?.model ||
                    info.getValue<Device>().erpDeviceInfo?.model || <NoData />,
            },
        ],
        []
    );

    return (
        <CustomDrawer
            title="Associer des machines à l’abonnement"
            {...antdDrawer(modal)}
            width={800}
            footer={
                <Button
                    size="large"
                    type="primary"
                    disabled={!selectedDeviceIds?.length}
                    loading={isUpdatingSubscription}
                    onClick={onSubmit}
                    block
                >
                    Associer les machines sélectionnées
                </Button>
            }
        >
            <Typography.Paragraph className="mb-16" strong>
                Recherchez ci-dessous les machines à ajouter :
            </Typography.Paragraph>
            <Input
                onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(0);
                    setSort(undefined);
                    setSortOrder(undefined);
                }}
                addonBefore={
                    <Select value={selectValue} onChange={setSelectValue}>
                        <Select.Option value="serial">N° de série</Select.Option>
                        <Select.Option value="domain">Domaine</Select.Option>
                    </Select>
                }
                placeholder={selectValue === 'serial' ? 'Saisir un numéro' : 'Saisir un domaine'}
                suffix={<SearchOutlined />}
                className="mb-16"
                allowClear
            />
            <Typography.Title level={3}>
                {debouncedSearch ? (
                    <>
                        <span>
                            {(devices?.totalCount ?? 0) > 1 ? formatNumber(devices?.totalCount) : 'Aucune'} machine
                            {(devices?.totalCount ?? 0) > 1 ? 's' : ''} trouvée
                            {(devices?.totalCount ?? 0) > 1 ? 's' : ''}
                        </span>
                        {!!selectedDeviceIds?.length && (
                            <>
                                <span className="ml-4 text-taupe font-semibold">/</span>
                                <span className="ml-4 text-blue font-medium">
                                    {formatNumber(selectedDeviceIds?.length)} sélectionnée
                                    {(selectedDeviceIds?.length ?? 0) > 1 ? 's' : ''}
                                </span>
                            </>
                        )}
                    </>
                ) : (
                    'Aucune machine recherchée'
                )}
            </Typography.Title>
            <ResizableTable<Device>
                rowKey="id"
                columns={columns}
                isLoading={isLoading}
                isError={isError}
                error={error}
                data={devices?.items}
                pagination={pagination}
                maxHeight="calc(100vh - 322px)"
                onSortChange={onSortChange}
                emptyContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Aucun résultat"
                    />
                }
                selectedRowKeys={selectedDeviceIds}
                onRowSelectionChange={setSelectedDeviceIds}
                enableColumnResizing
            />
        </CustomDrawer>
    );
});

export default SubscriptionLinkDevicesDrawer;

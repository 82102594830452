import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Avatar, Menu, Button } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

import { getRoute, RoutePathName } from '../routes';
import { useAuth } from '../context/AuthContext';
import { Logout } from './icons';
import { getFullName } from '../helpers';

const UserDropdown: VFC = () => {
    const { user, logout } = useAuth();
    const menu = (
        <Menu>
            <Menu.Item key="logout" icon={<Logout />}>
                <Link
                    to={getRoute(RoutePathName.login)}
                    onClick={() => {
                        logout.mutate();
                    }}
                >
                    Déconnexion
                </Link>
            </Menu.Item>
        </Menu>
    );

    return user?.firstName ? (
        <Dropdown trigger={['click']} overlay={menu} placement="topRight">
            <Button className="flex items-center h-full" type="text">
                <Avatar size={40} className="mr-12 bg-dust-red text-red" shape="square">
                    {user?.firstName?.substring(0, 1)}
                    {user?.lastName?.substring(0, 1)}
                </Avatar>
                <span className="flex items-center space-x-2">
                    <span className="flex flex-col text-left">
                        <span className="font-medium tracking-wide">{getFullName(user)}</span>
                        {user?.organization && (
                            <span className="font-10 text-taupe uppercase">
                                {user.organization.name} {user.organization.code && `(${user.organization.code})`}
                            </span>
                        )}
                    </span>
                    <CaretDownFilled className="text-taupe font-12" />
                </span>
            </Button>
        </Dropdown>
    ) : null;
};

export default UserDropdown;

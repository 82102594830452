import { UploadFile } from 'antd/lib/upload/interface';

import api from './apiClient';
import { Organization } from './types';

// Controller Interface
export interface CountersUploadPayload {
    file: UploadFile;
    organizationId?: Organization['id'];
}

// Routes
export const upload = async ({ file, organizationId }: CountersUploadPayload) => {
    const formData = new FormData();

    if (file) {
        formData.append('upload', file as unknown as Blob);
    }

    return await api
        .post(`/countersUpload`, formData, { params: { organization: organizationId } })
        .then((response) => response?.data);
};

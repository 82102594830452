import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { upload, CountersUploadPayload } from './api/countersUpload';

export const useCountersUpload = (options?: UseMutationOptions<undefined, AxiosError, CountersUploadPayload>) => {
    return useMutation<undefined, AxiosError, CountersUploadPayload>(async (params) => await upload(params), {
        ...options,
    });
};

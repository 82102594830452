import { Typography, Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useMemo, VFC } from 'react';

import { formatNumber } from '../../helpers/i18n';
import { Device } from '../../queries/api/types';

interface AverageConsumptionStatsSummaryProps {
    device?: Device;
}

const AverageConsumptionStatsSummary: VFC<AverageConsumptionStatsSummaryProps> = ({ device }) => {
    const data = useMemo(
        () => {
            return {
                actual:
                    (device?.lastCounter?.counter?.black ?? 0) +
                    (device?.lastCounter?.counter?.color ?? 0) +
                    (device?.lastCounter?.counter?.lowColor ?? 0) +
                    (device?.lastCounter?.counter?.erasableBlue ?? 0),
                vmm:
                    (device?.monthlyAverageCounter?.black ?? 0) +
                    (device?.monthlyAverageCounter?.color ?? 0) +
                    (device?.monthlyAverageCounter?.lowColor ?? 0) +
                    (device?.monthlyAverageCounter?.erasableBlue ?? 0),
                start: device?.firstPrintingCounter,
                days:
                    device?.lastCommunicationAt && device?.firstCommunicationAt
                        ? dayjs(device.lastCommunicationAt).diff(device.firstCommunicationAt, 'days')
                        : 0,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [device?.id]
    );

    return (
        <>
            <Typography.Title level={3} className="text-taupe font-12 font-bold leading-4.5">
                Consommation moyenne
            </Typography.Title>
            <Descriptions
                size="small"
                column={4}
                className="mb-16"
                labelStyle={{ width: 200, fontSize: '0.75rem', padding: '0.25rem 0.125rem' }}
                contentStyle={{ width: 274, fontSize: '0.75rem', padding: '0.125rem 0.125rem' }}
                title="Résumé"
                bordered
            >
                <Descriptions.Item label="Compteur actuel">{formatNumber(data.actual)}</Descriptions.Item>
                <Descriptions.Item label="VMM">{formatNumber(data.vmm)}</Descriptions.Item>
                <Descriptions.Item label="Compteur départ">{formatNumber(data.start)}</Descriptions.Item>
                <Descriptions.Item label="Jours">{formatNumber(data.days)}</Descriptions.Item>
            </Descriptions>
        </>
    );
};

export default AverageConsumptionStatsSummary;

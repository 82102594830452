import { Button, DrawerProps, Form, Input, Spin, Switch, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import { antdDrawer, create, useModal } from '@ebay/nice-modal-react';
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import CustomDrawer from '../CustomDrawer';
import { errorMessage, successMessage } from '../../helpers/message';
import { requiredRule } from '../../helpers';
import { defaultErrorMessageWithStatus } from '../../helpers/i18n';
import { Subscription } from '../../queries/api/types';
import OrganizationFormItem from '../form/OrganizationFormItem';
import { useSubscriptionCreate, useSubscriptionDetails, useSubscriptionUpdate } from '../../queries/subscriptions';
import { useAuth } from '../../context/AuthContext';

interface SubscriptionsFormDrawerProps extends DrawerProps, Record<string, unknown> {
    subscriptionId?: Subscription['id'];
}

const SubscriptionsFormDrawer = create<SubscriptionsFormDrawerProps>(({ subscriptionId }) => {
    const { user } = useAuth();
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const [form] = Form.useForm();
    const modal = useModal();
    const { mutateAsync: create, isLoading: isCreating } = useSubscriptionCreate();
    const { mutateAsync: update, isLoading: isUpdating } = useSubscriptionUpdate();
    const isEditing = subscriptionId !== undefined;
    const { data: subscriptionDetails, isLoading: isFetching } = useSubscriptionDetails(subscriptionId, {
        enabled: isEditing,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            form.setFieldsValue({
                ...data,
                organization: data.organization.id,
            });
        },
    });
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            update(
                {
                    id: subscriptionId,
                    ...values,
                },
                {
                    onSuccess: () => {
                        successMessage({
                            content: 'Abonnement modifié avec succès',
                        });
                        modal.resolve({ ...values });
                        modal.hide();
                    },
                    onError: (error) => {
                        if (error.response?.status === 409) {
                            const field = Object.keys(error.response?.data?.fields)?.[0];

                            if (field) {
                                form.setFields([
                                    {
                                        name: field,
                                        errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                                    },
                                ]);
                            }
                        } else {
                            errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                        }
                    },
                }
            );
        } else {
            create(values, {
                onSuccess: (data) => {
                    modal.resolve(data);
                    modal.hide();
                },
                onError: (error) => {
                    if (error.response?.status === 409) {
                        const field = Object.keys(error.response?.data?.fields)?.[0];

                        if (field) {
                            form.setFields([
                                {
                                    name: field,
                                    errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                                },
                            ]);
                        }
                    } else {
                        errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                    }
                },
            });
        }
    };

    return (
        <CustomDrawer
            title={`${isEditing ? 'Modifier' : 'Ajouter'} un abonnement`}
            {...antdDrawer(modal)}
            width={600}
            footer={
                <Button
                    type="primary"
                    size="large"
                    loading={isCreating || isUpdating}
                    disabled={!hasFormChanged}
                    onClick={() => form.submit()}
                    block
                >
                    {`${isEditing ? 'Modifier' : 'Ajouter'} l'abonnement`}
                </Button>
            }
        >
            <Spin spinning={isFetching}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={
                        isEditing
                            ? {
                                  ...subscriptionDetails,
                                  organization: subscriptionDetails?.organization.id,
                              }
                            : {
                                  emails: [''],
                                  briefReporting: false,
                                  detailedReporting: false,
                                  xlsReporting: false,
                                  active: false,
                                  organization: user?.organization?.id,
                              }
                    }
                    onReset={() => setHasFormChanged(false)}
                    onChange={() => setHasFormChanged(true)}
                    requiredMark={false}
                    scrollToFirstError
                >
                    <Form.Item name={['name']} label="Nom" rules={[requiredRule]}>
                        <Input placeholder="Saisir un nom" autoComplete="off" />
                    </Form.Item>
                    {isEditing && (
                        <Form.Item name={['reference']} label="Identifiant">
                            <Input disabled />
                        </Form.Item>
                    )}
                    <OrganizationFormItem
                        name="organization"
                        label="Organisation"
                        selectProps={{
                            dropdownMatchSelectWidth: false,
                        }}
                    />
                    <Typography.Title level={4} className="text-taupe font-semibold mb-12">
                        Statut de l&rsquo;abonnement
                    </Typography.Title>
                    <div className="flex items-center gap-8 mb-24">
                        <Form.Item name={['active']} valuePropName="checked" noStyle>
                            <Switch
                                unCheckedChildren={<CloseOutlined className="font-12" />}
                                checkedChildren={<CheckOutlined className="font-12" />}
                                onChange={() => setHasFormChanged(true)}
                            />
                        </Form.Item>
                        <Typography.Text>Abonnement actif</Typography.Text>
                    </div>
                    <Typography.Title level={4} className="text-taupe font-semibold mb-12">
                        Rapports
                    </Typography.Title>
                    <div className="flex items-center gap-8 mb-8">
                        <Form.Item name={['briefReporting']} valuePropName="checked" noStyle>
                            <Switch
                                unCheckedChildren={<CloseOutlined className="font-12" />}
                                checkedChildren={<CheckOutlined className="font-12" />}
                                onChange={() => setHasFormChanged(true)}
                            />
                        </Form.Item>
                        <Typography.Text>Rapport synthétique (Synthèse Parc)</Typography.Text>
                    </div>

                    <div className="flex items-center gap-8 mb-8">
                        <Form.Item name={['detailedReporting']} valuePropName="checked" noStyle>
                            <Switch
                                unCheckedChildren={<CloseOutlined className="font-12" />}
                                checkedChildren={<CheckOutlined className="font-12" />}
                                onChange={() => setHasFormChanged(true)}
                            />
                        </Form.Item>
                        <Typography.Text>Rapport détaillé (État parc)</Typography.Text>
                    </div>

                    <div className="flex items-center gap-8 mb-24">
                        <Form.Item name={['xlsReporting']} valuePropName="checked" noStyle>
                            <Switch
                                unCheckedChildren={<CloseOutlined className="font-12" />}
                                checkedChildren={<CheckOutlined className="font-12" />}
                                onChange={() => setHasFormChanged(true)}
                            />
                        </Form.Item>
                        <Typography.Text>Rapport XLS</Typography.Text>
                    </div>
                    <Typography.Title level={4} className="text-taupe font-semibold mb-12">
                        Destinataires des rapports
                    </Typography.Title>
                    <Form.List name="emails">
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div className="flex gap-8" key={key}>
                                        <Form.Item
                                            {...restField}
                                            name={[name]}
                                            label={`Adresse email du destinataire n°${index + 1}`}
                                            rules={[requiredRule]}
                                            className="flex-1"
                                            key={key}
                                        >
                                            <Input placeholder="Saisir une adresse e-mail" />
                                        </Form.Item>
                                        {index > 0 ? (
                                            <Button
                                                type="text"
                                                icon={<DeleteOutlined className="text-taupe" />}
                                                onClick={() => remove(name)}
                                                style={{ marginTop: 26 }}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                                <Form.Item className="form-list-add-more">
                                    <Button icon={<PlusOutlined className="text-primary" />} onClick={() => add()}>
                                        Ajouter un destinataire
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Spin>
        </CustomDrawer>
    );
});

export default SubscriptionsFormDrawer;

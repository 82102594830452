import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { TableTemplate, ListResponse } from './types';

// Controller Interface

export interface TableTemplateUpdatePayload extends Partial<Omit<TableTemplate, 'id'>> {
    id?: TableTemplate['id'];
}

export type TableTemplateIdPayload = TableTemplate['id'];

export type TableTemplateListPayload = SearchPaginationQuery;
export type TableTemplateListResponse = ListResponse<TableTemplate>;

// Routes
export const list = async (payload?: TableTemplateListPayload) => {
    return await api
        .get<TableTemplateListResponse>(`/tableTemplates`, { params: payload })
        .then((response) => response?.data);
};

export const update = async ({ id, ...payload }: TableTemplateUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<TableTemplate>(`/tableTemplates/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: TableTemplateIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<TableTemplate>(`/tableTemplates/${id}`).then((response) => response?.data);
};

export const remove = async (id?: TableTemplateIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/tableTemplates/${id}`).then((response) => response?.data);
};

export interface ApiInfos {
    name: string;
    version: string;
    env: 'development' | 'production' | 'preprod';
    instance: string;
    request: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export type ObjectId = string;

export enum TechnicalStatus {
    pending = 'pending',
    parsed = 'parsed',
    sourceUnknown = 'sourceUnknown',
    typeUnknown = 'typeUnknown',
    missingFields = 'missingFields',
    deviceUnknown = 'deviceUnknown',
}

export enum DataSource {
    csv = 'csv',
    xml = 'xml',
    body = 'body',
}

export enum Status {
    received = 'received',
    waiting = 'waiting',
    ignored = 'ignored',
    routed = 'routed',
    done = 'done',
    doneArtis = 'doneArtis',
}

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    // es = 'es',
    // du = 'du',
    // it = 'it',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export enum RoleName {
    superAdmin = 'SuperAdmin',
    owner = 'Owner',
    user = 'User',
    grc = 'KAIROS_GRC',
    call = 'KAIROS_CALL',
    sav = 'KAIROS_SAV',
    roc = 'KAIROS_ROC',
    dsc = 'KAIROS_DSC',
    dac = 'KAIROS_DAC',
    adminf = 'KAIROS_ADMF',
    admin = 'KAIROS_ADMIN',
}

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum Permission {
    admin = 'admin',
    alerts = 'alerts',
    alertGroups = 'alertGroups',
    alertGroupOrganizations = 'alertGroupOrganizations',
    alertTypes = 'alertTypes',
    alertTypeOrganizations = 'alertTypeOrganizations',
    devices = 'devices',
    devicesCounters = 'devicesCounters',
    errorCodes = 'errorCodes',
    errorCodeRecommendations = 'errorCodeRecommendations',
    errorCodeUnits = 'errorCodeUnits',
    mailMessages = 'mailMessages',
    mailTemplates = 'mailTemplates',
    orders = 'orders',
    organizations = 'organizations',
    privilegeCodes = 'privilegeCodes',
    roles = 'roles',
    sources = 'sources',
    subscriptions = 'subscriptions',
    staticPages = 'staticPages',
    superAdmin = 'superAdmin',
    tableTemplates = 'tableTemplates',
    users = 'users',
}

export enum RoleScope {
    organizations = 'organizations',
    users = 'users',
}

export enum SignedAction {
    passwordReset = 'passwordReset',
}

export enum EmailTemplate {
    welcome = 'welcome',
    passwordReset = 'passwordReset',
}

// #region  ##################### Project Enum ######################

export enum TableName {
    alertType = 'alertType',
    alert = 'alert',
}

export enum DeviceErrorType {
    none = 'none',
}

export enum FieldType {
    STRING = 'STRING',
    NUMERIC = 'NUMERIC',
    INT64 = 'INT64',
    DATE = 'DATE',
    BOOL = 'BOOL',
}

export enum FieldMode {
    REQUIRED = 'REQUIRED',
    NULLABLE = 'NULLABLE',
}

export type Field = {
    name: string;
    type: FieldType;
    mode: FieldMode;
    subObjectName?: string;
};

export enum DeviceStatus {
    active = 'active',
    silent = 'silent',
}

export enum AlertStatus {
    received = 'received',
    inProgress = 'inProgress',
    pending = 'pending',
    ignored = 'ignored',
    routed = 'routed',
    treated = 'treated',
    registered = 'registered',
}

export enum AlertPendingReason {
    accountingAlert = 'accountingAlert',
    billableCustomer = 'billableCustomer',
    other = 'other',
}

export enum MailMessageStatus {
    none = 'none',
    assigned = 'assigned',
    unassigned = 'unassigned',
    error = 'error',
}

export enum SeverityErrorCode {
    low = 'low',
    medium = 'medium',
    high = 'high',
}

export enum Color {
    black = 'black',
    cyan = 'cyan',
    magenta = 'magenta',
    yellow = 'yellow',
    erasableBlue = 'erasableBlue',
}

export enum TonerMonitoringStatus {
    active = 'active',
    highlight = 'highlight',
    deactivate = 'deactivate',
    silent = 'silent',
}

export enum ERPExportStatus {
    na = 'na',
    pending = 'pending',
    inProgress = 'progress',
    exported = 'exported',
    failed = 'failed',
}

export enum OrderStatus {
    inProgress = 'inProgress',
    shipped = 'shipped',
    partiallyDelivered = 'partiallyDelivered',
    deliveredInTotality = 'deliveredInTotality',
}

export enum MailResponseStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    sended = 'sended',
}

export enum AlertColumnKey {
    reference = 'reference',
    status = 'status',
    organizationReference = 'organizationReference',
    date = 'alertSortableProperties.dateFromBody',
    deviceSerial = 'deviceSerial',
    object = 'object',
    deviceHardCodedDeviceInfoModel = 'deviceHardCodedDeviceInfoModel',
    pendingStatus = 'pendingStatus',
    errorCodeReference = 'errorCodeReference',
    colorReferenceToOrder = 'colorReferenceToOrder',
    deviceHardCodedDeviceInfoCustomerName = 'deviceHardCodedDeviceInfoCustomerName',
    deviceHardCodedDeviceInfoCustomerMail = 'deviceHardCodedDeviceInfoCustomerMail',
    deviceHardCodedDeviceInfoCustomerPhone = 'deviceHardCodedDeviceInfoCustomerPhone',
    deviceHardCodedDeviceInfoCustomerAddressOneLineAddress = 'deviceHardCodedDeviceInfoCustomerAddressOneLineAddress',
    operatorFirstName = 'operatorFirstName',
    treatedAt = 'treatedAt',
}

export enum DeviceColumnKey {
    serial = 'serial',
    organizationCode = 'organizationCode',
    hardCodedDeviceInfoModel = 'hardCodedDeviceInfoModel',
    firstCommunicationAt = 'firstCommunicationAt',
    lastCommunicationAt = 'lastCommunicationAt',
    numberOfCommunication = 'communicationCount',
    instruction = 'instruction',
    lastCounterCounterBlack = 'lastCounterCounterBlack',
    lastCounterCounterColor = 'lastCounterCounterColor',
    erpDeviceInfoLowColorValueEnabled = 'erpDeviceInfoLowColorValueEnabled',
    lastCounterCounterLowColor = 'lastCounterCounterLowColor',
    erpDeviceInfoBlueErasableColorValueEnabled = 'erpDeviceInfoBlueErasableColorValueEnabled',
    lastCounterCounterErasableBlue = 'lastCounterCounterErasableBlue',
    hardCodedDeviceInfoCustomerName = 'hardCodedDeviceInfoCustomerName',
    hardCodedDeviceInfoCustomerMail = 'hardCodedDeviceInfoCustomerMail',
    hardCodedDeviceInfoCustomerPhone = 'hardCodedDeviceInfoCustomerPhone',
    hardCodedDeviceInfoCustomerAddressOneLineAddress = 'hardCodedDeviceInfoCustomerAddressOneLineAddress',
    hardCodedDeviceInfoCustomerMailTicketRecipient = 'hardCodedDeviceInfoCustomerMailTicketRecipient',
    ecc = 'ecc',
    auto = 'auto',
    subscriptionReference = 'subscriptionReference',
    hardCodedDeviceInfoCustomerNameCustomerContractReferences = 'hardCodedDeviceInfoCustomerNameCustomerContractReferences',
    lastExportedErpCounterExportedAt = 'lastExportedErpCounterExportedAt',
    erpDeviceInfoTechnician = 'erpDeviceInfoTechnician',
}

// #endregion

export interface Meta {
    [key: string]: string;
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}

export interface Address {
    id?: ObjectId;
    name?: string;
    oneLineAddress?: string;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    phone?: string;
    email?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export interface Organization {
    id: string;
    slug: string;
    code: string;
    name: string;
    ERPExportDays: number[];
    createdAt: string;
    updatedAt: string;
}

export interface User {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
    acceptedTerms?: boolean;
    acceptedTermsAt?: string;
    avatarUrl?: string;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        // [key in RoleScope]?: ObjectId[];
        [RoleScope.organizations]?: ObjectId[] | Organization[];
        [RoleScope.users]?: ObjectId[] | User[];
    };
    organization?: Organization;
    tableTemplates?: TableTemplate[];
}

export interface Scope {
    agencies: ObjectId[];
}

export interface Role {
    id: ObjectId;
    name: RoleName;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
    organization?: Organization;
}

export enum RoleSlug {}

export interface RemoteFile {
    id: ObjectId;
    url: string;
    filename: string;
    originalName?: string;
    createdAt: string;
    thumbnailUrl?: string;
}

// #region  ##################### Project ApiTypes ######################

export interface DeviceError {
    id: ObjectId;
    reference: string;
    type: DeviceErrorType;
    name: string;
    severity: number;
    recommendation: string;
    createdAt: string;
    updatedAt: string;
}

export interface Subscription {
    id: ObjectId;
    reference: string;
    organization: Organization;
    name: string;
    emails?: string[];
    detailedReporting: boolean;
    briefReporting: boolean;
    xlsReporting: boolean;
    deviceCount: number;
    active: boolean;
    lastSentAt?: string;
    createdAt: string;
    updatedAt: string;
    metrics?: {
        counter: {
            black: number;
            color: number;
            lowColor: number;
            erasableBlue: number;
            copy: number;
            printing: number;
            total: number;
        };
        fax: {
            printing: number;
            transmitted: number;
            received: number;
            total: number;
        };
        scan: {
            black: number;
            color: number;
            total: number;
        };
        monthlyAverageCounter: {
            black: number;
            color: number;
            lowColor: number;
            erasableBlue: number;
            total: number;
        };
        numberOfDevices: number;
    };
}

export interface Technician {
    firstName?: string;
    lastName?: string;
}

export interface Customer {
    reference?: string;
    name?: string;
    mail?: string;
    mailTicketRecipient?: string;
    phone?: string;
    address?: Address;
    contractReferences?: number[];
}

export interface PrivilegeCode {
    id: ObjectId;
    reference: string;
    name?: string;
    alertHighlighting: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface ERPDeviceInfo {
    brand?: string;
    model?: string;
    provider?: Organization;
    maintainer?: Organization;
    customer?: Customer;
    technician?: Technician;
    privilegeCode?: PrivilegeCode;
    countersMedia?: string;
    network?: boolean;
    colorValueEnabled?: boolean;
    lowColorValueEnabled?: boolean;
    blueErasableColorValueEnabled?: boolean;
    installedAt?: string;
    lastIntervention?: string;
    lastDelivery?: string;
    lastTonerYellowDelivery?: string;
    lastTonerCyanDelivery?: string;
    lastTonerMagentaDelivery?: string;
    lastTonerBlackDelivery?: string;
    lastTonerErasableBlueDelivery?: string;
}

export interface HardCodedDeviceInfo {
    brand?: string;
    model?: string;
    customer?: Customer;
}

export interface CounterColor {
    black?: number | null;
    color?: number | null;
    erasableBlue?: number | null;
    twin?: number | null;
    lowColor?: number | null;
    copy?: number | null;
    printing?: number | null;
}

export interface ScanCounter {
    black?: number | null;
    color?: number | null;
}

export interface CounterConsumption {
    black?: number | null;
    color?: number | null;
    erasableBlue?: number | null;
    twin?: number | null;
    lowColor?: number | null;
    copy?: number | null;
    printing?: number | null;
}

export interface Counter {
    counter?: CounterColor;
    fax?: {
        transmitted?: number | null;
        received?: number | null;
        printing?: number | null;
    };
    scan?: ScanCounter;
    consumption?: CounterConsumption;
    updatedAt?: string;
}

export interface MonthlyCounter {
    black?: number | null;
    color?: number | null;
    erasableBlue?: number | null;
    lowColor?: number | null;
    copy?: number | null;
    printing?: number | null;
    lastDateCounterOfTheMonth: string;
    lastDateOfMonth: string;
}

export interface MonthlyAverageCounter {
    black?: number | null;
    color?: number | null;
    erasableBlue?: number | null;
    lowColor?: number | null;
}

export interface TonerMonitoring {
    cyan: TonerMonitoringStatus;
    yellow: TonerMonitoringStatus;
    magenta: TonerMonitoringStatus;
    black: TonerMonitoringStatus;
    erasableBlue: TonerMonitoringStatus;
}

export interface ColorLevel {
    cyan?: number | null;
    yellow?: number | null;
    magenta?: number | null;
    black?: number | null;
    erasableBlue?: number | null;
    bothSides?: number | null;
    a3?: number | null;
    updatedAt?: string;
}

export enum MFPCommunication {
    green = 'green',
    orange = 'orange',
    red = 'red',
}

export enum ECCCommunication {
    green = 'green',
    orange = 'orange',
    red = 'red',
}

export enum FileGenerationRequestSlug {
    alerts = 'alerts',
    devices = 'devices',
    alertHistory = 'alertHistory',
}

export interface Device {
    id: ObjectId;

    organization: Organization;
    serial: string;

    subscription?: Subscription;

    erpDeviceInfo?: ERPDeviceInfo;
    hardCodedDeviceInfo?: HardCodedDeviceInfo;

    status: DeviceStatus;

    colorLevels?: ColorLevel;
    thresholdColorLevels?: ColorLevel;
    tonerMonitoring: TonerMonitoring;

    lastCounter?: Counter;
    lastExportedCounter?: Counter;
    counterERP?: Counter;

    monthlyCounter?: MonthlyCounter[];
    monthlyAverageCounter?: MonthlyAverageCounter;

    sourceReleve?: string;
    exportStatus?: ERPExportStatus;
    exportDate?: string;

    instruction?: string;
    numberOfCommunication?: number;
    firstPrintingCounter?: number;
    firstCommunicationAt?: string;
    lastCommunicationAt?: string;

    erpManaged: boolean;
    auto?: MFPCommunication;
    ecc?: ECCCommunication;

    lastExportedErpCounter?: DeviceERPCounter;
    lastCounterOfEachMonth?: DeviceCounter[];

    dateOfLastToner?: string;

    lastTNEAlert?: LastTNEAlert;

    alerts?: Alert[];

    createdAt: string;
    updatedAt: string;
}

export interface DeviceCounter {
    id: ObjectId;
    device: Device;
    counter?: Counter;
    createdAt: string;
    updatedAt: string;
}

export interface DeviceERPCounter {
    id: ObjectId;
    device: Device;
    counter?: Counter;
    erpExportStatus?: ERPExportStatus;
    exportedAt?: string;
    createdAt: string;
    updatedAt: string;
}

export interface RawDeviceCounter {
    id: ObjectId;
    device: Device;
    counterPrintBlueLargeCopy?: number | null;
    counterPrintBlueSmallCopy?: number | null;
    counterPrintBlueLargePrint?: number | null;
    counterPrintBlueSmallPrint?: number | null;
    counterPrintBlueLargeList?: number | null;
    counterPrintBlueSmallList?: number | null;
    counterPrintBlueLargeFax?: number | null;
    counterPrintBlueSmallFax?: number | null;
    counterPrintBlueLargeReceive?: number | null;
    counterPrintBlueSmallReceive?: number | null;
    counterPrintColorLargeCopy?: number | null;
    counterPrintColorLargePrint?: number | null;
    counterPrintColorSmallCopy?: number | null;
    counterPrintColorSmallPrint?: number | null;
    counterPrintLowLargeCopy?: number | null;
    counterPrintLowLargePrint?: number | null;
    counterPrintLowSmallCopy?: number | null;
    counterPrintLowSmallPrint?: number | null;
    counterPrintTwinLargeCopy?: number | null;
    counterPrintTwinLargePrint?: number | null;
    counterPrintTwinSmallCopy?: number | null;
    counterPrintTwinSmallPrint?: number | null;
    counterPrintBlackLargeCopy?: number | null;
    counterPrintBlackLargePrint?: number | null;
    counterPrintBlackLargeList?: number | null;
    counterPrintBlackLargeFax?: number | null;
    counterPrintBlackSmallCopy?: number | null;
    counterPrintBlackSmallPrint?: number | null;
    counterPrintBlackSmallList?: number | null;
    counterPrintBlackSmallFax?: number | null;
    counterScanColorLargeCopy?: number | null;
    counterScanColorSmallCopy?: number | null;
    counterScanColorLargeNet?: number | null;
    counterScanColorSmallNet?: number | null;
    counterScanLowLargeCopy?: number | null;
    counterScanLowSmallCopy?: number | null;
    counterScanLowLargeNet?: number | null;
    counterScanLowSmallNet?: number | null;
    counterScanTwinLargeCopy?: number | null;
    counterScanTwinSmallCopy?: number | null;
    counterScanBlackLargeCopy?: number | null;
    counterScanBlackSmallCopy?: number | null;
    counterScanBlackLargeNet?: number | null;
    counterScanBlackSmallNet?: number | null;
    counterScanBlackLargeFax?: number | null;
    counterScanBlackSmallFax?: number | null;
    counterFaxBlackLargeTransmit?: number | null;
    counterFaxBlackSmallTransmit?: number | null;
    counterFaxBlackLargeReceive?: number | null;
    counterFaxBlackSmallReceive?: number | null;
    counterOCRBlackTotal?: number | null;
    counterOCRColorTotal?: number | null;
    createdAt: string;
    updatedAt: string;
}

export interface Source {
    id: ObjectId;
    reference: string;
    name?: string;
    createdAt: string;
    updatedAt: string;
}

export interface AlertType {
    id: ObjectId;
    reference: string;
    name: string;
    alertGroup: AlertGroup;
    createdAt: string;
    updatedAt: string;
}

export interface AlertGroup {
    id: ObjectId;
    reference: string;
    alertTypes?: AlertType[];
    createdAt: string;
    updatedAt: string;
}

export interface AlertTypeOrganization {
    id: ObjectId;
    alertType: AlertType;
    organization: Organization;
    alertGroupOrganization: AlertGroupOrganization;
    received?: number;
    pending?: number;
    ignored?: number;
    routed?: number;
    treated?: number;
    registered?: number;
    lastCounterDate?: string;
    createdAt: string;
    updatedAt: string;
}

export interface AlertGroupOrganization {
    id: ObjectId;
    alertGroup: AlertGroup;
    organization: Organization;
    received?: number;
    pending?: number;
    ignored?: number;
    routed?: number;
    treated?: number;
    registered?: number;
    alertTypesOrganization?: AlertTypeOrganization[];
    createdAt: string;
    updatedAt: string;
}

export interface MailMessage {
    id: ObjectId;
    messageId: string;
    from: string;
    subject: string;
    dateFromBody?: string;
    fileBody: string;
    body?: string;
    processDuration?: number;
    attachmentFolder?: string;
    attachmentFiles?: string[];
    status: MailMessageStatus;
    receivedAt?: string;
    createdAt: string;
    updatedAt: string;
}

export interface ErrorCodeUnit {
    id: ObjectId;
    reference: string;
    createdAt: string;
    updatedAt: string;
}

export interface ErrorCodeRecommendation {
    id: ObjectId;
    reference: string;
    createdAt: string;
    updatedAt: string;
}

export interface ErrorCode {
    id: ObjectId;
    organization: Organization;
    reference: string;
    unit?: string;
    description?: string;
    status?: AlertStatus;
    recommendation?: string;
    managedOnKairos: boolean;
    color?: Color;
    createdAt: string;
    updatedAt: string;
}

export interface MailTemplate {
    id: ObjectId;
    reference: string;
    organization: Organization;
    name?: string;
    alertType?: AlertType;
    subject: string;
    body: string;
    index?: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface Alert {
    id: ObjectId;
    reference: string;
    index: number;
    device: Device;
    organization: Organization;
    source: Source;
    alertType: AlertType;
    mailMessage: MailMessage;
    mailResponse?: MailResponse;
    object?: string;
    colorReferenceToOrder?: Color;
    referenceToOrder?: string;
    receivedErrorCode?: string;
    errorCode?: ErrorCode;
    status: AlertStatus;
    pendingStatusReason?: AlertPendingReason;
    pendingStatusReasonComment?: string;
    customer?: Customer;
    deviceCounter?: DeviceCounter;
    deviceERPCounter?: DeviceERPCounter;
    rawDeviceCounter?: RawDeviceCounter;
    colorLevels?: ColorLevel;
    thresholdColorLevels?: ColorLevel;
    fieldsWithError?: string[];
    operator?: User;
    treatedAt?: string;
    windevAlarmId?: number;
    createdAt: string;
    updatedAt: string;
    alertSortableProperties?: { dateFromBody: Date };
}

export interface MailResponse {
    mailTemplate: MailTemplate;
    mailTo: string;
    subject: string;
    body: string;
    attachment?: RemoteFile;
    status: MailResponseStatus;
    sendedAt?: Date;
}

export interface AlertSortableProperties {
    serial?: string;
    model?: string;
    blackCounter?: number | null;
    colorCounter?: number | null;
    lowColorCounter?: number | null;
    erasableBlueCounter?: number | null;
    blackConsumption?: number | null;
    colorConsumption?: number | null;
    lowColorConsumption?: number | null;
    erasableBlueConsumption?: number | null;
    dateFromBody?: string;
}

export enum TableTemplateKey {
    alerts = 'alerts',
    alertGroups = 'alertGroups',
    devices = 'devices',
}

export interface TableTemplateItem {
    key: string;
    sortKey?: string;
    index: number;
}

export interface ColorLevelCount {
    yellow: number | null;
    cyan: number | null;
    magenta: number | null;
    black: number | null;
    erasableBlue: number | null;
    total: number | null;
}

export interface TableTemplate {
    id: ObjectId;
    key: TableTemplateKey | string;
    columns?: TableTemplateItem[];
    items?: TableTemplateItem[];
    user: User;
    createdAt: string;
    updatedAt: string;
}

export interface Order {
    id: ObjectId;
    reference: string;
    referenceFrs?: string;
    organization: Organization;
    device: Device;
    status: OrderStatus;
    orderedAt: string;
    editedAt?: string;
    itemCode: string;
    name?: string;
    createdAt: string;
    updatedAt: string;
}

export interface Monitoring {
    id: ObjectId;
    organization: Organization;
    day: string;
    totalUnprocessableMailsCount?: number | null;
    totalMailsCount?: number | null;
    totalDevicesCount?: number | null;
    totalDevicesWithErrorsCount?: number | null;
    totalIgnoredAlertsCount?: number | null;
    totalProcessingDuration?: number | null;
    createdAt: string;
    updatedAt: string;
}

export interface LastTNEAlert {
    yellow?: Date;
    cyan?: Date;
    magenta?: Date;
    black?: Date;
    erasableBlue?: Date;
}

export interface DacDeviceCounters {
    device: Device;
    counters: Counter[];
}

import { Button, Empty, Form, FormProps, Modal, Radio, Tooltip, Typography } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState, VFC } from 'react';
import dayjs from 'dayjs';
import { CheckSquareOutlined, CloseCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import { SortingState } from '@tanstack/react-table';
import { useLocalStorage } from 'usehooks-ts';

import {
    Alert,
    AlertColumnKey,
    AlertStatus,
    Color,
    FileGenerationRequestSlug,
    RoleName,
    SortOrder,
    TableTemplateKey,
} from '../../queries/api/types';
import { useAlertList, useAlertListExport, useAlertsIgnore } from '../../queries/alerts';
import useQueryParams from '../../hooks/queryParams';
import AlertStatusTag from '../../components/AlertStatusTag';
import ListColorLevel from '../../components/ColorLevel';
import {
    defaultErrorMessageWithStatus,
    formatDate,
    formatNumber,
    translateAlertColumn,
    translateAlertPendingReason,
    translateAlertStatus,
    translateTonerColor,
} from '../../helpers/i18n';
import { Alarm, SettingTwo } from '../../components/icons';
import { urlSearchParamsToObject } from '../../helpers';
import SelectWhite from '../../components/SelectWhite';
import { useLayout } from '../../context/LayoutContext';
import AlertColumnsOrderDrawer from '../../components/drawers/AlertColumnsOrderDrawer';
import { useAuth } from '../../context/AuthContext';
import NoData from '../../components/NoData';
import { useAlert } from '../../context/AlertContext';
import constants from '../../config/constants';
import ResizableTable, {
    getDefaultSort,
    ResizableTableColumn,
    ResizableTableProps,
} from '../../components/ResizableTable';
import { errorMessage, successMessage } from '../../helpers/message';
import { hasRole } from '../../helpers/security';

const dateFilters = {
    all: 'Toutes les périodes',
    yesterday: 'Hier',
    last5Days: '5 derniers jours',
    currentMonth: 'Mois en cours',
    lastMonth: 'Mois dernier',
    monthBeforeLast: 'Mois M-2',
};

const getStatusParam = (status: AlertStatus | 'all') => {
    switch (status) {
        case AlertStatus.received:
            return { status: [AlertStatus.received, AlertStatus.inProgress] };

        case 'all':
            return { status: Object.values(AlertStatus).filter((status) => status !== AlertStatus.routed) };

        default:
            return { status };
    }
};

const getDateParam = (date: keyof typeof dateFilters) => {
    switch (date) {
        case 'yesterday':
            return {
                fromDate: dayjs().subtract(1, 'day').startOf('day').toISOString(),
                toDate: dayjs().subtract(1, 'day').endOf('day').toISOString(),
            };
        case 'last5Days':
            return {
                fromDate: dayjs().subtract(5, 'day').startOf('day').toISOString(),
                toDate: dayjs().endOf('day').toISOString(),
            };
        case 'currentMonth':
            return {
                fromDate: dayjs().startOf('month').toISOString(),
                toDate: dayjs().endOf('day').toISOString(),
            };
        case 'lastMonth':
            return {
                fromDate: dayjs().subtract(1, 'month').startOf('month').toISOString(),
                toDate: dayjs().subtract(1, 'month').endOf('month').toISOString(),
            };
        case 'monthBeforeLast':
            return {
                fromDate: dayjs().subtract(2, 'month').startOf('month').toISOString(),
                toDate: dayjs().subtract(2, 'month').endOf('month').toISOString(),
            };

        default:
            return {};
    }
};

export const alertQueryParamsKey = 'alerts';

const AlertsList: VFC = memo(() => {
    const { user } = useAuth();
    const { alert, setAlert } = useAlert();
    const { organizationId } = useLayout();
    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const [queryParams, setQueryParams] = useQueryParams(alertQueryParamsKey);
    const alertTypeId = queryParams.get('alertTypeId') ?? undefined;
    const mailDomain = queryParams.get('mailDomain') ?? undefined;
    const mail = queryParams.get('mail') ?? undefined;
    const isFilteringByEmail = !!mail || !!mailDomain;
    const deviceSerialFilter = queryParams.get('deviceSerialFilter') ?? undefined;
    const status = (queryParams.get('status') as AlertStatus | 'all') ?? AlertStatus.received;
    const alertId = queryParams.get('alertId');
    const dates = (queryParams.get('dates') as keyof typeof dateFilters) ?? 'all';
    const sort = queryParams.get('sort') ?? AlertColumnKey.date;
    const sortOrder = queryParams.get('sortOrder') ?? SortOrder.asc;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const pageSize =
        queryParams.get('pageSize') !== null
            ? parseInt(queryParams.get('pageSize')!, 10) || constants.PAGE_SIZE
            : constants.PAGE_SIZE;
    const alertColumnsOrderDrawer = useModal(AlertColumnsOrderDrawer);
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const isUserDac = hasRole(user, [RoleName.dac]);
    const isMultiSelectionAvailable = !isUserDac && [AlertStatus.received, AlertStatus.pending, 'all'].includes(status);
    const [alertIdsToIgnore, setAlertIdsToIgnore] = useLocalStorage<Array<Alert['id']> | undefined>(
        'massIgnoreAlertIds',
        undefined
    );
    const [isMultiSelectionEnabled, setIsMultiSelectionEnabled] = useState(!!alertIdsToIgnore?.length);
    const { mutateAsync: ignoreAlerts } = useAlertsIgnore();
    const {
        data: alerts,
        isError,
        error,
        isLoading,
        isFetching,
    } = useAlertList(
        {
            deviceSerials: deviceSerialFilter,
            mail: mail || undefined,
            mailDomain: mailDomain || undefined,
            organization: organizationId,
            alertTypes: alertTypeId,
            page,
            pageSize,
            sort,
            sortOrder,
            ...getDateParam(dates),
            ...getStatusParam(status),
        },
        {
            enabled: alertTypeId !== undefined || isFilteringByEmail || deviceSerialFilter !== undefined,
            onSettled: (data) => {
                if (alertId && alertId === alert?.id) {
                    onSelectAlert(data?.items?.find((item) => item.id === alertId));
                } else if ((data?.page === 0 && !alertId) || !alert) {
                    onSelectAlert(data?.items?.[0]);
                }
            },
            staleTime: 0,
        }
    );
    const { isLoading: isExporting } = useAlertListExport(
        {
            deviceSerials: deviceSerialFilter,
            mail: mail || undefined,
            mailDomain: mailDomain || undefined,
            organization: organizationId,
            alertTypes: alertTypeId,
            fileGenerationRequestSlug: FileGenerationRequestSlug.alerts,
            page,
            pageSize,
            sort,
            sortOrder,
            ...getDateParam(dates),
            ...getStatusParam(status),
        },
        {
            enabled: isExportEnabled,
            onSettled: () => {
                setIsExportEnabled(false);
            },
            onError: (error) => {
                if (error?.response?.status === 400 && error?.response?.data?.fileGenerationRequestInProgressId) {
                    errorMessage({
                        content: "Une demande d'export est déjà en cours, veuillez patienter quelques instants",
                    });
                } else {
                    errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                }
            },
            onSuccess: () => {
                successMessage({
                    content: "Demande d'export envoyée avec succès, vous recevrez le fichier par e-mail",
                });
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );
    const [form] = Form.useForm();

    const onFiltersValueChange: FormProps['onValuesChange'] = (changedValues, allValues) => {
        const { dates, status: newStatus } = allValues;

        if (![AlertStatus.received, AlertStatus.pending, 'all'].includes(newStatus) || newStatus !== status) {
            setAlertIdsToIgnore(undefined);
            setIsMultiSelectionEnabled(false);
        }

        setQueryParams({
            dates: dates ?? undefined,
            status: newStatus ?? undefined,
            page: newStatus !== status ? undefined : page,
        });
    };
    const onSelectAlert = useCallback(
        (alert?: Alert) => {
            setAlert(alert);
            setQueryParams({
                ...urlSearchParamsToObject(queryParams),
                alertId: alert?.id,
                alertReference: alert?.reference,
                deviceSerial: alert?.device.serial,
                deviceId: alert?.device.id,
                customerEmail: alert?.device.hardCodedDeviceInfo?.customer?.mail,
            });
        },
        [queryParams, setAlert, setQueryParams]
    );
    const onClickMassIgnore = useCallback(() => {
        Modal.confirm({
            width: 410,
            centered: true,
            title: 'Ignorer les alertes',
            icon: <CloseCircleOutlined className="text-red" />,
            content:
                alertIdsToIgnore?.length === 1
                    ? 'Êtes-vous sûr de vouloir ignorer l’alerte sélectionnée ?'
                    : `Êtes-vous sûr de vouloir ignorer les ${formatNumber(
                          alertIdsToIgnore?.length
                      )} alertes sélectionnées ?`,
            okText: 'Ignorer',
            onOk: async () =>
                await ignoreAlerts(
                    { alertsIds: alertIdsToIgnore! },
                    {
                        onSuccess: () => {
                            successMessage({
                                content:
                                    alertIdsToIgnore?.length === 1
                                        ? 'Alerte ignorée avec succès'
                                        : 'Alertes ignorées avec succès',
                            });
                            setAlertIdsToIgnore(undefined);
                            setIsMultiSelectionEnabled(false);
                        },
                        onError: (error) => {
                            errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                        },
                    }
                ),
        });
    }, [alertIdsToIgnore, ignoreAlerts, setAlertIdsToIgnore]);
    const isAlertSelected = useCallback((row: Alert) => row?.id === alert?.id, [alert?.id]);
    const isAlertSelectable = useCallback(
        (row: Alert) => [AlertStatus.received, AlertStatus.pending].includes(row.status),
        []
    );
    const onClickExport = (type: 'excel' | 'pdf') => {
        if (type === 'excel') {
            setIsExportEnabled(true);
        }
    };
    const userAlertColumns = user?.tableTemplates?.find(
        (template) => template.key === TableTemplateKey.alerts
    )?.columns;

    const tableColumns: Array<ResizableTableColumn<Alert>> = useMemo(
        () => [
            ...Object.values(AlertColumnKey)
                .sort(
                    (a, b) =>
                        (userAlertColumns?.find((cols) => cols.key === a)?.index ?? 0) -
                        (userAlertColumns?.find((cols) => cols.key === b)?.index ?? 0)
                )
                .map((key, index) => {
                    let column: ResizableTableColumn<Alert> = {
                        id: userAlertColumns?.find((cols) => cols.key === key)?.sortKey ?? key,
                        header: translateAlertColumn(key),
                        fixed: index === 0 ? 'left' : undefined,
                    };
                    switch (key) {
                        case AlertColumnKey.reference:
                            column = {
                                ...column,
                                accessorKey: 'reference',
                                cell: (info) => {
                                    return info.getValue() ?? <NoData />;
                                },
                                size: 120,
                            };
                            break;
                        case AlertColumnKey.status:
                            column = {
                                ...column,
                                accessorFn: (row) => row,
                                cell: (info) => <AlertStatusTag alert={info.getValue<Alert>()} isList />,
                                size: 146,
                            };
                            break;
                        case AlertColumnKey.organizationReference:
                            column = {
                                ...column,
                                accessorFn: (row) => row.organization.code,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 55,
                            };
                            break;
                        case AlertColumnKey.date:
                            column = {
                                ...column,
                                accessorFn: (row) => row.mailMessage.dateFromBody,
                                cell: (info) =>
                                    formatDate(info.getValue<Date>(), {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }),
                                size: 140,
                                enableSorting: true,
                            };
                            break;
                        case AlertColumnKey.deviceSerial:
                            column = {
                                ...column,
                                accessorFn: (row) => row.device.serial,
                                cell: (info) =>
                                    info.getValue() ? (
                                        <Typography.Text copyable>{info.getValue<string>()}</Typography.Text>
                                    ) : (
                                        <NoData />
                                    ),
                                size: 160,
                                enableSorting: true,
                            };
                            break;
                        case AlertColumnKey.object:
                            column = {
                                ...column,
                                accessorFn: (row) => row.object,
                                cell: (info) =>
                                    info.getValue() ? (
                                        <Tooltip title={info.getValue<string>()} placement="topLeft">
                                            {info.row.original.object}
                                        </Tooltip>
                                    ) : (
                                        '-'
                                    ),
                                ellipsis: true,
                                enableSorting: true,
                                size: 186,
                            };
                            break;
                        case AlertColumnKey.deviceHardCodedDeviceInfoModel:
                            column = {
                                ...column,
                                accessorFn: (row) => row.device.hardCodedDeviceInfo?.model,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 260,
                                enableSorting: true,
                            };
                            break;
                        case AlertColumnKey.pendingStatus:
                            column = {
                                ...column,
                                accessorFn: (row) => row,
                                cell: (info) =>
                                    ([
                                        info.getValue<Alert>().pendingStatusReason &&
                                            translateAlertPendingReason(info.getValue<Alert>().pendingStatusReason),
                                        info.getValue<Alert>().pendingStatusReasonComment,
                                    ]
                                        .filter(Boolean)
                                        .join(' ') ||
                                        info.row.original.errorCode?.recommendation) ??
                                    '-',
                                size: 123,
                            };
                            break;
                        case AlertColumnKey.errorCodeReference:
                            column = {
                                ...column,
                                accessorFn: (row) => row.errorCode?.reference,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 130,
                                enableSorting: true,
                            };
                            break;
                        case AlertColumnKey.colorReferenceToOrder:
                            column = {
                                ...column,
                                accessorFn: (row) => row.colorReferenceToOrder,
                                cell: (info) =>
                                    info?.getValue() ? (
                                        <ListColorLevel
                                            colorLevel={{ [info.getValue?.<Color>()]: 1 }}
                                            label={translateTonerColor(info.getValue?.<Color>())}
                                        />
                                    ) : (
                                        info.getValue() ?? <NoData />
                                    ),
                                enableSorting: true,
                                size: 114,
                            };
                            break;
                        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerName:
                            column = {
                                ...column,
                                accessorFn: (row) => row.device.hardCodedDeviceInfo?.customer?.name,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 275,
                            };
                            break;
                        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerMail:
                            column = {
                                ...column,
                                accessorFn: (row) => row.device.hardCodedDeviceInfo?.customer?.mail,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 300,
                            };
                            break;
                        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerPhone:
                            column = {
                                ...column,
                                accessorFn: (row) => row.device.hardCodedDeviceInfo?.customer?.phone,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 130,
                                ellipsis: false,
                            };
                            break;
                        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerAddressOneLineAddress:
                            column = {
                                ...column,
                                accessorFn: (row) => row.device.hardCodedDeviceInfo?.customer?.address?.oneLineAddress,
                                cell: (info) =>
                                    info.getValue() ? (
                                        <Tooltip title={info.getValue<string>()} placement="topLeft">
                                            {info.getValue<string>()}
                                        </Tooltip>
                                    ) : (
                                        '-'
                                    ),
                                ellipsis: true,
                                size: 179,
                            };
                            break;
                        case AlertColumnKey.operatorFirstName:
                            column = {
                                ...column,
                                accessorFn: (row) => row.operator?.username,
                                cell: (info) => info.getValue() ?? <NoData />,
                                size: 165,
                            };
                            break;
                        case AlertColumnKey.treatedAt:
                            column = {
                                ...column,
                                accessorFn: (row) => row.treatedAt,
                                cell: (info) =>
                                    formatDate(info.getValue<string>(), {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }),
                                size: 156,
                            };
                            break;
                    }
                    return column;
                }),
        ],
        [userAlertColumns]
    );
    const defaultSortingState = useMemo<ResizableTableProps<Alert>['defaultSortingState']>(
        () => getDefaultSort(sort, sortOrder),
        [sort, sortOrder]
    );
    const pagination = useMemo<ResizableTableProps<Alert>['pagination']>(
        () => ({
            current: page + 1,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            size: 'small',
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            pageSize,
            inTableFooter: true,
            total: alerts?.totalCount ?? 0,
        }),
        [alerts?.totalCount, page, pageSize, setQueryParams]
    );
    const rowClassName: ResizableTableProps<Alert>['rowClassName'] = useCallback(
        (record) => record.device.erpDeviceInfo?.privilegeCode?.alertHighlighting && 'table-row-purple',
        []
    );
    const tableFooter = useCallback(
        () => (
            <div className="space-x-2">
                {isMultiSelectionEnabled ? (
                    <Button
                        onClick={() => {
                            setIsMultiSelectionEnabled(false);
                            setAlertIdsToIgnore(undefined);
                        }}
                    >
                        Annuler la sélection multiple
                    </Button>
                ) : (
                    <Button
                        icon={<CheckSquareOutlined className="text-primary" />}
                        onClick={() => setIsMultiSelectionEnabled(true)}
                    >
                        Sélection multiple
                    </Button>
                )}
                {!!alertIdsToIgnore?.length && (
                    <Button type="primary" icon={<CloseCircleOutlined />} onClick={onClickMassIgnore} danger>
                        {alertIdsToIgnore.length === 1
                            ? 'Ignorer l’alerte sélectionnée'
                            : `Ignorer les ${formatNumber(alertIdsToIgnore.length)} alertes sélectionnées`}
                    </Button>
                )}
            </div>
        ),
        [alertIdsToIgnore?.length, isMultiSelectionEnabled, onClickMassIgnore, setAlertIdsToIgnore]
    );

    useEffect(() => {
        form.setFieldsValue({
            status,
        });
    }, [form, status]);

    return (
        <>
            <div className="flex justify-between items-center mb-16">
                <Typography.Title level={2} className="mb-0">
                    Affichage des alertes
                    {deviceSerialFilter
                        ? ` pour la machine ${deviceSerialFilter}`
                        : isFilteringByEmail
                        ? mail
                            ? " pour l'adresse e-mail"
                            : ' pour le domaine'
                        : alerts?.items?.[0]
                        ? ` : ${alerts?.items[0].alertType.reference}`
                        : null}
                </Typography.Title>
                <Form
                    form={form}
                    onValuesChange={onFiltersValueChange}
                    initialValues={{
                        ...queryParams,
                        status,
                        dates,
                    }}
                    className="flex items-center"
                >
                    <Form.Item name="status" noStyle>
                        <Radio.Group>
                            <Radio.Button key="all" value="all">
                                Tous les statuts
                            </Radio.Button>
                            {Object.values(AlertStatus)
                                .filter((status) => ![AlertStatus.routed, AlertStatus.inProgress].includes(status))
                                .map((status) => (
                                    <Radio.Button key={status} value={status}>
                                        {translateAlertStatus(status)}
                                    </Radio.Button>
                                ))}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="dates" noStyle>
                        <SelectWhite filterOption={false} className="ml-24 w-[210]">
                            {(Object.keys(dateFilters) as Array<keyof typeof dateFilters>).map((key) => (
                                <SelectWhite.Option value={key} key={key}>
                                    {dateFilters[key]}
                                </SelectWhite.Option>
                            ))}
                        </SelectWhite>
                    </Form.Item>
                    <Button
                        className="ml-24"
                        icon={<FileExcelOutlined className="text-primary" />}
                        onClick={() => onClickExport('excel')}
                        loading={isExporting}
                    >
                        Excel
                    </Button>
                    <Button
                        className="ml-8"
                        icon={<SettingTwo className="text-taupe" />}
                        onClick={async () => await alertColumnsOrderDrawer.show()}
                    />
                </Form>
            </div>
            <ResizableTable<Alert>
                rowKey="id"
                data={alerts?.items}
                columns={tableColumns}
                pagination={pagination}
                defaultSortingState={defaultSortingState}
                onSortChange={onSortChange}
                isLoading={isLoading || isFetching}
                isError={isError}
                error={error}
                onRowHighlightChange={onSelectAlert}
                isRowHighlighted={isAlertSelected}
                maxHeight={340}
                rowClassName={rowClassName}
                emptyContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={<Alarm className="font-28 mt-56" />}
                        description={
                            alertTypeId || isFilteringByEmail ? 'Aucun résultat' : 'Aucun type d’alerte sélectionné'
                        }
                    />
                }
                isRowSelectable={isAlertSelectable}
                selectedRowKeys={isMultiSelectionEnabled ? alertIdsToIgnore : undefined}
                onRowSelectionChange={isMultiSelectionEnabled ? setAlertIdsToIgnore : undefined}
                footer={isMultiSelectionAvailable ? tableFooter : undefined}
                enableColumnResizing
            />
        </>
    );
});

export default AlertsList;

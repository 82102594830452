import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { DevicesColorLevelCountPayload, DevicesColorLevelCountResponse, count } from './api/devicesColorLevelCounts';

export const devicesColorLevelCountsKeys = {
    all: ['devicesColorLevelCounts'],
    counts: () => [...devicesColorLevelCountsKeys.all, 'count'],
    count: (params?: DevicesColorLevelCountPayload) => [...devicesColorLevelCountsKeys.counts(), params],
};

export const useDevicesColorLevelCount = <TData = DevicesColorLevelCountResponse>(
    params?: DevicesColorLevelCountPayload,
    options?: UseQueryOptions<DevicesColorLevelCountResponse, AxiosError, TData>
) => {
    return useQuery<DevicesColorLevelCountResponse, AxiosError, TData>(
        devicesColorLevelCountsKeys.count(params),
        async () => await count(params),
        { keepPreviousData: true, ...options }
    );
};

import { Button, Empty } from 'antd';
import { antdDrawer, useModal, create as createModal, show } from '@ebay/nice-modal-react';
import { useCallback, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { SortingState } from '@tanstack/react-table';

import CustomDrawer from '../CustomDrawer';
import OrganizationFormItem from '../form/OrganizationFormItem';
import { AlertStatus, ErrorCode, Organization } from '../../queries/api/types';
import { errorMessage, successMessage } from '../../helpers/message';
import DeletePopConfirm from '../DeletePopConfirm';
import ErrorCodesFormDrawer from './ErrorCodesFormDrawer';
import { useErrorCodeList, useErrorCodeRemove } from '../../queries/errorCodes';
import { translateAlertStatus } from '../../helpers/i18n';
import NoData from '../NoData';
import SelectWhite from '../SelectWhite';
import ErrorCodesMissingDrawer from './ErrorCodesMissingDrawer';
import { useLayout } from '../../context/LayoutContext';
import ResizableTable, { ResizableTableColumn, ResizableTableProps } from '../ResizableTable';

const errorCodesFormDrawerId = 'errorCodesFormDrawer';
const errorCodesMissingDrawerId = 'errorCodesMissingDrawer';

const ErrorCodesDrawer = createModal<Record<string, unknown>>(({ ...props }) => {
    const { organizationId: headerOrganizationId } = useLayout();
    const modal = useModal();
    const [organizationId, setOrganization] = useState<Organization['id'] | undefined>(headerOrganizationId);
    const [alertStatus, setAlertStatus] = useState<AlertStatus>();
    const [page, setPage] = useState<number>();
    const [sort, setSort] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>();
    const {
        data: errorCodes,
        isLoading,
        isError,
        error,
    } = useErrorCodeList({
        organization: organizationId,
        status: alertStatus ?? undefined,
        managedOnKairos: true,
        pageSize: 100,
        page,
        sort,
        sortOrder,
    });
    const { mutate: deleteErrorCode } = useErrorCodeRemove({
        onError: () => {
            errorMessage({ content: 'Une erreur est survenue pendant la suppression du code panne' });
        },
        onSuccess: () => {
            successMessage({
                content: 'Code panne supprimé avec succès',
            });
        },
    });
    const onClose = useCallback(() => {
        modal.hide();
    }, [modal]);
    const onSortChange = useCallback((sortingState: SortingState) => {
        setSort(sortingState[0]?.id);
        setSortOrder(sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined);
    }, []);
    const pagination = useMemo<ResizableTableProps<ErrorCode>['pagination']>(
        () => ({
            current: (page ?? 0) + 1,
            total: errorCodes?.totalCount ?? 0,
            onChange: (current) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setPage(newPage);
            },
            size: 'small',
            pageSize: errorCodes?.pageSize ?? 50,
            showSizeChanger: false,
            hideOnSinglePage: true,
        }),
        [errorCodes?.pageSize, errorCodes?.totalCount, page]
    );
    const columns = useMemo<Array<ResizableTableColumn<ErrorCode>>>(
        () => [
            {
                id: 'reference',
                header: 'Code A.T.',
                accessorKey: 'reference',
                cell: (info) => info.getValue() ?? <NoData />,
                enableSorting: true,
                size: 106,
            },
            {
                id: 'unit',
                header: 'Unité',
                accessorKey: 'unit',
                cell: (info) => info.getValue() ?? <NoData />,
                size: 108,
            },
            {
                id: 'description',
                header: 'État de la machine',
                accessorKey: 'description',
                cell: (info) => info.getValue() ?? <NoData />,
            },
            {
                id: 'status',
                header: 'Statut alerte',
                accessorKey: 'status',
                cell: (info) => translateAlertStatus(info.getValue<ErrorCode['status']>()),
                size: 100,
            },
            {
                id: 'recommendation',
                header: 'Préconisation',
                accessorKey: 'recommendation',
                cell: (info) => info.getValue() ?? <NoData />,
            },
            {
                id: 'actions',
                header: 'Actions',
                fixed: 'right',
                accessorKey: 'id',
                cell: (info) => {
                    return (
                        <div className="flex space-x-2">
                            <Button
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    show(errorCodesFormDrawerId, { errorCodeId: info.getValue<ErrorCode['id']>() });
                                }}
                            />
                            <DeletePopConfirm
                                key="delete"
                                title="Êtes-vous sûr de vouloir supprimer ce code panne?"
                                onConfirm={() => deleteErrorCode(info.getValue<ErrorCode['id']>())}
                            >
                                <Button size="small" icon={<DeleteOutlined />} />
                            </DeletePopConfirm>
                        </div>
                    );
                },
                size: 68,
            },
        ],
        [deleteErrorCode]
    );

    return (
        <CustomDrawer width={980} title="Gestion des codes pannes" {...props} {...antdDrawer(modal)} onClose={onClose}>
            <div className="flex justify-between mb-16">
                <div className="flex space-x-3">
                    <OrganizationFormItem
                        selectProps={{
                            onChange: setOrganization,
                            style: { width: 115 },
                            dropdownMatchSelectWidth: false,
                            allowClear: true,
                            defaultValue: headerOrganizationId,
                        }}
                        isWhite
                        noStyle
                    />
                    <SelectWhite<AlertStatus>
                        placeholder="Tous les statuts"
                        filterOption={false}
                        options={[AlertStatus.ignored, AlertStatus.pending, AlertStatus.received].map(
                            (alertStatus) => ({
                                label: translateAlertStatus(alertStatus),
                                value: alertStatus,
                            })
                        )}
                        onChange={setAlertStatus}
                        style={{ width: 150 }}
                        allowClear
                    />
                </div>
                <div className="flex space-x-3">
                    <Button
                        onClick={() => {
                            show(errorCodesFormDrawerId);
                        }}
                        icon={<PlusOutlined className="text-primary" />}
                    >
                        Ajouter un code
                    </Button>
                    <Button
                        onClick={() => {
                            show(errorCodesMissingDrawerId);
                        }}
                        icon={<ImportOutlined className="text-primary" />}
                    >
                        Codes manquants (Import auto.)
                    </Button>
                </div>
            </div>
            <ResizableTable<ErrorCode>
                rowKey="id"
                columns={columns}
                isLoading={isLoading}
                isError={isError}
                error={error}
                data={errorCodes?.items}
                pagination={pagination}
                onSortChange={onSortChange}
                emptyContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Aucun résultat"
                    />
                }
                maxHeight={`calc(100vh - ${(errorCodes?.pageCount ?? 0) > 1 ? 185 : 161}px)`}
                enableColumnResizing
            />
            <ErrorCodesFormDrawer id={errorCodesFormDrawerId} />
            <ErrorCodesMissingDrawer id={errorCodesMissingDrawerId} />
        </CustomDrawer>
    );
});

export default ErrorCodesDrawer;

import { Header, Cell, RowSelectionState, Row } from '@tanstack/react-table';
import { Checkbox } from 'antd';
import { MutableRefObject } from 'react';

import { ResizableTableColumn } from './ResizableTable';

export const addColumnResizing = <TData extends unknown>(
    columns: Array<ResizableTableColumn<TData>>
): Array<ResizableTableColumn<TData>> => {
    return columns.map((column) => ({
        ...column,
        enableResizing: column.enableResizing !== undefined ? column.enableResizing : true,
        columns: 'columns' in column && column.columns ? addColumnResizing(column.columns) : undefined,
    }));
};

export const addRowSelectionColumn = <TData extends unknown>({
    columns,
    dataLength,
    hasSomeLeftFixedColumns,
    onClickRowSelectionCheckbox,
    onClickPageSelectionCheckbox,
}: {
    columns: Array<ResizableTableColumn<TData>>;
    dataLength: number;
    hasSomeLeftFixedColumns: boolean;
    onClickRowSelectionCheckbox: (rowId: string, checked: boolean) => void;
    onClickPageSelectionCheckbox: (checked: boolean, rows: Array<Row<TData>>) => void;
}): Array<ResizableTableColumn<TData>> => {
    return [
        {
            id: 'row-selection',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    indeterminate={table.getIsSomePageRowsSelected()}
                    onChange={(e) => onClickPageSelectionCheckbox(e.target.checked, table.getPaginationRowModel().rows)}
                    disabled={!dataLength}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={(e) => onClickRowSelectionCheckbox(row.id, e.target.checked)}
                    onClick={(e) => e.stopPropagation()}
                />
            ),
            meta: {
                isSelectionColumn: true,
            },
            fixed: hasSomeLeftFixedColumns ? 'left' : undefined,
            size: 32,
            enableResizing: false,
        },
        ...columns,
    ];
};

export const getHeaderFixedSettings = <T extends unknown>(header: Header<T, unknown>) => {
    const isFixedRight = header.column.columnDef.fixed === 'right';
    const isFixedLeft = header.column.columnDef.fixed === 'left';
    const previousHeaders = header.headerGroup.headers.slice(0, header.index === 0 ? 0 : header.index);
    const left = isFixedLeft ? previousHeaders.reduce((size, previous) => (size += previous.getSize()), 0) : undefined;
    const nextHeaders = header.headerGroup.headers.slice(header.index + 1);
    const right = isFixedRight ? nextHeaders.reduce((size, next) => (size += next.getSize()), 0) : undefined;
    const isLastLeftFixed = isFixedLeft && !nextHeaders.some((next) => next.column.columnDef.fixed === 'left');
    const isFirstRightFixed =
        isFixedRight && !previousHeaders.some((previous) => previous.column.columnDef.fixed === 'right');

    return {
        isFixedRight,
        isFixedLeft,
        isLastLeftFixed,
        isFirstRightFixed,
        left,
        right,
    };
};

export const getCellFixedSettings = <T extends unknown>(cell: Cell<T, unknown>, cellIndex: number) => {
    const isFixedRight = cell.column.columnDef.fixed === 'right';
    const isFixedLeft = cell.column.columnDef.fixed === 'left';
    const previousCells = cell.row.getVisibleCells().slice(0, cellIndex === 0 ? 0 : cellIndex);
    const left = isFixedLeft
        ? previousCells.reduce((size, previous) => (size += previous.column.getSize()), 0)
        : undefined;
    const nextCells = cell.row.getVisibleCells().slice(cellIndex + 1);
    const right = isFixedRight ? nextCells.reduce((size, next) => (size += next.column.getSize()), 0) : undefined;
    const isLastLeftFixed = isFixedLeft && !nextCells.some((cell) => cell.column.columnDef.fixed === 'left');
    const isFirstRightFixed = isFixedRight && !previousCells.some((cell) => cell.column.columnDef.fixed === 'right');

    return {
        isFixedRight,
        isFixedLeft,
        isLastLeftFixed,
        isFirstRightFixed,
        left,
        right,
    };
};

export const getDefaultSort = (sort: string | undefined, sortOrder: string | undefined) => {
    return sort && sortOrder
        ? [
              {
                  id: sort,
                  desc: sortOrder === 'desc',
              },
          ]
        : undefined;
};

export const selectedRowKeysToObject = (selectedRowKeys?: string[]) =>
    selectedRowKeys?.reduce<Record<string, boolean>>((acc, key) => ({ ...acc, [key]: true }), {}) || {};

export const selectionStateToArray = (selectionState?: RowSelectionState) =>
    Object.entries(selectionState ?? {})
        ?.filter(([_, value]) => !!value)
        ?.map(([key]) => key);

export const scrollTableToTop = ({
    isScrollingToTopOnChange,
    scrollRef,
    hasYScroll,
}: {
    isScrollingToTopOnChange: boolean | undefined;
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    hasYScroll: boolean;
}) => {
    if (isScrollingToTopOnChange) {
        scrollRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        if (hasYScroll) {
            scrollRef.current?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }
};

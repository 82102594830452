import { DetailedHTMLProps, HTMLAttributes, MouseEvent, useEffect, useState } from 'react';
import { Table, TableProps } from 'antd';
import { SortableContainer, SortableElement, SortableHandle, SortEndHandler } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CustomizeComponent } from 'antd/node_modules/rc-table/lib/interface';
import { ColumnType } from 'antd/lib/table';

import '../assets/styles/SortableTable.less';

import { DragHandle as IconDragHandle } from './icons';
import { classNames } from '../helpers';

const DragHandle = SortableHandle(() => <IconDragHandle style={{ cursor: 'grab' }} className="text-dark-grey" />);

const SortableItem = SortableElement(
    (props: DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
    (props: DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => <tbody {...props} />
);

interface ExtraProps<T> {
    dragHandleColumnProps?: ColumnType<T>;
    onSort: (records: T[]) => void;
    onSortStart?: () => void;
    onHandleMouseDown?: () => void;
}

const SortableTable = <T extends Record<string, any>>({
    dataSource,
    columns,
    dragHandleColumnProps,
    onSort,
    onSortStart,
    ...props
}: TableProps<T> & ExtraProps<T>) => {
    const [data, setData] = useState(dataSource?.map((record, index) => ({ ...record, index })));
    const cols = [
        {
            key: 'sort',
            width: 30,
            ...dragHandleColumnProps,
            className: classNames('drag-visible', dragHandleColumnProps?.className),
            render: () => <DragHandle />,
            onCell: () => ({
                className: classNames('drag-handle', dragHandleColumnProps?.onCell?.(data?.[0] as T)?.className),
                onClick: (e: MouseEvent) => {
                    e.stopPropagation();
                },
            }),
        },
        ...(columns ?? []).map((col) => ({ ...col, className: classNames('drag-visible', col.className) })),
    ];

    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(([] as T[]).concat(data ?? ([] as T[])), oldIndex, newIndex).filter(
                (el) => !!el
            );
            onSort(newData);
            setData(newData as Array<T & { index: number }>);
        }
    };

    const DraggableContainer: CustomizeComponent = (props: any) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
            {...props}
        />
    );

    const DraggableBodyRow: CustomizeComponent = (props: any) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data?.findIndex((x) => x.index === props['data-row-key']) ?? 0;
        return <SortableItem index={index} {...props} />;
    };

    useEffect(() => {
        setData(dataSource?.map((record, index) => ({ ...record, index })));
    }, [dataSource]);

    return (
        <Table<T>
            pagination={false}
            dataSource={data}
            columns={cols}
            rowKey="index"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
            bordered
            {...props}
        />
    );
};

export default SortableTable;

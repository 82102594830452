import { useState, VFC } from 'react';
import { Button, Card, Typography, Slider, InputNumber, Divider, Radio, Statistic } from 'antd';

import { classNames } from '../../helpers';
import { formatNumber } from '../../helpers/i18n';
import useQueryParams from '../../hooks/queryParams';
import { Color, TonerMonitoringStatus } from '../../queries/api/types';
import ListColorLevel from '../../components/ColorLevel';
import { useDevicesColorLevelCount } from '../../queries/devicesColorLevelCounts';
import { useLayout } from '../../context/LayoutContext';

const AlertsTonerLevelsFilters: VFC = () => {
    const { organizationId } = useLayout();
    const [queryParams, setQueryParams] = useQueryParams('AlertsTonerLevels');
    const colorLevel = queryParams.get('colorLevel') ? Number(queryParams.get('colorLevel')) : 20;
    const color = (queryParams.get('color') as Color) ?? Color.yellow;
    const [colorValue, setColorValue] = useState<Color>(color);
    const [colorLevelValue, setColorLevelValue] = useState(colorLevel ?? 20);
    const {
        data: count,
        isLoading,
        isFetching,
    } = useDevicesColorLevelCount({
        organization: organizationId,
        tonerMonitoringStatus: [TonerMonitoringStatus.active, TonerMonitoringStatus.highlight],
        eccAndAutoNotFalse: true,
        colorLevel,
    });
    const onSubmit = () => {
        setQueryParams({
            colorLevel: colorLevelValue,
            color: colorValue,
            page: undefined,
        });
    };

    return (
        <Card bodyStyle={{ padding: '1.5rem 1rem' }} className="mb-16">
            <div className="flex alert-toner-levels">
                <div className="flex flex-auto items-center space-x-6 bg-grey-darker py-8 px-16 rounded-xl">
                    <div className="flex-1">
                        <Typography.Paragraph className="font-semibold mb-4">
                            Pourcentage de toner restant
                        </Typography.Paragraph>
                        <Slider
                            min={0}
                            max={100}
                            marks={{ 0: '0', 100: '100%' }}
                            onChange={setColorLevelValue}
                            value={colorLevelValue}
                        />
                    </div>
                    <InputNumber
                        value={colorLevelValue}
                        min={0}
                        max={100}
                        formatter={(value) => (value ? `${value}%` : '0%')}
                        parser={(value) => Number(value ? value.replace('%', '') : '0')}
                        onChange={setColorLevelValue}
                        className="input-white"
                        style={{ width: 68 }}
                    />
                    <Button type="primary" onClick={onSubmit}>
                        Calculer
                    </Button>
                </div>
                <Divider type="vertical" className="-my-24 ml-16 mr-24 top-0" style={{ height: 130 }} />
                <Statistic
                    className="total"
                    title="Total cartouches"
                    value={formatNumber(count?.total)}
                    loading={isLoading || isFetching}
                />
                <Radio.Group
                    buttonStyle="solid"
                    onChange={(e) => {
                        const val = e.target.value;
                        setColorValue(val);
                        setQueryParams({
                            color: val,
                            page: undefined,
                        });
                    }}
                    defaultValue={color}
                >
                    {[Color.yellow, Color.cyan, Color.magenta, Color.black, Color.erasableBlue].map((color) => (
                        <Radio.Button key={color} value={color} className={color}>
                            <Statistic
                                title={<ListColorLevel colorLevel={{ [color]: 1 }} />}
                                value={formatNumber(count?.[color])}
                                className={classNames(colorValue === color && 'selected')}
                                loading={isLoading || isFetching}
                            />
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </div>
        </Card>
    );
};

export default AlertsTonerLevelsFilters;

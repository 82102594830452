import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { ConfigProvider as AntdConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
// locale configuration when not using internationalisation
import fr_FR from 'antd/lib/locale/fr_FR';
import dayjs from 'dayjs';
import dayjsFr from 'dayjs/locale/fr.js';

import './assets/styles/AntOverride.less';

import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { LogsProvider } from './context/LogsContext';
import { AlertProvider } from './context/AlertContext';
import { LayoutProvider } from './context/LayoutContext';

dayjs.locale(dayjsFr);

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

// API mock data service worker
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./mocks/browser');
    worker.start();
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 20 * 1000, // 20s cache
        },
    },
});

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <LogsProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <BreakpointProvider>
                            <AntdConfigProvider locale={fr_FR}>
                                <AlertProvider>
                                    <LayoutProvider>
                                        <NiceModal.Provider>
                                            <Component />
                                        </NiceModal.Provider>
                                    </LayoutProvider>
                                </AlertProvider>
                            </AntdConfigProvider>
                        </BreakpointProvider>
                    </BrowserRouter>
                </AuthProvider>
            </LogsProvider>
        </QueryClientProvider>,
        document.getElementById('root') as HTMLElement
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

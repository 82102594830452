import { createContext, FC, useMemo, useContext, useCallback } from 'react';
import {
    QueryObserverResult,
    RefetchOptions,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from 'react-query';
import { AxiosError } from 'axios';

import { User } from '../queries/api/types';
import {
    me,
    login,
    logout,
    LoginPayload,
    facebookLogin,
    forgottenPassword,
    ForgottenPasswordPayload,
    ResetPasswordPayload,
    resetPassword,
    ResendInvitationPayload,
    resendInvitation,
    UpdateMePayload,
    update,
} from '../queries/api/auth';

export interface AuthContextType {
    user: User | undefined;
    isCheckingSession: boolean;
    checkSessionError: AxiosError | null;
    login: UseMutationResult<User, AxiosError, LoginPayload>;
    facebookLogin: UseMutationResult<User, AxiosError, void>;
    logout: UseMutationResult<unknown, AxiosError, void>;
    forgottenPassword: UseMutationResult<User, AxiosError, ForgottenPasswordPayload>;
    resetPassword: UseMutationResult<User, AxiosError, ResetPasswordPayload>;
    resendInvitation: UseMutationResult<User, AxiosError, ResendInvitationPayload>;
    updateUser: UseMutationResult<User, AxiosError, UpdateMePayload>;
    refetchUser: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<User, Error>>;
}

// context creator
export const AuthContext = createContext<AuthContextType | null>(null);
AuthContext.displayName = 'AuthContext';

// hook to retrieve auth data & helpers
export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};

// context provider
export const AuthProvider: FC = ({ children }) => {
    const queryClient = useQueryClient();

    const {
        data: user,
        error: checkSessionError,
        isLoading: isCheckingSession,
        refetch,
    } = useQuery<User, AxiosError>({
        retry: 0,
        queryKey: 'auth-user',
        queryFn: me,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 60 * 8, // 8h until data is marked as stale
    });

    const setUser = useCallback((data: User) => queryClient.setQueryData('auth-user', data), [queryClient]);

    const facebookLoginMutation = useMutation<User, AxiosError>({
        mutationFn: facebookLogin,
        onSuccess: (user) => {
            setUser(user);
        },
    });

    const loginMutation = useMutation<User, AxiosError, LoginPayload>({
        mutationFn: login,
        onSuccess: (user) => {
            setUser(user);
        },
    });

    const logoutMutation = useMutation<unknown, AxiosError>({
        mutationFn: logout,
        onError: () => {
            // flush user especially if logout failed
            queryClient.clear();
        },
        onSuccess: () => {
            // flush user on logout success
            queryClient.clear();
        },
    });

    const forgottenPasswordMutation = useMutation<User, AxiosError, ForgottenPasswordPayload>(forgottenPassword);
    const resetPasswordMutation = useMutation<User, AxiosError, ResetPasswordPayload>(resetPassword);
    const resendInvitationMutation = useMutation<User, AxiosError, ResendInvitationPayload>(resendInvitation);
    const updateUserMutation = useMutation<User, AxiosError, UpdateMePayload>(update);

    const value = useMemo(
        () => ({
            user,
            checkSessionError,
            isCheckingSession,
            refetchUser: refetch,
            login: loginMutation,
            facebookLogin: facebookLoginMutation,
            logout: logoutMutation,
            forgottenPassword: forgottenPasswordMutation,
            resetPassword: resetPasswordMutation,
            resendInvitation: resendInvitationMutation,
            updateUser: updateUserMutation,
        }),
        [
            user,
            checkSessionError,
            isCheckingSession,
            refetch,
            loginMutation,
            facebookLoginMutation,
            logoutMutation,
            forgottenPasswordMutation,
            resetPasswordMutation,
            resendInvitationMutation,
            updateUserMutation,
        ]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import { AxiosResponse } from 'axios';

import { SearchPaginationQuery } from '.';
import { Subscription, ListResponse, Organization, Device } from './types';
import api from './apiClient';

// Controller Interface
export interface SubscriptionCreatePayload {
    name: string;
    organization?: Organization['id'];
    active: boolean;
    detailedReporting: boolean;
    briefReporting: boolean;
    xlsReporting: boolean;
    emails: string[];
}

export interface SubscriptionUpdatePayload extends Partial<Omit<Subscription, 'id'>> {
    id?: Subscription['id'];
    devices?: Array<Device['id']>;
    removedDevices?: Array<Device['id']>;
}

export type SubscriptionIdPayload = Subscription['id'];

export type SubscriptionListPayload = SearchPaginationQuery & {
    organization?: Organization['id'];
};

export type SubscriptionListResponse = ListResponse<Subscription>;

// Routes
export const list = async (payload?: SubscriptionListPayload) => {
    return await api
        .get<SubscriptionListResponse>(`/subscriptions`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: SubscriptionCreatePayload) => {
    return await api.post(`/subscriptions`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: SubscriptionUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Subscription>(`/subscriptions/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: SubscriptionIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Subscription>(`/subscriptions/${id}`).then((response) => response?.data);
};

export const remove = async (id?: SubscriptionIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/subscriptions/${id}`).then((response) => response?.data);
};

export const metric = async (id?: SubscriptionIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Subscription>(`/subscriptions/${id}/metric`).then((response) => response?.data);
};

export const generation = async (url?: string) => {
    if (!url) {
        throw new Error('missing url');
    }
    return await api
        .get<AxiosResponse>('/pdfGeneration', {
            params: { url },
            headers: {
                'Content-Type': 'application/octet-stream',
            },
            responseType: 'blob',
        })
        .then((response) => response?.data);
};

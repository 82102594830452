import { ReactNode, useEffect, useState, VFC } from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';

import { errorMessage } from '../../helpers/message';
import { ErrorCodeRecommendation } from '../../queries/api/types';
import { defaultErrorMessage } from '../../helpers/i18n';
import { useDebounce } from '../../hooks';
import { classNames } from '../../helpers';
import { useErrorCodeRecommendationList } from '../../queries/errorCodeRecommendations';
import {
    ErrorCodeRecommendationListPayload,
    ErrorCodeRecommendationListResponse,
} from '../../queries/api/errorCodeRecommendations';

interface ErrorCodeRecommendationFormItemProps extends FormItemProps {
    selectProps?: SelectProps<ErrorCodeRecommendation['id']>;
    queryPayload?: ErrorCodeRecommendationListPayload;
    optionLabelRender?: (record: ErrorCodeRecommendation) => ReactNode;
    optionFilter?: (record: ErrorCodeRecommendation) => boolean;
    onFirstLoad?: (data: ErrorCodeRecommendationListResponse) => void;
    valueGetter?: (record: ErrorCodeRecommendation) => string | number;
    isWhite?: boolean;
}

const ErrorCodeRecommendationFormItem: VFC<ErrorCodeRecommendationFormItemProps> = ({
    selectProps,
    queryPayload,
    optionLabelRender = (record) => record.reference,
    optionFilter = () => true,
    valueGetter = (record) => record.id,
    onFirstLoad,
    isWhite,
    ...props
}) => {
    const [hasDoneFirstLoad, setHasDoneFirstLoad] = useState(false);
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: recommendations, isLoading } = useErrorCodeRecommendationList(
        { pageSize: 5, search: debouncedSearch || undefined, ...queryPayload },
        {
            onSettled: (data) => {
                if (!hasDoneFirstLoad && data) {
                    onFirstLoad?.(data);
                    setHasDoneFirstLoad(true);
                }
            },
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    useEffect(() => {
        if (!hasDoneFirstLoad && recommendations?.items?.[0]) {
            onFirstLoad?.(recommendations);
        }
    }, [recommendations, hasDoneFirstLoad, onFirstLoad]);

    return (
        <Form.Item {...props}>
            <Select
                {...selectProps}
                placeholder={selectProps?.placeholder ?? 'Sélectionner une recommendation'}
                className={classNames(isWhite && 'select-white', selectProps?.className)}
                filterOption={false}
                loading={isLoading}
                options={recommendations?.items?.filter(optionFilter).map((recommendation) => ({
                    label: optionLabelRender(recommendation),
                    value: valueGetter(recommendation),
                }))}
                onSearch={setSearch}
                defaultActiveFirstOption
                showSearch
            />
        </Form.Item>
    );
};

export default ErrorCodeRecommendationFormItem;

import { memo, useEffect, useState, VFC } from 'react';
import { Input, Form, FormProps, Dropdown, Button, Menu, Divider, Descriptions, Modal } from 'antd';
import {
    CaretDownFilled,
    DeleteOutlined,
    // FilePdfOutlined,
    LineChartOutlined,
    MailOutlined,
    ZoomInOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { show } from '@ebay/nice-modal-react';

import ToolbarCard from '../../components/ToolbarCard';
import useQueryParams from '../../hooks/queryParams';
import {
    Alarm,
    DocSearchTwo,
    EmailSearch,
    List,
    MailPackage,
    Printer,
    SendEmail,
    Sphere,
    Truck,
    User,
} from '../../components/icons';
import { getRoute, RoutePathName } from '../../routes';
import NoData from '../../components/NoData';
import { useAlertList, useAlertUpdate } from '../../queries/alerts';
import { errorMessage, successMessage } from '../../helpers/message';
import OrganizationFormItem from '../../components/form/OrganizationFormItem';
import { AlertStatus, Permission, PermissionRight } from '../../queries/api/types';
import { useLayout } from '../../context/LayoutContext';
import { hasPermission } from '../../helpers/security';
import { useAuth } from '../../context/AuthContext';
import { alertModalId, deviceDrawerId } from '../../App';

const AlertsActionBar: VFC = memo(() => {
    const { user } = useAuth();
    const { organizationId } = useLayout();
    const [reference, setReference] = useState<string>();
    const [queryParams, setQueryParams] = useQueryParams('alerts');
    const alertReference = queryParams.get('alertReference');
    const deviceSerial = queryParams.get('deviceSerial');
    const customerEmail = queryParams.get('customerEmail');
    const mail = queryParams.get('mail');
    const mailDomain = queryParams.get('mailDomain');
    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const onFiltersReset: FormProps['onReset'] = () => {
        setQueryParams({
            alertReference: undefined,
            deviceSerial: undefined,
            deviceSerialFilter: undefined,
            customerEmail: undefined,
            mailDomain: undefined,
            mail: undefined,
        });
    };
    const { mutateAsync: updateAlert } = useAlertUpdate();
    const { isLoading: isFetchingAlert } = useAlertList(
        {
            reference,
            organization: organizationId,
        },
        {
            enabled: !!reference,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onSuccess: (data) => {
                const alert = data?.items?.[0];
                if (alert) {
                    const transferModal = modal.confirm({
                        width: 480,
                        centered: true,
                        title: 'Transférer l’alerte vers une autre entité',
                        icon: null,
                        bodyStyle: { marginTop: 16 },
                        content: (
                            <>
                                <Descriptions
                                    size="small"
                                    column={1}
                                    className="mb-24"
                                    labelStyle={{ width: 124 }}
                                    bordered
                                >
                                    <Descriptions.Item label="N° de l’alerte">
                                        {alertReference ?? <NoData />}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="N° de série">
                                        {deviceSerial ?? <NoData />}
                                    </Descriptions.Item>
                                </Descriptions>
                                <OrganizationFormItem
                                    label="Veuillez choisir ci-dessous l’entité destinataire de l’alerte :"
                                    optionFilter={(record) => record.id !== alert.organization.id}
                                    selectProps={{
                                        onChange: (value) =>
                                            transferModal.update({
                                                okButtonProps: {
                                                    disabled: !value,
                                                },
                                                onOk: onTransferModalOk(value, alert?.id),
                                            }),
                                    }}
                                />
                            </>
                        ),
                        okButtonProps: {
                            disabled: true,
                        },
                        okText: 'Transférer',
                        onCancel: () => {
                            setReference(undefined);
                        },
                    });
                } else {
                    errorMessage({ content: 'Alerte non trouvée' });
                }
            },
            onError: (error) => {
                if (error.response?.status === 404) {
                    errorMessage({
                        content: 'Alerte non trouvée',
                    });
                } else {
                    errorMessage({
                        content: `Une erreur est survenue pendant la récupération de l'alerte (${
                            error?.response?.status ?? 0
                        })`,
                    });
                }
            },
        }
    );
    const onTransferModalOk = (organizationId?: string, alertId?: string) => async () =>
        await updateAlert(
            { id: alertId, status: AlertStatus.routed, organization: organizationId },
            {
                onSuccess: () => {
                    successMessage({ content: 'Alerte transférée avec succès' });
                    setReference(undefined);
                    setQueryParams({
                        alertId: undefined,
                        alertReference: undefined,
                        deviceSerial: undefined,
                        customerEmail: undefined,
                        deviceId: undefined,
                    });
                },
                onError: (error) => {
                    if (error?.response?.status !== 409) {
                        errorMessage({
                            content: `Une erreur est survenue pendant le transfert (${error?.response?.status ?? 0})`,
                        });
                    }
                },
            }
        );

    useEffect(() => {
        form.setFieldsValue({
            alertReference,
            deviceSerial,
            ...(customerEmail ? { customerEmail } : {}),
        });
    }, [form, alertReference, deviceSerial, customerEmail]);

    return (
        <ToolbarCard>
            {contextHolder}
            <Form
                form={form}
                onReset={onFiltersReset}
                initialValues={{
                    alertReference,
                    deviceSerial,
                    customerEmail: mail || mailDomain,
                }}
                className="flex"
            >
                <Form.Item name="deviceSerial" noStyle>
                    <Input placeholder="N° de série de la machine" prefix={<Printer />} />
                </Form.Item>
                <Form.Item dependencies={['deviceSerial']} noStyle>
                    {({ getFieldValue }) => (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="alertMachine"
                                        icon={<Printer className="text-primary font-16" />}
                                        onClick={() => {
                                            setQueryParams({
                                                status: 'all',
                                                mail: undefined,
                                                mailDomain: undefined,
                                                alertGroupIndex: undefined,
                                                alertTypeId: undefined,
                                                alertId: undefined,
                                                alertReference: undefined,
                                                deviceSerial: getFieldValue('deviceSerial'),
                                                deviceSerialFilter: getFieldValue('deviceSerial'),
                                            });
                                        }}
                                    >
                                        Interroger toutes les alertes de la machine
                                    </Menu.Item>
                                    <Menu.Item key="history" icon={<DocSearchTwo className="text-primary font-16" />}>
                                        <Link
                                            to={{
                                                pathname: getRoute(RoutePathName.alertHistory),
                                                search: new URLSearchParams({
                                                    deviceSerial: getFieldValue('deviceSerial') ?? '',
                                                }).toString(),
                                            }}
                                        >
                                            Historique des alertes
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="counters"
                                        icon={<LineChartOutlined className="text-primary font-16" />}
                                    >
                                        <Link
                                            to={{
                                                pathname: getRoute(RoutePathName.alertStats),
                                                search: new URLSearchParams({
                                                    deviceSerial: getFieldValue('deviceSerial') ?? '',
                                                }).toString(),
                                            }}
                                        >
                                            Compteurs
                                        </Link>
                                    </Menu.Item>

                                    {hasPermission(user, Permission.orders) && (
                                        <Menu.Item key="delivery" icon={<Truck className="text-primary font-16" />}>
                                            <Link
                                                to={{
                                                    pathname: getRoute(RoutePathName.orders),
                                                    search: new URLSearchParams({
                                                        deviceSerial: getFieldValue('deviceSerial'),
                                                    }).toString(),
                                                    state: { withBackButton: true },
                                                }}
                                            >
                                                Livraisons de consommables pour cette machine
                                            </Link>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item
                                        key="park"
                                        icon={<User className="text-primary font-16" />}
                                        onClick={async () =>
                                            await show(deviceDrawerId, {
                                                serial: getFieldValue('deviceSerial'),
                                                tab: 'artis',
                                            })
                                        }
                                    >
                                        Consulter la fiche parc
                                    </Menu.Item>

                                    {/* <Menu.Item key="pdf" icon={<FilePdfOutlined className="text-primary font-16" />}>
                                        <Link to={getRoute(RoutePathName.alerts)}>
                                            Editer un PDF de toutes les alertes (pour ce n° de série)
                                        </Link>
                                    </Menu.Item> */}
                                </Menu>
                            }
                            trigger={['click']}
                            className="ml-8"
                            disabled={!getFieldValue('deviceSerial')}
                        >
                            <Button disabled={!getFieldValue('deviceSerial')}>
                                Actions <CaretDownFilled className="text-taupe" />
                            </Button>
                        </Dropdown>
                    )}
                </Form.Item>
                {hasPermission(user, Permission.devices) && (
                    <Link to={getRoute(RoutePathName.deviceSearch)} className="ml-24">
                        <Button icon={<List className="text-primary" />}>Saisir une liste</Button>
                    </Link>
                )}
                <Divider type="vertical" className="h-56 -my-12 mx-12 top-0" />
                <Form.Item name="alertReference" noStyle>
                    <Input placeholder="N° de l'alerte" prefix={<Alarm />} />
                </Form.Item>
                <Form.Item dependencies={['alertReference']} noStyle>
                    {({ getFieldValue }) => (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="detail"
                                        icon={<ZoomInOutlined className="text-primary font-16" />}
                                        onClick={async () => {
                                            show(alertModalId, {
                                                reference: getFieldValue('alertReference'),
                                            });
                                        }}
                                    >
                                        Voir le détail
                                    </Menu.Item>

                                    {hasPermission(user, Permission.alerts, PermissionRight.write) && (
                                        <>
                                            <Menu.Item
                                                key="transfer"
                                                icon={<SendEmail className="text-primary font-16" />}
                                                onClick={() => setReference(getFieldValue('alertReference'))}
                                            >
                                                Transférer vers une autre entité
                                            </Menu.Item>
                                        </>
                                    )}
                                </Menu>
                            }
                            trigger={['click']}
                            className="ml-8"
                            disabled={!getFieldValue('alertReference')}
                        >
                            <Button disabled={!getFieldValue('alertReference')} loading={isFetchingAlert}>
                                Actions <CaretDownFilled className="text-taupe" />
                            </Button>
                        </Dropdown>
                    )}
                </Form.Item>
                <Divider type="vertical" className="h-56 -my-12 mx-12 top-0" />
                <Form.Item name="customerEmail" noStyle>
                    <Input placeholder="Adresse e-mail ou @domaine" prefix={<MailOutlined />} />
                </Form.Item>
                <Form.Item dependencies={['customerEmail']} noStyle>
                    {({ getFieldValue }) => (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="email"
                                        icon={<EmailSearch className="text-primary font-16" />}
                                        onClick={() => {
                                            setQueryParams({
                                                status: 'all',
                                                mail: getFieldValue('customerEmail'),
                                                mailDomain: undefined,
                                                alertGroupIndex: undefined,
                                                alertTypeId: undefined,
                                                alertId: undefined,
                                                alertReference: undefined,
                                                deviceSerial: undefined,
                                                deviceSerialFilter: undefined,
                                            });
                                        }}
                                    >
                                        Interroger toutes les alertes de l’e-mail
                                    </Menu.Item>
                                    <Menu.Item
                                        key="domain"
                                        icon={<Sphere className="text-primary font-16" />}
                                        onClick={() => {
                                            setQueryParams({
                                                status: 'all',
                                                mailDomain: getFieldValue('customerEmail'),
                                                mail: undefined,
                                                alertTypeId: undefined,
                                                alertGroupIndex: undefined,
                                                alertId: undefined,
                                                alertReference: undefined,
                                                deviceSerial: undefined,
                                                deviceSerialFilter: undefined,
                                            });
                                        }}
                                    >
                                        Interroger toutes les alertes du @domaine
                                    </Menu.Item>
                                    <Menu.Item key="listEmail" icon={<MailPackage className="text-primary font-16" />}>
                                        Lister toutes les adresses e-mail du @domaine
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                            className="ml-8"
                            disabled={!getFieldValue('customerEmail')}
                        >
                            <Button disabled={!getFieldValue('customerEmail')}>
                                Actions <CaretDownFilled className="text-taupe" />
                            </Button>
                        </Dropdown>
                    )}
                </Form.Item>

                <Divider type="vertical" className="h-56 -my-12 mx-12 top-0" />
                <Button icon={<DeleteOutlined className="font-20" />} type="text" htmlType="reset" />
            </Form>
        </ToolbarCard>
    );
});

export default AlertsActionBar;

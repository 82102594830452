import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { details, MailMessageIdPayload } from './api/mailMessages';
import { MailMessage } from './api/types';

export const mailMessagesKeys = {
    all: ['mailMessages'],
    details: () => [...mailMessagesKeys.all, 'details'],
    detail: (id?: MailMessageIdPayload) => [...mailMessagesKeys.details(), id],
};

export const useMailMessageDetails = <TData = MailMessage>(
    id?: MailMessageIdPayload,
    options?: UseQueryOptions<MailMessage, AxiosError, TData>
) => {
    return useQuery<MailMessage, AxiosError, TData>(
        mailMessagesKeys.detail(id),
        async () => await details(id),
        options
    );
};

import { FC, FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import './assets/styles/App.less';
import './assets/styles/Fonts.less';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import { getRawRoute, RoutePathName } from './routes';
import { useLogs } from './context/LogsContext';
import Alerts from './pages/alerts';
import AlertHistory from './pages/alertHistory';
import DeviceSearch from './pages/deviceSearch';
import PermissionRoute, { PermissionRouteProps } from './components/PermissionRoute';
import { Permission } from './queries/api/types';
import Orders from './pages/orders';
import AlertStats from './pages/alertStats';
import Subscriptions from './pages/subscriptions';
import Devices from './pages/devices';
import { ExportDevice, ExportSubscription } from './pages/exports';
import DeviceDrawer from './components/drawers/DeviceDrawer';
import AlertModal from './components/modals/AlertModal';

export const deviceDrawerId = 'DeviceDrawer';
export const alertModalId = 'AlertModal';

const routes: Array<PermissionRouteProps & { Children: FunctionComponent }> = [
    {
        path: getRawRoute(RoutePathName.home),
        // permission: Permission.dashboard,
        Children: Home,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.alertHistory),
        permission: Permission.alerts,
        Children: AlertHistory,
    },
    {
        path: getRawRoute(RoutePathName.alertStats),
        permission: Permission.alerts,
        Children: AlertStats,
    },
    {
        path: getRawRoute(RoutePathName.alerts),
        permission: Permission.alerts,
        Children: Alerts,
    },
    {
        path: getRawRoute(RoutePathName.deviceSearch),
        permission: Permission.devices,
        Children: DeviceSearch,
    },
    {
        path: getRawRoute(RoutePathName.orders),
        permission: Permission.orders,
        Children: Orders,
    },
    {
        path: getRawRoute(RoutePathName.subscriptions),
        permission: Permission.subscriptions,
        Children: Subscriptions,
    },
    {
        path: getRawRoute(RoutePathName.exportSubscription),
        permission: Permission.subscriptions,
        Children: ExportSubscription,
    },
    {
        path: getRawRoute(RoutePathName.devices),
        permission: Permission.devices,
        Children: Devices,
    },
    {
        path: getRawRoute(RoutePathName.exportDevice),
        permission: Permission.devices,
        Children: ExportDevice,
    },
];

const App: FC = () => {
    const { sendLogs } = useLogs();

    return (
        <Switch>
            <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
            <ProtectedRoute>
                <MainLayout>
                    <ErrorBoundary sendLogs={sendLogs}>
                        <Switch>
                            {routes.map(({ Children, ...props }) => (
                                <PermissionRoute
                                    key={Array.isArray(props.path) ? props.path.join('') : props.path}
                                    {...props}
                                >
                                    <Children />
                                </PermissionRoute>
                            ))}

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                        <DeviceDrawer id={deviceDrawerId} />
                        <AlertModal id={alertModalId} />
                    </ErrorBoundary>
                </MainLayout>
            </ProtectedRoute>
        </Switch>
    );
};

export default App;

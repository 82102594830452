import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    details,
    list,
    AlertGroupOrganizationIdPayload,
    AlertGroupOrganizationListPayload,
    AlertGroupOrganizationListResponse,
    AlertGroupOrganizationUpdatePayload,
    remove,
    update,
} from './api/alertGroupOrganizations';
import { AlertGroupOrganization } from './api/types';

export const alertGroupOrganizationsKeys = {
    all: ['alertGroupOrganizations'],
    lists: () => [...alertGroupOrganizationsKeys.all, 'list'],
    list: (params?: AlertGroupOrganizationListPayload) => [...alertGroupOrganizationsKeys.lists(), params],
    details: () => [...alertGroupOrganizationsKeys.all, 'details'],
    detail: (id?: AlertGroupOrganizationIdPayload) => [...alertGroupOrganizationsKeys.details(), id],
};

export const useAlertGroupOrganizationList = <TData = AlertGroupOrganizationListResponse>(
    params?: AlertGroupOrganizationListPayload,
    options?: UseQueryOptions<AlertGroupOrganizationListResponse, AxiosError, TData>
) => {
    return useQuery<AlertGroupOrganizationListResponse, AxiosError, TData>(
        alertGroupOrganizationsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useAlertGroupOrganizationDetails = <TData = AlertGroupOrganization>(
    id?: AlertGroupOrganizationIdPayload,
    options?: UseQueryOptions<AlertGroupOrganization, AxiosError, TData>
) => {
    return useQuery<AlertGroupOrganization, AxiosError, TData>(
        alertGroupOrganizationsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useAlertGroupOrganizationUpdate = (
    options?: UseMutationOptions<AlertGroupOrganization, AxiosError, AlertGroupOrganizationUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<AlertGroupOrganization, AxiosError, AlertGroupOrganizationUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(alertGroupOrganizationsKeys.detail(variables.id));
            },
        }
    );
};

export const useAlertGroupOrganizationRemove = (
    options?: UseMutationOptions<undefined, AxiosError, AlertGroupOrganizationIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, AlertGroupOrganizationIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(alertGroupOrganizationsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(alertGroupOrganizationsKeys.lists());
        },
    });
};

import React, { useCallback, useRef, useState } from 'react';
import { useModal, create as createModal, antdModal, show } from '@ebay/nice-modal-react';
import {
    Button,
    Card,
    Descriptions,
    Form,
    Input,
    ModalProps,
    Skeleton,
    Spin,
    Tabs,
    Typography,
    Upload,
    UploadProps,
} from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { CheckCircleOutlined, FilePdfOutlined, UserOutlined } from '@ant-design/icons';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal';

import { Alert, AlertStatus, MailTemplate, Permission, PermissionRight } from '../../queries/api/types';
import { errorMessage, successMessage } from '../../helpers/message';
import {
    defaultErrorMessageWithStatus,
    formatDate,
    formatNumber,
    translateAlertPendingReason,
    translateAlertStatus,
    translateTonerColor,
} from '../../helpers/i18n';
import { AlertListResponse, details as detailsApiCall, list as listApiCall } from '../../queries/api/alerts';
import { useAlertUpdate } from '../../queries/alerts';
import ApiResult from '../ApiResult';
import { hasPermission } from '../../helpers/security';
import { getFullName, isAlertGroupCounter, requiredRule } from '../../helpers';
import AlertTreatButton from '../alert/AlertTreatButton';
import AlertIgnoreButton from '../alert/AlertIgnoreButton';
import AlertPendingButton from '../alert/AlertPendingButton';
import { useAuth } from '../../context/AuthContext';
import { LocalPin, Message, Paperclip, PhoneTelephone, SaveOne } from '../icons';
import useCopyToClipboard, { useWindowSize } from '../../hooks';
import AlertInstructions from '../alert/AlertInstructions';
import NoData from '../NoData';
import AlertStatusTag from '../AlertStatusTag';
import ListColorLevel from '../ColorLevel';
import MessageCard from '../MessageCard';
import { mailTemplatesDrawerId } from '../MainMenu';
import AlertDetailsMessage from '../../pages/alerts/AlertDetailsMessage';
import AlertRemainingTonerLevels from '../alert/AlertRemainingTonerLevels';
import { useMailTemplateList } from '../../queries/mailTemplates';
import { useAlert } from '../../context/AlertContext';
import { useLayout } from '../../context/LayoutContext';
import { deviceDrawerId } from '../../App';

import '../../assets/styles/AlertModal.less';

const getSuccessMessage = (status: AlertStatus, withEmail: boolean) => {
    if (withEmail) {
        if (status === AlertStatus.registered) {
            return 'Commande enregistrée avec email avec succès';
        } else if (status === AlertStatus.treated) {
            return 'Alerte traitée avec email avec succès';
        }
        return '';
    } else {
        if (status === AlertStatus.registered) {
            return 'Commande enregistrée sans email avec succès';
        } else if (status === AlertStatus.treated) {
            return 'Alerte traitée sans email avec succès';
        }
        return '';
    }
};

const isAlertAlreadyFinalized = (error: AxiosError) =>
    error?.response?.status === 400 && error?.response?.data?.data?.alertAlreadyFinalized;

const getErrorMessage = (error: AxiosError) => {
    if (isAlertAlreadyFinalized(error)) {
        return `Le statut a déjà été finalisé avec le statut : ${translateAlertStatus(
            error?.response?.data?.data?.alertStatus
        )}`;
    }

    return defaultErrorMessageWithStatus(error.response?.status);
};

interface FormSchema {
    mailTo: string;
    body: string;
}

interface AlertModalProps extends ModalProps, Record<string, unknown> {
    alertId?: Alert['id'];
    reference?: Alert['reference'];
    isTreating?: boolean;
}

const AlertModal = createModal<AlertModalProps>(({ alertId, isTreating: isTreatingProp, reference, ...props }) => {
    const { organizationId } = useLayout();
    const { setAlert } = useAlert();
    const queryClient = useQueryClient();
    const modal = useModal();
    const { user } = useAuth();
    const [form] = Form.useForm();
    const treatingFormRef = useRef<HTMLDivElement>(null);
    const [windowWidth, windowHeight] = useWindowSize();
    // acquitter ou detail
    const [isTreating, setIsTreating] = useState(isTreatingProp);
    const [activeTabKey, setActiveTabKey] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [copiedValue, copy] = useCopyToClipboard();

    const initialStatus = useRef<AlertStatus | undefined>(undefined);

    const checkAlertStatusOnOpen = (alertData?: Alert) => {
        if (
            isTreating &&
            alertData?.status &&
            [AlertStatus.pending, AlertStatus.received, AlertStatus.ignored].includes(alertData?.status)
        ) {
            initialStatus.current = alertData?.status;
            updateAlert(
                {
                    id: alertData?.id,
                    status: AlertStatus.inProgress,
                },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries('alert-list-drawer');
                        queryClient.invalidateQueries('alert-details-drawer');
                    },
                }
            );
        }
    };
    const {
        data: details,
        isLoading: isLoadingDetails,
        isError: isErrorDetails,
        error: errorDetails,
        refetch: refetchAlertDetails,
    } = useQuery<Alert, AxiosError>(['alert-details-drawer', alertId], async () => await detailsApiCall(alertId), {
        enabled: !!alertId,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (isTreating) {
                form.setFieldsValue({
                    mailTo:
                        data?.device?.hardCodedDeviceInfo?.customer?.mailTicketRecipient ||
                        data?.device?.hardCodedDeviceInfo?.customer?.mail,
                });
                setActiveTabKey('treating');
                checkAlertStatusOnOpen(data);
            }

            if (data?.status && [AlertStatus.registered, AlertStatus.treated].includes(data?.status)) {
                setActiveTabKey('treated');
            }
        },
    });

    const {
        data: list,
        isLoading: isLoadingList,
        isError: isErrorList,
        error: errorList,
        refetch: refetchAlertList,
    } = useQuery<AlertListResponse, AxiosError>(
        ['alert-list-drawer', { reference, organization: organizationId }],
        async () => await listApiCall({ reference, organization: organizationId }).then((response) => response.data),
        {
            enabled: !!reference,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const alert = data?.items?.[0];

                if (isTreating) {
                    form.setFieldsValue({
                        mailTo:
                            alert?.device?.hardCodedDeviceInfo?.customer?.mailTicketRecipient ||
                            alert?.device?.hardCodedDeviceInfo?.customer?.mail,
                    });
                    setActiveTabKey('treating');
                    checkAlertStatusOnOpen(alert);
                }

                if (alert?.status && [AlertStatus.registered, AlertStatus.treated].includes(alert?.status)) {
                    setActiveTabKey('treated');
                }
            },
        }
    );

    const alert = reference ? list?.items?.[0] : details;
    const { mutateAsync: updateAlert, isLoading: isUpdating } = useAlertUpdate();
    const isLoading = isLoadingList || isLoadingDetails;
    const isError = isErrorList || isErrorDetails;
    const error = errorList || errorDetails;
    const refetchAlert = useCallback(() => {
        if (reference) {
            refetchAlertList();
        } else {
            refetchAlertDetails();
        }
    }, [reference, refetchAlertDetails, refetchAlertList]);
    const onClose = useCallback(() => {
        if (initialStatus.current && alert?.status === AlertStatus.inProgress) {
            updateAlert(
                {
                    id: alert?.id,
                    status: initialStatus.current,
                },
                {
                    onSuccess: () => {
                        modal.hide();
                    },
                    onError: (error) => {
                        errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                    },
                }
            );
        } else {
            modal.hide();
        }
    }, [alert?.status, alert?.id, modal, updateAlert]);

    const onClickCopyLocalization = async () => {
        const result = await copy(alert!.device.erpDeviceInfo!.customer!.address!.directives!);

        if (result) {
            successMessage({ content: 'Localisation copiée' });
        }
    };

    const onClickCopyPhone = async () => {
        const result = await copy(
            alert?.device?.erpDeviceInfo?.customer?.phone || alert?.device?.hardCodedDeviceInfo?.customer?.phone || ''
        );

        if (result) {
            successMessage({ content: 'Téléphone copié' });
        }
    };

    const onClickPdf = () => {
        // to implement
    };
    const mailTemplatesList = useMailTemplateList(
        {
            alertType: alert?.alertType.id,
        },
        {
            enabled: !!isTreating && !!alert?.alertType.id,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({ body: data.items?.[0]?.body });
                setMailTemplate(data.items?.[0]);
            },
        }
    );
    const [mailTemplate, setMailTemplate] = useState<MailTemplate | undefined>(mailTemplatesList.data?.items?.[0]);

    const onSubmit = async (status: AlertStatus, withEmail: boolean) => {
        if (withEmail) {
            try {
                const { attachments, ...values } = await form.validateFields();

                updateAlert(
                    {
                        id: alert?.id,
                        status,
                        mailResponse: {
                            mailTemplate: mailTemplate?.id,
                            subject: mailTemplate?.subject,
                            ...values,
                        },
                        attachments,
                    },
                    {
                        onSuccess: (data) => {
                            successMessage({ content: getSuccessMessage(status, withEmail) });
                            setAlert(data);
                            modal.resolve({ ...values });
                            modal.hide();
                        },
                        onError: (error) => {
                            errorMessage({ content: getErrorMessage(error) });
                            if (isAlertAlreadyFinalized(error)) {
                                refetchAlert();
                            }
                        },
                    }
                );
            } catch (error) {
                //
            }
        } else {
            updateAlert(
                {
                    id: alert?.id,
                    status,
                },
                {
                    onSuccess: (data) => {
                        successMessage({ content: getSuccessMessage(status, withEmail) });
                        setAlert(data);
                        modal.resolve();
                        modal.hide();
                    },
                    onError: (error) => {
                        errorMessage({ content: getErrorMessage(error) });
                        if (isAlertAlreadyFinalized(error)) {
                            refetchAlert();
                        }
                    },
                }
            );
        }
    };

    const onBeforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (file.size / 1024 > 20000) {
            errorMessage({
                content: 'Le fichier dépasse la limite de taille maximum de 20Mo',
            });
            return Upload.LIST_IGNORE;
        }

        return false;
    };

    return (
        <DraggableModalProvider>
            <DraggableModal
                className={'antd-alert-modal'}
                footer={null}
                maskClosable={true}
                title={`${isTreating ? 'Acquitter' : 'Détail de'} l'alerte`}
                {...props}
                {...antdModal(modal)}
                onCancel={onClose}
                initialHeight={windowHeight * 0.5}
                initialWidth={windowWidth * 0.5}
            >
                {isLoading ? (
                    <Skeleton active />
                ) : isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : !alert ? (
                    <ApiResult status={404} />
                ) : (
                    <>
                        <div className={'flex justify-between items-center mb-16'}>
                            <div className="space-x-2">
                                {!isTreating &&
                                    hasPermission(user, Permission.alerts, PermissionRight.write) &&
                                    !isAlertGroupCounter(alert) &&
                                    alert?.status !== AlertStatus.treated && (
                                        <>
                                            <AlertTreatButton
                                                alertStatus={alert?.status}
                                                onAllowed={() => {
                                                    refetchAlert();
                                                    setIsTreating(true);
                                                }}
                                                alertId={alertId}
                                                inDrawer
                                            />
                                            <AlertIgnoreButton alertId={alert?.id} alertStatus={alert?.status} />
                                            <AlertPendingButton alertId={alert?.id} alertStatus={alert?.status} />
                                        </>
                                    )}
                            </div>
                            <div className="header-btn-group">
                                {isTreating && (
                                    <>
                                        <Button
                                            icon={<LocalPin className="text-primary" />}
                                            onClick={onClickCopyLocalization}
                                            disabled={!alert?.device?.erpDeviceInfo?.customer?.address?.directives}
                                        >
                                            Copier localisation
                                        </Button>
                                        <Button
                                            icon={<PhoneTelephone className="text-primary" />}
                                            onClick={onClickCopyPhone}
                                            disabled={
                                                !alert?.device?.erpDeviceInfo?.customer?.phone &&
                                                !alert?.device?.hardCodedDeviceInfo?.customer?.phone
                                            }
                                        >
                                            Copier N° téléphone
                                        </Button>
                                    </>
                                )}
                                <Button
                                    icon={<UserOutlined className="text-primary" />}
                                    disabled={!alert?.device?.id}
                                    onClick={() => {
                                        show(deviceDrawerId, {
                                            id: alert?.device?.id,
                                            tab: 'artis',
                                        });
                                    }}
                                >
                                    Fiche parc
                                </Button>
                                {!isTreating && (
                                    <Button icon={<FilePdfOutlined className="text-primary" />} onClick={onClickPdf}>
                                        Editer PDF
                                    </Button>
                                )}
                            </div>
                        </div>
                        {isTreating && (
                            <div className="mb-16">
                                <AlertInstructions instructions={alert?.device?.instruction} />
                            </div>
                        )}
                        <Descriptions
                            size="small"
                            column={windowWidth < 1280 ? 1 : 2}
                            className="mb-16"
                            labelStyle={{ width: 200 }}
                            contentStyle={{ width: 274 }}
                            bordered
                        >
                            <Descriptions.Item label="N° alerte">{alert?.reference ?? <NoData />}</Descriptions.Item>
                            <Descriptions.Item label="Date & Heure">
                                {alert?.mailMessage.dateFromBody ? (
                                    formatDate(alert?.mailMessage.dateFromBody, {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })
                                ) : (
                                    <NoData />
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Statut">
                                <AlertStatusTag alert={alert} isList />
                            </Descriptions.Item>
                            <Descriptions.Item label="Type">{alert?.alertType.name ?? <NoData />}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            size="small"
                            column={windowWidth < 1280 ? 1 : 2}
                            className="mb-16"
                            labelStyle={{ width: 200 }}
                            contentStyle={{ width: 274 }}
                            bordered
                        >
                            <Descriptions.Item label="N° de série">
                                {alert?.device?.serial ? (
                                    <Typography.Text copyable>{alert?.device?.serial}</Typography.Text>
                                ) : (
                                    <NoData />
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Modèle">
                                {alert?.device?.hardCodedDeviceInfo?.model ?? <NoData />}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small" column={1} className="mb-16" labelStyle={{ width: 200 }} bordered>
                            <Descriptions.Item label="Objet">
                                {alert?.object ? (
                                    <Typography.Text copyable>{alert?.object}</Typography.Text>
                                ) : (
                                    <NoData />
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Préconisation">
                                {([
                                    alert?.pendingStatusReason &&
                                        translateAlertPendingReason(alert?.pendingStatusReason),
                                    alert?.pendingStatusReasonComment,
                                ]
                                    .filter(Boolean)
                                    .join(' ') ||
                                    alert?.errorCode?.recommendation) ?? <NoData />}
                            </Descriptions.Item>
                            {alert?.errorCode && (
                                <>
                                    <Descriptions.Item label="Code Panne">
                                        {alert?.errorCode?.reference ?? <NoData />}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="État de la machine">
                                        {alert?.errorCode?.description ?? <NoData />}
                                    </Descriptions.Item>
                                </>
                            )}
                            <Descriptions.Item label="Référence à commander">
                                {alert?.colorReferenceToOrder ? (
                                    <ListColorLevel
                                        colorLevel={{ [alert?.colorReferenceToOrder]: 1 }}
                                        label={translateTonerColor(alert?.colorReferenceToOrder)}
                                        large
                                    />
                                ) : (
                                    alert?.referenceToOrder ?? <NoData />
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small" column={1} className="mb-16" labelStyle={{ width: 200 }} bordered>
                            <Descriptions.Item label="Nom du client">
                                {alert?.device.hardCodedDeviceInfo?.customer?.name ?? <NoData />}
                            </Descriptions.Item>
                        </Descriptions>
                        <div ref={treatingFormRef} />
                        <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
                            {[AlertStatus.treated, AlertStatus.registered].includes(alert?.status) ? (
                                <Tabs.TabPane tab="Traitement de l’alerte" key="treated">
                                    <Descriptions
                                        size="small"
                                        column={2}
                                        className="mb-16"
                                        labelStyle={{ width: 200 }}
                                        contentStyle={{ width: 274 }}
                                        bordered
                                    >
                                        <Descriptions.Item label="Date">
                                            {alert?.treatedAt ? formatDate(alert?.treatedAt) : <NoData />}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Heure">
                                            {alert?.treatedAt ? (
                                                formatDate(alert?.treatedAt, {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })
                                            ) : (
                                                <NoData />
                                            )}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Opérateur" span={2}>
                                            {alert?.operator ? getFullName(alert?.operator) : <NoData />}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {alert?.mailResponse && (
                                        <MessageCard
                                            mailResponse={alert?.mailResponse}
                                            title="Mail réponse au client"
                                            error={error}
                                            isError={isError}
                                            loading={isLoading}
                                            bodyHeight={381}
                                        />
                                    )}
                                </Tabs.TabPane>
                            ) : (
                                isTreating && (
                                    <Tabs.TabPane tab="Traiter l’alerte" key="treating">
                                        <Form<FormSchema>
                                            form={form}
                                            layout="vertical"
                                            initialValues={{
                                                mailTo:
                                                    alert?.device?.hardCodedDeviceInfo?.customer?.mailTicketRecipient ||
                                                    alert?.device?.hardCodedDeviceInfo?.customer?.mail,
                                                body: mailTemplate?.body,
                                            }}
                                            requiredMark={false}
                                            scrollToFirstError
                                        >
                                            <Spin spinning={isUpdating}>
                                                <Card className="mb-16">
                                                    <div className="flex gap-32">
                                                        <Form.Item label="Nom du client" className="flex-1">
                                                            <Typography.Text className="font-semibold">
                                                                {alert?.device.hardCodedDeviceInfo?.customer?.name ?? (
                                                                    <NoData />
                                                                )}
                                                            </Typography.Text>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={['mailTo']}
                                                            rules={[
                                                                requiredRule,
                                                                {
                                                                    type: 'email',
                                                                    message: 'Veuillez entrer une adresse email valide',
                                                                },
                                                            ]}
                                                            label="Email destinataire"
                                                            className="flex-1"
                                                        >
                                                            <Input placeholder="Saisir une adresse email" allowClear />
                                                        </Form.Item>
                                                    </div>
                                                    <Form.Item name={['body']} label="Message" rules={[requiredRule]}>
                                                        <Input.TextArea placeholder="Saisir le message" rows={13} />
                                                    </Form.Item>
                                                    <div className="answer-btn-group">
                                                        <div className={'answer-btn-bigroup'}>
                                                            <Button
                                                                icon={<Message className="text-primary" />}
                                                                className={'primary_btn'}
                                                                onClick={() => {
                                                                    show(mailTemplatesDrawerId, {
                                                                        selection: true,
                                                                    }).then((value) => {
                                                                        setMailTemplate(value as MailTemplate);
                                                                        form.setFieldsValue({
                                                                            body: (value as MailTemplate).body,
                                                                        });
                                                                    });
                                                                }}
                                                            >
                                                                Réponse prédéfinies
                                                            </Button>
                                                            <div className={'upload-alert primary_btn'}>
                                                                <Form.Item
                                                                    name={['attachments']}
                                                                    valuePropName="fileList"
                                                                    getValueFromEvent={(e: any) =>
                                                                        Array.isArray(e) ? e : e?.fileList
                                                                    }
                                                                    noStyle
                                                                    className={'w-full'}
                                                                >
                                                                    <Upload
                                                                        beforeUpload={onBeforeUpload}
                                                                        onChange={({ fileList }) => {
                                                                            form.setFieldsValue({
                                                                                attachments: fileList?.length
                                                                                    ? fileList
                                                                                    : undefined,
                                                                            });
                                                                        }}
                                                                        onRemove={() => {
                                                                            form.setFieldsValue({
                                                                                attachments: undefined,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <Form.Item
                                                                            className={'w-full'}
                                                                            dependencies={['attachments']}
                                                                            noStyle
                                                                        >
                                                                            {({ getFieldValue }) => (
                                                                                <Button
                                                                                    className={'w-full'}
                                                                                    icon={
                                                                                        <Paperclip className="text-primary" />
                                                                                    }
                                                                                    disabled={
                                                                                        !!getFieldValue('attachments')
                                                                                            ?.length
                                                                                    }
                                                                                >
                                                                                    Ajouter fichier
                                                                                </Button>
                                                                            )}
                                                                        </Form.Item>
                                                                    </Upload>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="answer-btn-bigroup">
                                                            <Button
                                                                type="primary"
                                                                className={'primary_btn'}
                                                                icon={<CheckCircleOutlined className="text-white" />}
                                                                onClick={async () =>
                                                                    await onSubmit(AlertStatus.treated, true)
                                                                }
                                                            >
                                                                Traiter avec e-mail
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                className={'primary_btn'}
                                                                icon={<SaveOne className="text-white" />}
                                                                onClick={async () =>
                                                                    await onSubmit(AlertStatus.registered, true)
                                                                }
                                                            >
                                                                Commande enregistrée avec e-mail
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Spin>
                                            <Card>
                                                <Spin spinning={isUpdating}>
                                                    <div className="acquitter-box">
                                                        <Typography.Text>
                                                            Acquitter l&rsquo;alerte sans e-mail
                                                        </Typography.Text>
                                                        <div className="flex flex-col gap-4">
                                                            <Button
                                                                icon={<CheckCircleOutlined className="text-primary" />}
                                                                onClick={async () =>
                                                                    await onSubmit(AlertStatus.treated, false)
                                                                }
                                                            >
                                                                Traiter sans e-mail
                                                            </Button>
                                                            <Button
                                                                icon={<SaveOne className="text-primary" />}
                                                                onClick={async () =>
                                                                    await onSubmit(AlertStatus.registered, false)
                                                                }
                                                            >
                                                                Commande enregistrée sans e-mail
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Spin>
                                            </Card>
                                        </Form>
                                    </Tabs.TabPane>
                                )
                            )}
                            <Tabs.TabPane tab="Message reçu" key="message">
                                <AlertDetailsMessage
                                    alertId={alert?.id}
                                    title={undefined}
                                    bodyHeight={416}
                                    withCopyButton
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Surveillance toner / Compteurs" key="toner">
                                <div className="flex justify-around">
                                    <div>
                                        <AlertRemainingTonerLevels device={alert?.device} />
                                    </div>
                                    <div style={{ width: 506 }}>
                                        <Typography.Paragraph className="text-center" style={{ lineHeight: '44px' }}>
                                            Relevé CNO{' '}
                                            {alert?.device.lastCounter?.updatedAt
                                                ? `au ${formatDate(alert?.device.lastCounter.updatedAt)}`
                                                : null}
                                        </Typography.Paragraph>
                                        <Descriptions size="small" column={1} labelStyle={{ width: 200 }} bordered>
                                            <Descriptions.Item label="Total">
                                                {formatNumber(
                                                    (alert?.device?.lastCounter?.counter?.black ?? 0) +
                                                        (alert?.device?.lastCounter?.counter?.color ?? 0) +
                                                        (alert?.device?.lastCounter?.counter?.lowColor ?? 0)
                                                )}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Noir">
                                                {formatNumber(alert?.device?.lastCounter?.counter?.black)}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Couleur">
                                                {formatNumber(alert?.device?.lastCounter?.counter?.color)}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Marianne">
                                                {formatNumber(alert?.device?.lastCounter?.counter?.lowColor)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </>
                )}
            </DraggableModal>
        </DraggableModalProvider>
    );
});

export default AlertModal;

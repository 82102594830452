import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { details, list, SourceIdPayload, SourceListPayload, SourceListResponse } from './api/sources';
import { Source } from './api/types';

export const sourcesKeys = {
    all: ['sources'],
    lists: () => [...sourcesKeys.all, 'list'],
    list: (params?: SourceListPayload) => [...sourcesKeys.lists(), params],
    details: () => [...sourcesKeys.all, 'details'],
    detail: (id?: SourceIdPayload) => [...sourcesKeys.details(), id],
};

export const useSourceList = <TData = SourceListResponse>(
    params?: SourceListPayload,
    options?: UseQueryOptions<SourceListResponse, AxiosError, TData>
) => {
    return useQuery<SourceListResponse, AxiosError, TData>(sourcesKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        staleTime: Infinity,
        ...options,
    });
};

export const useSourceDetails = <TData = Source>(
    id?: SourceIdPayload,
    options?: UseQueryOptions<Source, AxiosError, TData>
) => {
    return useQuery<Source, AxiosError, TData>(sourcesKeys.detail(id), async () => await details(id), options);
};

import { createContext, useState, FC, useMemo, useContext, Dispatch, SetStateAction } from 'react';

import { Alert } from '../queries/api/types';

interface AlertContextTypes {
    alert?: Alert;
    setAlert: Dispatch<SetStateAction<Alert | undefined>>;
}

export const AlertContext = createContext<AlertContextTypes | null>(null);
AlertContext.displayName = 'AlertContext';

export const AlertProvider: FC = ({ children }) => {
    const [alert, setAlert] = useState<Alert>();

    const layoutContext = useMemo(
        () => ({
            alert,
            setAlert,
        }),
        [alert, setAlert]
    );

    return <AlertContext.Provider value={layoutContext}>{children}</AlertContext.Provider>;
};

export const useAlert = () => {
    const context = useContext(AlertContext);

    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }

    return context;
};

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 1a1 1 0 0 1 1 1v1h5V2a1 1 0 1 1 2 0v1H21a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4.5V2a1 1 0 0 1 1-1Zm-1 4H3v4h18V5h-4.5v1a1 1 0 1 1-2 0V5h-5v1a1 1 0 0 1-2 0V5ZM21 11h-4.5v4H21v-4Zm0 6h-4.5v4H21v-4Zm-6.5 4v-4h-5v4h5Zm-7 0v-4H3v4h4.5ZM3 15h4.5v-4H3v4Zm6.5-4v4h5v-4h-5Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgCalendarThree: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-calendar-three', props.className)} />
));
export default SvgCalendarThree;

import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { ModalProps, Modal, Upload, UploadProps, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

import { errorMessage, successMessage } from '../../helpers/message';
import { useCountersUpload } from '../../queries/countersUpload';
import { defaultErrorMessageWithStatus } from '../../helpers/i18n';
import { useLayout } from '../../context/LayoutContext';

const CountersUploadModal = NiceModal.create<
    Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'> & Record<string, any>
>((props) => {
    const { organizationId } = useLayout();
    const modal = useModal();
    const [file, setFile] = useState<UploadFile>();
    const { mutate: upload, isLoading } = useCountersUpload();
    const onBeforeUpload: UploadProps['beforeUpload'] = (_file) => {
        if (!_file.name.endsWith('.xls') && !_file.name.endsWith('.xlsx')) {
            errorMessage({
                content: 'Seuls les fichiers Excel (.xls ou .xlsx) sont supportés',
            });
            return Upload.LIST_IGNORE;
        }

        if (_file.size / 1024 > 20000) {
            errorMessage({
                content: 'Le fichier dépasse la limite de taille maximum de 20Mo',
            });
            return Upload.LIST_IGNORE;
        }

        return false;
    };
    const onSubmit = () => {
        if (file) {
            upload(
                { file, organizationId },
                {
                    onSuccess: () => {
                        successMessage({ content: 'Import effectué avec succès' });
                        modal.hide();
                    },
                    onError: (error) => {
                        errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                    },
                }
            );
        }
    };

    return (
        <Modal
            {...props}
            {...antdModal(modal)}
            title="Import des compteurs externes"
            footer={[
                <Button key="submit" type="primary" loading={isLoading} onClick={onSubmit} disabled={!file}>
                    Envoyer
                </Button>,
            ]}
            width={500}
            centered
        >
            <Upload.Dragger beforeUpload={onBeforeUpload} onChange={({ file }) => setFile(file)} maxCount={1}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Cliquez ou faites glisser ici votre fichier .xls</p>
                <p className="ant-upload-hint">(Max 20 Mo)</p>
            </Upload.Dragger>
            <div className="flex justify-end"></div>
        </Modal>
    );
});

export default CountersUploadModal;

import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';

import api from '../queries/api/apiClient';
import { ApiInfos } from '../queries/api/types';

export type Env = 'development' | 'preprod' | 'production' | undefined;

function useEnv() {
    const [env, setEnv] = useState<Env>(process.env.REACT_APP_ENV as 'development' | undefined);
    useQuery<ApiInfos, AxiosError>(
        ['apiInfos'],
        async () => await api.get<ApiInfos>('/').then((response) => response?.data),
        {
            onSuccess: (data) => {
                if (!env) {
                    setEnv(data.env);
                }
            },
        }
    );

    return env;
}

export default useEnv;

import { FC } from 'react';
import { PanelResizeHandle, PanelResizeHandleProps } from 'react-resizable-panels';

import { classNames } from '../helpers';
import { HorizontalResize } from '../components/icons';

type ResizeHandleProps = Omit<PanelResizeHandleProps, 'tagName'>;

const ResizeHandle: FC<ResizeHandleProps> = ({ className, id, children, ...props }) => (
    <PanelResizeHandle className={classNames(className, '')} id={id} {...props}>
        <div className="flex items-center justify-center w-14 h-full mx-2">
            {children || <HorizontalResize className="text-taupe" />}
        </div>
    </PanelResizeHandle>
);

export default ResizeHandle;

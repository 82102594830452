import { VFC } from 'react';
import { PanelGroup, Panel } from 'react-resizable-panels';

import ResizeHandle from '../../components/ResizeHandle';
import useQueryParams from '../../hooks/queryParams';
import AlertDetailsBar from './AlertDetailsBar';
import AlertDetailsHistory from './AlertDetailsHistory';
import AlertDetailsMessage from './AlertDetailsMessage';
import AlertDetailsToner from './AlertDetailsToner';

const AlertDetails: VFC = () => {
    const [queryParams] = useQueryParams('alerts');
    const alertId = queryParams.get('alertId') ?? undefined;

    return (
        <div className="space-y-4 flex flex-col">
            <AlertDetailsBar />
            <PanelGroup
                direction="horizontal"
                autoSaveId="alert-details-panels-v2"
                className="alert-details-panel-group"
            >
                <Panel defaultSize={40} minSize={20}>
                    <AlertDetailsMessage alertId={alertId} />
                </Panel>
                <ResizeHandle />
                <Panel defaultSize={60} minSize={50}>
                    <div className="flex space-x-4.5">
                        <AlertDetailsToner />
                        <AlertDetailsHistory />
                    </div>
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default AlertDetails;

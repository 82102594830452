import { VFC } from 'react';
import { FormItemProps, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { PrivilegeCode } from '../../queries/api/types';
import { usePrivilegeCodeUpdate } from '../../queries/privilegeCodes';

interface AlertHighlightingSwitchProps extends FormItemProps {
    value: boolean;
    privilegeCodeId: PrivilegeCode['id'];
}

const AlertHighlightingSwitch: VFC<AlertHighlightingSwitchProps> = ({ value, privilegeCodeId }) => {
    const { mutateAsync: updateCode, isLoading } = usePrivilegeCodeUpdate();

    return (
        <Switch
            checked={value}
            onChange={async () => await updateCode({ id: privilegeCodeId, alertHighlighting: !value })}
            loading={isLoading}
            unCheckedChildren={<CloseOutlined />}
            checkedChildren={<CheckOutlined />}
        />
    );
};

export default AlertHighlightingSwitch;

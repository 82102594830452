import { Button, Divider, Empty, Input, Radio, Skeleton, Typography } from 'antd';
import { antdDrawer, create, show, useModal } from '@ebay/nice-modal-react';
import { Fragment, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import CustomDrawer from '../CustomDrawer';
import { ErrorCode } from '../../queries/api/types';
import ApiResult from '../ApiResult';
import { useErrorCodeList } from '../../queries/errorCodes';
import { useDebounce } from '../../hooks';
import ErrorCodesFormDrawer from './ErrorCodesFormDrawer';

const errorCodesFormDrawerId = 'errorCodesFormDrawer';

const ErrorCodesMissingDrawer = create(() => {
    const modal = useModal();
    const [selectedErrorCode, setSelectedErrorCode] = useState<ErrorCode>();
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const {
        data: errorCodes,
        isLoading,
        isError,
        error,
    } = useErrorCodeList({ pageSize: 100, search: debouncedSearch || undefined, managedOnKairos: false });
    const onSubmit = () => {
        show(errorCodesFormDrawerId, { errorCode: selectedErrorCode }).then(() => {
            modal.resolve();
            modal.hide();
        });
    };

    return (
        <CustomDrawer
            title="Codes manquants"
            {...antdDrawer(modal)}
            width={600}
            footer={
                <Button size="large" type="primary" disabled={!selectedErrorCode} onClick={onSubmit} block>
                    Sélectionner
                </Button>
            }
        >
            <Typography.Paragraph className="mb-16" strong>
                Sélectionnez ci-dessous le code panne importé automatiquement à ajouter :
            </Typography.Paragraph>
            <Input
                onChange={(e) => {
                    setSearch(e.target.value);
                    setSelectedErrorCode(undefined);
                }}
                placeholder="Rechercher un code panne"
                suffix={<SearchOutlined />}
                className="mb-16"
                allowClear
            />
            {isLoading ? (
                <Skeleton active />
            ) : isError ? (
                <ApiResult status={error?.response?.status} />
            ) : errorCodes?.totalCount ? (
                <Radio.Group
                    onChange={(e) => setSelectedErrorCode(errorCodes?.items.find((code) => code.id === e.target.value))}
                    className="w-full"
                >
                    <div className="space-y-3 w-full">
                        {errorCodes?.items.map((errorCode, index) => (
                            <Fragment key={errorCode.id}>
                                {index === 0 && <Divider className="m-0" />}
                                <Radio value={errorCode.id} key={errorCode.id} className="w-full">
                                    <span className="font-semibold">{errorCode.reference}</span>
                                </Radio>
                                <Divider className="m-0" />
                            </Fragment>
                        ))}
                    </div>
                </Radio.Group>
            ) : (
                <Empty className="mb-32 text-taupe" image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucun résultat" />
            )}
            <ErrorCodesFormDrawer id={errorCodesFormDrawerId} />
        </CustomDrawer>
    );
});

export default ErrorCodesMissingDrawer;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.22.21a.777.777 0 0 1 1.06 0l4.5 4.285a.69.69 0 0 1 0 1.01l-4.5 4.286a.777.777 0 0 1-1.06 0 .69.69 0 0 1 0-1.01L4.19 5 .22 1.22a.69.69 0 0 1 0-1.01Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgChevronRight: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-chevron-right', props.className)} />
));
export default SvgChevronRight;

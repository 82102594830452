import api from './apiClient';
import { ColorLevelCount, Organization, TonerMonitoringStatus } from './types';

// Controller Interface
export interface DevicesColorLevelCountPayload {
    organization?: Organization['id'];
    colorLevel?: number;
    tonerMonitoringStatus?: TonerMonitoringStatus[];
    eccAndAutoNotFalse?: boolean;
}
export type DevicesColorLevelCountResponse = ColorLevelCount;

// Routes
export const count = async (payload?: DevicesColorLevelCountPayload) => {
    return await api
        .get<DevicesColorLevelCountResponse>(`/devicesColorLevelCount`, { params: payload })
        .then((response) => response?.data);
};

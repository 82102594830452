import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { PrivilegeCode, ListResponse, Organization } from './types';

// Controller Interface

export type PrivilegeCodeUpdatePayload = Partial<PrivilegeCode>;

export type PrivilegeCodeIdPayload = PrivilegeCode['id'];

export type PrivilegeCodeListPayload = SearchPaginationQuery & {
    organization?: Organization['id'];
};
export type PrivilegeCodeListResponse = ListResponse<PrivilegeCode>;

// Routes
export const list = async (payload?: PrivilegeCodeListPayload) => {
    return await api
        .get<PrivilegeCodeListResponse>(`/privilegeCodes`, { params: payload })
        .then((response) => response?.data);
};

export const update = async ({ id, ...payload }: PrivilegeCodeUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<PrivilegeCode>(`/privilegeCodes/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: PrivilegeCodeIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<PrivilegeCode>(`/privilegeCodes/${id}`).then((response) => response?.data);
};

import { AxiosError } from 'axios';
import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from 'react-query';

import { errorMessage } from '../helpers/message';
import { alertGroupOrganizationsKeys } from './alertGroupOrganizations';
import {
    details,
    list,
    AlertIdPayload,
    AlertListPayload,
    AlertListResponse,
    AlertUpdatePayload,
    remove,
    update,
    AlertCreatePayload,
    create,
    AlertExportResponse,
    AlertIgnorePayload,
    ignore,
} from './api/alerts';
import { Alert } from './api/types';

export const alertsKeys = {
    all: ['alerts'],
    lists: () => [...alertsKeys.all, 'list'],
    list: (params?: AlertListPayload) => [...alertsKeys.lists(), params],
    export: (params?: AlertListPayload) => [...alertsKeys.all, params],
    listInfinite: (params?: AlertListPayload) => [...alertsKeys.lists(), params],
    details: () => [...alertsKeys.all, 'details'],
    detail: (id?: AlertIdPayload) => [...alertsKeys.details(), id],
};

export const useAlertListInfinite = <TData = AlertListResponse>(
    payload: AlertListPayload,
    options?: UseInfiniteQueryOptions<
        AlertListResponse,
        AxiosError,
        TData,
        AlertListResponse,
        Array<string | AlertListPayload | undefined>
    >
) => {
    return useInfiniteQuery<AlertListResponse, AxiosError, TData, Array<string | AlertListPayload | undefined>>(
        alertsKeys.listInfinite(payload),
        async () => await list().then((response) => response.data),
        options
    );
};

export const useAlertList = <TData = AlertListResponse>(
    params: AlertListPayload,
    options?: UseQueryOptions<AlertListResponse, AxiosError, TData>
) => {
    return useQuery<AlertListResponse, AxiosError, TData>(
        alertsKeys.list(params),
        async () => await list(params).then((response) => response.data),
        {
            ...options,
            keepPreviousData: true,
        }
    );
};

export const useAlertListExport = <TData = AlertExportResponse>(
    params: AlertListPayload,
    options?: UseQueryOptions<AlertExportResponse, AxiosError, TData>
) => {
    return useQuery<AlertExportResponse, AxiosError, TData>(
        alertsKeys.export(params),
        async () => await list(params, { headers: { Accept: '\tapplication/vnd.ms-excel' } }),
        {
            ...options,
        }
    );
};

export const useAlertExport = <TData = AlertExportResponse>(
    params: AlertListPayload,
    options?: UseQueryOptions<AlertExportResponse, AxiosError, TData>
) => {
    return useQuery<AlertExportResponse, AxiosError, TData>(
        alertsKeys.export(params),
        async () => await list(params, { headers: { Accept: '\tapplication/vnd.ms-excel' }, responseType: 'blob' }),
        {
            ...options,
        }
    );
};

export const useAlertDetails = <TData = Alert>(
    id?: AlertIdPayload,
    options?: UseQueryOptions<Alert, AxiosError, TData>
) => {
    return useQuery<Alert, AxiosError, TData>(alertsKeys.detail(id), async () => await details(id), options);
};

export const useAlertCreate = (options?: UseMutationOptions<Alert, AxiosError, AlertCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Alert, AxiosError, AlertCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(alertsKeys.lists());

            queryClient.invalidateQueries(alertGroupOrganizationsKeys.lists());
        },
    });
};

export const useAlertUpdate = (options?: UseMutationOptions<Alert, AxiosError, AlertUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Alert, AxiosError, AlertUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            queryClient.invalidateQueries(alertsKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(alertsKeys.detail(variables.id));

            queryClient.invalidateQueries(alertGroupOrganizationsKeys.lists());
        },
        onError: (error, variables, context) => {
            options?.onError?.(error, variables, context);

            if (error?.response?.status === 409) {
                const username = error?.response?.data?.operator?.username as string | undefined;
                errorMessage({
                    content: `Cette alerte est déjà en cours de traitement par ${
                        username ? `l'utilisateur ${username}` : 'un autre utilisateur'
                    }`,
                });
            }
        },
    });
};

export const useAlertRemove = (options?: UseMutationOptions<undefined, AxiosError, AlertIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, AlertIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(alertsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(alertsKeys.lists());

            queryClient.invalidateQueries(alertGroupOrganizationsKeys.lists());
        },
    });
};

export const useAlertsIgnore = (options?: UseMutationOptions<undefined, AxiosError, AlertIgnorePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, AlertIgnorePayload>(async (params) => await ignore(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            queryClient.invalidateQueries(alertsKeys.lists());

            queryClient.invalidateQueries(alertGroupOrganizationsKeys.lists());
        },
    });
};

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 6a5 5 0 1 1 6 4.9V19a1 1 0 1 1-2 0v-8.1A5.002 5.002 0 0 1 7 6Zm8 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            fill="currentColor"
        />
        <path
            d="M6 15a1 1 0 0 0-.832.445l-4 6A1 1 0 0 0 2 23h20a1 1 0 0 0 .832-1.555l-4-6A1 1 0 0 0 18 15h-2a1 1 0 1 0 0 2h1.465l2.666 4H3.87l2.666-4H8a1 1 0 1 0 0-2H6Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgLocalPin: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-local-pin', props.className)} />
));
export default SvgLocalPin;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.039 32a1.28 1.28 0 0 1-.746-.24 1.24 1.24 0 0 1-.457-.628H7.567a1.24 1.24 0 0 1-.456.627c-.216.156-.478.24-.746.241H3.95c-.269 0-.53-.085-.746-.24a1.24 1.24 0 0 1-.457-.628H1.26c-.334 0-.654-.13-.89-.362a1.227 1.227 0 0 1-.37-.875V1.237C0 .909.133.595.37.363.605.13.925 0 1.26 0h13.87a1.274 1.274 0 0 1 .974.455l3.06 3.662h1.973c.334 0 .654.13.89.363.236.232.37.546.37.874v5.17l3.642-2.366c.28-.174.617-.232.94-.164.323.068.606.258.788.528a1.221 1.221 0 0 1-.335 1.704l-5.029 3.27v5.516l3.642-2.366a1.28 1.28 0 0 1 1.747.353c.184.275.25.61.182.93a1.237 1.237 0 0 1-.542.785l-5.029 3.269v7.912c0 .328-.133.643-.37.875a1.275 1.275 0 0 1-.89.362h-1.488a1.24 1.24 0 0 1-.456.627c-.216.156-.478.24-.746.241h-2.414Zm3.837-3.341v-6.105h-3.77l-.716 2.352a1.248 1.248 0 0 1-.455.637c-.218.16-.481.246-.752.248H8.026a1.286 1.286 0 0 1-.752-.248 1.247 1.247 0 0 1-.455-.637l-.716-2.353H2.52v6.107l17.355-.002Zm-6.631-5.348.231-.757H8.732l.232.757h4.281Zm6.631-3.237v-6.006h-3.77l-.716 2.352a1.248 1.248 0 0 1-.455.637c-.218.159-.481.246-.752.248H8.026a1.286 1.286 0 0 1-.752-.248 1.247 1.247 0 0 1-.455-.637l-.716-2.352H2.52v6.006h17.355Zm-6.631-5.25.231-.756h-4.74l.229.757h4.28Zm6.638-3.23V6.59H2.52v5.003h17.361Zm-3.98-7.477-1.371-1.643H2.52v1.643h13.382Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgPrinter: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-printer', props.className)} />
));
export default SvgPrinter;

import { VFC } from 'react';
import { Typography } from 'antd';

import { Color, Device } from '../../queries/api/types';
import TonerGauge from '../TonerGauge';
import { formatDate } from '../../helpers/i18n';

interface AlertRemainingTonerLevelsProps {
    device?: Device;
}

const AlertRemainingTonerLevels: VFC<AlertRemainingTonerLevelsProps> = ({ device }) => (
    <>
        <Typography.Paragraph className="text-center" style={{ lineHeight: '44px' }}>
            Niveaux restant de Toner (en %){' '}
            {device?.colorLevels?.updatedAt ? `au ${formatDate(device?.colorLevels?.updatedAt)}` : null}
        </Typography.Paragraph>
        <div className="flex justify-center gap-24 mb-24">
            <TonerGauge direction="vertical" color={Color.yellow} value={device?.colorLevels?.yellow} tooltip large />
            <TonerGauge direction="vertical" color={Color.magenta} value={device?.colorLevels?.magenta} tooltip large />
            <TonerGauge direction="vertical" color={Color.cyan} value={device?.colorLevels?.cyan} tooltip large />
            <TonerGauge direction="vertical" color={Color.black} value={device?.colorLevels?.black} tooltip large />
            <TonerGauge
                direction="vertical"
                color={Color.erasableBlue}
                value={device?.colorLevels?.erasableBlue}
                tooltip
                large
            />
        </div>
    </>
);

export default AlertRemainingTonerLevels;

import { VFC } from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { AlertStatus } from '../../queries/api/types';
import { classNames } from '../../helpers';
import { translateAlertStatus } from '../../helpers/i18n';

interface AlertStatusFormItemProps extends FormItemProps {
    selectProps?: SelectProps<AlertStatus>;
    optionFilter?: (record: AlertStatus) => boolean;
    customOptions?: DefaultOptionType[];
    isWhite?: boolean;
}

const AlertStatusFormItem: VFC<AlertStatusFormItemProps> = ({
    selectProps,
    optionFilter = () => true,
    customOptions,
    isWhite,
    ...props
}) => (
    <Form.Item {...props}>
        <Select
            {...selectProps}
            placeholder={selectProps?.placeholder ?? 'Choisir un type'}
            className={classNames(isWhite && 'select-white', selectProps?.className)}
            filterOption={false}
            options={(
                Object.values(AlertStatus)
                    .filter(optionFilter)
                    .map((status) => ({
                        label: translateAlertStatus(status),
                        value: status,
                    })) as unknown as DefaultOptionType
            )?.concat(customOptions ?? [])}
            showSearch
        />
    </Form.Item>
);

export default AlertStatusFormItem;

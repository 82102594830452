import { VFC } from 'react';

import Seo from '../../components/Seo';
import AlertsActionBar from './AlertsActionBar';
import AlertGroupOrganizationsList from './AlertGroupOrganizationsList';
import AlertsList from './AlertsList';
import AlertDetails from './AlertDetails';

const AlertsWrapper: VFC = () => {
    return (
        <>
            <Seo title="Synthèse des alertes" />
            <div className="space-y-4">
                <AlertsActionBar />
                <AlertGroupOrganizationsList />
                <div className="bg-grey-darker -mx-16  -mb-16 p-16 space-y-4">
                    <AlertsList />
                    <AlertDetails />
                </div>
            </div>
        </>
    );
};

export default AlertsWrapper;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 3.5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2V14a1 1 0 1 1-2 0V3.5H5v18h5.5a1 1 0 1 1 0 2H5a2 2 0 0 1-2-2v-18Z"
            fill="currentColor"
        />
        <path
            d="M7.5 6.5a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1ZM8.5 9.5a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2h-7ZM7.5 14.5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 15.5a3.5 3.5 0 1 0 1.665 6.58l1.128 1.127a1 1 0 0 0 1.414-1.414l-1.128-1.128A3.5 3.5 0 0 0 16 15.5Zm1.5 3.5c0 .415-.167.788-.44 1.06A1.5 1.5 0 1 1 17.5 19Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgDocSearchTwo: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-doc-search-two', props.className)} />
));
export default SvgDocSearchTwo;

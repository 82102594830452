/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.222 4.222A10.969 10.969 0 0 1 12 1c3.037 0 5.789 1.232 7.778 3.222A10.969 10.969 0 0 1 23 12c0 3.037-1.232 5.789-3.222 7.778A10.969 10.969 0 0 1 12 23a10.969 10.969 0 0 1-7.778-3.222A10.969 10.969 0 0 1 1 12c0-3.037 1.232-5.789 3.222-7.778ZM12 5a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Zm0 13.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
            fill="currentColor"
            fillOpacity={0.85}
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgAttention: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-attention', props.className)} />
));
export default SvgAttention;

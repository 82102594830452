/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 8a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2H7ZM6 13a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 16a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H7Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12v10a1 1 0 0 0 1 1h10c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12Zm2 0a9 9 0 1 1 9 9H3v-9Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgMessage: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-message', props.className)} />
));
export default SvgMessage;

import { Button, Typography } from 'antd';
import { antdDrawer, useModal, create as createModal } from '@ebay/nice-modal-react';
import { useState } from 'react';

import CustomDrawer from '../CustomDrawer';
import SortableList from '../list/SortableList';
import { useAuth } from '../../context/AuthContext';
import { AlertColumnKey, TableTemplateItem, TableTemplateKey } from '../../queries/api/types';
import { defaultErrorMessageWithStatus, translateAlertColumn } from '../../helpers/i18n';
import { useTableTemplateUpdate } from '../../queries/tableTemplates';
import { errorMessage, successMessage } from '../../helpers/message';

interface SortableColumn extends Omit<TableTemplateItem, 'key'> {
    id: string;
    key: AlertColumnKey;
}

const AlertColumnsOrderDrawer = createModal<Record<string, unknown>>(({ ...props }) => {
    const { user } = useAuth();
    const modal = useModal();
    const onClose = () => {
        modal.hide();
    };
    const tableTemplate = user?.tableTemplates?.find((template) => template.key === TableTemplateKey.alerts);
    const [columnsToSave, setColumnsToSave] = useState<SortableColumn[]>(
        ([...(tableTemplate?.columns ?? [])].sort((a, b) => a.index - b.index) as SortableColumn[]).map((column) => ({
            ...column,
            id: `${column.index}`,
        }))
    );
    const { mutate: updateTemplate, isLoading } = useTableTemplateUpdate({
        onSuccess: () => {
            successMessage({ content: 'Ordre des colonnes enregistré avec succès' });
            onClose();
        },
        onError: (error) => {
            errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
        },
    });
    const onChangeOrder = (columns: SortableColumn[]) => {
        setColumnsToSave(columns.map((col, index) => ({ ...col, index })));
    };
    const onSubmit = () => {
        updateTemplate({
            id: tableTemplate?.id,
            columns: columnsToSave,
        });
    };

    return (
        <CustomDrawer
            width={300}
            title="Personnaliser"
            {...props}
            {...antdDrawer(modal)}
            onClose={onClose}
            footer={
                <Button size="large" type="primary" onClick={onSubmit} loading={isLoading} block>
                    Enregistrer
                </Button>
            }
        >
            <Typography.Paragraph>Vous pouvez modifier ci-dessous l’ordre des colonnes :</Typography.Paragraph>
            <SortableList<SortableColumn>
                data={columnsToSave}
                rowKey={(record) => `${record.id}`}
                isLoading={false}
                onOrderChange={onChangeOrder}
                renderItem={(record) => <span className="uppercase">{translateAlertColumn(record.key)}</span>}
            />
        </CustomDrawer>
    );
});

export default AlertColumnsOrderDrawer;

import { Button, Card, Empty, Input, Pagination } from 'antd';
import { antdDrawer, useModal, create as createModal, show } from '@ebay/nice-modal-react';
import { useCallback, useState } from 'react';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import CustomDrawer from '../CustomDrawer';
import { useMailTemplateList, useMailTemplateRemove } from '../../queries/mailTemplates';
import CardSkeleton from '../CardSkeleton';
import ApiResult from '../ApiResult';
import { useDebounce } from '../../hooks';
import OrganizationFormItem from '../form/OrganizationFormItem';
import { AlertType, MailTemplate, Organization } from '../../queries/api/types';
import AlertTypeFormItem from '../form/AlertTypeFormItem';
import MailTemplatesFormDrawer from './MailTemplatesFormDrawer';
import { errorMessage, successMessage } from '../../helpers/message';
import DeletePopConfirm from '../DeletePopConfirm';
import { useLayout } from '../../context/LayoutContext';
import { CheckOne } from '../icons';

const mailTemplatesFormDrawerId = 'mailTemplatesFormDrawer';

interface MailTemplatesDrawerProps extends Record<string, unknown> {
    selection?: boolean;
}

const MailTemplatesDrawer = createModal<MailTemplatesDrawerProps>(({ selection, ...props }) => {
    const { organizationId: headerOrganizationId } = useLayout();
    const modal = useModal();
    const [search, setSearch] = useState<string>();
    const [organizationId, setOrganization] = useState<Organization['id'] | undefined>(headerOrganizationId);
    const [alertTypeId, setAlertTypeId] = useState<AlertType['id'] | null>();
    const debouncedSearch = useDebounce(search, 300);
    const {
        data: mailTemplates,
        isLoading,
        isError,
        error,
    } = useMailTemplateList({
        search: debouncedSearch || undefined,
        organization: organizationId,
        alertType: alertTypeId ?? undefined,
        sort: 'updatedAt',
        sortOrder: 'desc',
    });
    const { mutate: deleteMailTemplate } = useMailTemplateRemove({
        onError: () => {
            errorMessage({ content: 'Une erreur est survenue pendant la suppression du modèle' });
        },
        onSuccess: () => {
            successMessage({
                content: 'Modèle supprimé avec succès',
            });
        },
    });
    const onClose = useCallback(() => {
        modal.hide();
    }, [modal]);
    const onSelect = (mailTemplate: MailTemplate) => {
        modal.resolve({ ...mailTemplate });
        onClose();
    };

    return (
        <CustomDrawer
            width={600}
            title="Modèles de réponse par email"
            {...props}
            {...antdDrawer(modal)}
            onClose={onClose}
            footer={
                !selection && (
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => {
                            show(mailTemplatesFormDrawerId);
                        }}
                        ghost
                        block
                    >
                        Ajouter un modèle de réponse
                    </Button>
                )
            }
        >
            <div className="flex space-x-3 mb-16">
                {!selection && (
                    <OrganizationFormItem
                        selectProps={{
                            onChange: setOrganization,
                            style: { width: 115 },
                            dropdownMatchSelectWidth: false,
                            allowClear: true,
                            defaultValue: headerOrganizationId,
                        }}
                        isWhite
                        noStyle
                    />
                )}
                <AlertTypeFormItem
                    selectProps={{
                        onChange: setAlertTypeId,
                        style: { width: 145 },
                        dropdownMatchSelectWidth: false,
                        placeholder: 'Tous les types',
                        allowClear: true,
                    }}
                    optionLabelRender={(alertType) => alertType.reference}
                    customOptions={[
                        {
                            label: 'Messages personnalisés',
                            value: 'null',
                        },
                    ]}
                    isWhite
                    noStyle
                />
                {!selection && (
                    <Input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Rechercher un message"
                        suffix={<SearchOutlined />}
                        allowClear
                    />
                )}
            </div>
            {isLoading ? (
                <div className="space-y-4">
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                </div>
            ) : isError ? (
                <ApiResult status={error?.response?.status} />
            ) : (
                <div className="space-y-4">
                    {mailTemplates?.totalCount === 0 && (
                        <Empty
                            className="text-taupe"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Aucun résultat"
                        />
                    )}
                    {mailTemplates?.items.map((mailTemplate) => (
                        <Card
                            key={mailTemplate.id}
                            title={
                                mailTemplate?.alertType
                                    ? `Type d’alerte : ${mailTemplate?.alertType.reference}`
                                    : `${mailTemplate.reference}${mailTemplate.name ? ` - ${mailTemplate.name}` : ''}`
                            }
                            bodyStyle={{
                                height: 154,
                            }}
                            actions={
                                selection
                                    ? [
                                          <Button
                                              key="edit"
                                              type="text"
                                              size="large"
                                              className="p-16 font-medium font-14"
                                              icon={<CheckOne className="text-primary" />}
                                              onClick={() => onSelect(mailTemplate)}
                                              block
                                          >
                                              Utiliser cette réponse
                                          </Button>,
                                      ]
                                    : [
                                          <Button
                                              key="edit"
                                              type="text"
                                              size="large"
                                              className="p-16 font-medium font-14"
                                              icon={<EditOutlined className="text-primary" />}
                                              onClick={() => {
                                                  show(mailTemplatesFormDrawerId, { mailTemplateId: mailTemplate.id });
                                              }}
                                              block
                                          >
                                              Éditer
                                          </Button>,
                                          <DeletePopConfirm
                                              key="delete"
                                              onConfirm={() => deleteMailTemplate(mailTemplate.id)}
                                          >
                                              <Button
                                                  icon={<DeleteOutlined className="text-primary" />}
                                                  type="text"
                                                  size="large"
                                                  className="p-16 font-medium font-14"
                                                  block
                                              >
                                                  Supprimer
                                              </Button>
                                          </DeletePopConfirm>,
                                      ]
                            }
                        >
                            <pre className="p-12 -m-12 overflow-visible">{mailTemplate?.body}</pre>
                        </Card>
                    ))}
                    {(mailTemplates?.pageCount ?? 0) > 1 && (
                        <Pagination
                            pageSize={mailTemplates?.pageSize ?? 50}
                            current={mailTemplates?.page !== undefined ? mailTemplates.page + 1 : 1}
                            total={mailTemplates?.totalCount}
                        />
                    )}
                </div>
            )}
            <MailTemplatesFormDrawer id={mailTemplatesFormDrawerId} />
        </CustomDrawer>
    );
});

export default MailTemplatesDrawer;

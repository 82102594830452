import { Tooltip } from 'antd';
import { ReactNode, VFC } from 'react';

import { classNames, getTonerColorClassName } from '../helpers';
import { formatNumber, translateTonerColor } from '../helpers/i18n';
import { Color } from '../queries/api/types';

interface TonerGaugeProps {
    direction?: 'horizontal' | 'vertical';
    color: Color | 'colors' | 'lowColor';
    value?: number | null;
    large?: boolean;
    valueFormatter?: (value: number) => ReactNode;
    tooltip?: boolean;
}

const defaultValueFormatter = (value: number) => formatNumber(value, { maximumFractionDigits: 1 });

const TonerGauge: VFC<TonerGaugeProps> = ({
    direction = 'vertical',
    value,
    color,
    large,
    tooltip,
    valueFormatter = defaultValueFormatter,
}) => {
    const width = direction === 'vertical' ? (large ? 56 : 40) : 200;
    const height = direction === 'vertical' ? (large ? 200 : 100) : 32;
    const gauge = (
        <span className="rounded relative flex bg-taupe-light overflow-hidden" style={{ width, height }}>
            <span
                className={classNames(
                    'absolute transition-all',
                    getTonerColorClassName(color),
                    direction === 'vertical' ? 'rounded-b bottom-0 left-0 w-full' : 'rounded-l left-0 top-0 h-full'
                )}
                style={direction === 'vertical' ? { height: `${value ?? 0}%` } : { width: `${value ?? 0}%` }}
            />
            <span
                className={classNames(
                    'absolute font-medium',
                    large ? 'font-14' : 'font-12',
                    direction === 'vertical' ? (large ? 'bottom-16' : 'bottom-3') : 'left-8',
                    [Color.black, 'lowColor', 'colors'].includes(color) && value && value > 10
                        ? 'text-white'
                        : 'text-black'
                )}
                style={
                    direction === 'vertical'
                        ? { width, lineHeight: '20px', textAlign: 'center' }
                        : { lineHeight: `${height}px` }
                }
            >
                {value ? valueFormatter(value) : '-'}
            </span>
        </span>
    );

    return tooltip ? <Tooltip title={translateTonerColor(color)}>{gauge}</Tooltip> : gauge;
};

export default TonerGauge;

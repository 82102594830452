import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    ErrorCodeUnitCreatePayload,
    ErrorCodeUnitIdPayload,
    ErrorCodeUnitListPayload,
    ErrorCodeUnitListResponse,
    ErrorCodeUnitUpdatePayload,
    remove,
    update,
} from './api/errorCodeUnits';
import { ErrorCodeUnit } from './api/types';

export const errorCodeUnitsKeys = {
    all: ['errorCodeUnits'],
    lists: () => [...errorCodeUnitsKeys.all, 'list'],
    list: (params?: ErrorCodeUnitListPayload) => [...errorCodeUnitsKeys.lists(), params],
    details: () => [...errorCodeUnitsKeys.all, 'details'],
    detail: (id?: ErrorCodeUnitIdPayload) => [...errorCodeUnitsKeys.details(), id],
};

export const useErrorCodeUnitList = <TData = ErrorCodeUnitListResponse>(
    params?: ErrorCodeUnitListPayload,
    options?: UseQueryOptions<ErrorCodeUnitListResponse, AxiosError, TData>
) => {
    return useQuery<ErrorCodeUnitListResponse, AxiosError, TData>(
        errorCodeUnitsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useErrorCodeUnitDetails = <TData = ErrorCodeUnit>(
    id?: ErrorCodeUnitIdPayload,
    options?: UseQueryOptions<ErrorCodeUnit, AxiosError, TData>
) => {
    return useQuery<ErrorCodeUnit, AxiosError, TData>(
        errorCodeUnitsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useErrorCodeUnitCreate = (
    options?: UseMutationOptions<ErrorCodeUnit, AxiosError, ErrorCodeUnitCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ErrorCodeUnit, AxiosError, ErrorCodeUnitCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(errorCodeUnitsKeys.lists());
        },
    });
};

export const useErrorCodeUnitUpdate = (
    options?: UseMutationOptions<ErrorCodeUnit, AxiosError, ErrorCodeUnitUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ErrorCodeUnit, AxiosError, ErrorCodeUnitUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(errorCodeUnitsKeys.detail(variables.id));
        },
    });
};

export const useErrorCodeUnitRemove = (options?: UseMutationOptions<undefined, AxiosError, ErrorCodeUnitIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ErrorCodeUnitIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(errorCodeUnitsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(errorCodeUnitsKeys.lists());
        },
    });
};

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    ErrorCodeCreatePayload,
    ErrorCodeIdPayload,
    ErrorCodeListPayload,
    ErrorCodeListResponse,
    ErrorCodeUpdatePayload,
    remove,
    update,
} from './api/errorCodes';
import { ErrorCode } from './api/types';

export const errorCodesKeys = {
    all: ['errorCodes'],
    lists: () => [...errorCodesKeys.all, 'list'],
    list: (params?: ErrorCodeListPayload) => [...errorCodesKeys.lists(), params],
    details: () => [...errorCodesKeys.all, 'details'],
    detail: (id?: ErrorCodeIdPayload) => [...errorCodesKeys.details(), id],
};

export const useErrorCodeList = <TData = ErrorCodeListResponse>(
    params?: ErrorCodeListPayload,
    options?: UseQueryOptions<ErrorCodeListResponse, AxiosError, TData>
) => {
    return useQuery<ErrorCodeListResponse, AxiosError, TData>(
        errorCodesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useErrorCodeDetails = <TData = ErrorCode>(
    id?: ErrorCodeIdPayload,
    options?: UseQueryOptions<ErrorCode, AxiosError, TData>
) => {
    return useQuery<ErrorCode, AxiosError, TData>(errorCodesKeys.detail(id), async () => await details(id), options);
};

export const useErrorCodeCreate = (options?: UseMutationOptions<ErrorCode, AxiosError, ErrorCodeCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<ErrorCode, AxiosError, ErrorCodeCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(errorCodesKeys.lists());
        },
    });
};

export const useErrorCodeUpdate = (options?: UseMutationOptions<ErrorCode, AxiosError, ErrorCodeUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<ErrorCode, AxiosError, ErrorCodeUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            queryClient.invalidateQueries(errorCodesKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(errorCodesKeys.detail(variables.id));
        },
    });
};

export const useErrorCodeRemove = (options?: UseMutationOptions<undefined, AxiosError, ErrorCodeIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ErrorCodeIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(errorCodesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(errorCodesKeys.lists());
        },
    });
};

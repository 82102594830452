import { Button, Card, Empty, Form, Input, Modal, Tag, Typography } from 'antd';
import { useCallback, useMemo, useState, VFC } from 'react';
import {
    CheckCircleOutlined,
    CloseCircleFilled,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { show } from '@ebay/nice-modal-react';
import { useQueryClient } from 'react-query';
import { SortingState } from '@tanstack/react-table';

import { classNames, downloadBlobFile } from '../../helpers';
import { defaultErrorMessageWithStatus, formatDate, formatNumber, formatRelativeTime } from '../../helpers/i18n';
import { Device, Permission, PermissionRight, Subscription } from '../../queries/api/types';
import NoData from '../../components/NoData';
import useQueryParams from '../../hooks/queryParams';
import { useLayout } from '../../context/LayoutContext';
import {
    useSubscriptionGeneration,
    useSubscriptionList,
    useSubscriptionRemove,
    useSubscriptionUpdate,
} from '../../queries/subscriptions';
import { CheckOne, NetworkTree, SettingTwo } from '../../components/icons';
import SubscriptionsFormDrawer from '../../components/drawers/SubscriptionsFormDrawer';
import DeletePopConfirm from '../../components/DeletePopConfirm';
import { errorMessage, successMessage } from '../../helpers/message';
import DevicesList from '../../components/DevicesList';
import BasicList from '../../components/BasicList';
import Seo from '../../components/Seo';
import { hasPermission } from '../../helpers/security';
import { useAuth } from '../../context/AuthContext';
import { deviceDrawerId } from '../../App';
import SubscriptionLinkDevicesDrawer from '../../components/drawers/SubscriptionLinkDevicesDrawer';
import { devicesKeys } from '../../queries/devices';
import constants from '../../config/constants';
import { getRoute, RoutePathName } from '../../routes';
import SubscriptionDevicesExportButton from '../../components/SubscriptionDevicesExportButton';
import { FileType } from '../../queries/api/index';
import ResizableTable, {
    ResizableTableColumn,
    ResizableTableProps,
    getDefaultSort,
} from '../../components/ResizableTable';

const subscriptionsFormDrawerId = 'SubscriptionsFormDrawer';
const subscriptionLinkDevicesDrawerId = 'SubscriptionLinkDevicesDrawer';

const Subscriptions: VFC = () => {
    const { user } = useAuth();
    const { organizationId } = useLayout();
    const queryClient = useQueryClient();
    const [isSubscriptionExportEnabled, setIsSubscriptionExportEnabled] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
    const [selectedDevice, setSelectedDevice] = useState<Device>();
    const [devicesToDelete, setDevicesToDelete] = useState<Array<Device['id']>>();
    const [queryParams, setQueryParams] = useQueryParams('Orders');
    const page = parseInt(queryParams.get('page') ?? '0', 10) || 0;
    const subscriptionSearch = queryParams.get('subscriptionSearch') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const devicePage = parseInt(queryParams.get('devicePage') ?? '0', 10) || 0;
    const devicePageSize =
        parseInt(queryParams.get('devicePageSize') ?? `${constants.PAGE_SIZE}`, 10) || constants.PAGE_SIZE;
    const deviceSort = queryParams.get('deviceSort') ?? undefined;
    const deviceSortOrder = queryParams.get('deviceSortOrder') ?? undefined;
    const [subscriptionSearchValue, setSubscriptionSearchValue] = useState<string | undefined>(subscriptionSearch);

    const canWrite = hasPermission(user, Permission.subscriptions, PermissionRight.write);
    const {
        data: subscriptions,
        isLoading,
        isFetching,
        isError,
        error,
    } = useSubscriptionList({
        organization: organizationId,
        sort,
        sortOrder,
        page,
        search: subscriptionSearch || undefined,
    });

    const { mutateAsync: updateSubscription } = useSubscriptionUpdate();
    const { mutate: removeSubscription, isLoading: isRemovingSubscription } = useSubscriptionRemove();
    const subscriptionDefaultSortingState = useMemo(() => getDefaultSort(sort, sortOrder), [sort, sortOrder]);
    const onSubscriptionSortChange = useCallback(
        (sortingState: SortingState) => {
            const sort = sortingState[0]?.id;
            const sortOrder = sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined;

            setQueryParams({
                sort: sort,
                sortOrder: sortOrder,
            });
        },
        [setQueryParams]
    );
    const onDeviceSortChange = useCallback(
        (sortingState: SortingState) => {
            const sort = sortingState[0]?.id;
            const sortOrder = sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined;

            setQueryParams({
                deviceSort: sort,
                deviceSortOrder: sortOrder,
            });
        },
        [setQueryParams]
    );

    const { isLoading: isExportingSubscription } = useSubscriptionGeneration(
        `${window.location.origin}${getRoute(RoutePathName.exportSubscription)}?subscriptionId=${
            selectedSubscription?.id ?? ''
        }`,
        {
            enabled: isSubscriptionExportEnabled,
            onSettled: () => {
                setIsSubscriptionExportEnabled(false);
            },
            onError: (error) => {
                errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
            },
            onSuccess: (data) => {
                downloadBlobFile(
                    `${formatDate(new Date(), {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                    })}-abonnement-${selectedSubscription?.name ?? ''}`,
                    data
                );
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );

    const onSubmitSearch = () => {
        setQueryParams({
            subscriptionSearch: subscriptionSearchValue || undefined,
        });
    };
    const onResetSearch = () => {
        setQueryParams({
            subscriptionSearch: undefined,
            sort: undefined,
            sortOrder: undefined,
            page: undefined,
        });
        setSubscriptionSearchValue(undefined);
    };
    // const onClickExport = (type: 'excel' | 'pdf') => {
    //     console.log(type);
    // };

    const onClickSubscriptionExport = () => {
        setIsSubscriptionExportEnabled(true);
    };

    const deviceQueryPayload = useMemo(
        () => ({
            subscription: selectedSubscription?.id,
            organization: organizationId,
        }),
        [selectedSubscription?.id, organizationId]
    );
    const onClickRemove = useCallback(() => {
        const isMultiple = devicesToDelete && devicesToDelete?.length !== 1;
        Modal.confirm({
            width: 480,
            centered: true,
            title: 'Confirmation de dissociation',
            icon: <ExclamationCircleOutlined className="text-warning" />,
            content: `Êtes-vous sûr de vouloir dissocier ${
                isMultiple ? 'les machines sélectionnées' : 'la machine sélectionnée'
            } de cet abonnement ?`,
            okText: 'Dissocier',
            onOk: async () =>
                await updateSubscription(
                    {
                        id: selectedSubscription?.id,
                        removedDevices: devicesToDelete,
                    },
                    {
                        onSuccess: () => {
                            successMessage({
                                content: `${isMultiple ? 'Machines dissociées' : 'Machine dissociée'} avec succès`,
                            });
                            setDevicesToDelete([]);
                            queryClient.invalidateQueries(devicesKeys.lists());
                        },
                        onError: (error) => {
                            errorMessage({
                                content: `Une erreur est survenue pendant la dissociation ${
                                    isMultiple ? 'des machines' : 'de la machine'
                                } (${error?.response?.status ?? 0})`,
                            });
                        },
                    }
                ),
        });
    }, [devicesToDelete, queryClient, selectedSubscription?.id, updateSubscription]);
    const deviceRenderHeader = useCallback(
        (devices) => (
            <div className={classNames('flex justify-between', canWrite ? 'items-end' : 'items-center')}>
                <div>
                    <Typography.Title level={2} className={canWrite ? 'mb-12' : 'mb-0'}>
                        {selectedSubscription && !isLoading ? (
                            <>
                                <span>{`${
                                    devices?.totalCount === 0 ? 'Aucune' : formatNumber(devices?.totalCount)
                                } machine${devices?.totalCount <= 1 ? '' : 's'} associée${
                                    devices?.totalCount <= 1 ? '' : 's'
                                } à cet abonnement`}</span>
                                {!!devicesToDelete?.length && (
                                    <>
                                        <span className="ml-4 text-taupe font-semibold">/</span>
                                        <span className="ml-4 text-blue font-medium">
                                            {formatNumber(devicesToDelete?.length)} sélectionnée
                                            {(devicesToDelete?.length ?? 0) > 1 ? 's' : ''}
                                        </span>
                                    </>
                                )}
                            </>
                        ) : (
                            "Machines liées à l'abonnement"
                        )}
                    </Typography.Title>
                    {canWrite && (
                        <div className="space-x-2">
                            <Button
                                icon={<PlusOutlined className="text-primary" />}
                                disabled={!selectedSubscription}
                                onClick={async () =>
                                    await show(subscriptionLinkDevicesDrawerId, {
                                        subscriptionId: selectedSubscription?.id,
                                    })
                                }
                            >
                                Associer machines
                            </Button>
                            <Button
                                icon={<DeleteOutlined className="text-primary" />}
                                disabled={!devicesToDelete?.length}
                                onClick={onClickRemove}
                            >
                                Dissocier machines
                            </Button>
                        </div>
                    )}
                </div>
                <div className="flex space-x-2">
                    {/* <Button
                        icon={<FilePdfOutlined className="text-primary" />}
                        disabled={!selectedDevice}
                        onClick={() => onClickExport('pdf')}
                    >
                        Analyse détailée de la machine
                    </Button>
                    <Button
                        icon={<FileExcelOutlined className="text-primary" />}
                        disabled={!selectedDevice}
                        onClick={() => onClickExport('excel')}
                    >
                        Historique alertes
                    </Button> */}
                    <Button
                        icon={<SettingTwo className="text-primary" />}
                        disabled={!selectedDevice}
                        onClick={async () =>
                            await show(deviceDrawerId, {
                                id: selectedDevice?.id,
                                tab: 'device',
                            })
                        }
                    >
                        MAJ Machine
                    </Button>
                </div>
            </div>
        ),
        [canWrite, devicesToDelete?.length, isLoading, onClickRemove, selectedDevice, selectedSubscription]
    );

    const subscriptionColumn = useMemo<Array<ResizableTableColumn<Subscription>>>(
        () => [
            {
                accessorKey: 'reference',
                id: 'reference',
                header: 'Identifiant',
                cell: (info) => info.getValue() ?? <NoData />,
                rowSpan: 2,
                size: 100,
            },
            {
                id: 'organization',
                accessorFn: (info) => info.organization.code,
                header: 'Org.',
                cell: (info) => info.getValue() ?? <NoData />,
                rowSpan: 2,
                size: 70,
            },
            {
                id: 'name',
                accessorKey: 'name',
                header: "Nom de l'abonnement",
                cell: (info) => info.getValue() ?? <NoData />,
                rowSpan: 2,
                size: 176,
            },
            {
                id: 'active',
                accessorKey: 'active',
                header: 'Actif',
                cell: (info) =>
                    info.getValue() ? (
                        <CheckOne className="font-20 text-success" />
                    ) : (
                        <CloseCircleFilled className="font-20 text-error" />
                    ),
                rowSpan: 2,
                size: 60,
            },
            {
                id: 'deviceCount',
                header: 'Machines',
                accessorKey: 'deviceCount',
                cell: (info) => formatNumber((info.getValue() as number) ?? 0),
                enableSorting: true,
                rowSpan: 2,
                size: 96,
            },
            {
                id: 'lastSendedAt',
                header: 'Dernier envoi',
                accessorKey: 'lastSendedAt',
                cell: (info) =>
                    info.getValue() ? (
                        `${formatDate(info.getValue() as Date)} (${formatRelativeTime(info.getValue() as Date)})`
                    ) : (
                        <NoData />
                    ),
                enableSorting: true,
                rowSpan: 2,
            },
            {
                id: 'exports',
                header: 'Rapports',
                columns: [
                    {
                        accessorKey: 'briefReporting',
                        header: 'Synthétique',
                        cell: (info) =>
                            info?.getValue() ? (
                                <CheckOne className="font-20 text-success" />
                            ) : (
                                <CloseCircleFilled className="font-20 text-error" />
                            ),
                        size: 200,
                    },
                    {
                        accessorKey: 'detailedReporting',
                        header: 'Détaillé',
                        cell: (info) =>
                            info.getValue() ? (
                                <CheckOne className="font-20 text-success" />
                            ) : (
                                <CloseCircleFilled className="font-20 text-error" />
                            ),
                        size: 62,
                    },
                    {
                        accessorKey: 'xlsReporting',
                        header: 'Excel',
                        cell: (info) =>
                            info.getValue() ? (
                                <CheckOne className="font-20 text-success" />
                            ) : (
                                <CloseCircleFilled className="font-20 text-error" />
                            ),
                        size: 62,
                    },
                ],
            },
            {
                id: 'emails',
                accessorKey: 'emails',
                header: 'Destinataires',
                cell: (info) => {
                    const emails = info.getValue();

                    if (!emails) {
                        return <NoData />;
                    }

                    return (
                        <BasicList
                            className="flex-wrap"
                            style={{ marginLeft: '-0.25rem', marginRight: '-0.25rem' }}
                            inline
                        >
                            {(info.getValue() as string[]).filter(Boolean).map((email, index) => (
                                <li key={`${email}-${index}`} style={{ margin: '0.125rem' }}>
                                    <Tag>{email}</Tag>
                                </li>
                            ))}
                        </BasicList>
                    );
                },
                size: 400,
                rowSpan: 2,
            },
        ],
        []
    );

    const subscriptionsPagination = useMemo<ResizableTableProps<Subscription>['pagination']>(
        () => ({
            current: page + 1,
            total: subscriptions?.totalCount ?? 0,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            pageSize: subscriptions?.pageSize,
            isScrollingToTopOnChange: true,
            showSizeChanger: false,
        }),
        [page, setQueryParams, subscriptions?.pageSize, subscriptions?.totalCount]
    );

    const onSubscriptionRowHighlightChange = useCallback<(row: Subscription, index: number) => void>((row) => {
        setSelectedSubscription(row);
    }, []);
    const isSubscriptionRowHighlighted = useCallback<(row: Subscription, index: number) => boolean>(
        (row) => row.id === selectedSubscription?.id,
        [selectedSubscription?.id]
    );

    const onDeviceRowHighlightChange = useCallback<(row: Device, index: number) => void>((row) => {
        setSelectedDevice(row);
    }, []);
    const isDeviceRowHighlighted = useCallback<(row: Device, index: number) => boolean>(
        (row) => row.id === selectedDevice?.id,
        [selectedDevice?.id]
    );

    const devicesPagination = useMemo<ResizableTableProps<Device>['pagination']>(
        () => ({
            current: devicePage + 1,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    devicePage: newPage,
                    devicePageSize,
                    pageSize,
                });
            },
            pageSize: devicePageSize,
            showSizeChanger: false,
            isScrollingToTopOnChange: true,
        }),
        [devicePage, devicePageSize, setQueryParams]
    );

    return (
        <div className="flex flex-col min-h-full">
            <Seo title="Visualisation des abonnements (Rapports statistiques Gestion de parc)" />
            <Typography.Title className="mb-16">
                Visualisation des abonnements (Rapports statistiques Gestion de parc)
            </Typography.Title>
            <Card className="mb-16">
                <div className="flex items-center justify-between">
                    <div className="flex space-x-3">
                        <Form.Item label="Rechercher un abonnement" className="inline mb-0">
                            <Input
                                placeholder="Saisir un identifiant ou nom"
                                prefix={<NetworkTree />}
                                onChange={(e) => setSubscriptionSearchValue(e.target.value)}
                                value={subscriptionSearchValue}
                                onPressEnter={onSubmitSearch}
                                style={{ minWidth: 250 }}
                            />
                        </Form.Item>
                        <Button type="primary" onClick={onSubmitSearch}>
                            Rechercher
                        </Button>
                        <Button onClick={onResetSearch}>Effacer</Button>
                    </div>
                    <Button
                        icon={<PlusOutlined className="text-primary" />}
                        onClick={() => {
                            show(subscriptionsFormDrawerId).then((value) => {
                                const subscription = value as Subscription;
                                setSelectedSubscription(subscription);
                                Modal.confirm({
                                    width: 480,
                                    centered: true,
                                    title: 'Abonnement créé avec succès',
                                    icon: <CheckCircleOutlined className="text-success" />,
                                    content: 'Voulez-vous y associer des machines maintenant ?',
                                    okText: 'Associer maintenant',
                                    cancelText: 'Plus tard',
                                    onOk: () => {
                                        show(subscriptionLinkDevicesDrawerId, {
                                            subscriptionId: subscription?.id,
                                        });
                                    },
                                });
                            });
                        }}
                    >
                        Créer un abonnement
                    </Button>
                </div>
            </Card>
            <div className="flex items-center justify-between gap-16 mb-16">
                <Typography.Title level={2} className="mb-0">
                    {formatNumber(subscriptions?.totalCount)} abonnement{subscriptions?.totalCount === 1 ? '' : 's'}{' '}
                    trouvé
                    {subscriptions?.totalCount === 1 ? '' : 's'}
                </Typography.Title>
                <div className="space-x-2">
                    <Button
                        icon={<EditOutlined className="text-primary" />}
                        disabled={!selectedSubscription}
                        onClick={() => {
                            show(subscriptionsFormDrawerId, { subscriptionId: selectedSubscription?.id });
                        }}
                    >
                        Modifier
                    </Button>
                    <DeletePopConfirm
                        title={
                            (selectedSubscription?.deviceCount ?? 0) > 0
                                ? `Des machines sont associées à l'abonnement « ${
                                      selectedSubscription?.name ?? selectedSubscription?.reference ?? ''
                                  } ». Êtes-vous sûr de vouloir le supprimer ?`
                                : `Voulez-vous vraiment supprimer l'abonnement « ${
                                      selectedSubscription?.name ?? selectedSubscription?.reference ?? ''
                                  } »`
                        }
                        onConfirm={() => {
                            if (selectedSubscription) {
                                removeSubscription(selectedSubscription.id, {
                                    onSuccess: () => {
                                        successMessage({
                                            content: `L'abonnement « ${
                                                selectedSubscription?.name ?? selectedSubscription?.reference ?? ''
                                            } » a été supprimé avec succès`,
                                        });
                                    },
                                    onError: (error) => {
                                        errorMessage({
                                            content: defaultErrorMessageWithStatus(error?.response?.status),
                                        });
                                    },
                                });
                            }
                        }}
                    >
                        <Button
                            icon={<DeleteOutlined className="text-primary" />}
                            disabled={!selectedSubscription}
                            loading={isRemovingSubscription}
                        >
                            Supprimer
                        </Button>
                    </DeletePopConfirm>
                    <Button
                        icon={<FilePdfOutlined className="text-primary" />}
                        onClick={() => onClickSubscriptionExport()}
                        loading={isExportingSubscription}
                        disabled={!selectedSubscription || !selectedSubscription.deviceCount}
                    >
                        Synthèse du parc
                    </Button>
                    <SubscriptionDevicesExportButton
                        selectedSubscription={selectedSubscription}
                        icon={<FilePdfOutlined className={'text-primary'} />}
                        type={FileType.PDF}
                    >
                        État de Parc
                    </SubscriptionDevicesExportButton>
                    <SubscriptionDevicesExportButton
                        icon={<FileExcelOutlined className="text-primary" />}
                        type={FileType.EXCEL}
                        selectedSubscription={selectedSubscription}
                    >
                        Export Parc
                    </SubscriptionDevicesExportButton>
                </div>
            </div>
            <ResizableTable<Subscription>
                data={subscriptions?.items}
                columns={subscriptionColumn}
                isLoading={isLoading || isFetching}
                isError={isError}
                error={error}
                defaultSortingState={subscriptionDefaultSortingState}
                onSortChange={onSubscriptionSortChange}
                emptyContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Aucun résultat"
                    />
                }
                maxHeight={413}
                onRowHighlightChange={onSubscriptionRowHighlightChange}
                isRowHighlighted={isSubscriptionRowHighlighted}
                pagination={subscriptionsPagination}
                enableColumnResizing
            />
            <div className="bg-grey-darker -mx-16 -mb-16 mt-16 p-16 space-y-4 flex-auto">
                <DevicesList
                    sort={deviceSort}
                    sortOrder={deviceSortOrder}
                    onSortChange={onDeviceSortChange}
                    pagination={devicesPagination}
                    queryEnabled={!!selectedSubscription}
                    queryPayload={deviceQueryPayload}
                    renderHeader={deviceRenderHeader}
                    emptyText={
                        selectedSubscription
                            ? 'Aucune machine associée à cet abonnement'
                            : 'Aucun abonnement sélectionné'
                    }
                    onRowHighlightChange={onDeviceRowHighlightChange}
                    isRowHighlighted={isDeviceRowHighlighted}
                    selectedRowKeys={devicesToDelete}
                    onRowSelectionChange={setDevicesToDelete}
                />
            </div>
            <SubscriptionsFormDrawer id={subscriptionsFormDrawerId} />
            <SubscriptionLinkDevicesDrawer id={subscriptionLinkDevicesDrawerId} organizationId={organizationId} />
        </div>
    );
};

export default Subscriptions;

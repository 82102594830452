import { Button, DrawerProps, Form, Input, Select, Spin, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import { antdDrawer, create, useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';

import CustomDrawer from '../CustomDrawer';
import { errorMessage, successMessage } from '../../helpers/message';
import { requiredRule } from '../../helpers';
import { ChevronRight } from '../icons';
import { defaultErrorMessageWithStatus, translateAlertStatus } from '../../helpers/i18n';
import { AlertStatus, ErrorCode } from '../../queries/api/types';
import OrganizationFormItem from '../form/OrganizationFormItem';
import { useErrorCodeCreate, useErrorCodeDetails, useErrorCodeUpdate } from '../../queries/errorCodes';
import ErrorCodeUnitFormItem from '../form/ErrorCodeUnitFormItem';
import ErrorCodeRecommendationFormItem from '../form/ErrorCodeRecommendationFormItem';

interface ErrorCodesFormDrawerProps extends DrawerProps, Record<string, unknown> {
    errorCodeId?: ErrorCode['id'];
    errorCode?: ErrorCode;
}

const ErrorCodesFormDrawer = create<ErrorCodesFormDrawerProps>(({ errorCodeId, errorCode }) => {
    const [form] = Form.useForm();
    const modal = useModal();
    const [isManualUnit, setIsManualUnit] = useState(false);
    const [isManualRecommendation, setIsManualRecommendation] = useState(false);
    const { mutateAsync: create, isLoading: isCreating } = useErrorCodeCreate();
    const { mutateAsync: update, isLoading: isUpdating } = useErrorCodeUpdate();
    const isEditing = errorCodeId !== undefined;
    const isAddingMissing = errorCode !== undefined;
    const { data: errorCodeDetails, isLoading: isFetching } = useErrorCodeDetails(errorCodeId, {
        enabled: isEditing,
        onSuccess: (data) => {
            form.setFieldsValue({
                ...data,
                organization: data.organization.id,
            });
            setIsManualUnit(false);
        },
    });
    const onSubmit: FormProps['onFinish'] = (values) => {
        const payload = {
            ...values,
            recommendation: values.recommendation || null,
            unit: values.unit || null,
            description: values.description || null,
        };
        if (isEditing || isAddingMissing) {
            update(
                {
                    id: errorCodeId || errorCode?.id,
                    ...payload,
                    managedOnKairos: isAddingMissing ? true : undefined,
                },
                {
                    onSuccess: () => {
                        successMessage({
                            content: isAddingMissing
                                ? 'Code panne manquant ajouté avec succès'
                                : 'Code panne modifié avec succès',
                        });
                        modal.resolve({ ...payload });
                        modal.hide();
                    },
                    onError: (error) => {
                        if (error.response?.status === 409) {
                            const field = Object.keys(error.response?.data?.fields)?.[0];

                            if (field) {
                                form.setFields([
                                    {
                                        name: field,
                                        errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                                    },
                                ]);
                            }
                        } else {
                            errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                        }
                    },
                }
            );
        } else {
            create(payload, {
                onSuccess: () => {
                    successMessage({ content: 'Code panne ajouté avec succès' });
                    modal.resolve({ ...payload });
                    modal.hide();
                },
                onError: (error) => {
                    if (error.response?.status === 409) {
                        const field = Object.keys(error.response?.data?.fields)?.[0];

                        if (field) {
                            form.setFields([
                                {
                                    name: field,
                                    errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                                },
                            ]);
                        }
                    } else {
                        errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                    }
                },
            });
        }
    };

    return (
        <CustomDrawer title={`${isEditing ? 'Modifier' : 'Ajouter'} un code`} {...antdDrawer(modal)} width={600}>
            <Spin spinning={isFetching}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={
                        isEditing
                            ? {
                                  ...errorCodeDetails,
                                  organization: errorCodeDetails?.organization.id,
                              }
                            : isAddingMissing
                            ? {
                                  ...errorCode,
                                  organization: errorCode?.organization.id,
                              }
                            : undefined
                    }
                    requiredMark={false}
                    scrollToFirstError
                >
                    {isAddingMissing && (
                        <Typography.Paragraph strong>
                            Pour terminer l’ajout, veuillez compléter l’ensemble des champs ci-dessous :
                        </Typography.Paragraph>
                    )}
                    <Form.Item name={['reference']} label="Code A.T" rules={[requiredRule]}>
                        <Input placeholder="Saisir un code" autoComplete="off" disabled={isAddingMissing} />
                    </Form.Item>
                    <OrganizationFormItem
                        name="organization"
                        label="Organisation"
                        rules={[requiredRule]}
                        selectProps={{
                            dropdownMatchSelectWidth: false,
                            disabled: isEditing || isAddingMissing,
                        }}
                    />
                    {isManualUnit ? (
                        <Form.Item
                            name="unit"
                            label="Unité"
                            extra={
                                <Button
                                    type="link"
                                    className="p-0 font-12"
                                    onClick={() => setIsManualUnit((value) => !value)}
                                >
                                    Sélectionner une unité existante <ChevronRight className="font-10" />
                                </Button>
                            }
                        >
                            <Input placeholder="Saisir une unité" autoComplete="off" />
                        </Form.Item>
                    ) : (
                        <ErrorCodeUnitFormItem
                            name="unit"
                            label="Unité"
                            selectProps={{
                                dropdownMatchSelectWidth: false,
                                allowClear: true,
                            }}
                            valueGetter={(record) => record.reference}
                            extra={
                                <Button
                                    type="link"
                                    className="p-0 font-12"
                                    onClick={() => setIsManualUnit((value) => !value)}
                                >
                                    {'Saisir manuellement'} <ChevronRight className="font-10" />
                                </Button>
                            }
                        />
                    )}
                    <Form.Item name={['description']} label="État de la machine">
                        <Input placeholder="Saisir un état" autoComplete="off" />
                    </Form.Item>
                    <Form.Item name={['status']} label="Statut automatique de l'alerte" rules={[requiredRule]}>
                        <Select<AlertStatus>
                            placeholder="Sélectionner un statut"
                            filterOption={false}
                            options={[AlertStatus.ignored, AlertStatus.pending, AlertStatus.received].map(
                                (alertStatus) => ({
                                    label: translateAlertStatus(alertStatus),
                                    value: alertStatus,
                                })
                            )}
                        />
                    </Form.Item>
                    {isManualRecommendation ? (
                        <Form.Item
                            name="recommendation"
                            label="Préconisation"
                            extra={
                                <Button
                                    type="link"
                                    className="p-0 font-12"
                                    onClick={() => setIsManualRecommendation((value) => !value)}
                                >
                                    Sélectionner une préconisation existante <ChevronRight className="font-10" />
                                </Button>
                            }
                        >
                            <Input placeholder="Saisir une préconisation" autoComplete="off" />
                        </Form.Item>
                    ) : (
                        <ErrorCodeRecommendationFormItem
                            name="recommendation"
                            label="Préconisation"
                            selectProps={{
                                dropdownMatchSelectWidth: false,
                                allowClear: true,
                            }}
                            valueGetter={(record) => record.reference}
                            extra={
                                <Button
                                    type="link"
                                    className="p-0 font-12"
                                    onClick={() => setIsManualRecommendation((value) => !value)}
                                >
                                    {'Saisir manuellement'} <ChevronRight className="font-10" />
                                </Button>
                            }
                        />
                    )}
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={isCreating || isUpdating}
                                disabled={
                                    !isAddingMissing &&
                                    (!form.isFieldsTouched() ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length)
                                }
                                block
                            >
                                {isAddingMissing
                                    ? "Valider l'import du code panne"
                                    : `${isEditing ? 'Modifier' : 'Ajouter'} le code`}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Spin>
        </CustomDrawer>
    );
});

export default ErrorCodesFormDrawer;

import { useCallback, useState } from 'react';

export default function useSessionStorage(key: any, initialValue: any) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = useCallback(
        (value: any) => {
            try {
                setStoredValue(value);
                window.sessionStorage.setItem(key, JSON.stringify(value));
            } catch (error) {
                console.log(error);
            }
        },
        [key]
    );

    return [storedValue, setValue];
}

import { FC } from 'react';
import { Card, CardProps } from 'antd';

import { classNames } from '../helpers';

const ToolbarCard: FC<CardProps> = ({ children, className, ...props }) => (
    <Card size="small" className={classNames('flex', className)} bodyStyle={{ width: '100%' }} {...props} bordered>
        {children}
    </Card>
);

export default ToolbarCard;

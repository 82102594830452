import { VFC } from 'react';
import { CardProps } from 'antd';

import { useMailMessageDetails } from '../../queries/mailMessages';
import { useAlertDetails } from '../../queries/alerts';
import MessageCard from '../../components/MessageCard';
import { isDeviceUsingLowColor } from '../../helpers';
import { Alert } from '../../queries/api/types';

interface AlertDetailsMessageProps extends CardProps {
    withCopyButton?: boolean;
    bodyHeight?: number;
    alertId?: Alert['id'];
}

const AlertDetailsMessage: VFC<AlertDetailsMessageProps> = ({
    withCopyButton,
    bodyHeight = 329,
    alertId,
    ...props
}) => {
    const {
        data: alert,
        isLoading: isLoadingAlert,
        isError: isErrorAlert,
        error: errorAlert,
    } = useAlertDetails(alertId, { enabled: !!alertId });
    const {
        data: mailMessage,
        isError,
        isLoading,
        error,
    } = useMailMessageDetails(alert?.mailMessage.id, {
        enabled: !!alert?.mailMessage.id,
    });
    const isUsingLowColor = isDeviceUsingLowColor(alert?.device);

    return (
        <MessageCard
            mailMessage={mailMessage}
            title="Message reçu"
            error={error || errorAlert}
            isError={isError || isErrorAlert}
            loading={isLoading || isLoadingAlert}
            isEmpty={!alertId}
            withCopyButton={withCopyButton}
            bodyHeight={isUsingLowColor ? 373 : bodyHeight}
            {...props}
        />
    );
};

export default AlertDetailsMessage;

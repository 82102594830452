import React, { useState } from 'react';
import { Button } from 'antd';

import { Subscription } from '../queries/api/types';
import { useSubscriptionFileGeneration } from '../queries/devices';
import { downloadBlobFile } from '../helpers';
import { errorMessage } from '../helpers/message';
import { defaultErrorMessageWithStatus } from '../helpers/i18n';
import { FileType } from '../queries/api';

type Props = {
    selectedSubscription?: Subscription;
    type: FileType;
    icon: JSX.Element;
};
const SubscriptionDevicesExportButton: React.FC<Props> = ({ children, selectedSubscription, type, icon }) => {
    const [isExportParcActivated, setIsExportParcActivated] = useState(false);

    const { isLoading } = useSubscriptionFileGeneration(
        {
            subscriptionId: selectedSubscription?.id,
            type,
        },
        {
            enabled: isExportParcActivated,
            onSettled: () => {
                setIsExportParcActivated(!isExportParcActivated);
            },
            onSuccess: (data) =>
                type === FileType.EXCEL
                    ? downloadBlobFile(`export_parc-${+new Date()}.xlsx`, data)
                    : downloadBlobFile(`Etat_du_parc-${+new Date()}.pdf`, data),
            onError: (err) => {
                errorMessage({ content: defaultErrorMessageWithStatus(err?.response?.status) });
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
        }
    );

    const handleExportParc = () => {
        setIsExportParcActivated(!isExportParcActivated);
    };

    return (
        <Button
            icon={icon}
            onClick={() => handleExportParc()}
            disabled={!selectedSubscription || !selectedSubscription?.deviceCount}
            loading={isLoading}
        >
            {children}
        </Button>
    );
};

export default SubscriptionDevicesExportButton;

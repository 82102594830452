import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { ErrorCodeUnit, ListResponse } from './types';

// Controller Interface
export interface ErrorCodeUnitCreatePayload {
    reference: string;
}

export interface ErrorCodeUnitUpdatePayload extends Omit<ErrorCodeUnit, 'id'> {
    id?: ErrorCodeUnit['id'];
}

export type ErrorCodeUnitIdPayload = ErrorCodeUnit['id'];

export type ErrorCodeUnitListPayload = SearchPaginationQuery;
export type ErrorCodeUnitListResponse = ListResponse<ErrorCodeUnit>;

// Routes
export const list = async (payload?: ErrorCodeUnitListPayload) => {
    return await api
        .get<ErrorCodeUnitListResponse>(`/errorCodeUnits`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: ErrorCodeUnitCreatePayload) => {
    return await api.post(`/errorCodeUnits`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: ErrorCodeUnitUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<ErrorCodeUnit>(`/errorCodeUnits/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: ErrorCodeUnitIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<ErrorCodeUnit>(`/errorCodeUnits/${id}`).then((response) => response?.data);
};

export const remove = async (id?: ErrorCodeUnitIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/errorCodeUnits/${id}`).then((response) => response?.data);
};

import { FC, useState } from 'react';
import { Button, ButtonProps, Modal } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { Alert, AlertStatus } from '../../queries/api/types';
import { useAlert } from '../../context/AlertContext';
import { getFullName, isAlertGroupCounter } from '../../helpers';
import { useAuth } from '../../context/AuthContext';
import { details } from '../../queries/api/alerts';

interface AlertTreatButtonProps extends ButtonProps {
    onAllowed: () => void;
    inDrawer?: boolean;
    alertId?: Alert['id'];

    alertStatus?: Alert['status'];
}

const AlertTreatButton: FC<AlertTreatButtonProps> = ({ onAllowed, inDrawer, alertId, alertStatus, ...props }) => {
    const { alert } = useAlert();
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);
    const { user } = useAuth();
    const id = alertId || alert?.id;
    const status = alertStatus || alert?.status;
    const { isLoading } = useQuery<Alert, AxiosError>(
        ['alert-details-treat-button', id, inDrawer],
        async () => await details(id),
        {
            enabled: isQueryEnabled,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 0,
            onSettled: () => {
                setIsQueryEnabled(false);
            },
            onSuccess: (data) => {
                if (data?.status === AlertStatus.inProgress && data?.operator?.id !== user?.id) {
                    Modal.error({
                        width: 480,
                        centered: true,
                        title: 'Traitement de l’alerte en cours',
                        content: `L’alerte sélectionnée est déjà en cours de traitement par ${
                            data?.operator ? `l’utilisateur « ${getFullName(data?.operator)} »` : 'un autre utilisateur'
                        }.`,
                        okText: 'Fermer',
                        okButtonProps: {
                            type: 'default',
                        },
                    });
                } else if (!data?.device.erpDeviceInfo?.customer?.contractReferences?.length) {
                    Modal.confirm({
                        width: 388,
                        centered: true,
                        title: 'Veuillez confirmez ci-dessous',
                        icon: <ExclamationCircleOutlined className="text-warning" />,
                        content: (
                            <p>
                                Cette machine est inconnue ou ne semble pas couverte par un contrat.
                                <br />
                                <br />
                                Que souhaitez-vous faire ?
                            </p>
                        ),
                        okText: 'Acquitter l’alerte',
                        onOk: () => {
                            onAllowed();
                        },
                    });
                } else {
                    onAllowed();
                }
            },
        }
    );
    const onClick: ButtonProps['onClick'] = (e) => {
        props?.onClick?.(e);

        setIsQueryEnabled(true);
    };

    return (
        <Button
            className="btn-success"
            icon={<CheckCircleOutlined />}
            loading={isLoading}
            disabled={
                !status ||
                (status && [AlertStatus.treated, AlertStatus.registered].includes(status)) ||
                isAlertGroupCounter(alert)
            }
            onClick={onClick}
            {...props}
        >
            Acquitter
        </Button>
    );
};

export default AlertTreatButton;

import { ReactNode, VFC } from 'react';

import { classNames, getTonerColorClassName } from '../helpers';
import { translateTonerColor } from '../helpers/i18n';
import { Color, ColorLevel as ColorLevelType } from '../queries/api/types';

interface ColorLevelProps {
    colorLevel?: ColorLevelType;
    label?: ReactNode;
    large?: boolean;
}

const ListColorLevel: VFC<ColorLevelProps> = ({ colorLevel, label, large }) => {
    const color = Object.values(Color).find((color) => colorLevel?.[color] !== undefined);

    return (
        <span className="flex items-center space-x-2">
            <span
                className={classNames(
                    'inline-block',
                    getTonerColorClassName(color),
                    large ? 'w-22 h-22 rounded' : 'w-16 h-16 rounded-sm'
                )}
                aria-hidden
            />
            {label ? <span>{label}</span> : <span className="uppercase">{translateTonerColor(color)}</span>}
        </span>
    );
};

export default ListColorLevel;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 3a5 5 0 0 0-4.422 7.337 1 1 0 0 1-.176 1.175L3.414 19.5 4.5 20.586l7.988-7.988a1 1 0 0 1 1.175-.176 5 5 0 0 0 7.24-5.41l-2.696 2.695a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 0 1 0-1.414l2.695-2.696A5.029 5.029 0 0 0 16 3ZM9 8a7 7 0 0 1 10.273-6.189 1 1 0 0 1 .24 1.59L16.413 6.5 17.5 7.586l3.098-3.098a1 1 0 0 1 1.59.239 7 7 0 0 1-8.78 9.778l-8.2 8.202a1 1 0 0 1-1.415 0l-2.5-2.5a1 1 0 0 1 0-1.414l8.202-8.202A6.984 6.984 0 0 1 9 8Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgTool: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-tool', props.className)} />
));
export default SvgTool;

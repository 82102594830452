import { Fragment, useMemo, VFC } from 'react';
import { Divider, Empty, Spin, Typography } from 'antd';
import { BarChart, BarChartProps } from '@ezeedev/react-charts';

import { formatDate, formatNumber, formatNumberSmall, translateTonerColor } from '../../helpers/i18n';
import { Color, Counter, CounterConsumption, Device, MonthlyCounter } from '../../queries/api/types';
import BasicList from '../BasicList';
import { classNames, isDeviceUsingColors, isDeviceUsingErasableBlue, isDeviceUsingLowColor } from '../../helpers';

interface Datum
    extends Omit<MonthlyCounter, 'color' | 'lastDateCounterOfTheMonth' | 'lastDateOfMonth'>,
        Omit<CounterConsumption, 'color'> {
    color?: string;
    colors?: number;
    date: string;
}

const xAccessor = (d: Datum) => new Date(d?.date);
const yAccessor = (key: ColorKeys) => (d?: Datum) => (d?.[key] as number) ?? 0;
const colorAccessor = (d: Datum) => d.color;
type ColorKeys = 'colors' | 'black' | 'lowColor' | 'erasableBlue';

const seriesBase: Array<{
    key: ColorKeys;
    color: string;
    xAccessor: typeof xAccessor;
    colorAccessor: typeof colorAccessor;
}> = [
    {
        key: 'colors',
        color: '#E61E1E',
        xAccessor,
        colorAccessor,
    },
    {
        key: 'black',
        color: '#4D4D4D',
        xAccessor,
        colorAccessor,
    },
    {
        key: 'lowColor',
        color: '#000091',
        xAccessor,
        colorAccessor,
    },
    {
        key: 'erasableBlue',
        color: '#2F54EB',
        xAccessor,
        colorAccessor,
    },
];

const defaultValue = { hasBlack: false, hasErasableBlue: false, hasLowColor: false, hasColor: false };

const formatData = (
    acc: typeof defaultValue,
    counter: MonthlyCounter | CounterConsumption | undefined,
    device: Device | undefined
) => ({
    hasBlack: acc.hasBlack || (counter?.black !== null && counter?.black !== undefined),
    hasErasableBlue:
        acc.hasErasableBlue ||
        (isDeviceUsingErasableBlue(device) && counter?.erasableBlue !== null && counter?.erasableBlue !== undefined),
    hasLowColor:
        acc.hasLowColor ||
        (isDeviceUsingLowColor(device) && counter?.lowColor !== null && counter?.lowColor !== undefined),
    hasColor: acc.hasColor || (isDeviceUsingColors(device) && counter?.color !== null && counter?.color !== undefined),
});

interface YearlyVolumeEvolutionProps extends Partial<BarChartProps<Datum>> {
    height: number;
    device?: Device;
    counters?: Counter[];
    isLoading?: boolean;
    legendInHeader?: boolean;
    source?: 'device' | 'DAC';
}

const YearlyVolumeEvolution: VFC<YearlyVolumeEvolutionProps> = ({
    height,
    device,
    counters,
    isLoading,
    theme,
    legendInHeader,
    source = 'device',
}) => {
    const hasData = source === 'device' ? !!device?.monthlyCounter : !!counters?.length;
    const { hasBlack, hasErasableBlue, hasLowColor, hasColor } = useMemo(() => {
        if (!hasData) {
            return defaultValue;
        }

        if (source === 'device') {
            return (
                device?.monthlyCounter?.reduce<{
                    hasBlack: boolean;
                    hasErasableBlue: boolean;
                    hasLowColor: boolean;
                    hasColor: boolean;
                }>(
                    (acc, monthlyCounter) => ({
                        ...acc,
                        ...formatData(acc, monthlyCounter, device),
                    }),
                    { ...defaultValue }
                ) ?? defaultValue
            );
        } else if (source === 'DAC') {
            return (
                counters?.reduce<{
                    hasBlack: boolean;
                    hasErasableBlue: boolean;
                    hasLowColor: boolean;
                    hasColor: boolean;
                }>(
                    (acc, counter) => ({
                        ...acc,
                        ...formatData(acc, counter.consumption, device),
                    }),
                    { ...defaultValue }
                ) ?? defaultValue
            );
        } else {
            return defaultValue;
        }
    }, [device, source, counters, hasData]);
    const series = useMemo(
        () =>
            seriesBase
                .filter((serie) => {
                    switch (serie.key) {
                        case 'colors':
                            return hasColor;
                        case 'black':
                            return hasBlack;
                        case 'lowColor':
                            return hasLowColor;
                        case 'erasableBlue':
                            return hasErasableBlue;

                        default:
                            return false;
                    }
                })
                .map((serie) => ({
                    ...serie,
                    data:
                        source === 'device'
                            ? [...(device?.monthlyCounter ?? [])]
                                  .sort(
                                      (a, b) =>
                                          new Date(a.lastDateOfMonth).getTime() - new Date(b.lastDateOfMonth).getTime()
                                  )
                                  .map((monthlyCounter) => ({
                                      date: monthlyCounter?.lastDateOfMonth ?? undefined,
                                      black: monthlyCounter?.black ?? undefined,
                                      erasableBlue: monthlyCounter?.erasableBlue ?? undefined,
                                      lowColor: monthlyCounter?.lowColor ?? undefined,
                                      colors: monthlyCounter?.color ?? undefined,
                                      color: serie.color,
                                  })) ?? []
                            : [...(counters ?? [])]
                                  .sort(
                                      (a, b) =>
                                          new Date(a.updatedAt ?? '').getTime() - new Date(b.updatedAt ?? '').getTime()
                                  )
                                  .map((counter) => ({
                                      date: counter?.updatedAt ?? '',
                                      black: counter?.consumption?.black ?? undefined,
                                      erasableBlue: counter?.consumption?.erasableBlue ?? undefined,
                                      lowColor: counter?.consumption?.lowColor ?? undefined,
                                      colors: counter?.consumption?.color ?? undefined,
                                      color: serie.color,
                                  })) ?? [],
                    yAccessor: yAccessor(serie.key),
                })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [device?.id, device?.monthlyCounter, counters]
    );
    const legend = useMemo(() => {
        if (legendInHeader) {
            const seriesWithData = series.reduce<ColorKeys[]>((acc, serie) => {
                for (const serieBase of seriesBase) {
                    if (serie.data.some((d) => d[serieBase.key]) && !acc.includes(serieBase.key)) {
                        acc.push(serieBase.key);
                    }
                }

                return acc;
            }, []);

            return (
                <span className="flex items-center space-x-8 font-12">
                    {seriesWithData.map((key) => (
                        <Fragment key={key}>
                            <span
                                className={classNames('inline-block w-12 h-12 rounded mr-4')}
                                style={{ backgroundColor: seriesBase.find((s) => s.key === key)?.color }}
                                aria-hidden
                            />
                            {translateTonerColor(key as Color)}
                        </Fragment>
                    ))}
                </span>
            );
        }

        return null;
    }, [legendInHeader, series]);

    return (
        <div style={{ height }}>
            <div className="flex justify-between items-center">
                <Typography.Title level={3} className="text-taupe font-12 font-bold leading-4.5">
                    Évolution des VM sur un an
                </Typography.Title>
                {legend}
            </div>
            {isLoading ? (
                <div className="flex items-center justify-center w-full" style={{ height: height - 18 }}>
                    <Spin />
                </div>
            ) : hasData ? (
                <div style={{ height: height - 18 }}>
                    <BarChart<Datum>
                        series={series}
                        xTickFormat={(d) => formatDate(d, { month: 'short' })}
                        yTickFormat={(d) => formatNumberSmall(d)}
                        xScale={{
                            type: 'band',
                            padding: 0.4,
                        }}
                        yScale={{
                            type: 'linear',
                        }}
                        stack
                        theme={{
                            margin: theme?.margin || {
                                left: 36,
                                top: 4,
                                right: 0,
                                bottom: 24,
                            },
                            axis: {
                                tickStrokeColor: 'transparent',
                                tickLabelColor: '#4D4D4D',
                                tickLabelFontSize: 10,
                                tickLabelFontWeight: 500,
                            },
                        }}
                        renderTooltip={({ tooltipData }) => {
                            const { nearestDatum, datumByKey } = tooltipData ?? {};
                            const total = Object.keys(datumByKey ?? {}).reduce(
                                (acc, key) => (acc += yAccessor(key as ColorKeys)(datumByKey?.[key].datum) ?? 0),
                                0
                            );

                            return nearestDatum ? (
                                <div className="p-8">
                                    <p className="text-gray-500 mb-2 capitalize">
                                        {formatDate(xAccessor?.(nearestDatum.datum), { month: 'long' })}
                                    </p>
                                    <p className="font-bold flex justify-between">
                                        <span>Total</span>
                                        <span>{formatNumber(total)}</span>
                                    </p>
                                    <Divider style={{ margin: '0.5rem 0' }} />
                                    <BasicList>
                                        {Object.keys(datumByKey ?? {}).map((key) => (
                                            <li
                                                key={key}
                                                className={`font-bold flex p-1 rounded-sm justify-between items-center p-4 mt-4 gap-8${
                                                    nearestDatum?.key === key ? ' bg-grey-light' : ''
                                                }`}
                                            >
                                                <span className="flex items-center mr-16">
                                                    <span
                                                        className="inline-block w-12 h-12 rounded mr-4"
                                                        style={{ backgroundColor: datumByKey?.[key].datum.color }}
                                                    />
                                                    {translateTonerColor(key as Color)}
                                                </span>
                                                <span>
                                                    {formatNumber(yAccessor(key as ColorKeys)(datumByKey?.[key].datum))}
                                                </span>
                                            </li>
                                        ))}
                                    </BasicList>
                                </div>
                            ) : null;
                        }}
                        gridColumns={false}
                        snapTooltipToDatumY
                        snapTooltipToDatumX
                        showVerticalCrosshair
                        verticalCrosshairStyle={{
                            stroke: '#A38F8F',
                            strokeDasharray: '4,4',
                        }}
                    />
                </div>
            ) : (
                <Empty className="text-taupe" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </div>
    );
};

export default YearlyVolumeEvolution;

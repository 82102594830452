import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, DrawerProps } from 'antd';
import { FC, KeyboardEvent, MouseEvent } from 'react';

import '../assets/styles/CustomDrawer.less';

import { classNames } from '../helpers';

const CustomDrawer: FC<DrawerProps> = ({ className, closable = true, onClose, title, ...props }) => (
    <Drawer
        title={
            <>
                {title}
                {closable && (
                    <Button
                        icon={<CloseOutlined />}
                        type="text"
                        size="small"
                        aria-label="close"
                        onClick={() =>
                            onClose?.(
                                {} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>
                            )
                        }
                    />
                )}
            </>
        }
        onClose={onClose}
        closable={false}
        {...props}
        className={classNames('custom-drawer', className)}
    />
);

export default CustomDrawer;

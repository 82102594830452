import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Layout } from 'antd';
import { useLocalStorage } from 'usehooks-ts';

import '../assets/styles/Header.less';

import { getRoute, RoutePathName } from '../routes';
import UserDropdown from './UserDropdown';
import Logo from './Logo';
import { useAuth } from '../context/AuthContext';
import { hasRole } from '../helpers/security';
import { Alert, Organization, RoleName } from '../queries/api/types';
import OrganizationFormItem from './form/OrganizationFormItem';
import { useLayout } from '../context/LayoutContext';

const Header: FC = () => {
    const { user } = useAuth();
    const { organizationId, setOrganizationId } = useLayout();
    const [, setAlertIdsToIgnore] = useLocalStorage<Array<Alert['id']> | undefined>('massIgnoreAlertIds', undefined);
    const onOrganizationChange = (value: Organization['id'] | undefined) => {
        setOrganizationId(value);
        setAlertIdsToIgnore(undefined);
    };
    const isAdmin = hasRole(user, [RoleName.admin, RoleName.dsc]);

    return (
        <Layout.Header id="main-header">
            <div className="flex">
                <nav className="flex">
                    <Link to={getRoute(RoutePathName.alerts)} title="Accueil">
                        <Logo className="w-144" />
                    </Link>
                </nav>
                {isAdmin && (
                    <div className="flex items-center">
                        <Divider type="vertical" style={{ minHeight: 32 }} className="mx-24" />
                        <div className="rounded py-4 pr-4 pl-8 bg-taupe-lighter flex items-center">
                            <label
                                htmlFor="header-organization"
                                className="uppercase font-12 font-semibold text-taupe mr-12"
                            >
                                Organisation affichée
                            </label>
                            <OrganizationFormItem
                                selectProps={{
                                    onChange: onOrganizationChange,
                                    value: organizationId,
                                    style: { minWidth: 110 },
                                    dropdownMatchSelectWidth: false,
                                    allowClear: true,
                                    id: 'header-organization',
                                    defaultValue: user?.organization?.id,
                                }}
                                isWhite
                                noStyle
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex items-center h-full space-x-4">
                <UserDropdown />
            </div>
        </Layout.Header>
    );
};

export default Header;

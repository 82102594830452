import jwt_decode from 'jwt-decode';

import {
    Address,
    Alert,
    AlertStatus,
    Color,
    Counter,
    Device,
    MailResponseStatus,
    OrderStatus,
    Subscription,
    User,
} from '../queries/api/types';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const sortOrderConverter = (value: string) => {
    switch (value) {
        case 'ascend':
            return 'asc';
        case 'descend':
            return 'desc';
        default:
            return value;
    }
};

export const tableSorterConverter = (value: string) => {
    switch (value) {
        case 'asc':
            return 'ascend';
        case 'desc':
            return 'descend';
        default:
            return undefined;
    }
};

export const getFullName = (user?: Pick<User, 'firstName' | 'lastName'>) => {
    if (!user?.firstName && !user?.lastName) {
        return '-';
    }

    return `${user?.firstName ?? ''}${user?.lastName ? ` ${user?.lastName}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(' ') || undefined;
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).reduce<Record<string, any>>((acc, param) => {
        if (Object.prototype.hasOwnProperty.call(acc, param[0])) {
            if (Array.isArray(acc[param[0]])) {
                return { ...acc, [param[0]]: [...acc[param[0]], param[1]] };
            } else {
                return { ...acc, [param[0]]: [acc[param[0]], param[1]] };
            }
        }

        return { ...acc, [param[0]]: param[1] };
    }, {});

export const requiredRule = { required: true, message: 'champ requis' };

export const getAlertStatusColor = (status?: AlertStatus) => {
    switch (status) {
        case AlertStatus.received:
        case AlertStatus.inProgress:
            return 'blue';
        case AlertStatus.pending:
            return 'orange';
        case AlertStatus.treated:
        case AlertStatus.registered:
            return 'green';

        default:
            return undefined;
    }
};

export const getOrderStatusColor = (status?: OrderStatus) => {
    switch (status) {
        case OrderStatus.inProgress:
            return 'blue';
        case OrderStatus.shipped:
        case OrderStatus.deliveredInTotality:
            return 'green';
        case OrderStatus.partiallyDelivered:
            return 'orange';

        default:
            return undefined;
    }
};

export const getTonerColorClassName = (color?: Color | 'colors' | 'lowColor') => {
    switch (color) {
        case Color.black:
            return 'bg-toner-black';
        case Color.cyan:
            return 'bg-toner-cyan';
        case Color.erasableBlue:
            return 'bg-erasable-blue';
        case Color.magenta:
            return 'bg-toner-magenta';
        case Color.yellow:
            return 'bg-toner-yellow';
        case 'colors':
            return 'bg-red';
        case 'lowColor':
            return 'bg-marianne-blue';

        default:
            return color ?? '-';
    }
};

export const getMailResponseStatusColor = (status?: MailResponseStatus) => {
    switch (status) {
        case MailResponseStatus.inProgress:
            return 'blue';
        case MailResponseStatus.sended:
            return 'green';

        default:
            return 'default';
    }
};

export const isDeviceUsingErasableBlue = (device?: Device) => {
    return Boolean(device?.erpDeviceInfo?.blueErasableColorValueEnabled);
};

export const isDeviceUsingLowColor = (device?: Device) => {
    return Boolean(device?.erpDeviceInfo?.lowColorValueEnabled);
};

export const isDeviceUsingColors = (device?: Device) => {
    return Boolean(device?.erpDeviceInfo?.colorValueEnabled);
};

export const getDeviceCounterTotal = (device: Device, counter?: Counter) => {
    if (!counter?.counter) {
        return undefined;
    }

    return (Object.keys(counter.counter) as Array<keyof typeof counter.counter>).reduce((total, key) => {
        if (['black', 'color', 'erasableBlue', 'lowColor'].includes(key)) {
            switch (key) {
                case 'black':
                    return (total += counter.counter![key] ?? 0);
                case 'color':
                    return isDeviceUsingColors(device) ? (total += counter.counter![key] ?? 0) : total;
                case 'erasableBlue':
                    return isDeviceUsingErasableBlue(device) ? (total += counter.counter![key] ?? 0) : total;
                case 'lowColor':
                    return isDeviceUsingLowColor(device) ? (total += counter.counter![key] ?? 0) : total;
            }
        }

        return total;
    }, 0);
};

export const isAlertGroupCounter = (alert?: Alert) => alert?.alertType.alertGroup.reference === 'Compteurs';

export const resetAlertQueryParams = (
    setQueryParams: (params: { [key: string]: any }) => void,
    params?: Record<string, any>
) => {
    setQueryParams({
        ...params,
        alertId: undefined,
        alertReference: undefined,
        deviceSerial: undefined,
        deviceSerialFilter: undefined,
        customerEmail: undefined,
        mailDomain: undefined,
        mail: undefined,
        status: undefined,
        page: undefined,
        historyPage: undefined,
    });
};

type ColorKeys = 'color' | 'black' | 'lowColor' | 'erasableBlue';

export const getDeviceLastCounterColorPercentage = (device: Device | undefined, key: ColorKeys) => {
    return getCounterColorPercentage(device?.lastCounter?.counter, key);
};

export const getCounterColorPercentage = (counter: Counter['counter'] | undefined, key: ColorKeys) => {
    const total =
        (counter?.color ?? 0) + (counter?.black ?? 0) + (counter?.lowColor ?? 0) + (counter?.erasableBlue ?? 0);

    return total === 0 ? 0 : ((counter?.[key] ?? 0) * 100) / total;
};

export const formatAddress = (address?: Address) => {
    return [address?.street, [address?.zipCode, address?.city].filter(Boolean).join(' ')].filter(Boolean).join(', ');
};

export const downloadBlobFile = (filename: string, data: any, type = 'application/pdf') => {
    const file = new Blob([data], { type });
    const fileUrl = URL.createObjectURL(file);

    const element = document.createElement('a');
    element.href = fileUrl;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
};

export const isDevice = (item?: Device | Subscription): item is Device => {
    return 'lastCounter' in (item || {});
};

export const isNotUndefinedNorNull = (value?: any) => value !== undefined && value !== null;

import { Descriptions } from 'antd';
import { useMemo, VFC } from 'react';

import { isDevice } from '../../helpers';
import { formatNumber } from '../../helpers/i18n';
import { Counter, Device, Subscription } from '../../queries/api/types';

interface AverageConsumptionStatsFaxScanProps {
    record?: Device | Subscription;
}

const AverageConsumptionStatsFaxScan: VFC<AverageConsumptionStatsFaxScanProps> = ({ record }) => {
    const data = useMemo(
        () => {
            let scan: Counter['scan'];
            let fax: Counter['fax'];

            // check if the record is a Device or a Subscription
            if (isDevice(record)) {
                scan = record.lastCounter?.scan;
                fax = record.lastCounter?.fax;
            } else {
                scan = record?.metrics?.scan;
                fax = record?.metrics?.fax;
            }

            const scanBlack = scan?.black ?? 0;
            const scanColor = scan?.color ?? 0;
            const faxTransmitted = fax?.transmitted ?? 0;
            const faxReceived = fax?.received ?? 0;
            return {
                scanTotal: scanBlack + scanColor,
                scanBlack: scan?.black,
                scanBlackPercentage:
                    scanBlack + scanColor > 0 ? (scanBlack * 100) / (scanBlack + scanColor) : undefined,
                scanColor: scan?.color,
                scanColorPercentage:
                    scanBlack + scanColor > 0 ? (scanColor * 100) / (scanBlack + scanColor) : undefined,
                faxTotal: faxTransmitted + faxReceived,
                faxTransmitted: fax?.transmitted,
                faxTransmittedPercentage:
                    faxTransmitted + faxReceived > 0
                        ? (faxTransmitted * 100) / (faxTransmitted + faxReceived)
                        : undefined,
                faxReceived: fax?.received,
                faxReceivedPercentage:
                    faxTransmitted + faxReceived > 0 ? (faxReceived * 100) / (faxTransmitted + faxReceived) : undefined,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record?.id]
    );

    return (
        <div className="flex gap-16">
            <Descriptions
                size="small"
                column={2}
                labelStyle={{ width: 200, fontSize: '0.75rem', padding: '0.25rem 0.125rem' }}
                contentStyle={{ width: 274, fontSize: '0.75rem', padding: '0.125rem' }}
                title="Scan"
                bordered
            >
                <Descriptions.Item label="Scan noir">
                    <span className="font-bold">{formatNumber(data.scanBlack)}</span>
                    <span className="bg-white rounded pt-1 px-4 ml-8 whitespace-nowrap">
                        {formatNumber(data.scanBlackPercentage ? data.scanBlackPercentage / 100 : undefined, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                        })}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="Scan couleur">
                    <span className="font-bold">{formatNumber(data.scanColor)}</span>
                    <span className="bg-white rounded pt-1 px-4 ml-8 whitespace-nowrap">
                        {formatNumber(data.scanColorPercentage ? data.scanColorPercentage / 100 : undefined, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                        })}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item
                    label="Total scan réseau"
                    labelStyle={{ width: 300, fontSize: '0.75rem', padding: '0.25rem 0.125rem' }}
                >
                    <span className="font-bold">{formatNumber(data.scanTotal)}</span>
                </Descriptions.Item>
            </Descriptions>
            <Descriptions
                size="small"
                column={2}
                labelStyle={{ width: 200, fontSize: '0.75rem', padding: '0.25rem 0.125rem' }}
                contentStyle={{ width: 274, fontSize: '0.75rem', padding: '0.125rem' }}
                title="Fax"
                bordered
            >
                <Descriptions.Item label="Transmission">
                    <span className="font-bold">{formatNumber(data.faxTransmitted)}</span>
                    <span className="bg-white rounded pt-1 px-4 ml-8 whitespace-nowrap">
                        {formatNumber(data.faxTransmittedPercentage ? data.faxTransmittedPercentage / 100 : undefined, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                        })}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="Réception">
                    <span className="font-bold">{formatNumber(data.faxReceived)}</span>
                    <span className="bg-white rounded pt-1 px-4 ml-8 whitespace-nowrap">
                        {formatNumber(data.faxReceivedPercentage ? data.faxReceivedPercentage / 100 : undefined, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                        })}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="Total fax">
                    <span className="font-bold">{formatNumber(data.faxTotal)}</span>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};

export default AverageConsumptionStatsFaxScan;

import { Tooltip } from 'antd';
import { flexRender, SortingState, StringOrTemplateHeader, Table } from '@tanstack/react-table';

import { classNames } from '../../helpers';
import { Sort, SortAsc, SortDesc } from '../icons';
import { getHeaderFixedSettings } from './helpers';

const getTooltipTitleText = (sortLabel: string, sorting: SortingState) => {
    if (!sorting[0]?.id) {
        return 'Trier par ordre croissant';
    }

    if (sorting[0].id === sortLabel && !sorting[0].desc) {
        return 'Trier par ordre décroissant';
    }

    return 'Annuler le tri';
};

const isString = <T extends unknown>(header: StringOrTemplateHeader<T, unknown> | undefined): header is string => {
    return typeof header === 'string';
};

const getHeader = <T extends unknown>(header: StringOrTemplateHeader<T, unknown> | undefined) =>
    isString(header) ? header.toString().toUpperCase() : header;

interface ResizableTableHeaderProps<T> {
    table: Table<T>;
    sorting: SortingState;
}

const ResizableTableHeader = <T extends unknown>({ table, sorting }: ResizableTableHeaderProps<T>) => {
    const isResizingAnyHeader = table.getFlatHeaders().some((h) => h.column.getIsResizing());

    return (
        <thead className={classNames(isResizingAnyHeader && 'is-resizing')}>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        const rowSpan = header.column.columnDef.rowSpan;
                        const isHeaderGroup =
                            !header.isPlaceholder &&
                            !!header.subHeaders.length &&
                            header.subHeaders.every((subHeader) => !subHeader.isPlaceholder);

                        if (!header.isPlaceholder && rowSpan !== undefined && header.id === header.column.id) {
                            return null;
                        }

                        const canSort = header?.column?.columnDef?.enableSorting && header.column.getCanSort();
                        const sortDirection = header.column.getIsSorted();
                        const headerContent = flexRender(
                            getHeader<T>(header.column.columnDef.header),
                            header.getContext()
                        );
                        const fixedSettings = getHeaderFixedSettings<T>(header);
                        const isResizing = header.column.getIsResizing();

                        return (
                            <th
                                className={classNames(
                                    canSort && 'with-sort',
                                    fixedSettings.isFixedLeft && 'fixed-left',
                                    fixedSettings.isLastLeftFixed && 'fixed-left-last',
                                    fixedSettings.isFixedRight && 'fixed-right',
                                    fixedSettings.isFirstRightFixed && 'fixed-right-first',
                                    header.column.columnDef.meta?.isSelectionColumn && 'ts-th-selection'
                                )}
                                key={header.id}
                                colSpan={header.colSpan}
                                rowSpan={rowSpan}
                                style={{
                                    width: header.getSize(),
                                    left: fixedSettings.left,
                                    right: fixedSettings.right,
                                    textAlign: isHeaderGroup ? 'center' : undefined,
                                }}
                                aria-sort={
                                    sortDirection ? (sortDirection === 'desc' ? 'descending' : 'ascending') : undefined
                                }
                            >
                                {canSort ? (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            isResizingAnyHeader ? undefined : getTooltipTitleText(header.id, sorting)
                                        }
                                        arrowPointAtCenter
                                    >
                                        <button
                                            type="button"
                                            className={classNames('ts-sorter-button', sortDirection && 'sort-active')}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {headerContent}
                                            <span aria-hidden="true" className="flex">
                                                {!sortDirection && <Sort />}
                                                {sortDirection === 'asc' && <SortAsc />}
                                                {sortDirection === 'desc' && <SortDesc />}
                                            </span>
                                        </button>
                                    </Tooltip>
                                ) : (
                                    headerContent
                                )}
                                {header.column.columnDef?.enableResizing && (
                                    <span
                                        role="presentation"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        onMouseDown={header.getResizeHandler()}
                                        onTouchStart={header.getResizeHandler()}
                                        className={classNames('ts-column-resizer', isResizing && 'is-resizing')}
                                        // columnResizeMode is 'onChange' for now, if needed we can change it to onEnd
                                        {...(table.options.columnResizeMode === 'onEnd' && isResizing
                                            ? {
                                                  style: {
                                                      transform: `translateX(${
                                                          table.getState().columnSizingInfo.deltaOffset || 0
                                                      }px)`,
                                                  },
                                              }
                                            : {})}
                                    />
                                )}
                            </th>
                        );
                    })}
                </tr>
            ))}
        </thead>
    );
};

export default ResizableTableHeader;

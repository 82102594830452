import { ReactNode, useState, VFC } from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { errorMessage } from '../../helpers/message';
import { Source } from '../../queries/api/types';
import { useSourceList } from '../../queries/sources';
import { SourceListPayload } from '../../queries/api/sources';
import { defaultErrorMessage } from '../../helpers/i18n';
import { useDebounce } from '../../hooks';
import { classNames } from '../../helpers';

interface SourceFormItemProps extends FormItemProps {
    selectProps?: SelectProps<Source['id']>;
    queryPayload?: SourceListPayload;
    optionLabelRender?: (record: Source) => ReactNode;
    optionFilter?: (record: Source) => boolean;
    customOptions?: DefaultOptionType[];
    isWhite?: boolean;
}

const SourceFormItem: VFC<SourceFormItemProps> = ({
    selectProps,
    queryPayload,
    optionLabelRender = (record) => record.name,
    optionFilter = () => true,
    customOptions,
    isWhite,
    ...props
}) => {
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: sources, isLoading } = useSourceList(
        { pageSize: 20, search: debouncedSearch || undefined, ...queryPayload },
        {
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    return (
        <Form.Item {...props}>
            <Select
                {...selectProps}
                placeholder={selectProps?.placeholder ?? 'Choisir une source'}
                className={classNames(isWhite && 'select-white', selectProps?.className)}
                filterOption={false}
                loading={isLoading}
                options={(
                    sources?.items?.filter(optionFilter).map((source) => ({
                        label: optionLabelRender(source),
                        value: source.id,
                    })) as unknown as DefaultOptionType
                )?.concat(customOptions ?? [])}
                onSearch={setSearch}
                defaultActiveFirstOption
                showSearch
            />
        </Form.Item>
    );
};

export default SourceFormItem;

import { CloseCircleFilled } from '@ant-design/icons';
import { FC } from 'react';

import { ECCCommunication, MFPCommunication } from '../queries/api/types';
import { classNames } from '../helpers';
import { Attention, CheckOne } from './icons';

interface EccAutoStatusIconProps {
    status?: MFPCommunication | ECCCommunication;
    inTag?: boolean;
}

const EccAutoStatusIcon: FC<EccAutoStatusIconProps> = ({ status, inTag }) => {
    switch (status) {
        case 'green':
            return <CheckOne className={classNames('text-success', !inTag && 'font-20')} />;
        case 'orange':
            return <Attention className={classNames('text-warning', !inTag && 'font-20')} />;
        case 'red':
            return <CloseCircleFilled className={classNames('text-error', !inTag && 'font-20')} />;
        default:
            return <CloseCircleFilled className={classNames('text-error', !inTag && 'font-20')} />;
    }
};

export default EccAutoStatusIcon;

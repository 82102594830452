/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.997 1.5a1 1 0 0 1 .898.555l2.864 5.77 6.385.931a1 1 0 0 1 .556 1.704l-4.645 4.549 1.103 6.32a1 1 0 0 1-1.456 1.053L12 19.343l-5.701 3.04a1 1 0 0 1-1.456-1.055l1.111-6.318-4.652-4.548a1 1 0 0 1 .556-1.705l6.42-.931 2.824-5.765a1 1 0 0 1 .896-.56Zm.008 3.261L9.841 9.18a1 1 0 0 1-.755.55l-4.94.716 3.583 3.502a1 1 0 0 1 .285.889l-.847 4.816 4.362-2.325a1 1 0 0 1 .94 0l4.366 2.327-.84-4.82a1 1 0 0 1 .285-.886l3.578-3.504-4.907-.716a1 1 0 0 1-.751-.545l-2.195-4.422Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgStar: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-star', props.className)} />
));
export default SvgStar;

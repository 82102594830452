import { ArrowLeftOutlined, FileExcelOutlined, LineChartOutlined, UserOutlined } from '@ant-design/icons';
import { show } from '@ebay/nice-modal-react';
import {
    Button,
    Descriptions,
    Divider,
    Form,
    FormProps,
    Tooltip,
    Typography,
    Empty,
    Pagination,
    PaginationProps,
} from 'antd';
import { useCallback, useMemo, useState, VFC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SortingState } from '@tanstack/react-table';

import AlertStatusTag from '../../components/AlertStatusTag';
import ListColorLevel from '../../components/ColorLevel';
import AlertStatusFormItem from '../../components/form/AlertStatusFormItem';
import AlertTypeFormItem from '../../components/form/AlertTypeFormItem';
import ListTonerGauge from '../../components/ListTonerGauge';
import NoData from '../../components/NoData';
import Seo from '../../components/Seo';
import constants from '../../config/constants';
import { useLayout } from '../../context/LayoutContext';
import {
    getDeviceCounterTotal,
    isDeviceUsingColors,
    isDeviceUsingErasableBlue,
    isDeviceUsingLowColor,
} from '../../helpers';
import { defaultErrorMessageWithStatus, formatDate, formatNumber, translateTonerColor } from '../../helpers/i18n';
import useQueryParams, { useStoredQueryParams } from '../../hooks/queryParams';
import { useAlertList, useAlertListExport } from '../../queries/alerts';
import { Alert, AlertStatus, AlertType, Color, FileGenerationRequestSlug, Source } from '../../queries/api/types';
import { useDeviceList } from '../../queries/devices';
import { getRoute, RoutePathName } from '../../routes';
import { alertModalId, deviceDrawerId } from '../../App';
import { alertQueryParamsKey } from '../alerts/AlertsList';
import ResizableTable, { ResizableTableColumn, ResizableTableProps } from '../../components/ResizableTable';
import { Alarm } from '../../components/icons';
import SourceFormItem from '../../components/form/SourceFormItem';
import { errorMessage, successMessage } from '../../helpers/message';

const getAlertStatusPayload = (alertStatuses?: AlertStatus[]) =>
    alertStatuses?.includes(AlertStatus.received) ? [...alertStatuses, AlertStatus.inProgress] : alertStatuses;

const AlertHistory: VFC = () => {
    const { organizationId } = useLayout();
    const { withBackButton } = useLocation<{ withBackButton?: boolean }>()?.state || {};
    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const alertQueryParams = useStoredQueryParams(alertQueryParamsKey);
    const [queryParams, setQueryParams] = useQueryParams('alertHistory');
    const deviceSerial = queryParams.get('deviceSerial') ?? undefined;
    const alertTypes = queryParams.getAll('alertTypes') as Array<AlertType['id']> | undefined;
    const sources = queryParams.getAll('sources') as Array<Source['id']> | undefined;
    const alertStatuses = queryParams.getAll('alertStatuses') as AlertStatus[] | undefined;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const pageSize =
        queryParams.get('pageSize') !== null
            ? parseInt(queryParams.get('pageSize')!, 10) || constants.PAGE_SIZE
            : constants.PAGE_SIZE;
    const sort = queryParams.get('sort') ?? 'alertSortableProperties.dateFromBody';
    const sortOrder = queryParams.get('sortOrder') ?? 'desc';
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const { data: devices, isLoading: isLoadingDevices } = useDeviceList(
        {
            serials: deviceSerial ? [deviceSerial] : undefined,
            organization: organizationId,
        },
        {
            enabled: !!deviceSerial,
        }
    );
    const device = devices?.items[0];
    const {
        data: alertList,
        isLoading: isLoadingAlerts,
        isError: isAlertsError,
        error: alertsError,
    } = useAlertList(
        {
            devices: device?.id,
            page,
            pageSize,
            sort,
            sortOrder,
            organization: organizationId,
            status: getAlertStatusPayload(alertStatuses),
            alertTypes,
            sources,
        },
        {
            enabled: !!device?.id,
        }
    );
    const { isLoading: isExporting } = useAlertListExport(
        {
            devices: device?.id,
            page,
            sort,
            sortOrder,
            organization: organizationId,
            status: getAlertStatusPayload(alertStatuses),
            alertTypes,
            sources,
            fileGenerationRequestSlug: FileGenerationRequestSlug.alertHistory,
        },
        {
            enabled: isExportEnabled,
            onSettled: () => {
                setIsExportEnabled(false);
            },
            onError: (error) => {
                if (error?.response?.status === 400 && error?.response?.data?.fileGenerationRequestInProgressId) {
                    errorMessage({
                        content: "Une demande d'export est déjà en cours, veuillez patienter quelques instants",
                    });
                } else {
                    errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                }
            },
            onSuccess: () => {
                successMessage({
                    content: "Demande d'export envoyée avec succès, vous recevrez le fichier par e-mail",
                });
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );
    const isLoadingList = isLoadingDevices || isLoadingAlerts;
    const [form] = Form.useForm();
    const onPaginationChange: PaginationProps['onChange'] = (newPage, newPageSize) => {
        setQueryParams({
            page: (newPage ?? 1) - 1,
            pageSize: newPageSize,
        });
    };
    const onFiltersValueChange: FormProps['onValuesChange'] = (changedValues, allValues) => {
        const { alertTypes, alertStatuses, sources } = allValues;

        setQueryParams({
            page: 0,
            alertTypes: alertTypes ?? undefined,
            alertStatuses: alertStatuses ?? undefined,
            sources: sources ?? undefined,
        });
    };
    const onClickExport = (type: 'excel' | 'pdf') => {
        if (type === 'excel') {
            setIsExportEnabled(true);
        }
    };
    const columns: Array<ResizableTableColumn<Alert>> = useMemo(
        () => [
            {
                accessorFn: (row) => row,
                id: 'reference',
                header: "N° d'alerte",
                cell: (info) =>
                    info.getValue<Alert>().reference ? (
                        <Button
                            type="text"
                            className="custom-btn-text font-bold blue-link"
                            onClick={() => {
                                show(alertModalId, {
                                    alertId: info.getValue<Alert>().id,
                                });
                            }}
                        >
                            {info.getValue<Alert>().reference}
                        </Button>
                    ) : (
                        <NoData />
                    ),
                size: 140,
                fixed: 'left',
                rowSpan: 2,
                enableSorting: true,
            },
            {
                accessorFn: (row) => row.mailMessage.dateFromBody,
                id: 'alertSortableProperties.dateFromBody',
                header: 'Date & heure',
                cell: (info) =>
                    formatDate(info.getValue<string>(), {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    }),
                enableSorting: true,
                size: 140,
                rowSpan: 2,
            },
            {
                accessorFn: (row) => row,
                id: 'status',
                header: 'Statut',
                cell: (info) => <AlertStatusTag alert={info.getValue<Alert>()} isList />,
                size: 146,
                enableSorting: true,
                rowSpan: 2,
            },
            {
                accessorFn: (row) => row.treatedAt,
                id: 'treatedAt',
                header: 'Date trait.',
                cell: (info) => formatDate(info.getValue<string>()),
                enableSorting: true,
                size: 120,
                rowSpan: 2,
            },
            {
                accessorFn: (row) => row.operator?.username,
                id: 'operator',
                header: 'Opérateur',
                cell: (info) => info.getValue<string>() ?? <NoData />,
                size: 106,
                rowSpan: 2,
            },
            {
                accessorKey: 'object',
                id: 'object',
                header: 'Objet',
                cell: (info) =>
                    info.getValue() ? (
                        <Tooltip title={info.getValue<string>()} placement="topLeft">
                            {info.getValue<string>()}
                        </Tooltip>
                    ) : (
                        <NoData />
                    ),
                size: 240,
                rowSpan: 2,
                ellipsis: true,
            },
            {
                accessorFn: (row) => row.source.reference,
                id: 'source',
                header: 'Source',
                size: 100,
                rowSpan: 2,
            },
            {
                id: 'counters',
                header: 'Compteurs',
                columns: [
                    {
                        id: 'alertSortableProperties.totalCounter',
                        header: 'Total',
                        accessorFn: (row) => row,
                        cell: (info) => (
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {formatNumber(
                                    getDeviceCounterTotal(
                                        info.getValue<Alert>().device,
                                        info.getValue<Alert>().deviceCounter?.counter
                                    )
                                )}
                            </span>
                        ),
                        enableSorting: true,
                        size: 80,
                    },
                    {
                        id: 'alertSortableProperties.blackCounter',
                        header: 'Noir',
                        accessorFn: (row) => row.deviceCounter?.counter?.counter?.black,
                        cell: (info) => (
                            <span style={{ whiteSpace: 'nowrap' }}>{formatNumber(info.getValue<number | null>())}</span>
                        ),
                        enableSorting: true,
                        size: 79,
                    },
                    {
                        id: 'alertSortableProperties.colorCounter',
                        header: 'Couleur',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            const color = alert?.deviceCounter?.counter?.counter?.color;

                            return isDeviceUsingColors(alert.device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>{formatNumber(color)}</span>
                            ) : (
                                <NoData />
                            );
                        },
                        enableSorting: true,
                        size: 103,
                    },
                    {
                        id: 'alertSortableProperties.lowColorCounter',
                        header: 'Marianne',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            const color = alert?.deviceCounter?.counter?.counter?.lowColor;

                            return isDeviceUsingColors(alert.device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>{formatNumber(color)}</span>
                            ) : (
                                <NoData />
                            );
                        },
                        enableSorting: true,
                        size: 114,
                    },
                    {
                        id: 'alertSortableProperties.erasableBlueCounter',
                        header: 'Bleu effaç.',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            const color = alert?.deviceCounter?.counter?.counter?.erasableBlue;

                            return isDeviceUsingColors(alert.device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>{formatNumber(color)}</span>
                            ) : (
                                <NoData />
                            );
                        },
                        enableSorting: true,
                        size: 200,
                    },
                ],
            },
            {
                id: 'toner',
                header: 'Toner restant (%)',
                columns: [
                    {
                        id: 'colorLevels.cyan',
                        header: 'Cyan',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            return (
                                <ListTonerGauge
                                    color={Color.cyan}
                                    value={isDeviceUsingColors(alert.device) ? alert.colorLevels?.cyan : undefined}
                                />
                            );
                        },
                        enableSorting: true,
                        size: 84,
                    },
                    {
                        id: 'colorLevels.yellow',
                        header: 'Yel.',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            return (
                                <ListTonerGauge
                                    color={Color.yellow}
                                    value={isDeviceUsingColors(alert.device) ? alert.colorLevels?.yellow : undefined}
                                />
                            );
                        },
                        enableSorting: true,
                        size: 84,
                    },
                    {
                        id: 'colorLevels.magenta',
                        header: 'Mag.',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            return (
                                <ListTonerGauge
                                    color={Color.magenta}
                                    value={isDeviceUsingColors(alert.device) ? alert.colorLevels?.magenta : undefined}
                                />
                            );
                        },
                        enableSorting: true,
                        size: 84,
                    },
                    {
                        id: 'colorLevels.black',
                        header: 'Black',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();
                            return <ListTonerGauge color={Color.black} value={alert.colorLevels?.black} />;
                        },
                        enableSorting: true,
                        size: 84,
                    },
                    {
                        id: 'colorLevels.erasableBlue',
                        header: 'Effaç.',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();

                            return (
                                <ListTonerGauge
                                    color={Color.erasableBlue}
                                    value={
                                        isDeviceUsingErasableBlue(alert.device)
                                            ? alert.colorLevels?.erasableBlue
                                            : undefined
                                    }
                                />
                            );
                        },
                        enableSorting: true,
                        size: 84,
                    },
                ],
            },
            {
                accessorFn: (row) => row,
                id: 'colorReferenceToOrder',
                header: 'Référence',
                cell: (info) =>
                    info.getValue<Alert>()?.colorReferenceToOrder ? (
                        <ListColorLevel
                            colorLevel={{ [info.getValue<Alert>()?.colorReferenceToOrder as Color]: 1 }}
                            label={translateTonerColor(info.getValue<Alert>()?.colorReferenceToOrder)}
                        />
                    ) : (
                        info.getValue<Alert>()?.referenceToOrder ?? <NoData />
                    ),
                size: 106,
                rowSpan: 2,
            },
            {
                id: 'delta',
                header: 'Delta',
                columns: [
                    {
                        id: 'alertSortableProperties.blackConsumption',
                        header: 'Noir',
                        accessorFn: (row) => row.deviceCounter?.counter?.consumption?.black,
                        cell: (info) => <span style={{ whiteSpace: 'nowrap' }}>{info.getValue<number | null>()}</span>,
                        enableSorting: true,
                        size: 79,
                    },
                    {
                        id: 'alertSortableProperties.colorConsumption',
                        header: 'Couleur',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();

                            return isDeviceUsingColors(alert.device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {formatNumber(alert.deviceCounter?.counter?.consumption?.color)}
                                </span>
                            ) : (
                                <NoData />
                            );
                        },
                        enableSorting: true,
                        size: 103,
                    },
                    {
                        id: 'alertSortableProperties.lowColorConsumption',
                        header: 'Marianne',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();

                            return isDeviceUsingLowColor(alert.device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {formatNumber(alert.deviceCounter?.counter?.consumption?.lowColor)}
                                </span>
                            ) : (
                                <NoData />
                            );
                        },
                        enableSorting: true,
                        size: 114,
                    },
                    {
                        id: 'alertSortableProperties.erasableBlueConsumption',
                        header: 'Bleu effaç.',
                        accessorFn: (row) => row,
                        cell: (info) => {
                            const alert = info.getValue<Alert>();

                            return isDeviceUsingErasableBlue(alert.device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {formatNumber(alert.deviceCounter?.counter?.consumption?.erasableBlue)}
                                </span>
                            ) : (
                                <NoData />
                            );
                        },
                        enableSorting: true,
                        size: 200,
                    },
                ],
            },
        ],
        []
    );

    const pagination = useMemo<ResizableTableProps<Alert>['pagination']>(
        () => ({
            current: page + 1,
            total: alertList?.totalCount,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            size: 'small' as const,
            pageSize: alertList?.pageSize ?? 50,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            hideOnSinglePage: false,
            inTableFooter: true,
            showSizeChanger: true,
        }),
        [alertList?.pageSize, alertList?.totalCount, page, setQueryParams]
    );

    return (
        <div className="flex flex-col min-h-full">
            <Seo title="Historique des alertes machine" />
            <div className="-mt-16 -mx-16 p-16">
                <div className="flex justify-between">
                    {withBackButton ? (
                        <Link
                            to={{
                                pathname: getRoute(RoutePathName.alerts),
                                search: new URLSearchParams(alertQueryParams).toString(),
                            }}
                        >
                            <Button icon={<ArrowLeftOutlined />} type="link" className="pl-0 font-semibold">
                                Retour à l&rsquo;alerte
                            </Button>
                        </Link>
                    ) : (
                        <div />
                    )}
                    <div className="space-x-4">
                        <Link
                            to={{
                                pathname: getRoute(RoutePathName.alertStats),
                                search: new URLSearchParams({
                                    deviceSerial: deviceSerial ?? '',
                                }).toString(),
                            }}
                        >
                            <Button icon={<LineChartOutlined className="text-primary" />}>Compteurs</Button>
                        </Link>
                        <Button
                            icon={<UserOutlined className="text-primary" />}
                            disabled={!device?.id}
                            onClick={() => {
                                show(deviceDrawerId, {
                                    id: device?.id,
                                    tab: 'artis',
                                });
                            }}
                        >
                            Fiche parc
                        </Button>
                        <Button
                            icon={<FileExcelOutlined className="text-primary" />}
                            onClick={() => onClickExport('excel')}
                            loading={isExporting}
                        >
                            Excel
                        </Button>
                    </div>
                </div>
                <Divider orientation="left" orientationMargin="0">
                    <Typography.Title>Historique des alertes machine</Typography.Title>
                </Divider>
                <Descriptions size="small" layout="vertical" colon={false} column={5}>
                    <Descriptions.Item label="Numéro de série">{deviceSerial}</Descriptions.Item>
                    <Descriptions.Item label="Machine">
                        {device?.hardCodedDeviceInfo?.model ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Client">
                        {device?.hardCodedDeviceInfo?.customer?.name ?? <NoData />}
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <div className="bg-grey-darker -mx-16 -mb-16 p-16 space-y-4 flex-1">
                <Form
                    form={form}
                    onValuesChange={onFiltersValueChange}
                    initialValues={{
                        alertTypes,
                        alertStatuses,
                        sources,
                    }}
                    className="flex items-center mb-16 justify-between space-x-3"
                >
                    <div className="flex items-center space-x-3">
                        <AlertTypeFormItem
                            selectProps={{
                                style: { minWidth: 150 },
                                dropdownMatchSelectWidth: false,
                                placeholder: 'Tous les types',
                                allowClear: true,
                                mode: 'multiple',
                            }}
                            name="alertTypes"
                            optionLabelRender={(alertType) => alertType.reference}
                            isWhite
                            noStyle
                        />
                        <AlertStatusFormItem
                            selectProps={{
                                style: { minWidth: 150 },
                                dropdownMatchSelectWidth: false,
                                placeholder: 'Tous les statuts',
                                allowClear: true,
                                mode: 'multiple',
                            }}
                            name="alertStatuses"
                            optionFilter={(status) => ![AlertStatus.inProgress, AlertStatus.routed].includes(status)}
                            isWhite
                            noStyle
                        />
                        <SourceFormItem
                            selectProps={{
                                style: { minWidth: 150 },
                                dropdownMatchSelectWidth: false,
                                placeholder: 'Toutes les sources',
                                allowClear: true,
                                mode: 'multiple',
                            }}
                            name="sources"
                            optionLabelRender={(source) => source.reference}
                            isWhite
                            noStyle
                        />
                    </div>
                    <Pagination
                        total={alertList?.totalCount}
                        current={page + 1}
                        pageSize={alertList?.pageSize ?? 50}
                        onChange={onPaginationChange}
                        pageSizeOptions={['10', '20', '50', '100', '200']}
                        showSizeChanger
                        size="small"
                    />
                </Form>
                <ResizableTable
                    data={alertList?.items}
                    columns={columns}
                    isLoading={isLoadingList}
                    isError={isAlertsError}
                    error={alertsError}
                    minWidth={2500}
                    emptyContent={
                        <Empty
                            className="mb-32 text-taupe"
                            image={<Alarm className="font-28 mt-56" />}
                            description={'Aucun résultat'}
                        />
                    }
                    pagination={pagination}
                    onSortChange={onSortChange}
                    enableColumnResizing
                />
            </div>
        </div>
    );
};

export default AlertHistory;

import { VFC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Alert, Button, Typography } from 'antd';

import LoginLayout from '../../components/LoginLayout';
import Seo from '../../components/Seo';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import Logo from '../../components/Logo';
import useQueryParams from '../../hooks/queryParams';
import { defaultErrorMessage } from '../../helpers/i18n';

const Login: VFC = () => {
    const [queryParams] = useQueryParams('login');
    const error = queryParams?.get('errorCode') ? Number(queryParams?.get('errorCode')) : undefined;
    const { from } = useLocation<{ from?: Location }>().state ?? {};
    const { user, checkSessionError, login } = useAuth();

    const onDevLogin = () => {
        login.mutate({
            username: 'tfis@onefirst.dev',
            password: 'Password01$',
        });
    };

    if (user && !checkSessionError) {
        return <Redirect to={from ?? getRoute(RoutePathName.alerts)} />;
    }

    let errorMessage = error !== undefined ? defaultErrorMessage : null;

    switch (error) {
        case 401:
            errorMessage = 'Organisation ou rôle inconnu';
            break;
    }

    return (
        <LoginLayout>
            <Seo title="Connexion" />
            <Logo className="mb-24" />
            <Typography.Title className="mb-32">Connectez-vous</Typography.Title>
            {errorMessage && <Alert type="error" message={errorMessage} className="mb-16" />}
            {process.env.NODE_ENV === 'development' ? (
                <Button type="primary" size="large" onClick={onDevLogin}>
                    Connexion dev
                </Button>
            ) : (
                <a href="/api/auth/sso">
                    <Button type="primary" size="large">
                        Connexion avec votre compte professionnel
                    </Button>
                </a>
            )}
        </LoginLayout>
    );
};

export default Login;

import { compile } from 'path-to-regexp';

import { debug } from './helpers';

export enum RoutePathName {
    usersList = 'usersList',
    usersDetails = 'usersDetails',
    home = 'home',
    login = 'login',
    notFound = 'notFound',
    privacy = 'privacy',
    forgottenPassword = 'forgottenPassword',
    resetPassword = 'resetPassword',
    notifications = 'notifications',
    organizations = 'organizations',
    test = 'test',

    alerts = 'alerts',
    alertHistory = 'alertHistory',
    alertStats = 'alertStats',
    deviceSearch = 'deviceSearch',
    orders = 'orders',
    subscriptions = 'subscriptions',
    exportSubscription = 'exportSubscription',
    devices = 'devices',
    exportDevice = 'exportDevice',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.usersList]: '/users',
    [RoutePathName.usersDetails]: '/users/:userId',
    [RoutePathName.home]: '/',
    [RoutePathName.login]: '/login',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.privacy]: '/confidentialite',
    [RoutePathName.forgottenPassword]: '/mot-de-passe-oublie',
    [RoutePathName.resetPassword]: '/reset-password',
    [RoutePathName.notifications]: '/notifications',
    [RoutePathName.organizations]: '/organizations',
    // enum based param example, any param that does not match the enum will result in a 404
    [RoutePathName.test]: `/test/:param(${Object.values(RoutePathName).join('|')})`,

    [RoutePathName.alertHistory]: '/alertes/historique',
    [RoutePathName.alertStats]: '/alertes/analyse-compteurs',
    [RoutePathName.alerts]: '/alertes/:param(surveillance-niveaux-toner)?',
    [RoutePathName.deviceSearch]: '/machines/recherche',
    [RoutePathName.orders]: '/commandes-livraisons-toner',
    [RoutePathName.subscriptions]: '/abonnements',
    [RoutePathName.exportSubscription]: '/exports/subscription',
    [RoutePathName.devices]: '/parc-machines',
    [RoutePathName.exportDevice]: '/exports/device',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error('[getRoute] route compile error :', error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};

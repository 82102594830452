import { useCallback, useMemo, useState, VFC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Empty, Modal, Typography } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, LineChartOutlined, UserOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { show } from '@ebay/nice-modal-react';
import { SortingState } from '@tanstack/react-table';

import { Alarm, DocSearchTwo, SettingTwo, Truck } from '../../components/icons';
import NoData from '../../components/NoData';
import Seo from '../../components/Seo';
import { formatDate, formatNumber, formatRelativeTime, translateTonerColor } from '../../helpers/i18n';
import useQueryParams from '../../hooks/queryParams';
import { Color, Device, Permission, TonerMonitoringStatus } from '../../queries/api/types';
import { devicesKeys, useDeviceList, useDeviceUpdate } from '../../queries/devices';
import { getRoute, RoutePathName } from '../../routes';
import AlertsTonerLevelsFilters from './AlertsTonerLevelsFilters';
import { errorMessage, successMessage } from '../../helpers/message';
import { useLayout } from '../../context/LayoutContext';
import { deviceDrawerId } from '../../App';
import { hasPermission } from '../../helpers/security';
import { useAuth } from '../../context/AuthContext';
import { useAlertCreate } from '../../queries/alerts';
import constants from '../../config/constants';
import EccAutoStatusIcon from '../../components/EccAutoStatusIcon';
import ResizableTable, { ResizableTableColumn, ResizableTableProps } from '../../components/ResizableTable';
import { classNames } from '../../helpers';

const AlertsTonerLevels: VFC = () => {
    const { user } = useAuth();
    const { organizationId } = useLayout();
    const queryClient = useQueryClient();
    const [queryParams, setQueryParams] = useQueryParams('AlertsTonerLevels');
    const colorLevel = queryParams.get('colorLevel') ? Number(queryParams.get('colorLevel')) : 20;
    const color = (queryParams.get('color') as Color) ?? Color.yellow;
    const selectedDeviceId = queryParams.get('selectedDeviceId') ?? undefined;
    const selectedDeviceSerial = queryParams.get('selectedDeviceSerial') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const pageSize =
        queryParams.get('pageSize') !== null
            ? parseInt(queryParams.get('pageSize')!, 10) || constants.PAGE_SIZE
            : constants.PAGE_SIZE;
    const [selectedDeviceIds, setSelectedDeviceIds] = useState<Array<Device['id']>>();
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const isListVisible = !!color && colorLevel !== undefined;
    const {
        data: devices,
        isLoading,
        isFetching,
        isError,
        error,
    } = useDeviceList(
        {
            organization: organizationId,
            tonerMonitoringStatus: [TonerMonitoringStatus.active, TonerMonitoringStatus.highlight],
            eccAndAutoNotFalse: true,
            pageSize,
            color,
            colorLevel,
            sort,
            sortOrder,
            page,
        },
        {
            enabled: isListVisible,
            staleTime: 0,
        }
    );
    const { mutateAsync: updateDevice } = useDeviceUpdate();
    const { mutateAsync: createAlert } = useAlertCreate();
    const pagination = useMemo<ResizableTableProps<Device>['pagination']>(
        () => ({
            current: page + 1,
            total: devices?.totalCount ?? 0,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            size: 'small',
            pageSize: devices?.pageSize ?? 50,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            inTableFooter: true,
            showSizeChanger: true,
        }),
        [devices?.pageSize, devices?.totalCount, page, setQueryParams]
    );
    const onClickCreateAlert = () => {
        const isMultiple = selectedDeviceIds!.length !== 1;
        Modal.confirm({
            width: 425,
            centered: true,
            title: "Création d'une alerte",
            icon: <ExclamationCircleOutlined className="text-warning" />,
            content: 'Voulez-vous créer une alerte de régularisation ?',
            okText: "Créer l'alerte",
            onOk: async () =>
                await Promise.allSettled(
                    selectedDeviceIds!.map(
                        async (deviceId) =>
                            await createAlert({
                                organization: organizationId,
                                device: deviceId,
                                colorReferenceToOrder: color,
                            })
                    )
                ).then((results) => {
                    if (results.every((result) => result.status === 'fulfilled')) {
                        successMessage({
                            content: `${isMultiple ? 'Alertes créees' : 'Alerte créee'} avec succès`,
                        });
                    } else {
                        errorMessage({
                            content: `Une erreur est survenue pendant la création ${
                                isMultiple ? 'des alertes' : "de l'alerte"
                            }`,
                        });
                    }
                    queryClient.invalidateQueries(devicesKeys.lists());

                    const remainingDeviceIds = results
                        .map((result, index) => (result.status === 'rejected' ? selectedDeviceIds![index] : undefined))
                        .filter(Boolean) as string[];

                    setSelectedDeviceIds(remainingDeviceIds);
                }),
        });
    };
    const onClickUncontrol = () => {
        const isMultiple = selectedDeviceIds!.length !== 1;
        Modal.confirm({
            width: 480,
            centered: true,
            title: 'Ne plus contrôler les machines',
            icon: <ExclamationCircleOutlined className="text-warning" />,
            content: 'Êtes-vous sûr de ne plus vouloir contrôler les machines sélectionnées',
            okText: 'Ne plus contrôler',
            onOk: async () =>
                await Promise.allSettled(
                    selectedDeviceIds!.map(
                        async (deviceId) =>
                            await updateDevice({
                                id: deviceId,
                                tonerMonitoring: {
                                    [color]: TonerMonitoringStatus.deactivate,
                                },
                            })
                    )
                ).then((results) => {
                    if (results.every((result) => result.status === 'fulfilled')) {
                        successMessage({
                            content: `${isMultiple ? 'Machines masquées' : 'Machine masquée'} avec succès`,
                        });
                    } else {
                        errorMessage({
                            content: `Une erreur est survenue pendant le masquage ${
                                isMultiple ? 'des machines' : 'de la machine'
                            }`,
                        });
                    }
                    queryClient.invalidateQueries(devicesKeys.lists());

                    const remainingDeviceIds = results
                        .map((result, index) => (result.status === 'rejected' ? selectedDeviceIds![index] : undefined))
                        .filter(Boolean) as string[];

                    setSelectedDeviceIds(remainingDeviceIds);
                }),
        });
    };

    const onRowHighlightChange = useCallback<(row: Device, index: number) => void>(
        (row) => {
            setQueryParams({
                selectedDeviceId: row.id,
                selectedDeviceSerial: row.serial,
            });
        },
        [setQueryParams]
    );
    const isRowHighlighted = useCallback<(row: Device, index: number) => boolean>(
        (row) => row.id === selectedDeviceId,
        [selectedDeviceId]
    );
    const rowClassName = useCallback<(row: Device, index: number) => string | undefined>(
        (row) => classNames(row.tonerMonitoring?.[color] === TonerMonitoringStatus.highlight && 'table-row-purple'),
        [color]
    );
    const columns = useMemo<Array<ResizableTableColumn<Device>>>(
        () => [
            {
                id: 'serial',
                header: 'N° de série',
                accessorFn: (row) => row.serial,
                cell: (info) => info.getValue<string>() ?? <NoData />,
                enableSorting: true,
                fixed: 'left',
                size: 140,
            },
            {
                id: 'hardCodedDeviceInfoModel',
                header: 'Matériel',
                accessorFn: (row) => row,
                cell: (info) => {
                    const device = info.getValue<Device>();
                    return device.hardCodedDeviceInfo?.model || device.erpDeviceInfo?.model || <NoData />;
                },
                size: 260,
            },
            {
                id: 'erpDeviceInfoCustomerReference',
                header: 'Code client',
                accessorFn: (row) => row,
                cell: (info) => {
                    const device = info.getValue<Device>();
                    return (
                        device.erpDeviceInfo?.customer?.reference ??
                        device.hardCodedDeviceInfo?.customer?.reference ?? <NoData />
                    );
                },
                size: 113,
            },
            {
                id: 'erpDeviceInfoCustomerName',
                header: 'Nom du client',
                accessorFn: (row) => row,
                cell: (info) => {
                    const device = info.getValue<Device>();
                    return (
                        device.erpDeviceInfo?.customer?.name ?? device.hardCodedDeviceInfo?.customer?.name ?? <NoData />
                    );
                },
                size: 260,
            },
            {
                id: 'ecc',
                header: 'ECC',
                accessorFn: (row) => row.ecc,
                cell: (info) => <EccAutoStatusIcon status={info.getValue<Device['ecc']>()} />,
                size: 51,
            },
            {
                id: 'auto',
                header: 'AUTO',
                accessorFn: (row) => row.auto,
                cell: (info) => <EccAutoStatusIcon status={info.getValue<Device['auto']>()} />,
                size: 64,
            },
            {
                id: 'colorLevels.updatedAt',
                header: 'Toner restant à la date du',
                accessorFn: (row) => row.colorLevels?.updatedAt,
                cell: (info) => {
                    const date = info.getValue<string | undefined>();

                    return date ? `${formatDate(date)} - ${formatRelativeTime(date)}` : <NoData />;
                },
                enableSorting: true,
                size: 230,
            },
            {
                id: `colorLevels.${color}`,
                header: 'Toner restant (%)',
                accessorFn: (row) => row.colorLevels?.[color],
                cell: (info) => {
                    const level = info.getValue<number | null | undefined>();

                    return level !== undefined && level !== null ? (
                        formatNumber(level / 100, { style: 'percent' })
                    ) : (
                        <NoData />
                    );
                },
                enableSorting: true,
                size: 230,
            },
            {
                id: `thresholdColorLevels.${color}`,
                header: 'Dernier seuil (%)',
                accessorFn: (row) => row.thresholdColorLevels?.[color],
                cell: (info) => {
                    const level = info.getValue<number | null | undefined>();

                    return level !== undefined && level !== null ? (
                        formatNumber(level / 100, { style: 'percent' })
                    ) : (
                        <NoData />
                    );
                },
                enableSorting: true,
                size: 230,
            },
            {
                id: `lastTNEAlert.${color}`,
                header: 'Dernier TNE',
                accessorFn: (row) => row.lastTNEAlert,
                cell: (info) => {
                    const lastTNEAlert = info.getValue<Device['lastTNEAlert']>();
                    let date;

                    switch (color) {
                        case Color.yellow:
                            date = lastTNEAlert?.yellow;
                            break;
                        case Color.cyan:
                            date = lastTNEAlert?.cyan;
                            break;
                        case Color.magenta:
                            date = lastTNEAlert?.magenta;
                            break;
                        case Color.black:
                            date = lastTNEAlert?.black;
                            break;
                        case Color.erasableBlue:
                            date = lastTNEAlert?.erasableBlue;
                            break;
                    }

                    return date ? `${formatDate(date)} - ${formatRelativeTime(date)}` : <NoData />;
                },
                size: 230,
            },
            {
                id: 'lastDelivery',
                header: 'Dernière commande ERP',
                accessorFn: (row) => row.erpDeviceInfo,
                cell: (info) => {
                    const erpDeviceInfo = info.getValue<Device['erpDeviceInfo']>();
                    let date;

                    switch (color) {
                        case Color.yellow:
                            date = erpDeviceInfo?.lastTonerYellowDelivery;
                            break;
                        case Color.cyan:
                            date = erpDeviceInfo?.lastTonerCyanDelivery;
                            break;
                        case Color.magenta:
                            date = erpDeviceInfo?.lastTonerMagentaDelivery;
                            break;
                        case Color.black:
                            date = erpDeviceInfo?.lastTonerBlackDelivery;
                            break;
                        case Color.erasableBlue:
                            date = erpDeviceInfo?.lastTonerErasableBlueDelivery;
                            break;
                    }

                    return date ? `${formatDate(date)} - ${formatRelativeTime(date)}` : <NoData />;
                },
                size: 230,
            },
        ],
        [color]
    );

    return (
        <div className="flex flex-col" style={{ height: 'calc(100% - 63px)' }}>
            <Seo title="Surveillance des niveaux de Toner" />
            <AlertsTonerLevelsFilters />
            <div className="bg-grey-darker -mx-16 -mb-16 p-16 space-y-4 flex-1">
                {isListVisible && (
                    <>
                        <div>
                            <Typography.Title level={2} className="mb-12">
                                Liste des machines trouvées - Toner {`${translateTonerColor(color)}`} &le;{' '}
                                {`${colorLevel}`}%
                            </Typography.Title>
                            <div className="flex justify-between items-center">
                                <div className="flex space-x-2">
                                    <Button
                                        icon={<CheckCircleOutlined className="text-primary" />}
                                        disabled={!selectedDeviceIds?.length}
                                        onClick={onClickUncontrol}
                                    >
                                        Ne plus contrôler
                                    </Button>
                                    <Button
                                        icon={<Alarm className="text-primary" />}
                                        disabled={!selectedDeviceIds?.length}
                                        onClick={onClickCreateAlert}
                                    >
                                        Créer une alerte
                                    </Button>
                                </div>
                                <div className="flex space-x-2">
                                    <Button
                                        icon={<UserOutlined className="text-primary" />}
                                        disabled={!selectedDeviceId}
                                        onClick={async () =>
                                            await show(deviceDrawerId, {
                                                id: selectedDeviceId,
                                                tab: 'artis',
                                            })
                                        }
                                    >
                                        Fiche parc
                                    </Button>
                                    <Link
                                        to={{
                                            pathname: getRoute(RoutePathName.alertHistory),
                                            search: new URLSearchParams({
                                                deviceSerial: selectedDeviceSerial ?? '',
                                            }).toString(),
                                        }}
                                    >
                                        <Button
                                            icon={<DocSearchTwo className="text-primary" />}
                                            disabled={!selectedDeviceSerial}
                                        >
                                            Historique alertes
                                        </Button>
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: getRoute(RoutePathName.alertStats),
                                            search: new URLSearchParams({
                                                deviceSerial: selectedDeviceSerial ?? '',
                                            }).toString(),
                                        }}
                                    >
                                        <Button
                                            icon={<LineChartOutlined className="text-primary" />}
                                            disabled={!selectedDeviceSerial}
                                        >
                                            Compteurs
                                        </Button>
                                    </Link>
                                    <Button
                                        icon={<SettingTwo className="text-primary" />}
                                        disabled={!selectedDeviceId}
                                        onClick={async () =>
                                            await show(deviceDrawerId, {
                                                id: selectedDeviceId,
                                                tab: 'device',
                                            })
                                        }
                                    >
                                        MAJ Machine
                                    </Button>
                                    {hasPermission(user, Permission.orders) && (
                                        <Link
                                            to={{
                                                pathname: getRoute(RoutePathName.orders),
                                                search: new URLSearchParams({
                                                    deviceSerial: selectedDeviceSerial ?? '',
                                                }).toString(),
                                            }}
                                        >
                                            <Button
                                                icon={<Truck className="text-primary" />}
                                                disabled={!selectedDeviceSerial}
                                            >
                                                Livraisons
                                            </Button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                        <ResizableTable<Device>
                            columns={columns}
                            isLoading={isLoading || isFetching}
                            isError={isError}
                            error={error}
                            data={devices?.items}
                            maxHeight={`calc(100vh - 503px)`}
                            onSortChange={onSortChange}
                            emptyContent={
                                <Empty
                                    className="mb-32 text-taupe"
                                    image={<Alarm className="font-28 mt-56" />}
                                    description={'Aucun résultat'}
                                />
                            }
                            rowKey="id"
                            pagination={pagination}
                            onRowHighlightChange={onRowHighlightChange}
                            isRowHighlighted={isRowHighlighted}
                            rowClassName={rowClassName}
                            selectedRowKeys={selectedDeviceIds}
                            onRowSelectionChange={setSelectedDeviceIds}
                            enableColumnResizing
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default AlertsTonerLevels;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.591 1.194 12 2l-.591-.806a1 1 0 0 1 1.182 0ZM12 3.278a24.117 24.117 0 0 0-2.982 2.987c-1.562 1.874-3.036 4.278-3.421 6.918A16.88 16.88 0 0 1 7 12.945c1.56-.195 3.677-.224 5.446.66 1.231.616 2.863.645 4.304.465.68-.085 1.28-.212 1.717-.32-.24-2.861-1.813-5.477-3.486-7.485A24.116 24.116 0 0 0 12 3.278Zm6.365 12.547a16.77 16.77 0 0 1-1.366.23c-1.56.195-3.677.224-5.446-.66-1.231-.616-2.863-.645-4.304-.465-.675.084-1.27.21-1.707.317a6.501 6.501 0 0 0 12.823.578ZM11.408 1.194 12 2l.592-.806.003.002.006.004.02.015a9.081 9.081 0 0 1 .326.254c.217.174.523.427.89.752.73.65 1.704 1.592 2.681 2.764C18.45 7.303 20.5 10.653 20.5 14.5a8.5 8.5 0 0 1-17 0c0-3.847 2.05-7.197 3.982-9.515a26.114 26.114 0 0 1 3.571-3.516 14.71 14.71 0 0 1 .326-.254l.02-.015.006-.004.002-.001.001-.001Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgWaterLevel: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-water-level', props.className)} />
));
export default SvgWaterLevel;

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { details, list, AlertTypeIdPayload, AlertTypeListPayload, AlertTypeListResponse } from './api/alertTypes';
import { AlertType } from './api/types';

export const alertTypesKeys = {
    all: ['alertTypes'],
    lists: () => [...alertTypesKeys.all, 'list'],
    list: (params?: AlertTypeListPayload) => [...alertTypesKeys.lists(), params],
    details: () => [...alertTypesKeys.all, 'details'],
    detail: (id?: AlertTypeIdPayload) => [...alertTypesKeys.details(), id],
};

export const useAlertTypeList = <TData = AlertTypeListResponse>(
    params?: AlertTypeListPayload,
    options?: UseQueryOptions<AlertTypeListResponse, AxiosError, TData>
) => {
    return useQuery<AlertTypeListResponse, AxiosError, TData>(
        alertTypesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, staleTime: Infinity, ...options }
    );
};

export const useAlertTypeDetails = <TData = AlertType>(
    id?: AlertTypeIdPayload,
    options?: UseQueryOptions<AlertType, AxiosError, TData>
) => {
    return useQuery<AlertType, AxiosError, TData>(alertTypesKeys.detail(id), async () => await details(id), options);
};

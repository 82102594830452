import { VFC } from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { MFPCommunication } from '../../queries/api/types';
import { classNames } from '../../helpers';
import EccAutoStatusIcon from '../EccAutoStatusIcon';

interface DeviceEccStatusFormItemProps extends FormItemProps {
    selectProps?: SelectProps<MFPCommunication>;
    optionFilter?: (record: MFPCommunication) => boolean;
    customOptions?: DefaultOptionType[];
    isWhite?: boolean;
}

const DeviceEccStatusFormItem: VFC<DeviceEccStatusFormItemProps> = ({
    selectProps,
    optionFilter = () => true,
    customOptions,
    isWhite,
    ...props
}) => (
    <Form.Item {...props}>
        <Select
            {...selectProps}
            placeholder={selectProps?.placeholder ?? 'Choisir un statut'}
            className={classNames('auto-ecc-select', isWhite && 'select-white', selectProps?.className)}
            filterOption={false}
            options={(
                Object.values(MFPCommunication)
                    .filter(optionFilter)
                    .map((status) => ({
                        label: <EccAutoStatusIcon status={status} />,
                        value: status,
                    })) as unknown as DefaultOptionType
            )?.concat(customOptions ?? [])}
            showSearch
        />
    </Form.Item>
);

export default DeviceEccStatusFormItem;

import { VFC } from 'react';
import { Button } from 'antd';
import { LineChartOutlined, UserOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { show } from '@ebay/nice-modal-react';

import { getRoute, RoutePathName } from '../../routes';
import { DocSearchTwo, SettingTwo, Truck } from '../../components/icons';
import useQueryParams from '../../hooks/queryParams';
import { Permission, PermissionRight } from '../../queries/api/types';
import AlertIgnoreButton from '../../components/alert/AlertIgnoreButton';
import AlertPendingButton from '../../components/alert/AlertPendingButton';
import { useAuth } from '../../context/AuthContext';
import { hasPermission } from '../../helpers/security';
import { useAlert } from '../../context/AlertContext';
import { alertModalId, deviceDrawerId } from '../../App';
import AlertTreatButton from '../../components/alert/AlertTreatButton';
import AlertInstructions from '../../components/alert/AlertInstructions';

const AlertDetailsBar: VFC = () => {
    const { alert } = useAlert();
    const { user } = useAuth();
    const [queryParams] = useQueryParams('alerts');
    const deviceSerial = queryParams.get('deviceSerial');
    const alertId = queryParams.get('alertId') ?? undefined;
    const deviceId = queryParams.get('deviceId');

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <Button
                    icon={<ZoomInOutlined className="text-primary" />}
                    disabled={!alertId}
                    onClick={() => {
                        show(alertModalId, {
                            alertId,
                        });
                    }}
                >
                    Détail alerte
                </Button>
                <div className="space-x-2">
                    {hasPermission(user, Permission.alerts, PermissionRight.write) && (
                        <>
                            <AlertTreatButton
                                onAllowed={() => {
                                    show(alertModalId, {
                                        alertId: alert?.id,
                                        isTreating: true,
                                    });
                                }}
                            />
                            <AlertIgnoreButton />
                            <AlertPendingButton />
                        </>
                    )}
                </div>
                <div className="space-x-2">
                    <Button
                        icon={<UserOutlined className="text-primary" />}
                        disabled={!deviceId}
                        onClick={async () =>
                            await show(deviceDrawerId, {
                                id: deviceId,
                                tab: 'artis',
                            })
                        }
                    >
                        Fiche parc
                    </Button>
                    <Link
                        to={{
                            pathname: getRoute(RoutePathName.alertHistory),
                            search: new URLSearchParams({ deviceSerial: deviceSerial ?? '' }).toString(),
                        }}
                    >
                        <Button icon={<DocSearchTwo className="text-primary" />} disabled={!deviceSerial}>
                            Historique alertes
                        </Button>
                    </Link>
                    <Link
                        to={{
                            pathname: getRoute(RoutePathName.alertStats),
                            search: new URLSearchParams({ deviceSerial: deviceSerial ?? '' }).toString(),
                            state: { withBackButton: true },
                        }}
                    >
                        <Button icon={<LineChartOutlined className="text-primary" />} disabled={!deviceSerial}>
                            Compteurs
                        </Button>
                    </Link>
                    <Button
                        icon={<SettingTwo className="text-primary" />}
                        disabled={!deviceId}
                        onClick={async () =>
                            await show(deviceDrawerId, {
                                id: deviceId,
                                tab: 'device',
                            })
                        }
                    >
                        MAJ Machine
                    </Button>
                    {hasPermission(user, Permission.orders) && (
                        <Link
                            to={{
                                pathname: getRoute(RoutePathName.orders),
                                search: new URLSearchParams({ deviceSerial: deviceSerial ?? '' }).toString(),
                                state: { withBackButton: true },
                            }}
                        >
                            <Button icon={<Truck className="text-primary" />} disabled={!deviceSerial}>
                                Livraisons
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <AlertInstructions instructions={alert?.device?.instruction} />
        </div>
    );
};

export default AlertDetailsBar;

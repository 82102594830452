import { ReactNode, useEffect, useState, VFC } from 'react';
import { Empty, Form, FormItemProps, Select, SelectProps } from 'antd';

import { errorMessage } from '../../helpers/message';
import { Organization } from '../../queries/api/types';
import { useOrganizationsList } from '../../queries/organizations';
import { OrganizationListPayload, OrganizationListResponse } from '../../queries/api/organizations';
import { defaultErrorMessage } from '../../helpers/i18n';
import { useDebounce } from '../../hooks';
import { classNames } from '../../helpers';
import { useAuth } from '../../context/AuthContext';

interface OrganizationFormItemProps extends FormItemProps {
    selectProps?: SelectProps<Organization['id']>;
    queryPayload?: OrganizationListPayload;
    optionLabelRender?: (record: Organization) => ReactNode;
    optionFilter?: (record: Organization) => boolean;
    onFirstLoad?: (data: OrganizationListResponse) => void;
    isWhite?: boolean;
}

const OrganizationFormItem: VFC<OrganizationFormItemProps> = ({
    selectProps,
    queryPayload,
    optionLabelRender = (record) => `${record.name} (${record.code})`,
    optionFilter = () => true,
    onFirstLoad,
    isWhite,
    ...props
}) => {
    const { user } = useAuth();
    const [hasDoneFirstLoad, setHasDoneFirstLoad] = useState(false);
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: organizations, isLoading } = useOrganizationsList(
        { pageSize: 10, isActive: true, search: debouncedSearch || undefined, ...queryPayload },
        {
            onSettled: (data) => {
                if (!hasDoneFirstLoad && data) {
                    onFirstLoad?.(data);
                    setHasDoneFirstLoad(true);
                }
            },
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    useEffect(() => {
        if (!hasDoneFirstLoad && organizations?.items?.[0]) {
            onFirstLoad?.(organizations);
        }
    }, [organizations, hasDoneFirstLoad, onFirstLoad]);

    return (
        <Form.Item {...props}>
            <Select<Organization['id']>
                {...selectProps}
                placeholder={selectProps?.placeholder ?? 'Choisir une organisation'}
                className={classNames(isWhite && 'select-white', selectProps?.className)}
                filterOption={false}
                loading={isLoading}
                options={(user?.organization
                    ? [
                          {
                              label: optionLabelRender(user?.organization),
                              value: user?.organization.id,
                          },
                      ]
                    : []
                )?.concat(
                    organizations?.items
                        ?.filter((org) => org.id !== user?.organization?.id)
                        .filter(optionFilter)
                        .map((organization) => ({
                            label: optionLabelRender(organization),
                            value: organization.id,
                        })) ?? []
                )}
                notFoundContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Aucun résultat"
                    />
                }
                onSearch={setSearch}
                defaultActiveFirstOption
                showSearch
            />
        </Form.Item>
    );
};

export default OrganizationFormItem;

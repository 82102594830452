import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { MailTemplate, ListResponse, Organization, AlertType } from './types';

// Controller Interface
export interface MailTemplateCreatePayload {
    reference: string;
    organization: Organization['id'];
    alertType?: AlertType['id'];
    name: string;
    subject: string;
    body: string;
}

export interface MailTemplateUpdatePayload extends Omit<MailTemplate, 'id'> {
    id?: MailTemplate['id'];
}

export type MailTemplateIdPayload = MailTemplate['id'];

export type MailTemplateListPayload = SearchPaginationQuery & {
    organization?: Organization['id'];
    alertType?: AlertType['id'] | 'null';
};
export type MailTemplateListResponse = ListResponse<MailTemplate>;

// Routes
export const list = async (payload?: MailTemplateListPayload) => {
    return await api
        .get<MailTemplateListResponse>(`/mailTemplates`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: MailTemplateCreatePayload) => {
    return await api.post(`/mailTemplates`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: MailTemplateUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<MailTemplate>(`/mailTemplates/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: MailTemplateIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<MailTemplate>(`/mailTemplates/${id}`).then((response) => response?.data);
};

export const remove = async (id?: MailTemplateIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/mailTemplates/${id}`).then((response) => response?.data);
};

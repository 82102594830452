/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.333 2c0-.368.299-.667.667-.667h5.997a.667.667 0 0 1 0 1.334h-5.33v10.666H8a.667.667 0 0 1 0 1.334H2A.667.667 0 0 1 1.333 14V2Z"
            fill="currentColor"
        />
        <path
            d="M10.529 4.529c.26-.26.682-.26.942 0l2.964 2.963a.665.665 0 0 1 .232.505V8a.665.665 0 0 1-.196.472l-3 3a.667.667 0 1 1-.942-.942l1.864-1.865h-7.06a.667.667 0 1 1 0-1.333h7.055l-1.86-1.86a.667.667 0 0 1 0-.942Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgLogout: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-logout', props.className)} />
));
export default SvgLogout;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.79.22a.777.777 0 0 1 0 1.06l-4.285 4.5a.69.69 0 0 1-1.01 0L.209 1.28a.777.777 0 0 1 0-1.06.69.69 0 0 1 1.01 0L5 4.19 8.78.22a.69.69 0 0 1 1.01 0Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgChevronDown: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-chevron-down', props.className)} />
));
export default SvgChevronDown;

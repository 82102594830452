import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    count,
    details,
    list,
    OrderCountPayload,
    OrderCountResponse,
    OrderIdPayload,
    OrderListPayload,
    OrderListResponse,
    OrderUpdatePayload,
    remove,
    update,
} from './api/orders';
import { Order } from './api/types';

export const ordersKeys = {
    all: ['orders'],
    lists: () => [...ordersKeys.all, 'list'],
    count: (params?: OrderCountPayload) => [...ordersKeys.all, 'count', params],
    list: (params?: OrderListPayload) => [...ordersKeys.lists(), params],
    details: () => [...ordersKeys.all, 'details'],
    detail: (id?: OrderIdPayload) => [...ordersKeys.details(), id],
};

export const useOrderList = <TData = OrderListResponse>(
    params?: OrderListPayload,
    options?: UseQueryOptions<OrderListResponse, AxiosError, TData>
) => {
    return useQuery<OrderListResponse, AxiosError, TData>(ordersKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useOrderCount = <TData = OrderCountResponse>(
    params?: OrderCountPayload,
    options?: UseQueryOptions<OrderCountResponse, AxiosError, TData>
) => {
    return useQuery<OrderCountResponse, AxiosError, TData>(
        ordersKeys.count(params),
        async () => await count(params),
        options
    );
};

export const useOrderDetails = <TData = Order>(
    id?: OrderIdPayload,
    options?: UseQueryOptions<Order, AxiosError, TData>
) => {
    return useQuery<Order, AxiosError, TData>(ordersKeys.detail(id), async () => await details(id), options);
};

export const useOrderUpdate = (options?: UseMutationOptions<Order, AxiosError, OrderUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Order, AxiosError, OrderUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(ordersKeys.detail(variables.id));
        },
    });
};

export const useOrderRemove = (options?: UseMutationOptions<undefined, AxiosError, OrderIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, OrderIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(ordersKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(ordersKeys.lists());
        },
    });
};

import { memo, VFC } from 'react';
import { Tag } from 'antd';

import { translateAlertListStatus, translateAlertStatus } from '../helpers/i18n';
import { Alert } from '../queries/api/types';
import { getAlertStatusColor } from '../helpers';

interface AlertStatusTagProps {
    alert?: Alert;
    isList?: boolean;
}

const AlertStatusTag: VFC<AlertStatusTagProps> = memo(
    ({ alert, isList }) => (
        <Tag color={getAlertStatusColor(alert?.status)} className="uppercase">
            {isList ? translateAlertListStatus(alert) : translateAlertStatus(alert?.status)}
        </Tag>
    ),
    (prevProps, nextProps) =>
        prevProps.alert?.status === nextProps.alert?.status &&
        prevProps.alert?.alertType.reference === nextProps.alert?.alertType.reference
);

export default AlertStatusTag;

import { FC, useState, useLayoutEffect, useRef, ReactChild } from 'react';
import { Button } from 'antd';
import useResizeAware from 'react-resize-aware';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import '../assets/styles/TruncatedText.less';

import { classNames } from '../helpers';
import { useIsMounted } from '../hooks';

interface TruncatedTextProps {
    className?: string;
    expandText?: string | ReactChild;
    expandedText?: string | ReactChild;
    maxHeight: number;
    withFade?: boolean;
}

const TruncatedText: FC<TruncatedTextProps> = ({
    className,
    expandText,
    expandedText,
    children,
    maxHeight,
    withFade,
}) => {
    const isMounted = useIsMounted();
    const [resizeListener, sizes] = useResizeAware();
    const [isExpanded, setIsExpanded] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const onToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useLayoutEffect(() => {
        if (wrapperRef.current) {
            setShowExpandButton(wrapperRef.current.scrollHeight > maxHeight);

            // firefox :D
            window.setTimeout(() => {
                if (wrapperRef.current && isMounted) {
                    setShowExpandButton(wrapperRef.current.scrollHeight > maxHeight);
                }
            }, 500);
        }
    }, [maxHeight, sizes.height, isMounted]);

    return (
        <div className={classNames('truncated-text', isExpanded && 'is-expanded', className)}>
            <div
                className="truncated-text-inner"
                ref={wrapperRef}
                style={
                    wrapperRef.current
                        ? { maxHeight: isExpanded ? wrapperRef.current.scrollHeight : maxHeight }
                        : undefined
                }
            >
                {resizeListener}
                {children}
            </div>
            {showExpandButton && (
                <>
                    {withFade && <div className="truncated-text-fade" />}
                    <Button type="link" onClick={onToggle}>
                        {isExpanded ? (
                            <>
                                {expandedText ?? 'Voir moins'}
                                <UpOutlined />
                            </>
                        ) : (
                            <>
                                {expandText ?? 'Voir plus'}
                                <DownOutlined />
                            </>
                        )}
                    </Button>
                </>
            )}
        </div>
    );
};

export default TruncatedText;

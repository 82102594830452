import { ArrowLeftOutlined, FileExcelOutlined, FilePdfOutlined, UserOutlined } from '@ant-design/icons';
import { show } from '@ebay/nice-modal-react';
import { Button, Descriptions, Divider, Typography, Card, Empty } from 'antd';
import { useCallback, useMemo, useState, VFC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SortingState } from '@tanstack/react-table';

import ColorUsagePieChart from '../../components/charts/ColorUsagePieChart';
import CopyPrintUsagePieChart from '../../components/charts/CopyPrintUsagePieChart';
import YearlyVolumeComparison from '../../components/charts/YearlyVolumeComparison';
import YearlyVolumeEvolution from '../../components/charts/YearlyVolumeEvolution';
import NoData from '../../components/NoData';
import { downloadBlobFile, isDeviceUsingColors, isDeviceUsingErasableBlue, isDeviceUsingLowColor } from '../../helpers';
import { defaultErrorMessageWithStatus, formatDate, formatNumber } from '../../helpers/i18n';
import useQueryParams, { useStoredQueryParams } from '../../hooks/queryParams';
import { DeviceCounter } from '../../queries/api/types';
import { useDeviceGeneration, useDeviceList } from '../../queries/devices';
import { useDevicesCounterExport, useDevicesCounterList } from '../../queries/devicesCounters';
import { getRoute, RoutePathName } from '../../routes';
import { alertQueryParamsKey } from '../alerts/AlertsList';
import { deviceDrawerId } from '../../App';
import AverageConsumptionStats from './AverageConsumptionStats';
import Seo from '../../components/Seo';
import { useLayout } from '../../context/LayoutContext';
import { errorMessage } from '../../helpers/message';
import ResizableTable, { ResizableTableColumn, ResizableTableProps } from '../../components/ResizableTable';

const AlertStats: VFC = () => {
    const { organizationId } = useLayout();
    const { withBackButton } = useLocation<{ withBackButton?: boolean }>()?.state || {};
    const [isXlsExportEnabled, setIsXlsExportEnabled] = useState(false);
    const alertQueryParams = useStoredQueryParams(alertQueryParamsKey);
    const [queryParams, setQueryParams] = useQueryParams('AlertStats');
    const deviceSerial = queryParams.get('deviceSerial') ?? undefined;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const pageSize = queryParams.get('pageSize') !== null ? parseInt(queryParams.get('pageSize')!, 10) || 50 : 50;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const { data: devices, isLoading: isLoadingDevices } = useDeviceList(
        {
            serials: deviceSerial ? [deviceSerial] : undefined,
            organization: organizationId,
        },
        {
            enabled: !!deviceSerial,
        }
    );
    const device = devices?.items[0];
    const {
        data: deviceCounterList,
        isLoading: isLoadingAlerts,
        isError: isErrorAlerts,
        error: errorAlerts,
    } = useDevicesCounterList(
        {
            devices: device?.id,
            organization: organizationId,
            page,
            sort,
            sortOrder,
            lastCounterOfEachMonth: true,
        },
        {
            enabled: !!device?.id,
        }
    );
    const { mutate: pdfExport, isLoading: isLoadingPdf } = useDeviceGeneration();
    const isLoadingList = isLoadingDevices || isLoadingAlerts;
    const { isLoading: isExportingXls } = useDevicesCounterExport(
        {
            devices: device?.id,
            organization: organizationId,
        },
        {
            enabled: isXlsExportEnabled,
            onSettled: () => {
                setIsXlsExportEnabled(false);
            },
            onError: (error) => {
                errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
            },
            onSuccess: (data) => {
                const filename = data.headers['content-disposition'].split('"')[1];
                downloadBlobFile(filename, data.data, 'blob');
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined,
            });
        },
        [setQueryParams]
    );
    const onClickExport = (type: 'excel' | 'pdf') => {
        if (type === 'pdf') {
            pdfExport(`${window.location.origin}${getRoute(RoutePathName.exportDevice)}?deviceId=${device?.id ?? ''}`, {
                onSuccess: (data) => {
                    downloadBlobFile(
                        `${formatDate(new Date(), {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        })}-machine-${device?.serial ?? ''}`,
                        data
                    );
                },
                onError: (error) => {
                    errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
                },
            });
        } else {
            setIsXlsExportEnabled(true);
        }
    };

    const columns = useMemo<Array<ResizableTableColumn<DeviceCounter>>>(
        () => [
            {
                id: 'counter.updatedAt',
                accessorFn: (info) => info.counter?.updatedAt,
                header: 'Date',
                cell: (info) => {
                    return formatDate(info.getValue<Date>());
                },
                enableSorting: true,
                rowSpan: 2,
            },
            {
                header: 'Compteurs',
                columns: [
                    {
                        id: 'counter.counter.black',
                        accessorFn: (info) => info.counter?.counter?.black,
                        header: 'Noir',
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        size: 103,
                    },
                    {
                        id: 'counter.counter.color',
                        header: 'Couleur',
                        accessorFn: (info) => info.counter?.counter?.color,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        size: 103,
                    },
                    {
                        id: 'counter.counter.lowColor',
                        header: 'Marianne',
                        accessorFn: (info) => info.counter?.counter?.lowColor,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        size: 114,
                    },
                    {
                        id: 'counter.counter.erasableBlue',
                        header: 'Bleu effaçable',
                        accessorFn: (info) => info.counter?.counter?.erasableBlue,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        size: 256,
                    },
                    {
                        id: 'counter.counter.copy',
                        header: 'Copy',
                        accessorFn: (info) => info.counter?.counter?.copy,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        size: 114,
                    },
                    {
                        id: 'counter.counter.printing',
                        header: 'Impression',
                        accessorFn: (info) => info.counter?.counter?.printing,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        size: 142,
                    },
                ],
            },
            {
                header: 'Fax',
                id: 'fax-1',
                columns: [
                    {
                        id: 'counter.fax.transmitted',
                        accessorFn: (info) => info.counter?.fax?.transmitted,
                        header: 'Transmis',
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                    },
                    {
                        id: 'counter.fax.received',
                        header: 'Reçus',
                        accessorFn: (info) => info.counter?.fax?.received,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                    },
                    {
                        id: 'counter.fax.printing',
                        header: 'Impression',
                        accessorFn: (info) => info.counter?.fax?.printing,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                    },
                ],
            },
            {
                header: 'Scan',
                columns: [
                    {
                        id: 'counter.scan.black',
                        header: 'Noir',
                        accessorFn: (info) => info.counter?.scan?.black,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                    },
                    {
                        id: 'counter.scan.color',
                        header: 'Couleur',
                        accessorFn: (info) => info.counter?.scan?.color,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                    },
                ],
            },
            {
                header: 'Conso',
                columns: [
                    {
                        id: 'counter.consumption.black',
                        header: 'Noir',
                        accessorFn: (info) => info.counter?.consumption?.black,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        minSize: 80,
                    },
                    {
                        id: 'counter.consumption.color',
                        header: 'Couleur',
                        accessorFn: (info) => info,
                        cell: (info) =>
                            isDeviceUsingColors(info.getValue<DeviceCounter>().device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {formatNumber(info.getValue<DeviceCounter>()?.counter?.consumption?.color)}
                                </span>
                            ) : (
                                <NoData />
                            ),
                        enableSorting: true,
                        minSize: 80,
                    },
                    {
                        id: 'counter.consumption.lowColor',
                        header: 'Marianne',
                        accessorFn: (info) => info,
                        cell: (info) =>
                            isDeviceUsingLowColor(info.getValue<DeviceCounter>().device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {formatNumber(info.getValue<DeviceCounter>()?.counter?.consumption?.lowColor)}
                                </span>
                            ) : (
                                <NoData />
                            ),
                        enableSorting: true,
                        minSize: 80,
                    },
                    {
                        id: 'counter.consumption.erasableBlue',
                        header: 'Bleu effaçable',
                        accessorFn: (info) => info,
                        cell: (info) =>
                            isDeviceUsingErasableBlue(info.getValue<DeviceCounter>().device) ? (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {formatNumber(info.getValue<DeviceCounter>()?.counter?.consumption?.erasableBlue)}
                                </span>
                            ) : (
                                <NoData />
                            ),
                        enableSorting: true,
                        minSize: 80,
                    },
                    {
                        id: 'counter.consumption.copy',
                        header: 'Copy',
                        accessorFn: (info) => info.counter?.consumption?.copy,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        minSize: 80,
                    },
                    {
                        id: 'counter.consumption.printing',
                        header: 'Impression',
                        accessorFn: (info) => info.counter?.consumption?.printing,
                        cell: (info) => formatNumber(info.getValue()),
                        enableSorting: true,
                        minSize: 80,
                    },
                ],
            },
        ],
        []
    );

    const pagination = useMemo<ResizableTableProps<DeviceCounter>['pagination']>(
        () => ({
            current: page + 1,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    page: newPage,
                    pageSize,
                });
            },
            pageSizeDefault: pageSize,
            total: deviceCounterList?.totalCount ?? 0,
        }),
        [deviceCounterList?.totalCount, page, pageSize, setQueryParams]
    );

    return (
        <div className="flex flex-col min-h-full">
            <Seo title="Analyse des compteurs" />
            <div className="-mt-16 -mx-16 p-16">
                <div className="flex justify-between">
                    {withBackButton ? (
                        <Link
                            to={{
                                pathname: getRoute(RoutePathName.alerts),
                                search: new URLSearchParams(alertQueryParams).toString(),
                            }}
                        >
                            <Button icon={<ArrowLeftOutlined />} type="link" className="pl-0 font-semibold">
                                Retour à l&rsquo;alerte
                            </Button>
                        </Link>
                    ) : (
                        <div />
                    )}
                    <div className="space-x-4">
                        <Button
                            icon={<UserOutlined className="text-primary" />}
                            disabled={!device?.id}
                            onClick={() => {
                                show(deviceDrawerId, {
                                    id: device?.id,
                                    tab: 'artis',
                                });
                            }}
                        >
                            Fiche parc
                        </Button>
                        <Button
                            icon={<FileExcelOutlined className="text-primary" />}
                            onClick={() => onClickExport('excel')}
                            loading={isExportingXls}
                        >
                            Excel
                        </Button>
                        <Button
                            icon={<FilePdfOutlined className="text-primary" />}
                            onClick={() => onClickExport('pdf')}
                            loading={isLoadingPdf}
                        >
                            PDF
                        </Button>
                    </div>
                </div>
                <Divider orientation="left" orientationMargin="0">
                    <Typography.Title>Analyse des compteurs</Typography.Title>
                </Divider>
                <Descriptions size="small" layout="vertical" colon={false} column={5}>
                    <Descriptions.Item label="Numéro de série">{deviceSerial}</Descriptions.Item>
                    <Descriptions.Item label="Machine">
                        {device?.hardCodedDeviceInfo?.model ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Client">
                        {device?.hardCodedDeviceInfo?.customer?.name ?? device?.erpDeviceInfo?.customer?.name ?? (
                            <NoData />
                        )}
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <div className="bg-grey-darker -mx-16 -mb-16 p-16 space-y-4 pb-56">
                <ResizableTable<DeviceCounter>
                    columns={columns}
                    data={deviceCounterList?.items}
                    onSortChange={onSortChange}
                    pagination={pagination}
                    isLoading={isLoadingList}
                    isError={isErrorAlerts}
                    error={errorAlerts}
                    emptyContent={
                        <Empty
                            className="mb-32 text-taupe"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Aucun résultat"
                        />
                    }
                    maxHeight={320}
                    enableColumnResizing
                />
                <div className="flex gap-16">
                    <Card className="flex-auto" bodyStyle={{ padding: '1rem' }} style={{ flex: '1 1 60%' }}>
                        <div className="flex gap-24">
                            <div style={{ flex: '1 1 55%', minWidth: 0 }}>
                                <ColorUsagePieChart height={284} record={device} isLoading={isLoadingDevices} />
                            </div>
                            <div className="flex flex-col" style={{ flex: '1 1 45%', minWidth: 0 }}>
                                <div className="mb-12">
                                    <YearlyVolumeEvolution height={200} device={device} isLoading={isLoadingDevices} />
                                </div>
                                <YearlyVolumeComparison height={200} device={device} isLoading={isLoadingDevices} />
                            </div>
                        </div>
                    </Card>
                    <div className="flex-auto flex flex-col gap-16">
                        <Card bodyStyle={{ padding: '1rem' }}>
                            <CopyPrintUsagePieChart height={180} record={device} isLoading={isLoadingDevices} />
                        </Card>
                        <div className="flex-auto flex flex-col gap-12">
                            <AverageConsumptionStats device={device} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertStats;

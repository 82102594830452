/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4a2.5 2.5 0 0 1 2.5-2.5h17A2.5 2.5 0 0 1 23 4v17a2.5 2.5 0 0 1-2.5 2.5h-17A2.5 2.5 0 0 1 1 21V4Zm17-.5v8a1 1 0 0 1-1 1H7.5a1 1 0 0 1-1-1v-8h-3A.5.5 0 0 0 3 4v17a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5H18Zm-9.5 0v7H16v-7H8.5Zm5 1.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgSaveOne: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-save-one', props.className)} />
));
export default SvgSaveOne;

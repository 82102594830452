import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { AlertStatus, ErrorCode, ListResponse, Organization } from './types';

// Controller Interface
export interface ErrorCodeCreatePayload {
    organization: Organization['id'];
    reference: string;
    unit: string;
    description: string;
    recommendation: string;
    status: AlertStatus;
}

export interface ErrorCodeUpdatePayload extends Omit<ErrorCode, 'id'> {
    id?: ErrorCode['id'];
}

export type ErrorCodeIdPayload = ErrorCode['id'];

export type ErrorCodeListPayload = SearchPaginationQuery & {
    organization?: Organization['id'];
    status?: AlertStatus;
    managedOnKairos?: boolean;
};
export type ErrorCodeListResponse = ListResponse<ErrorCode>;

// Routes
export const list = async (payload?: ErrorCodeListPayload) => {
    return await api.get<ErrorCodeListResponse>(`/errorCodes`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: ErrorCodeCreatePayload) => {
    return await api.post(`/errorCodes`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: ErrorCodeUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<ErrorCode>(`/errorCodes/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: ErrorCodeIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<ErrorCode>(`/errorCodes/${id}`).then((response) => response?.data);
};

export const remove = async (id?: ErrorCodeIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/errorCodes/${id}`).then((response) => response?.data);
};

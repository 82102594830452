import { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { hasPermission } from '../helpers/security';
import ErrorPage from '../pages/error';
import { Permission, PermissionRight } from '../queries/api/types';

export interface PermissionRouteProps extends RouteProps {
    permission?: Permission;
    permissionRight?: PermissionRight;
}

const PermissionRoute: FC<PermissionRouteProps> = ({ children, permission, permissionRight, ...props }) => {
    const { user } = useAuth();

    return (
        <Route {...props}>
            {!permission || hasPermission(user, permission, permissionRight) ? children : <ErrorPage status={403} />}
        </Route>
    );
};

export default PermissionRoute;

import { Select as AntdSelect } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';

import { classNames } from '../helpers';

declare type InternalSelectType = <VT extends SelectValue = SelectValue>(
    props: SelectProps<VT> & {
        ref?: ((instance: RefSelectProps | null) => void) | React.RefObject<RefSelectProps> | null | undefined;
    }
) => React.ReactElement;
interface SelectInterface extends InternalSelectType {
    SECRET_COMBOBOX_MODE_DO_NOT_USE: string;
    Option: typeof AntdSelect.Option;
    OptGroup: typeof AntdSelect.OptGroup;
}

const SelectWhite: SelectInterface = (props) => (
    <AntdSelect {...props} className={classNames('select-white', props.className)}>
        {props.children}
    </AntdSelect>
);

SelectWhite.SECRET_COMBOBOX_MODE_DO_NOT_USE = AntdSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE;
SelectWhite.Option = AntdSelect.Option;
SelectWhite.OptGroup = AntdSelect.OptGroup;

export default SelectWhite;

import { useMemo, useState, VFC } from 'react';
import { Empty, Spin, Typography } from 'antd';
import { PieChart, PieChartProps } from '@ezeedev/react-charts';

import { formatNumber, translatePrintingType } from '../../helpers/i18n';
import { classNames, isDevice, isNotUndefinedNorNull } from '../../helpers';
import { Counter, Device, Subscription } from '../../queries/api/types';

interface Datum {
    label: DatumKeys;
    value: number;
    total?: number;
    color: string;
}

type DatumKeys = 'copy' | 'printing' | 'fax';

const getPercentage = (counter: Counter['counter'], fax: Counter['fax'], key: DatumKeys) => {
    const total = (counter?.copy ?? 0) + (counter?.printing ?? 0) + (fax?.printing ?? 0);

    switch (key) {
        case 'copy':
            return ((counter?.copy ?? 0) * 100) / total;
        case 'printing':
            return ((counter?.printing ?? 0) * 100) / total;
        case 'fax':
            return ((fax?.printing ?? 0) * 100) / total;
    }
};

const valueAccessor = (d: Datum) => d.value;
const labelValueAccessor = (d: Datum) => formatNumber(d.value / 100, { style: 'percent' });
const labelTitleAccessor = (d: Datum) => translatePrintingType(d.label);

interface CopyPrintUsagePieChartProps extends Partial<PieChartProps<Datum>> {
    height?: number;
    record?: Device | Subscription;
    isLoading?: boolean;
    small?: boolean;
}

const CopyPrintUsagePieChart: VFC<CopyPrintUsagePieChartProps> = ({
    height,
    record,
    isLoading,
    theme,
    small,
    ...props
}) => {
    const [hasFax, setHasFax] = useState(false);

    const data = useMemo(() => {
        let counter: Counter['counter'];
        let fax: Counter['fax'];

        // check if the record is a Device or a Subscription
        if (isDevice(record)) {
            counter = record.lastCounter?.counter;
            fax = record.lastCounter?.fax;
        } else {
            counter = record?.metrics?.counter;
            fax = record?.metrics?.fax;
        }

        const hasFax = isNotUndefinedNorNull(fax?.printing);
        const hasCopy = isNotUndefinedNorNull(counter?.copy);
        const hasPrinting = isNotUndefinedNorNull(counter?.printing);

        setHasFax(hasFax);

        const data = [
            hasCopy && {
                label: 'copy',
                value: getPercentage(counter, fax, 'copy'),
                total: counter?.copy,
                color: '#E61E1E',
            },
            hasPrinting && {
                label: 'printing',
                value: getPercentage(counter, fax, 'printing'),
                total: counter?.printing,
                color: '#4D4D4D',
            },
            hasFax && {
                label: 'fax',
                value: getPercentage(counter, fax, 'fax'),
                total: fax?.printing,
                color: '#C2C7D6',
                valueLabelColor: '#4D4D4D',
            },
        ].filter(Boolean) as Datum[];

        return data.length ? data : undefined;
    }, [record]);

    return (
        <div>
            <Typography.Title level={3} className="text-taupe font-12 font-bold leading-4.5">
                Utilisation en copie{hasFax ? ', impression et fax imprimés' : ' et impression'}
            </Typography.Title>
            <div className="flex items-center gap-16">
                {data && (
                    <div className="py-8 px-12 rounded bg-grey-light">
                        <table className={classNames(small && 'leading-3')}>
                            <tbody>
                                {data?.map((datum) => (
                                    <tr key={datum.label}>
                                        <th className="font-12 text-dark-grey font-medium px-4 text-left">
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames('inline-block w-12 h-12 rounded mr-8')}
                                                    style={{ backgroundColor: datum.color }}
                                                    aria-hidden
                                                />
                                                {translatePrintingType(datum.label)}
                                            </div>
                                        </th>
                                        <td className="font-12 font-bold px-4">{formatNumber(datum.total)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="flex-auto" style={{ height }}>
                    {isLoading ? (
                        <div className="flex items-center justify-center w-full h-full">
                            <Spin />
                        </div>
                    ) : data ? (
                        <PieChart
                            {...props}
                            data={data}
                            valueAccessor={valueAccessor}
                            labelValueAccessor={labelValueAccessor}
                            labelTitleAccessor={labelTitleAccessor}
                            showLabelTitle={false}
                            theme={{
                                margin: theme?.margin || {
                                    left: 32,
                                    top: 10,
                                    right: 40,
                                    bottom: 16,
                                },
                                pie: {
                                    valueLabel: {
                                        fill: '#fff',
                                        fontSize: 10,
                                    },
                                    titleLabel: {
                                        fill: '#010101',
                                        fontSize: 12,
                                        tickStrokeColor: '#E9E2E2',
                                    },
                                },
                            }}
                        />
                    ) : (
                        <Empty className="text-taupe" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CopyPrintUsagePieChart;

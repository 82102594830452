import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    DevicesCounterCreatePayload,
    DevicesCounterIdPayload,
    DevicesCounterListPayload,
    DevicesCounterListResponse,
    DevicesCounterUpdatePayload,
    remove,
    update,
    DevicesCounterExportResponse,
} from './api/devicesCounters';
import { DeviceCounter } from './api/types';

export const devicesCountersKeys = {
    all: ['devicesCounters'],
    lists: () => [...devicesCountersKeys.all, 'list'],
    list: (params?: DevicesCounterListPayload) => [...devicesCountersKeys.lists(), params],
    export: (params?: DevicesCounterListPayload) => [...devicesCountersKeys.all, params],
    details: () => [...devicesCountersKeys.all, 'details'],
    detail: (id?: DevicesCounterIdPayload) => [...devicesCountersKeys.details(), id],
};

export const useDevicesCounterList = <TData = DevicesCounterListResponse>(
    params?: DevicesCounterListPayload,
    options?: UseQueryOptions<DevicesCounterListResponse, AxiosError, TData>
) => {
    return useQuery<DevicesCounterListResponse, AxiosError, TData>(
        devicesCountersKeys.list(params),
        async () => await list(params).then((response) => response.data),
        { keepPreviousData: true, ...options }
    );
};

export const useDevicesCounterExport = <TData = DevicesCounterExportResponse>(
    params?: DevicesCounterListPayload,
    options?: UseQueryOptions<DevicesCounterExportResponse, AxiosError, TData>
) => {
    return useQuery<DevicesCounterExportResponse, AxiosError, TData>(
        devicesCountersKeys.export(params),
        async () => await list(params, { headers: { Accept: '\tapplication/vnd.ms-excel' }, responseType: 'blob' }),
        { ...options }
    );
};

export const useDevicesCounterDetails = <TData = DeviceCounter>(
    id?: DevicesCounterIdPayload,
    options?: UseQueryOptions<DeviceCounter, AxiosError, TData>
) => {
    return useQuery<DeviceCounter, AxiosError, TData>(
        devicesCountersKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useDevicesCounterCreate = (
    options?: UseMutationOptions<DeviceCounter, AxiosError, DevicesCounterCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<DeviceCounter, AxiosError, DevicesCounterCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(devicesCountersKeys.lists());
        },
    });
};

export const useDevicesCounterUpdate = (
    options?: UseMutationOptions<DeviceCounter, AxiosError, DevicesCounterUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<DeviceCounter, AxiosError, DevicesCounterUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(devicesCountersKeys.detail(variables.id));
        },
    });
};

export const useDevicesCounterRemove = (
    options?: UseMutationOptions<undefined, AxiosError, DevicesCounterIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, DevicesCounterIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(devicesCountersKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(devicesCountersKeys.lists());
        },
    });
};

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.64 15.34c.29.15.598.289.918.417.841.336 1.808.614 2.865.82.206 1.057.483 2.024.82 2.865.128.32.267.627.416.918a9.03 9.03 0 0 1-5.019-5.02ZM12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11Zm8.36-7.66a9.03 9.03 0 0 1-5.02 5.02c.15-.29.289-.598.417-.918.336-.841.614-1.808.82-2.865a16.085 16.085 0 0 0 2.865-.82c.32-.128.627-.267.918-.416Zm0-6.68a9.03 9.03 0 0 0-5.02-5.02c.15.29.289.598.417.918.336.841.614 1.808.82 2.865 1.057.206 2.024.483 2.865.82.32.128.627.267.918.416Zm-3.478.875c.67.16 1.28.35 1.818.565.835.334 1.444.707 1.827 1.07.381.36.473.641.473.83 0 .189-.091.47-.473.83-.384.363-.992.736-1.827 1.07-.537.215-1.148.405-1.818.565.078-.794.118-1.62.118-2.465 0-.845-.04-1.671-.118-2.465Zm-2.417-2.417c-.16-.67-.35-1.28-.565-1.818-.334-.835-.707-1.443-1.07-1.827C12.47 3.092 12.19 3 12 3c-.189 0-.47.091-.83.473-.363.384-.736.992-1.07 1.827a13.12 13.12 0 0 0-.565 1.818C10.329 7.04 11.155 7 12 7c.845 0 1.671.04 2.465.118ZM9.17 9.17C10.058 9.06 11.008 9 12 9s1.942.06 2.83.17c.11.888.17 1.838.17 2.83s-.06 1.942-.17 2.83c-.888.11-1.838.17-2.83.17s-1.942-.06-2.83-.17C9.06 13.942 9 12.992 9 12s.06-1.942.17-2.83Zm-2.052.365c-.67.16-1.28.35-1.818.565-.835.334-1.443.707-1.827 1.07-.381.36-.473.641-.473.83 0 .189.091.47.473.83.384.363.992.736 1.827 1.07.537.215 1.148.405 1.818.565A25.55 25.55 0 0 1 7 12c0-.845.04-1.671.118-2.465Zm.305-2.112c.206-1.057.483-2.024.82-2.865.128-.32.267-.627.416-.918A9.03 9.03 0 0 0 3.64 8.66c.29-.15.598-.289.918-.417a16.077 16.077 0 0 1 2.865-.82Zm2.112 9.46c.16.67.35 1.28.565 1.817.334.835.707 1.444 1.07 1.827.36.381.641.473.83.473.189 0 .47-.091.83-.473.363-.384.736-.992 1.07-1.827.215-.537.405-1.148.565-1.818-.794.078-1.62.118-2.465.118-.845 0-1.671-.04-2.465-.118Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgSphere: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-sphere', props.className)} />
));
export default SvgSphere;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.333 2.333a1.167 1.167 0 1 0 0 2.334h9.334a1.167 1.167 0 0 0 0-2.334H9.333ZM5.833 7c0-.644.523-1.167 1.167-1.167h14a1.167 1.167 0 0 1 0 2.334H7A1.167 1.167 0 0 1 5.833 7Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.207 10.195c.047-.175.134-.334.251-.467h.001a1.16 1.16 0 0 1 .846-.394H25.666a1.165 1.165 0 0 1 1.167 1.178V24.5c0 .644-.522 1.167-1.166 1.167H2.333A1.167 1.167 0 0 1 1.167 24.5V10.512a1.165 1.165 0 0 1 .04-.317ZM24.5 12.833l-9.8 7.35a1.167 1.167 0 0 1-1.4 0l-9.8-7.35v10.5h21v-10.5Zm-2.333-1.166H5.833L14 17.792l8.167-6.125Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgMailPackage: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-mail-package', props.className)} />
));
export default SvgMailPackage;

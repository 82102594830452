import api from './apiClient';

export interface LogsPayload {
    streams: Array<{
        stream: {
            app: string;
            organization: string | null;
        };
        values: Array<[string, string]>;
    }>;
}

export const logs = async (payload: LogsPayload) =>
    await api
        .post<undefined>('/debug/logs', payload, {
            headers: {
                'content-type': 'application/json',
            },
            baseURL: '/',
        })
        .then((response) => response?.data);

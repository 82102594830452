import { Empty } from 'antd';
import dayjs from 'dayjs';
import { FC, memo, ReactNode, useMemo } from 'react';

import {
    classNames,
    getDeviceLastCounterColorPercentage,
    isDeviceUsingColors,
    isDeviceUsingErasableBlue,
    isDeviceUsingLowColor,
} from '../helpers';
import { formatDate, formatNumber } from '../helpers/i18n';
import { DeviceListPayload, DeviceListResponse } from '../queries/api/devices';
import { Color, Device, ECCCommunication, ERPExportStatus, MFPCommunication, ScanCounter } from '../queries/api/types';
import { useDeviceList } from '../queries/devices';
import EccAutoStatusIcon from './EccAutoStatusIcon';
import { CheckOne, Printer } from './icons';
import ListTonerGauge from './ListTonerGauge';
import NoData from './NoData';
import ResizableTable, { ResizableTableColumn, ResizableTableProps, getDefaultSort } from './ResizableTable';

interface DevicesListProps extends Omit<ResizableTableProps<Device>, 'columns'> {
    sort?: string;
    sortOrder?: string;
    queryPayload?: DeviceListPayload;
    queryEnabled?: boolean;
    renderHeader?: (devices: DeviceListResponse | undefined) => ReactNode;
    selectedDeviceId?: Device['id'];
    emptyText?: string;
}

const DevicesList: FC<DevicesListProps> = memo(
    ({
        sort,
        sortOrder,
        maxHeight,
        pagination: paginationProp,
        onSortChange,
        queryPayload,
        queryEnabled,
        renderHeader,
        selectedDeviceId,
        emptyText = 'Aucun résultat',
        ...props
    }) => {
        const {
            data: deviceList,
            isLoading,
            isError,
            error,
            isFetching,
        } = useDeviceList(
            {
                sort,
                sortOrder,
                page: (paginationProp?.current ?? 1) - 1,
                pageSize: paginationProp?.pageSize,
                ...queryPayload,
            },
            {
                enabled: queryEnabled,
            }
        );

        const tableColumns = useMemo<Array<ResizableTableColumn<Device>>>(
            () => [
                {
                    id: 'serial',
                    header: 'N° de série',
                    accessorKey: 'serial',
                    cell: (info) => info.getValue() || <NoData />,
                    enableSorting: true,
                    fixed: 'left',
                    size: 142,
                },
                {
                    id: 'org',
                    header: 'Org.',
                    accessorFn: (info) => info.organization.code,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 66,
                },
                {
                    id: 'hardCodedDeviceInfo.model',
                    header: 'Matériel',
                    accessorFn: (info) => info.hardCodedDeviceInfo?.model,
                    cell: (info) => {
                        return info.getValue() || <NoData />;
                    },
                    enableSorting: true,
                    size: 260,
                },
                {
                    id: 'firstCommunicationAt',
                    header: 'Première comm.',
                    accessorKey: 'firstCommunicationAt',
                    cell: (info) => (info.getValue() ? formatDate(info.getValue<Date>()) : <NoData />),
                    enableSorting: true,
                    size: 260,
                },
                {
                    id: 'lastCommunicationAt',
                    header: 'Dernière comm.',
                    accessorKey: 'lastCommunicationAt',
                    cell: (info) => (info.getValue() ? formatDate(info.getValue<Date>()) : <NoData />),
                    enableSorting: true,
                    size: 260,
                },
                {
                    id: 'numberOfCommunication',
                    header: 'NB comm.',
                    accessorKey: 'numberOfCommunication',
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info.getValue() as number),
                    size: 260,
                },
                {
                    id: 'instruction',
                    header: 'Consigne',
                    accessorFn: (info) => info,
                    cell: (info) =>
                        info.getValue<Device>()?.instruction ? (
                            <CheckOne
                                className={classNames(
                                    'font-20',
                                    selectedDeviceId === info.getValue<Device>().id && 'text-blue'
                                )}
                            />
                        ) : (
                            <NoData />
                        ),
                    size: 100,
                },
                {
                    id: 'hardCodedDeviceInfoCustomerName',
                    header: 'Nom du client',
                    accessorFn: (info) => info.hardCodedDeviceInfo?.customer?.name,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 260,
                },
                {
                    id: 'hardCodedDeviceInfoCustomerPhone',
                    header: 'Téléphone',
                    accessorFn: (info) => info.hardCodedDeviceInfo?.customer?.phone,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 260,
                },
                {
                    id: 'hardCodedDeviceInfoCustomerMail',
                    header: 'Email du client',
                    accessorFn: (info) => info.hardCodedDeviceInfo?.customer?.mail,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 260,
                },
                {
                    id: 'hardCodedDeviceInfoCustomerAddressOneLineAddress',
                    header: 'Adresse du client',
                    accessorFn: (info) => info.hardCodedDeviceInfo?.customer?.address?.oneLineAddress,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 260,
                },
                {
                    id: 'hardCodedDeviceInfoCustomerMailTicketRecipient',
                    header: 'E-mail destinataire ticket',
                    accessorFn: (info) => info.hardCodedDeviceInfo?.customer?.mailTicketRecipient,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 260,
                },
                {
                    id: 'ecc',
                    header: 'ECC',
                    accessorKey: 'ecc',
                    cell: (info) => <EccAutoStatusIcon status={info.getValue<ECCCommunication>()} />,
                    size: 51,
                    enableResizing: false,
                    enableSorting: true,
                },
                {
                    id: 'auto',
                    header: 'AUTO',
                    accessorKey: 'auto',
                    cell: (info) => <EccAutoStatusIcon status={info.getValue<MFPCommunication>()} />,
                    size: 64,
                    enableResizing: false,
                    enableSorting: true,
                },
                {
                    id: 'subscriptionReference',
                    header: 'ID Abon.',
                    accessorFn: (info) => info.subscription?.reference,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 120,
                },
                {
                    id: 'lastCounter',
                    header: 'Total relevé',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        const counter = device?.lastCounter?.counter;
                        return formatNumber(
                            (counter?.black ?? 0) +
                                (isDeviceUsingColors(device) ? counter?.color ?? 0 : 0) +
                                (isDeviceUsingLowColor(device) ? counter?.lowColor ?? 0 : 0) +
                                (isDeviceUsingErasableBlue(device) ? counter?.erasableBlue ?? 0 : 0)
                        );
                    },
                    size: 160,
                },
                {
                    id: 'lastCounter.updatedAt',
                    header: 'Date dernier relevé',
                    accessorFn: (info) => info.lastCounter?.updatedAt,
                    cell: (info) => (info.getValue() ? formatDate(info.getValue<Date>()) : <NoData />),
                    enableSorting: true,
                    size: 190,
                },
                {
                    id: 'vmm',
                    header: 'Volume Moyen Mens.',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        const macounter = device?.monthlyAverageCounter;
                        return formatNumber(
                            (macounter?.black ?? 0) +
                                (isDeviceUsingColors(device) ? macounter?.color ?? 0 : 0) +
                                (isDeviceUsingLowColor(device) ? macounter?.lowColor ?? 0 : 0) +
                                (isDeviceUsingErasableBlue(device) ? macounter?.erasableBlue ?? 0 : 0)
                        );
                    },
                    size: 180,
                },
                {
                    id: 'lastCounter.counter.black',
                    header: 'Total Noir',
                    accessorFn: (info) => info.lastCounter?.counter?.black,
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info?.getValue<number>()),
                    enableSorting: true,
                    size: 120,
                },
                {
                    id: 'lastCounter.counter.black.percent',
                    header: '% Noir',
                    accessorFn: (info) => info,
                    cell: (info) =>
                        formatNumber(getDeviceLastCounterColorPercentage(info.getValue<Device>(), 'black') / 100, {
                            style: 'percent',
                        }),
                    size: 120,
                },
                {
                    id: 'lastCounter.counter.color',
                    header: 'Total Couleur',
                    accessorFn: (info) => info.lastCounter?.counter?.color,
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info?.getValue<number>()),
                    enableSorting: true,
                    size: 160,
                },
                {
                    id: 'lastCounter.counter.color.percent',
                    header: '% Couleur',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        return isDeviceUsingColors(device) ? (
                            formatNumber(getDeviceLastCounterColorPercentage(device, 'color') / 100, {
                                style: 'percent',
                            })
                        ) : (
                            <NoData />
                        );
                    },
                    size: 120,
                },
                {
                    id: 'lowColorValueEnabled',
                    header: 'Marianne',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();

                        return isDeviceUsingLowColor(device) ? (
                            <CheckOne className="font-20 text-success" />
                        ) : (
                            <NoData />
                        );
                    },
                    size: 100,
                },
                {
                    id: 'lastCounter.counter.lowColor',
                    header: 'Total Marianne',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        const lowColor = device?.lastCounter?.counter?.lowColor;
                        return lowColor === undefined || !isDeviceUsingLowColor(device) ? (
                            <NoData />
                        ) : (
                            formatNumber(lowColor)
                        );
                    },
                    enableSorting: true,
                    size: 160,
                },
                {
                    id: 'lastCounter.counter.lowColor.percent',
                    header: '% Marianne',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        return device?.lastCounter?.counter?.lowColor === undefined ||
                            !isDeviceUsingLowColor(device) ? (
                            <NoData />
                        ) : (
                            formatNumber(getDeviceLastCounterColorPercentage(device, 'lowColor') / 100, {
                                style: 'percent',
                            })
                        );
                    },
                    size: 120,
                },
                {
                    id: 'blueErasableColorValueEnabled',
                    header: 'Effac.',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        return isDeviceUsingErasableBlue(device) ||
                            (device?.lastCounter?.counter?.erasableBlue ?? 0) > 0 ? (
                            <CheckOne className="font-20 text-success" />
                        ) : (
                            <NoData />
                        );
                    },
                    size: 80,
                },
                {
                    id: 'lastCounter.counter.erasableBlue',
                    header: 'Total Effac.',
                    accessorFn: (info) => info.lastCounter?.counter?.erasableBlue,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        return device?.lastCounter?.counter?.erasableBlue === undefined ||
                            !isDeviceUsingErasableBlue(device) ? (
                            <NoData />
                        ) : (
                            formatNumber(device?.lastCounter?.counter?.erasableBlue)
                        );
                    },
                    enableSorting: true,
                    size: 130,
                },
                {
                    id: 'lastCounter.counter.erasableBlue.percent',
                    header: '% Effac.',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        return isDeviceUsingErasableBlue(device) ? (
                            formatNumber(getDeviceLastCounterColorPercentage(device, 'erasableBlue') / 100, {
                                style: 'percent',
                            })
                        ) : (
                            <NoData />
                        );
                    },
                    size: 120,
                },
                {
                    id: 'scanTotal',
                    header: 'Scan réseau',
                    accessorFn: (info) => info?.lastCounter?.scan,
                    cell: (info) =>
                        formatNumber(
                            (info.getValue<ScanCounter>()?.black ?? 0) + (info.getValue<ScanCounter>()?.color ?? 0)
                        ),
                    size: 120,
                },
                {
                    id: 'lastCounter.scan.black',
                    header: 'Scan noir',
                    accessorFn: (info) => info?.lastCounter?.scan?.black,
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info.getValue<number>()),
                    size: 120,
                },
                {
                    id: 'lastCounter.scan.color',
                    header: 'Scan couleur',
                    accessorFn: (info) => info?.lastCounter?.scan?.color,
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info.getValue<number>()),
                    size: 130,
                },
                {
                    id: 'lastCounter.fax.transmitted',
                    header: 'Fax transmis',
                    accessorFn: (info) => info?.lastCounter?.fax?.transmitted,
                    cell: (info) =>
                        info?.getValue() === undefined ? <NoData /> : formatNumber(info.getValue<number>()),
                    size: 120,
                },
                {
                    id: 'lastCounter.fax.received',
                    header: 'Fax Réception',
                    accessorFn: (info) => info?.lastCounter?.fax?.received,
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info.getValue<number>()),
                    size: 180,
                },
                {
                    id: 'lastExportedCounter.counter.black',
                    header: 'Transmis noir',
                    accessorFn: (info) => info?.lastExportedCounter?.counter?.black,
                    cell: (info) =>
                        info.getValue() === undefined ? <NoData /> : formatNumber(info.getValue<number>()),
                    size: 160,
                },
                {
                    id: 'lastExportedCounter.counter.color',
                    header: 'Transmis couleur',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        const color = device.lastExportedCounter?.counter?.color;
                        return !isDeviceUsingColors(device) || color === undefined ? <NoData /> : formatNumber(color);
                    },
                    size: 180,
                },
                {
                    id: 'exportStatus',
                    header: 'Compteurs à exp.',
                    accessorFn: (info) => info,
                    cell: (info) =>
                        info.getValue<Device>().exportStatus === ERPExportStatus.pending &&
                        info.getValue<Device>().erpManaged ? (
                            <CheckOne className="font-20 text-success" />
                        ) : (
                            <NoData />
                        ),
                    size: 180,
                },
                {
                    id: 'exportDate',
                    header: 'Date exp. compteurs',
                    accessorKey: 'exportDate',
                    cell: (info) => (info.getValue() ? formatDate(info.getValue<Date>()) : <NoData />),
                    size: 200,
                    enableSorting: true,
                },
                {
                    id: 'erpDeviceInfo.technician.lastName',
                    header: 'Nom technicien ERP',
                    accessorFn: (info) => info.erpDeviceInfo?.technician?.lastName,
                    cell: (info) => info.getValue<string | undefined>() || <NoData />,
                    size: 200,
                },
                {
                    id: 'erpDeviceInfo.customer.reference',
                    header: 'Code client livré',
                    accessorFn: (info) => info.erpDeviceInfo?.customer?.reference,
                    cell: (info) => info.getValue<string | undefined>() || <NoData />,
                    size: 200,
                },
                {
                    id: 'erpDeviceInfo.customer.name',
                    header: 'Nom client livré',
                    accessorFn: (info) => info.erpDeviceInfo?.customer?.name,
                    cell: (info) => {
                        return info.getValue() || <NoData />;
                    },
                    size: 200,
                    ellipsis: true,
                },
                {
                    id: 'erpDeviceInfo.customer.address.zipCode',
                    header: 'Code Postal',
                    accessorFn: (info) => info.erpDeviceInfo?.customer?.address?.zipCode,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 120,
                },
                {
                    id: 'erpDeviceInfo.customer.address.city',
                    header: 'Ville',
                    accessorFn: (info) => info.erpDeviceInfo?.customer?.address?.city,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 120,
                },
                {
                    id: 'erpDeviceInfo.customer.address.directives',
                    header: 'Localisation',
                    accessorFn: (info) => info.erpDeviceInfo?.customer?.address?.directives,
                    cell: (info) => info.getValue() || <NoData />,
                    size: 120,
                },
                {
                    id: 'erpDeviceInfo',
                    header: 'Fiche(s) ERP',
                    accessorKey: 'erpDeviceInfo',
                    cell: (info) => (info.getValue() ? 1 : 0).toString(),
                    size: 120,
                },
                {
                    id: 'erpDeviceInfo.installedAt',
                    header: 'Date installation',
                    accessorFn: (info) => info.erpDeviceInfo?.installedAt,
                    cell: (info) => (info.getValue() ? formatDate(info.getValue<Date>()) : <NoData />),
                    enableSorting: true,
                    size: 180,
                },
                {
                    id: 'contractReferences',
                    header: 'N° contrat',
                    accessorFn: (info) => info.erpDeviceInfo?.customer?.contractReferences,
                    cell: (info) => info.getValue<number[] | undefined>()?.join(', ') || <NoData />,
                    size: 112,
                },
                {
                    id: 'dateOfLastToner',
                    header: 'Date dernière jauge',
                    accessorKey: 'dateOfLastToner',
                    cell: (info) => (info.getValue() ? formatDate(info.getValue<Date>()) : <NoData />),
                    size: 200,
                },
                {
                    id: 'colorLevels.cyan',
                    header: 'Cyan restant',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();

                        return (
                            <ListTonerGauge
                                selected={device?.id === selectedDeviceId}
                                color={Color.cyan}
                                value={isDeviceUsingColors(device) ? device.colorLevels?.cyan : undefined}
                            />
                        );
                    },
                    size: 140,
                },
                {
                    id: 'colorLevels.yellow',
                    header: 'Yel. restant',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();

                        return (
                            <ListTonerGauge
                                selected={device?.id === selectedDeviceId}
                                color={Color.yellow}
                                value={isDeviceUsingColors(device) ? device.colorLevels?.yellow : undefined}
                            />
                        );
                    },
                    size: 140,
                },
                {
                    id: 'colorLevels.magenta',
                    header: 'Mag. restant',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();

                        return (
                            <ListTonerGauge
                                selected={device?.id === selectedDeviceId}
                                color={Color.magenta}
                                value={isDeviceUsingColors(device) ? device.colorLevels?.magenta : undefined}
                            />
                        );
                    },
                    size: 140,
                },
                {
                    id: 'colorLevels.black',
                    header: 'Black restant',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();

                        return (
                            <ListTonerGauge
                                selected={device?.id === selectedDeviceId}
                                color={Color.black}
                                value={device?.colorLevels?.black}
                            />
                        );
                    },
                    size: 140,
                },
                {
                    id: 'colorLevels.erasableBlue',
                    header: 'Effaç. restant',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();

                        return (
                            <ListTonerGauge
                                selected={device?.id === selectedDeviceId}
                                color={Color.erasableBlue}
                                value={isDeviceUsingErasableBlue(device) ? device.colorLevels?.erasableBlue : undefined}
                            />
                        );
                    },
                    size: 140,
                },
                {
                    id: 'lastCounter.updatedAt.month',
                    header: 'Période dernier relevé',
                    accessorFn: (info) => info.lastCounter?.updatedAt,
                    cell: (info) =>
                        info.getValue() ? (
                            formatDate(info.getValue<Date>(), { month: 'numeric', year: 'numeric' })
                        ) : (
                            <NoData />
                        ),
                    size: 190,
                },
                {
                    id: 'communicationDiffInDays',
                    header: 'Jours écoulés depuis dern. com.',
                    accessorFn: (info) => info,
                    cell: (info) => {
                        const device = info.getValue<Device>();
                        if (!device.lastCommunicationAt) {
                            return <NoData />;
                        }

                        const diff = dayjs().diff(dayjs(device.lastCommunicationAt), 'days');

                        return `${formatNumber(diff)} jour${diff > 1 ? 's' : ''}`;
                    },
                    size: 260,
                },
                {
                    id: 'firstCommunicationPeriod',
                    header: 'Période première communication',
                    accessorKey: 'firstCommunicationAt',
                    cell: (info) =>
                        info.getValue() ? (
                            formatDate(info.getValue() as Date, { month: 'numeric', year: 'numeric' })
                        ) : (
                            <NoData />
                        ),
                    size: 260,
                },
                {
                    id: 'lastCommunicationPeriod',
                    header: 'Période dernière communication',
                    accessorKey: 'lastCommunicationAt',
                    cell: (info) =>
                        info.getValue() ? (
                            formatDate(info.getValue() as Date, { month: 'numeric', year: 'numeric' })
                        ) : (
                            <NoData />
                        ),
                    size: 260,
                },
            ],
            [selectedDeviceId]
        );

        const pagination = useMemo<ResizableTableProps<Device>['pagination']>(
            () => ({
                ...paginationProp,
                pageSizeOptions: ['10', '20', '50', '100', '200'],
                total: deviceList?.totalCount ?? 0,
            }),
            [deviceList?.totalCount, paginationProp]
        );

        const defaultSortingState = useMemo(() => getDefaultSort(sort, sortOrder), [sort, sortOrder]);

        return (
            <>
                {renderHeader?.(deviceList)}
                <ResizableTable<Device>
                    rowKey="id"
                    data={deviceList?.items}
                    columns={tableColumns}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    error={error}
                    defaultSortingState={defaultSortingState}
                    onSortChange={onSortChange}
                    pagination={pagination}
                    maxHeight={maxHeight}
                    emptyContent={
                        <Empty
                            className="mb-32 text-taupe"
                            image={<Printer className="font-32 mt-32" />}
                            description={emptyText}
                        />
                    }
                    enableColumnResizing
                    {...props}
                />
            </>
        );
    }
);

export default DevicesList;

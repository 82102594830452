/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 5.154c0 1.19-.895 2.154-2 2.154s-2-.965-2-2.154C5.5 3.964 6.395 3 7.5 3s2 .964 2 2.154Zm-2 9.153c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.153 2 2.153Zm0 7c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.154 2 2.154ZM18.5 5.154c0 1.19-.895 2.154-2 2.154s-2-.965-2-2.154c0-1.19.895-2.154 2-2.154s2 .964 2 2.154Zm-2 9.153c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.153 2 2.153Zm0 7c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.154 2 2.154Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgDrag: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-drag', props.className)} />
));
export default SvgDrag;

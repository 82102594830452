/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.735 0c.75 0 1.358.642 1.358 1.434v2.152c0 .792-.608 1.435-1.358 1.435-.75 0-1.359-.643-1.359-1.435V1.434c0-.792.609-1.434 1.359-1.434ZM4.784 3.44a1.31 1.31 0 0 1 1.914.177l1.31 1.648c.482.607.407 1.512-.168 2.021a1.31 1.31 0 0 1-1.914-.177l-1.31-1.648c-.482-.607-.407-1.512.168-2.021Zm17.902 0c.575.51.65 1.414.168 2.02l-1.31 1.65a1.31 1.31 0 0 1-1.915.176 1.49 1.49 0 0 1-.167-2.02l1.31-1.65a1.31 1.31 0 0 1 1.914-.176Zm-8.951 6.601c-3.001 0-5.435 2.57-5.435 5.738v7.352h10.87v-7.352c0-3.169-2.434-5.738-5.435-5.738Zm8.151 13.09v-7.352c0-4.753-3.65-8.607-8.151-8.607-4.502 0-8.152 3.854-8.152 8.607v7.352H1.508c-.75 0-1.359.642-1.359 1.434C.149 25.358.757 26 1.508 26H26.64c.75 0 1.359-.642 1.359-1.434 0-.793-.608-1.435-1.359-1.435h-4.755ZM.021 12.151c.13-.781.835-1.302 1.574-1.164l2.007.373c.739.138 1.232.882 1.102 1.662-.13.78-.835 1.301-1.574 1.164l-2.007-.374C.384 13.674-.11 12.93.02 12.15Zm27.428 0c.13.78-.363 1.523-1.102 1.661l-2.007.374c-.74.137-1.444-.384-1.574-1.164-.13-.78.363-1.524 1.102-1.662l2.007-.373c.739-.138 1.444.383 1.574 1.163Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgAlarm: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-alarm', props.className)} />
));
export default SvgAlarm;

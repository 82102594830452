import { VFC } from 'react';
import { Button, Card, CardProps, Empty, Skeleton, Tag } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';

import { Alarm, Paperclip } from './icons';
import ApiResult from './ApiResult';
import useCopyToClipboard from '../hooks';
import { successMessage } from '../helpers/message';
import { MailMessage, MailResponse } from '../queries/api/types';
import { getMailResponseStatusColor } from '../helpers';
import { translateMailResponseStatus } from '../helpers/i18n';

interface MessageCardProps extends CardProps {
    withCopyButton?: boolean;
    bodyHeight?: number;
    mailMessage?: MailMessage;
    mailResponse?: MailResponse;
    attachmentFilename?: string;
    error: AxiosError | null;
    isError?: boolean;
    isEmpty?: boolean;
}

const MessageCard: VFC<MessageCardProps> = ({
    mailMessage,
    mailResponse,
    withCopyButton,
    bodyHeight = 325,
    attachmentFilename,
    error,
    isError,
    isEmpty,
    loading,
    ...props
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [copiedValue, copy] = useCopyToClipboard();
    const body = mailMessage?.body ?? mailResponse?.body;

    return (
        <Card
            actions={
                !loading
                    ? [
                          withCopyButton && body && (
                              <Button
                                  type="text"
                                  size="large"
                                  className="p-16 font-12"
                                  icon={<CopyOutlined className="text-primary" />}
                                  key="copy"
                                  onClick={async () => {
                                      const result = await copy(body);

                                      if (result) {
                                          successMessage({ content: 'Message copié' });
                                      }
                                  }}
                                  block
                              >
                                  Copier le message
                              </Button>
                          ),
                          (!!mailMessage?.attachmentFiles || !!mailMessage?.attachmentFolder) && (
                              <a
                                  key="dl"
                                  href={`/api/mailMessages/${mailMessage.id ?? ''}/attachments`}
                                  download={attachmentFilename}
                              >
                                  <Button
                                      type="text"
                                      size="large"
                                      className="p-16 font-12"
                                      icon={<Paperclip className="text-primary" />}
                                      block
                                  >
                                      Télécharger les pièces jointes
                                  </Button>
                              </a>
                          ),
                          !!mailResponse?.attachment && (
                              <a
                                  key="dl"
                                  href={mailResponse?.attachment?.url}
                                  download={
                                      mailResponse?.attachment?.originalName ?? mailResponse?.attachment?.filename
                                  }
                              >
                                  <Button
                                      type="text"
                                      size="large"
                                      className="p-16 font-12"
                                      icon={<Paperclip className="text-primary" />}
                                      block
                                  >
                                      Télécharger la pièce jointe
                                  </Button>
                              </a>
                          ),
                      ].filter(Boolean)
                    : undefined
            }
            bodyStyle={{
                height: bodyHeight,
                overflow: 'auto',
            }}
            extra={
                mailResponse?.status ? (
                    <Tag color={getMailResponseStatusColor(mailResponse?.status)}>
                        {translateMailResponseStatus(mailResponse?.status)}
                    </Tag>
                ) : undefined
            }
            {...props}
        >
            {loading ? (
                <Skeleton paragraph={{ rows: 10 }} title={false} active />
            ) : isEmpty ? (
                <Empty
                    className="mb-32 text-taupe"
                    image={<Alarm className="font-28 mt-56" />}
                    description="Aucune alerte sélectionnée"
                />
            ) : (
                <>
                    {isError ? (
                        <ApiResult status={error?.response?.status} />
                    ) : (
                        <pre className="p-12 -m-12 overflow-visible">{body}</pre>
                    )}
                </>
            )}
        </Card>
    );
};

export default MessageCard;

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    details,
    list,
    PrivilegeCodeIdPayload,
    PrivilegeCodeListPayload,
    PrivilegeCodeListResponse,
    PrivilegeCodeUpdatePayload,
    update,
} from './api/privilegeCodes';
import { PrivilegeCode } from './api/types';

export const privilegeCodesKeys = {
    all: ['privilegeCodes'],
    lists: () => [...privilegeCodesKeys.all, 'list'],
    list: (params?: PrivilegeCodeListPayload) => [...privilegeCodesKeys.lists(), params],
    details: () => [...privilegeCodesKeys.all, 'details'],
    detail: (id?: PrivilegeCodeIdPayload) => [...privilegeCodesKeys.details(), id],
};

export const usePrivilegeCodeList = <TData = PrivilegeCodeListResponse>(
    params?: PrivilegeCodeListPayload,
    options?: UseQueryOptions<PrivilegeCodeListResponse, AxiosError, TData>
) => {
    return useQuery<PrivilegeCodeListResponse, AxiosError, TData>(
        privilegeCodesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const usePrivilegeCodeDetails = <TData = PrivilegeCode>(
    id?: PrivilegeCodeIdPayload,
    options?: UseQueryOptions<PrivilegeCode, AxiosError, TData>
) => {
    return useQuery<PrivilegeCode, AxiosError, TData>(
        privilegeCodesKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const usePrivilegeCodeUpdate = (
    options?: UseMutationOptions<PrivilegeCode, AxiosError, PrivilegeCodeUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<PrivilegeCode, AxiosError, PrivilegeCodeUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            queryClient.invalidateQueries(privilegeCodesKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(privilegeCodesKeys.detail(variables.id));
        },
    });
};

import { Layout } from 'antd';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import MainMenu from './MainMenu';

const MainLayout: FC = ({ children }) => {
    const { pathname } = useLocation();
    const isExportRoute = pathname.startsWith('/exports/');

    return (
        <>
            {isExportRoute ? (
                children
            ) : (
                <Layout id="main-layout">
                    <Header />
                    <Layout>
                        <MainMenu />
                        <Layout.Content className="p-16">{children}</Layout.Content>
                    </Layout>
                </Layout>
            )}
        </>
    );
};

export default MainLayout;

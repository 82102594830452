import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Source, ListResponse } from './types';

// Controller Interface

export type SourceIdPayload = Source['id'];

export type SourceListPayload = SearchPaginationQuery;
export type SourceListResponse = ListResponse<Source>;

// Routes
export const list = async (payload?: SourceListPayload) => {
    return await api.get<SourceListResponse>(`/sources`, { params: payload }).then((response) => response?.data);
};

export const details = async (id?: SourceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Source>(`/sources/${id}`).then((response) => response?.data);
};

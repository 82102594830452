import { Descriptions, Skeleton } from 'antd';
import { VFC } from 'react';

import { useLayout } from '../../context/LayoutContext';
import { getFullName } from '../../helpers';
import { formatDate } from '../../helpers/i18n';
import { Device } from '../../queries/api/types';
import { useDeviceDetails, useDeviceList } from '../../queries/devices';
import ApiResult from '../ApiResult';
import NoData from '../NoData';

interface DeviceDrawerArtisProps {
    id?: Device['id'];
    serial?: Device['serial'];
}

const DeviceDrawerArtis: VFC<DeviceDrawerArtisProps> = ({ id, serial }) => {
    const { organizationId } = useLayout();
    const {
        data: details,
        isLoading: isLoadingDetails,
        isError: isErrorDetails,
        error: errorDetails,
    } = useDeviceDetails(id, {
        enabled: !!id,
    });
    const {
        data: list,
        isLoading: isLoadingList,
        isError: isErrorList,
        error: errorList,
    } = useDeviceList(
        { serials: serial ? [serial] : undefined, organization: organizationId },
        {
            enabled: !!serial,
        }
    );

    const device = details || list?.items?.[0];

    if (isLoadingDetails || isLoadingList) {
        return <Skeleton active />;
    }

    if (isErrorDetails || isErrorList || !device) {
        return <ApiResult status={!device ? 404 : (errorDetails || errorList)?.response?.status} />;
    }

    return (
        <>
            <Descriptions
                size="small"
                column={2}
                className="mb-16"
                labelStyle={{ width: 160 }}
                contentStyle={{ width: 339 }}
                bordered
            >
                <Descriptions.Item label="N° de série">{device?.serial ?? <NoData />}</Descriptions.Item>
                <Descriptions.Item label="Modèle">{device?.erpDeviceInfo?.model ?? <NoData />}</Descriptions.Item>
                <Descriptions.Item label="Apporteur">
                    {device?.erpDeviceInfo?.provider?.code ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Mainteneur">
                    {device?.erpDeviceInfo?.maintainer?.code ?? <NoData />}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions
                size="small"
                column={2}
                className="mb-16"
                labelStyle={{ width: 160 }}
                contentStyle={{ width: 339 }}
                bordered
            >
                <Descriptions.Item label="Code client">
                    {device?.erpDeviceInfo?.customer?.reference ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Code contrat(s)">
                    {device?.erpDeviceInfo?.customer?.contractReferences?.join(', ') ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Nom client livré">
                    {device?.erpDeviceInfo?.customer?.name ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Téléphone">
                    {device?.erpDeviceInfo?.customer?.phone ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Email utilisateur" span={2}>
                    {device?.erpDeviceInfo?.customer?.mail ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Ville client livré">
                    {device?.erpDeviceInfo?.customer?.address?.city ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Code postal">
                    {device?.erpDeviceInfo?.customer?.address?.zipCode ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Localisation" span={2}>
                    {device?.erpDeviceInfo?.customer?.address?.directives ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Technicien">
                    {getFullName(device?.erpDeviceInfo?.technician) !== '-' ? (
                        getFullName(device?.erpDeviceInfo?.technician)
                    ) : (
                        <NoData />
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Privilège">
                    {device?.erpDeviceInfo?.privilegeCode?.name ?? <NoData />}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions
                size="small"
                column={2}
                className="mb-16"
                labelStyle={{ width: 160 }}
                contentStyle={{ width: 339 }}
                bordered
            >
                <Descriptions.Item label="Installation" span={2}>
                    {formatDate(device?.erpDeviceInfo?.installedAt) === '-' ? (
                        <NoData />
                    ) : (
                        formatDate(device?.erpDeviceInfo?.installedAt)
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Dernière intervention">
                    {formatDate(device?.erpDeviceInfo?.lastIntervention) === '-' ? (
                        <NoData />
                    ) : (
                        formatDate(device?.erpDeviceInfo?.lastIntervention)
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Dernière livraison">
                    {formatDate(device?.erpDeviceInfo?.lastDelivery) === '-' ? (
                        <NoData />
                    ) : (
                        formatDate(device?.erpDeviceInfo?.lastDelivery)
                    )}
                </Descriptions.Item>
            </Descriptions>
        </>
    );
};

export default DeviceDrawerArtis;

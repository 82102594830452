import { Button, DrawerProps, Form, Input, Spin } from 'antd';
import { FormProps } from 'antd/lib/form';
import { antdDrawer, create, useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';

import CustomDrawer from '../CustomDrawer';
import { errorMessage, successMessage } from '../../helpers/message';
import { requiredRule } from '../../helpers';
import AlertTypeFormItem from '../form/AlertTypeFormItem';
import { ChevronRight } from '../icons';
import { defaultErrorMessageWithStatus } from '../../helpers/i18n';
import { useMailTemplateCreate, useMailTemplateUpdate, useMailTemplateDetails } from '../../queries/mailTemplates';
import { MailTemplate } from '../../queries/api/types';
import OrganizationFormItem from '../form/OrganizationFormItem';

interface MailTemplatesFormDrawerProps extends DrawerProps, Record<string, unknown> {
    mailTemplateId?: MailTemplate['id'];
}

const MailTemplatesFormDrawer = create<MailTemplatesFormDrawerProps>(({ mailTemplateId }) => {
    const [form] = Form.useForm();
    const modal = useModal();
    const [isAlertType, setIsAlertType] = useState(false);
    const { mutateAsync: create, isLoading: isCreating } = useMailTemplateCreate();
    const { mutateAsync: update, isLoading: isUpdating } = useMailTemplateUpdate();
    const isEditing = mailTemplateId !== undefined;
    const { data: mailTemplate, isLoading: isFetching } = useMailTemplateDetails(mailTemplateId, {
        enabled: isEditing,
        onSuccess: (data) => {
            form.setFieldsValue({
                ...data,
                organization: data.organization.id,
                alertType: data.alertType?.id,
            });
            setIsAlertType(!!data.alertType);
        },
    });
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            update(
                {
                    id: mailTemplateId,
                    ...values,
                    name: values.name || null,
                    subject: values.subject || null,
                },
                {
                    onSuccess: () => {
                        successMessage({ content: 'Modèle modifié avec succès' });
                        modal.resolve({ ...values });
                        modal.hide();
                    },
                    onError: (error) => {
                        if (error.response?.status === 409) {
                            const field = Object.keys(error.response?.data?.fields)?.[0];

                            if (field) {
                                form.setFields([
                                    {
                                        name: field,
                                        errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                                    },
                                ]);
                            }
                        } else {
                            errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                        }
                    },
                }
            );
        } else {
            create(values, {
                onSuccess: () => {
                    successMessage({ content: 'Modèle ajouté avec succès' });
                    modal.resolve({ ...values });
                    modal.hide();
                },
                onError: (error) => {
                    if (error.response?.status === 409) {
                        const field = Object.keys(error.response?.data?.fields)?.[0];

                        if (field) {
                            form.setFields([
                                {
                                    name: field,
                                    errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                                },
                            ]);
                        }
                    } else {
                        errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                    }
                },
            });
        }
    };

    return (
        <CustomDrawer
            title={`${isEditing ? 'Modifier' : 'Ajouter'} un modèle de réponse`}
            {...antdDrawer(modal)}
            width={600}
        >
            <Spin spinning={isFetching}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={
                        isEditing
                            ? {
                                  ...mailTemplate,
                                  organization: mailTemplate?.organization.id,
                                  alertType: mailTemplate?.alertType?.id,
                              }
                            : undefined
                    }
                    requiredMark={false}
                    scrollToFirstError
                >
                    <AlertTypeFormItem
                        name={isAlertType ? ['alertType'] : undefined}
                        label={`Type${isAlertType ? " d'alerte" : ''}`}
                        rules={isAlertType ? [requiredRule] : undefined}
                        selectProps={{
                            dropdownMatchSelectWidth: false,
                            placeholder: isAlertType
                                ? 'Sélectionner un type alerte sans modèle'
                                : 'Message personnalisé',
                            disabled: !isAlertType,
                        }}
                        queryPayload={{ registeredMail: 'false' }}
                        extra={
                            !isEditing && (
                                <Button
                                    type="link"
                                    className="p-0 font-12"
                                    onClick={() => setIsAlertType((value) => !value)}
                                >
                                    {isAlertType ? 'Ne pas associer' : 'Associer'} à un type d’alerte{' '}
                                    <ChevronRight className="font-10" />
                                </Button>
                            )
                        }
                    />
                    <OrganizationFormItem
                        name="organization"
                        label="Organisation"
                        selectProps={{
                            dropdownMatchSelectWidth: false,
                            disabled: isEditing,
                        }}
                    />
                    {!isAlertType && (
                        <Form.Item name={['name']} label="Titre">
                            <Input placeholder="Saisir un titre" />
                        </Form.Item>
                    )}
                    <Form.Item name={['subject']} label="Objet de l'email">
                        <Input placeholder="Saisir un objet" autoComplete="off" />
                    </Form.Item>
                    <Form.Item name={['body']} label="Message" rules={[requiredRule]}>
                        <Input.TextArea placeholder="Saisir le message" rows={18} />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={isCreating || isUpdating}
                                disabled={
                                    !form.isFieldsTouched() ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                }
                                block
                            >
                                {`${isEditing ? 'Modifier' : 'Ajouter'} le modèle`}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Spin>
        </CustomDrawer>
    );
});

export default MailTemplatesFormDrawer;

import { serialize } from 'object-to-formdata';
import { UploadChangeParam } from 'antd/lib/upload';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import {
    Alert,
    AlertStatus,
    AlertType,
    Color,
    Device,
    FileGenerationRequestSlug,
    ListResponse,
    MailTemplate,
    Organization,
    Source,
    User,
} from './types';

// Controller Interface
export interface AlertCreatePayload {
    organization?: Organization['id'];
    device: Device['id'];
    colorReferenceToOrder: Color;
}

export interface AlertUpdatePayload extends Partial<Omit<Alert, 'id' | 'organization' | 'mailResponse' | 'operator'>> {
    id?: Alert['id'];
    organization?: Organization['id'];
    attachments?: UploadChangeParam['fileList'];
    operator?: User['id'] | null;
    mailResponse?: {
        mailTemplate?: MailTemplate['id'];
        subject?: string;
        mailTo: string;
        body: string;
    };
}

export type AlertIdPayload = Alert['id'];

export type AlertListPayload = SearchPaginationQuery & {
    organization?: Organization['id'];
    alertTypes?: AlertType['id'] | Array<AlertType['id']>;
    sources?: Source['id'] | Array<Source['id']>;
    status?: AlertStatus | AlertStatus[];
    fromDate?: string;
    toDate?: string;
    mail?: string;
    mailDomain?: string;
    ignoreAlertTypeIds?: Array<AlertType['id']>;
    reference?: Alert['reference'];
    devices?: Device['id'];
    deviceSerials?: string | string[];
    fileGenerationRequestSlug?: FileGenerationRequestSlug;
};
export type AlertListResponse = ListResponse<Alert>;
export type AlertExportResponse = AxiosResponse;
export interface AlertIgnorePayload {
    alertsIds: Array<Alert['id']>;
}

// Routes
export const list = async (payload?: AlertListPayload, axiosOptions?: AxiosRequestConfig) => {
    return await api.get<AlertListResponse>(`/alerts`, { params: payload, ...axiosOptions });
};

export const create = async (payload: AlertCreatePayload) => {
    return await api.post(`/alerts`, payload).then((response) => response?.data);
};

export const update = async ({ id, attachments, ...payload }: AlertUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    const formData = serialize(payload, { indices: true, allowEmptyArrays: true });

    for (const attachment of attachments ?? []) {
        const file = attachment.originFileObj;
        if (file) {
            formData.append('attachments', file);
        }
    }

    return await api.put<Alert>(`/alerts/${id}`, formData).then((response) => response?.data);
};

export const details = async (id?: AlertIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Alert>(`/alerts/${id}`).then((response) => response?.data);
};

export const remove = async (id?: AlertIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/alerts/${id}`).then((response) => response?.data);
};

export const ignore = async (payload?: AlertIgnorePayload) => {
    if (!payload) {
        throw new Error('missing ids');
    }
    return await api.post<undefined>('/alerts/ignore', payload).then((response) => response?.data);
};

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Device, DeviceCounter, ListResponse, Organization } from './types';

// Controller Interface
export type DevicesCounterCreatePayload = undefined;

export interface DevicesCounterUpdatePayload extends Omit<DeviceCounter, 'id'> {
    id?: DeviceCounter['id'];
}

export type DevicesCounterIdPayload = DeviceCounter['id'];

export type DevicesCounterListPayload = SearchPaginationQuery & {
    devices?: Device['id'];
    organization?: Organization['id'];
    lastCounterOfEachMonth?: boolean;
};
export type DevicesCounterListResponse = ListResponse<DeviceCounter>;
export type DevicesCounterExportResponse = AxiosResponse;

// Routes
export const list = async (payload?: DevicesCounterListPayload, axiosOptions?: AxiosRequestConfig) => {
    return await api.get<DevicesCounterListResponse>(`/devicesCounters`, { params: payload, ...axiosOptions });
};

export const create = async (payload: DevicesCounterCreatePayload) => {
    return await api.post(`/devicesCounters`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: DevicesCounterUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<DeviceCounter>(`/devicesCounters/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: DevicesCounterIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<DeviceCounter>(`/devicesCounters/${id}`).then((response) => response?.data);
};

export const remove = async (id?: DevicesCounterIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/devicesCounters/${id}`).then((response) => response?.data);
};

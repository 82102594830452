import { VFC } from 'react';
import { Card, Divider, Empty, Skeleton, Spin, Statistic, Typography } from 'antd';

import { Alarm, CalendarThree } from '../../components/icons';
import ApiResult from '../../components/ApiResult';
import { useAlertDetails } from '../../queries/alerts';
import useQueryParams from '../../hooks/queryParams';
import { classNames, isDeviceUsingErasableBlue, isDeviceUsingLowColor } from '../../helpers';
import { formatDate, formatNumber, formatRelativeTime, translateTonerColor } from '../../helpers/i18n';
import TagWhite from '../../components/TagWhite';
import TonerGauge from '../../components/TonerGauge';
import { Color } from '../../queries/api/types';
import EccAutoStatusIcon from '../../components/EccAutoStatusIcon';

const AlertDetailsToner: VFC = () => {
    const [queryParams] = useQueryParams('alerts');
    const alertId = queryParams.get('alertId') ?? undefined;
    const { data: alert, isLoading, isError, error } = useAlertDetails(alertId, { enabled: !!alertId });
    const {
        colorLevels,
        auto,
        ecc,
        thresholdColorLevels,
        lastCounter: counter,
        monthlyAverageCounter,
    } = alert?.device ?? {};
    const isUsingErasableBlue = isDeviceUsingErasableBlue(alert?.device);
    const isUsingLowColor = isDeviceUsingLowColor(alert?.device);
    // const isUsingColors = isDeviceUsingColors(alert?.device);
    const counterTotal =
        (counter?.counter?.black ?? 0) +
        (counter?.counter?.color ? counter?.counter?.color : 0) +
        (isUsingErasableBlue && counter?.counter?.erasableBlue ? counter?.counter?.erasableBlue : 0) +
        (isUsingLowColor && counter?.counter?.lowColor ? counter?.counter?.lowColor : 0);
    const getCounterPercentage = (value?: number | null) => {
        if (!value || counterTotal === 0) {
            return 0;
        }

        return (value * 100) / counterTotal;
    };

    return (
        <Card
            title="Informations toners & compteurs"
            actions={
                !!alertId && !isError && !isLoading
                    ? [
                          <Statistic
                              key="black"
                              className="m-12 cursor-default"
                              title="VMM NOIR"
                              value={monthlyAverageCounter?.black ?? undefined}
                          />,
                          isUsingErasableBlue && (
                              <Statistic
                                  key="erasable-blue"
                                  className="m-12 cursor-default"
                                  title="VMM BLEU EFF."
                                  value={monthlyAverageCounter?.erasableBlue ?? undefined}
                              />
                          ),
                          isUsingLowColor && (
                              <Statistic
                                  key="marianne"
                                  className="m-12 cursor-default"
                                  title="VMM MARIANNE"
                                  value={monthlyAverageCounter?.lowColor ?? undefined}
                              />
                          ),
                          <Statistic
                              key="colors"
                              className="m-12 cursor-default"
                              title="VMM COULEUR"
                              value={monthlyAverageCounter?.color ?? undefined}
                          />,
                      ].filter(Boolean)
                    : undefined
            }
            bodyStyle={{
                height: isUsingLowColor ? 355 : 311,
            }}
            style={{ minWidth: 369, maxWidth: 369 }}
        >
            {!alertId ? (
                <Empty
                    className="mb-32 text-taupe"
                    image={<Alarm className="font-28 mt-56" />}
                    description="Aucune alerte sélectionnée"
                />
            ) : isError ? (
                <ApiResult status={error?.response?.status} />
            ) : (
                <Spin spinning={isLoading} delay={100}>
                    <div className="flex gap-16 justify-between mb-12">
                        <div className="flex flex-col items-center font-12 flex-[0_0_154px]">
                            <Typography.Title level={4} className="uppercase mb-4">
                                Dernière mise à jour
                            </Typography.Title>
                            {isLoading ? (
                                <div className="text-center" style={{ width: '80%' }}>
                                    <Skeleton paragraph={{ rows: 1 }} title={false} active />
                                </div>
                            ) : (
                                <>
                                    <Typography.Text
                                        className={classNames(
                                            'inline-flex items-center space-x-1 font-medium',
                                            !colorLevels?.updatedAt && 'text-dark-grey'
                                        )}
                                    >
                                        <CalendarThree className="text-blue font-16" />{' '}
                                        <span>
                                            {colorLevels?.updatedAt
                                                ? formatDate(colorLevels?.updatedAt)
                                                : 'Pas de date connue'}
                                        </span>
                                    </Typography.Text>
                                    {!!colorLevels?.updatedAt && (
                                        <Typography.Text className="font-medium text-dark-grey">
                                            {formatRelativeTime(colorLevels.updatedAt)}
                                        </Typography.Text>
                                    )}
                                </>
                            )}

                            <div className="flex mt-6 space-x-2">
                                <TagWhite className="m-0" icon={<EccAutoStatusIcon status={auto} inTag />}>
                                    AUTO
                                </TagWhite>
                                <TagWhite className="m-0" icon={<EccAutoStatusIcon status={ecc} inTag />}>
                                    ECC
                                </TagWhite>
                            </div>
                        </div>
                        <div
                            className={classNames(
                                'flex gap-4',
                                isUsingErasableBlue ? 'justify-around' : 'justify-between'
                            )}
                        >
                            {isUsingErasableBlue ? (
                                <>
                                    <TonerGauge color={Color.black} value={colorLevels?.black} />
                                    <TonerGauge color={Color.erasableBlue} value={colorLevels?.erasableBlue} />
                                </>
                            ) : (
                                <>
                                    <TonerGauge color={Color.yellow} value={colorLevels?.yellow} />
                                    <TonerGauge color={Color.magenta} value={colorLevels?.magenta} />
                                    <TonerGauge color={Color.cyan} value={colorLevels?.cyan} />
                                    <TonerGauge color={Color.black} value={colorLevels?.black} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-16">
                        <Typography.Text className="flex-[0_0_154px] font-12 text-center">
                            Dernier seuil déclenché
                        </Typography.Text>
                        <TagWhite
                            className={classNames(
                                'flex flex-[0_0_172px] m-0 text-dark-grey py-1',
                                isUsingErasableBlue ? 'justify-around' : 'px-12 justify-between'
                            )}
                        >
                            {isUsingErasableBlue ? (
                                <>
                                    <span>{thresholdColorLevels?.black ?? '-'}</span>
                                    <span>{thresholdColorLevels?.erasableBlue ?? '-'}</span>
                                </>
                            ) : (
                                <>
                                    <span>{thresholdColorLevels?.yellow ?? '-'}</span>
                                    <span>{thresholdColorLevels?.magenta ?? '-'}</span>
                                    <span>{thresholdColorLevels?.cyan ?? '-'}</span>
                                    <span>{thresholdColorLevels?.black ?? '-'}</span>
                                </>
                            )}
                        </TagWhite>
                    </div>
                    <Divider className="-mx-16 my-12 w-auto" />
                    <Typography.Title level={4} className="uppercase mb-4">
                        Derniers compteurs cno
                    </Typography.Title>
                    <Typography.Text
                        className={classNames(
                            'inline-flex items-center space-x-1 font-medium font-12 mb-12',
                            !counter?.updatedAt && 'text-dark-grey'
                        )}
                    >
                        <CalendarThree className="text-blue font-16" />{' '}
                        <span>
                            {counter?.updatedAt ? (
                                <>
                                    {formatDate(counter?.updatedAt)}{' '}
                                    <span className="text-dark-grey ml-12">
                                        {formatRelativeTime(counter.updatedAt)}
                                    </span>
                                </>
                            ) : (
                                'Pas de date connue'
                            )}
                        </span>
                    </Typography.Text>
                    {isUsingErasableBlue ? (
                        <div className="flex gap-24">
                            <div className="flex-[0_1_200px] space-y-2">
                                <TonerGauge
                                    direction="horizontal"
                                    color={Color.black}
                                    value={getCounterPercentage(counter?.counter?.black)}
                                    valueFormatter={(value) =>
                                        formatNumber(value / 100, { style: 'percent', maximumFractionDigits: 1 })
                                    }
                                />
                                <TonerGauge
                                    direction="horizontal"
                                    color={Color.erasableBlue}
                                    value={getCounterPercentage(counter?.counter?.erasableBlue)}
                                    valueFormatter={(value) =>
                                        formatNumber(value / 100, { style: 'percent', maximumFractionDigits: 1 })
                                    }
                                />
                            </div>
                            <div className="space-y-2">
                                <p className="flex mb-0 leading-8">
                                    <span className="uppercase text-dark-grey font-10">
                                        {translateTonerColor(Color.black)}
                                    </span>{' '}
                                    <span className="font-12 font-semibold ml-8">
                                        {formatNumber(counter?.counter?.black)}
                                    </span>
                                </p>
                                <p className="flex mb-0 leading-8">
                                    <span className="uppercase text-dark-grey font-10">
                                        {translateTonerColor(Color.erasableBlue)}
                                    </span>{' '}
                                    <span className="font-12 font-semibold ml-8">
                                        {formatNumber(counter?.counter?.erasableBlue)}
                                    </span>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex gap-24">
                            <div className="flex-[0_1_200px] space-y-2">
                                <TonerGauge
                                    direction="horizontal"
                                    color={Color.black}
                                    value={getCounterPercentage(counter?.counter?.black)}
                                    valueFormatter={(value) =>
                                        formatNumber(value / 100, { style: 'percent', maximumFractionDigits: 1 })
                                    }
                                />
                                <TonerGauge
                                    direction="horizontal"
                                    color="colors"
                                    value={getCounterPercentage(counter?.counter?.color)}
                                    valueFormatter={(value) =>
                                        formatNumber(value / 100, { style: 'percent', maximumFractionDigits: 1 })
                                    }
                                />
                                {isUsingLowColor && (
                                    <TonerGauge
                                        direction="horizontal"
                                        color="lowColor"
                                        value={getCounterPercentage(counter?.counter?.lowColor)}
                                        valueFormatter={(value) =>
                                            formatNumber(value / 100, { style: 'percent', maximumFractionDigits: 1 })
                                        }
                                    />
                                )}
                            </div>
                            <div className="space-y-2">
                                <p className="flex mb-0 leading-8">
                                    <span className="uppercase text-dark-grey font-10">
                                        {translateTonerColor(Color.black)}
                                    </span>{' '}
                                    <span className="font-12 font-semibold ml-8">
                                        {formatNumber(counter?.counter?.black)}
                                    </span>
                                </p>
                                <p className="flex mb-0 leading-8">
                                    <span className="uppercase text-dark-grey font-10">
                                        {translateTonerColor('colors')}
                                    </span>{' '}
                                    <span className="font-12 font-semibold ml-8">
                                        {formatNumber(counter?.counter?.color)}
                                    </span>
                                </p>
                                {isUsingLowColor && (
                                    <p className="flex mb-0 leading-8">
                                        <span className="uppercase text-dark-grey font-10">
                                            {translateTonerColor('lowColor')}
                                        </span>{' '}
                                        <span className="font-12 font-semibold ml-8">
                                            {formatNumber(counter?.counter?.lowColor)}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </Spin>
            )}
        </Card>
    );
};

export default AlertDetailsToner;

import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    metric,
    SubscriptionCreatePayload,
    SubscriptionIdPayload,
    SubscriptionListPayload,
    SubscriptionListResponse,
    SubscriptionUpdatePayload,
    remove,
    update,
    generation,
} from './api/subscriptions';
import { Subscription } from './api/types';

export const subscriptionsKeys = {
    all: ['subscriptions'],
    lists: () => [...subscriptionsKeys.all, 'list'],
    list: (params?: SubscriptionListPayload) => [...subscriptionsKeys.lists(), params],
    export: (params?: string) => [...subscriptionsKeys.all, params],
    details: () => [...subscriptionsKeys.all, 'details'],
    detail: (id?: SubscriptionIdPayload) => [...subscriptionsKeys.details(), id],
    metric: (id?: SubscriptionIdPayload) => [...subscriptionsKeys.details(), id],
};

export const useSubscriptionList = <TData = SubscriptionListResponse>(
    params?: SubscriptionListPayload,
    options?: UseQueryOptions<SubscriptionListResponse, AxiosError, TData>
) => {
    return useQuery<SubscriptionListResponse, AxiosError, TData>(
        subscriptionsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useSubscriptionDetails = <TData = Subscription>(
    id?: SubscriptionIdPayload,
    options?: UseQueryOptions<Subscription, AxiosError, TData>
) => {
    return useQuery<Subscription, AxiosError, TData>(
        subscriptionsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useSubscriptionCreate = (
    options?: UseMutationOptions<Subscription, AxiosError, SubscriptionCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Subscription, AxiosError, SubscriptionCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(subscriptionsKeys.lists());
        },
    });
};

export const useSubscriptionUpdate = (
    options?: UseMutationOptions<Subscription, AxiosError, SubscriptionUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Subscription, AxiosError, SubscriptionUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(subscriptionsKeys.detail(variables.id));
            queryClient.invalidateQueries(subscriptionsKeys.metric(variables.id));

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(subscriptionsKeys.lists());
        },
    });
};

export const useSubscriptionRemove = (options?: UseMutationOptions<undefined, AxiosError, SubscriptionIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, SubscriptionIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(subscriptionsKeys.detail(variables));
            queryClient.invalidateQueries(subscriptionsKeys.metric(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(subscriptionsKeys.lists());
        },
    });
};

export const useSubscriptionMetric = <TData = Subscription>(
    id?: SubscriptionIdPayload,
    options?: UseQueryOptions<Subscription, AxiosError, TData>
) => {
    return useQuery<Subscription, AxiosError, TData>(
        subscriptionsKeys.metric(id),
        async () => await metric(id),
        options
    );
};

export const useSubscriptionGeneration = <TData = AxiosResponse>(
    params?: string,
    options?: UseQueryOptions<AxiosResponse, AxiosError, TData>
) => {
    return useQuery<AxiosResponse, AxiosError, TData>(
        subscriptionsKeys.export(params),
        async () => await generation(params),
        { ...options }
    );
};

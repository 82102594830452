/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 13a3.5 3.5 0 0 1 3.284 4.713l1.316.987a1 1 0 0 1-1.095 1.669L21.4 20.3l-1.316-.987A3.5 3.5 0 1 1 18 13Zm4-10h.036l.06.005L22 3c.06 0 .12.005.178.016l.03.006a.914.914 0 0 1 .222.075c.143.068.267.17.363.293l.007.01.015.02a1 1 0 0 1 .045.07l-.06-.09a1.013 1.013 0 0 1 .193.483l.005.046.002.05V11.5a1 1 0 0 1-1.993.117L21 11.5V6l-8.4 6.3a1 1 0 0 1-1.09.072l-.11-.072L3 6v12h9a1 1 0 0 1 .993.883L13 19a1 1 0 0 1-.883.993L12 20H2a1 1 0 0 1-.993-.883L1 19V3.989l.003-.069L1 4a1.01 1.01 0 0 1 .14-.51 1.003 1.003 0 0 1 .652-.468l.03-.006a.878.878 0 0 1 .061-.01l.021-.001c.02-.002.042-.004.062-.004L2 3h20Zm-4 12a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm1-10H5l7 5.25L19 5Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgEmailSearch: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-email-search', props.className)} />
));
export default SvgEmailSearch;

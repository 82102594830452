import { CheckOutlined, CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FC } from 'react';

interface AlertInstructionsProps {
    instructions?: string;
}

const AlertInstructions: FC<AlertInstructionsProps> = ({ instructions }) =>
    instructions ? (
        <div className="flex w-full rounded shadow">
            <div className="flex items-center p-12 bg-orange-alert rounded-l">
                <ExclamationCircleOutlined className="text-white font-18" />
                <span className="uppercase ml-4 text-white">CONSIGNE</span>
            </div>
            <div className="flex-auto p-12 bg-orange-light border-t border-t-solid border-r border-r-solid border-b border-b-solid border-orange-light rounded-r text-black-alert font-14 font-semibold">
                <Typography.Paragraph
                    className="mb-0 w-full whitespace-pre-wrap"
                    copyable={{
                        icon: [
                            <CopyOutlined key="copy-icon" className="text-orange-alert font-14" />,
                            <CheckOutlined key="copied-icon" className="font-14" />,
                        ],
                    }}
                    style={{ color: 'inherit' }}
                >
                    {instructions}
                </Typography.Paragraph>
            </div>
        </div>
    ) : null;

export default AlertInstructions;

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { ErrorCodeRecommendation, ListResponse } from './types';

// Controller Interface
export interface ErrorCodeRecommendationCreatePayload {
    reference: string;
}

export interface ErrorCodeRecommendationUpdatePayload extends Omit<ErrorCodeRecommendation, 'id'> {
    id?: ErrorCodeRecommendation['id'];
}

export type ErrorCodeRecommendationIdPayload = ErrorCodeRecommendation['id'];

export type ErrorCodeRecommendationListPayload = SearchPaginationQuery;
export type ErrorCodeRecommendationListResponse = ListResponse<ErrorCodeRecommendation>;

// Routes
export const list = async (payload?: ErrorCodeRecommendationListPayload) => {
    return await api
        .get<ErrorCodeRecommendationListResponse>(`/errorCodeRecommendations`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: ErrorCodeRecommendationCreatePayload) => {
    return await api.post(`/errorCodeRecommendations`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: ErrorCodeRecommendationUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .put<ErrorCodeRecommendation>(`/errorCodeRecommendations/${id}`, payload)
        .then((response) => response?.data);
};

export const details = async (id?: ErrorCodeRecommendationIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<ErrorCodeRecommendation>(`/errorCodeRecommendations/${id}`).then((response) => response?.data);
};

export const remove = async (id?: ErrorCodeRecommendationIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/errorCodeRecommendations/${id}`).then((response) => response?.data);
};

import { Empty, Form } from 'antd';
import { antdDrawer, useModal, create as createModal } from '@ebay/nice-modal-react';
import { useCallback, useMemo, useState } from 'react';
import { SortingState } from '@tanstack/react-table';

import CustomDrawer from '../CustomDrawer';
import OrganizationFormItem from '../form/OrganizationFormItem';
import { Organization, PrivilegeCode } from '../../queries/api/types';
import { usePrivilegeCodeList } from '../../queries/privilegeCodes';
import AlertHighlightingSwitch from '../form/AlertHighlightingSwitch';
import { useLayout } from '../../context/LayoutContext';
import ResizableTable, { ResizableTableColumn, ResizableTableProps } from '../ResizableTable';
import NoData from '../NoData';

const PrivilegeCodesDrawer = createModal<Record<string, unknown>>(({ ...props }) => {
    const { organizationId: headerOrganizationId } = useLayout();
    const modal = useModal();
    const [form] = Form.useForm();
    const [page, setPage] = useState<number>();
    const [sort, setSort] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>();
    const [organizationId, setOrganizationId] = useState<Organization['id'] | undefined>(headerOrganizationId);
    const {
        data: privilegeCodes,
        isLoading,
        isError,
        error,
    } = usePrivilegeCodeList(
        {
            organization: organizationId,
            pageSize: 100,
            sort,
            sortOrder,
            page,
        },
        {
            enabled: !!organizationId,
        }
    );
    const onSortChange = useCallback((sortingState: SortingState) => {
        setSort(sortingState[0]?.id);
        setSortOrder(sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined);
    }, []);
    const pagination = useMemo<ResizableTableProps<PrivilegeCode>['pagination']>(
        () => ({
            current: (page ?? 0) + 1,
            total: privilegeCodes?.totalCount ?? 0,
            onChange: (current) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setPage(newPage);
            },
            size: 'small',
            pageSize: privilegeCodes?.pageSize ?? 50,
            showSizeChanger: false,
            hideOnSinglePage: true,
        }),
        [privilegeCodes?.pageSize, privilegeCodes?.totalCount, page]
    );
    const onClose = useCallback(() => {
        form.resetFields();
        modal.hide();
    }, [modal, form]);
    const columns = useMemo<Array<ResizableTableColumn<PrivilegeCode>>>(
        () => [
            {
                id: 'reference',
                header: 'Code',
                accessorKey: 'reference',
                cell: (info) => info.getValue() ?? <NoData />,
                enableSorting: true,
                size: 89,
            },
            {
                id: 'name',
                header: 'Libellé',
                accessorKey: 'name',
                cell: (info) => info.getValue() ?? <NoData />,
                size: 294,
            },
            {
                id: 'alertHighlighting',
                header: 'Mise en surbrillance',
                accessorFn: (info) => info,
                cell: (info) => {
                    return (
                        <AlertHighlightingSwitch
                            value={info.getValue<PrivilegeCode>().alertHighlighting}
                            privilegeCodeId={info.getValue<PrivilegeCode>().id}
                        />
                    );
                },
                size: 181,
            },
        ],
        []
    );

    return (
        <CustomDrawer
            width={600}
            title="Gestion des codes privilège"
            {...props}
            {...antdDrawer(modal)}
            onClose={onClose}
        >
            <Form form={form} onValuesChange={(changed, all) => setOrganizationId(all.organizationId)}>
                <OrganizationFormItem
                    name="organizationId"
                    selectProps={{
                        className: 'mb-16 w-full',
                        defaultValue: headerOrganizationId,
                    }}
                    isWhite
                    noStyle
                />
            </Form>
            <ResizableTable<PrivilegeCode>
                rowKey="id"
                columns={columns}
                isLoading={isLoading}
                isError={isError}
                error={error}
                data={privilegeCodes?.items}
                pagination={pagination}
                onSortChange={onSortChange}
                emptyContent={
                    <Empty
                        className="mb-32 text-taupe"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Aucun résultat"
                    />
                }
                maxHeight={`calc(100vh - ${(privilegeCodes?.pageCount ?? 0) > 1 ? 185 : 161}px)`}
                enableColumnResizing
            />
        </CustomDrawer>
    );
});

export default PrivilegeCodesDrawer;

import { VFC } from 'react';

import { classNames, getTonerColorClassName } from '../helpers';
import { formatNumber } from '../helpers/i18n';
import { Color } from '../queries/api/types';

interface ListTonerGaugeProps {
    color: Color | 'colors' | 'lowColor';
    value?: number | null;
    selected?: boolean;
}

const ListTonerGauge: VFC<ListTonerGaugeProps> = ({ value, color, selected }) => {
    return (
        <span
            className={classNames(
                'rounded relative flex justify-center overflow-hidden w-full pt-2 pb-8',
                selected ? 'bg-#F5FAFF' : 'bg-taupe-light'
            )}
        >
            <span
                className={classNames('absolute transition-all left-0 bottom-0 h-2', getTonerColorClassName(color))}
                style={{ width: `${value || 0}%` }}
            />
            <span className={classNames('font-medium font-12 leading-5')}>
                {formatNumber(value !== null && value !== undefined ? value / 100 : undefined, {
                    style: 'percent',
                    maximumFractionDigits: 1,
                })}
            </span>
        </span>
    );
};

export default ListTonerGauge;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6.417C0 5.772.522 5.25 1.167 5.25h16.916c.645 0 1.167.522 1.167 1.167v2.916h3.833c.366 0 .71.172.93.463l3.75 4.959c.154.202.237.45.237.703v4.959c0 .644-.522 1.166-1.167 1.166h-3.115a3.501 3.501 0 0 1-6.602 0H10.3a3.501 3.501 0 0 1-6.602 0H1.167A1.167 1.167 0 0 1 0 20.417v-14ZM3.7 19.25a3.501 3.501 0 0 1 6.6 0h6.617V7.583H2.333V19.25H3.7Zm15.55-2.134v-5.45h3.253l3.164 4.184v3.4h-1.95a3.501 3.501 0 0 0-4.467-2.134ZM7 19.25a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333Zm13.417 0a1.167 1.167 0 1 0 0 2.334 1.167 1.167 0 0 0 0-2.334Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgTruck: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-truck', props.className)} />
));
export default SvgTruck;

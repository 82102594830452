import { Descriptions, Typography } from 'antd';
import { VFC } from 'react';

import ColorUsagePieChart from '../../components/charts/ColorUsagePieChart';
import CopyPrintUsagePieChart from '../../components/charts/CopyPrintUsagePieChart';
import Logo from '../../components/Logo';
import { formatDate, formatNumber } from '../../helpers/i18n';
import useQueryParams from '../../hooks/queryParams';
import { useSubscriptionMetric } from '../../queries/subscriptions';
import AverageConsumptionStatsFaxScan from './AverageConsumptionStatsFaxScan';

const ExportSubscription: VFC = () => {
    const [queryParams] = useQueryParams('SubscriptionStats');
    const subscriptionId = queryParams.get('subscriptionId') ?? undefined;
    const { data: subscriptionDetails } = useSubscriptionMetric(subscriptionId);

    return (
        <div id="export-wrapper">
            <Logo className="absolute" style={{ height: 40 }} />
            <div className="flex justify-center items-center mb-16">
                <Typography.Title>Synthèse du parc</Typography.Title>
            </div>
            <div className="absolute top-5 right-5">{formatDate(new Date())}</div>
            <div>
                <Typography.Title className="flex justify-center mb-24">{subscriptionDetails?.name}</Typography.Title>
            </div>
            <div className="flex gap-24">
                <div style={{ minWidth: 0, flex: '1 1 58%' }}>
                    {subscriptionDetails ? (
                        <ColorUsagePieChart
                            height={200}
                            record={subscriptionDetails}
                            theme={{
                                margin: {
                                    left: 24,
                                    top: 0,
                                    right: 27,
                                    bottom: 0,
                                },
                            }}
                            showLabelTitle={false}
                            legendPosition="left"
                            small
                        />
                    ) : (
                        'NO DATA'
                    )}
                </div>
                <div className="flex flex-col gap-16" style={{ minWidth: 0, flex: '1 1 50%' }}>
                    {subscriptionDetails ? (
                        <CopyPrintUsagePieChart
                            height={200}
                            record={subscriptionDetails}
                            showLabelTitle={false}
                            theme={{
                                margin: {
                                    left: 24,
                                    top: 0,
                                    right: 24,
                                    bottom: 0,
                                },
                            }}
                            small
                        />
                    ) : (
                        'NO DATA'
                    )}
                </div>
            </div>
            <div>
                <Typography.Title level={3} className="text-taupe font-12 font-bold leading-4.5">
                    Consommation
                </Typography.Title>
                <Descriptions
                    size="small"
                    column={3}
                    className="mb-16"
                    labelStyle={{ width: 200, fontSize: '0.75rem', padding: '0.25rem 0.125rem' }}
                    contentStyle={{ width: 274, fontSize: '0.75rem', padding: '0.125rem 0.125rem' }}
                    bordered
                >
                    <Descriptions.Item label="Compteur actuel">
                        {formatNumber(subscriptionDetails?.metrics?.counter.total)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nombre de machines">
                        {formatNumber(subscriptionDetails?.metrics?.numberOfDevices)}
                    </Descriptions.Item>
                    <Descriptions.Item label="VMM">
                        {formatNumber(subscriptionDetails?.metrics?.monthlyAverageCounter.total)}
                    </Descriptions.Item>
                </Descriptions>
            </div>

            <div className="mb-16">
                <AverageConsumptionStatsFaxScan record={subscriptionDetails} />
            </div>
        </div>
    );
};

export default ExportSubscription;

/**
 * AUTO-GENERATED with npm run generate:icons
 */
import { forwardRef, ReactElement, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM7 6a5 5 0 1 1 10 0A5 5 0 0 1 7 6ZM2 22c0-5.523 4.477-10 10-10s10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 1 0-16 0 1 1 0 1 1-2 0Z"
            fill="currentColor"
        />
    </svg>
);

type ForwardedComponent = (props: IconComponentProps & RefAttributes<HTMLSpanElement>) => ReactElement | null;
const SvgUser: ForwardedComponent = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-user', props.className)} />
));
export default SvgUser;

import { Typography, Card, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useMemo, VFC } from 'react';

import { formatNumber } from '../../helpers/i18n';
import { Device } from '../../queries/api/types';

interface AverageConsumptionStatsProps {
    device?: Device;
}

const AverageConsumptionStats: VFC<AverageConsumptionStatsProps> = ({ device }) => {
    const data = useMemo(
        () => {
            const scanBlack = device?.lastCounter?.scan?.black ?? 0;
            const scanColor = device?.lastCounter?.scan?.color ?? 0;
            const faxTransmitted = device?.lastCounter?.fax?.transmitted ?? 0;
            const faxReceived = device?.lastCounter?.fax?.received ?? 0;
            return {
                actual:
                    (device?.lastCounter?.counter?.black ?? 0) +
                    (device?.lastCounter?.counter?.color ?? 0) +
                    (device?.lastCounter?.counter?.lowColor ?? 0) +
                    (device?.lastCounter?.counter?.erasableBlue ?? 0),
                vmm:
                    (device?.monthlyAverageCounter?.black ?? 0) +
                    (device?.monthlyAverageCounter?.color ?? 0) +
                    (device?.monthlyAverageCounter?.lowColor ?? 0) +
                    (device?.monthlyAverageCounter?.erasableBlue ?? 0),
                start: device?.firstPrintingCounter,
                days:
                    device?.lastCommunicationAt && device?.firstCommunicationAt
                        ? dayjs(device.lastCommunicationAt).diff(device.firstCommunicationAt, 'days')
                        : 0,
                scanTotal: scanBlack + scanColor,
                scanBlack: device?.lastCounter?.scan?.black,
                scanBlackPercentage:
                    scanBlack + scanColor > 0 ? (scanBlack * 100) / (scanBlack + scanColor) : undefined,
                scanColor: device?.lastCounter?.scan?.color,
                scanColorPercentage:
                    scanBlack + scanColor > 0 ? (scanColor * 100) / (scanBlack + scanColor) : undefined,
                faxTotal: faxTransmitted + faxReceived,
                faxTransmitted: device?.lastCounter?.fax?.transmitted,
                faxTransmittedPercentage:
                    faxTransmitted + faxReceived > 0
                        ? (faxTransmitted * 100) / (faxTransmitted + faxReceived)
                        : undefined,
                faxReceived: device?.lastCounter?.fax?.received,
                faxReceivedPercentage:
                    faxTransmitted + faxReceived > 0 ? (faxReceived * 100) / (faxTransmitted + faxReceived) : undefined,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [device?.id]
    );

    return (
        <Card bodyStyle={{ padding: '1rem' }}>
            <Typography.Title level={3} className="text-taupe font-12 font-bold leading-4.5">
                Consommation moyenne
            </Typography.Title>
            <Tabs defaultActiveKey={'summary'}>
                <Tabs.TabPane tab="Résumé" key="summary">
                    <div className="flex gap-32 w-full mb-12">
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Compteur actuel <span className="font-bold ml-8">{formatNumber(data.actual)}</span>
                        </div>
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Vol. Moyen Mois <span className="font-bold ml-8">{formatNumber(data.vmm)}</span>
                        </div>
                    </div>
                    <div className="flex gap-32 w-full">
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Compteur départ <span className="font-bold ml-8">{formatNumber(data.start)}</span>
                        </div>
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Jours écoulés <span className="font-bold ml-8">{formatNumber(data.days)}</span>
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Scan" key="scan">
                    <div className="flex gap-32 w-full mb-12">
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Scan noir <span className="font-bold ml-8">{formatNumber(data.scanBlack)}</span>
                            <span className="bg-white rounded pt-1 px-4 ml-12">
                                {formatNumber(data.scanBlackPercentage ? data.scanBlackPercentage / 100 : undefined, {
                                    style: 'percent',
                                    maximumFractionDigits: 0,
                                })}
                            </span>
                        </div>
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Scan couleur <span className="font-bold ml-8">{formatNumber(data.scanColor)}</span>
                            <span className="bg-white rounded pt-1 px-4 ml-12">
                                {formatNumber(data.scanColorPercentage ? data.scanColorPercentage / 100 : undefined, {
                                    style: 'percent',
                                    maximumFractionDigits: 0,
                                })}
                            </span>
                        </div>
                    </div>
                    <div className="inline-block py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                        Total scan réseau <span className="font-bold ml-8">{formatNumber(data.scanTotal)}</span>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Fax" key="fax">
                    <div className="flex gap-32 w-full mb-12">
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Transmission <span className="font-bold ml-8">{formatNumber(data.faxTransmitted)}</span>
                            <span className="bg-white rounded pt-1 px-4 ml-12">
                                {formatNumber(
                                    data.faxTransmittedPercentage ? data.faxTransmittedPercentage / 100 : undefined,
                                    {
                                        style: 'percent',
                                        maximumFractionDigits: 0,
                                    }
                                )}
                            </span>
                        </div>
                        <div className="py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                            Réception <span className="font-bold ml-8">{formatNumber(data.faxReceived)}</span>
                            <span className="bg-white rounded pt-1 px-4 ml-12">
                                {formatNumber(
                                    data.faxReceivedPercentage ? data.faxReceivedPercentage / 100 : undefined,
                                    {
                                        style: 'percent',
                                        maximumFractionDigits: 0,
                                    }
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="inline-block py-4 px-8 rounded bg-grey-light font-12 font-medium text-dark-grey">
                        Total fax <span className="font-bold ml-8">{formatNumber(data.faxTotal)}</span>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </Card>
    );
};

export default AverageConsumptionStats;

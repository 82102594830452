import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { AlertGroupOrganization, ListResponse, Organization } from './types';

// Controller Interface

export interface AlertGroupOrganizationUpdatePayload extends Omit<AlertGroupOrganization, 'id'> {
    id?: AlertGroupOrganization['id'];
}

export type AlertGroupOrganizationIdPayload = AlertGroupOrganization['id'];

export type AlertGroupOrganizationListPayload = SearchPaginationQuery & {
    organization?: Organization['id'];
};
export type AlertGroupOrganizationListResponse = ListResponse<AlertGroupOrganization>;

// Routes
export const list = async (payload?: AlertGroupOrganizationListPayload) => {
    return await api
        .get<AlertGroupOrganizationListResponse>(`/alertGroupOrganizations`, { params: payload })
        .then((response) => response?.data);
};

export const update = async ({ id, ...payload }: AlertGroupOrganizationUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .put<AlertGroupOrganization>(`/alertGroupOrganizations/${id}`, payload)
        .then((response) => response?.data);
};

export const details = async (id?: AlertGroupOrganizationIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<AlertGroupOrganization>(`/alertGroupOrganizations/${id}`).then((response) => response?.data);
};

export const remove = async (id?: AlertGroupOrganizationIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/alertGroupOrganizations/${id}`).then((response) => response?.data);
};

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Card,
    Descriptions,
    Form,
    FormInstance,
    FormProps,
    Input,
    InputNumber,
    List,
    Skeleton,
    Switch,
    Typography,
} from 'antd';
import { Dispatch, SetStateAction, VFC } from 'react';
import { useQueryClient } from 'react-query';

import { useAuth } from '../../context/AuthContext';
import { useLayout } from '../../context/LayoutContext';
import { classNames } from '../../helpers';
import { formatDate, formatNumber, translateTonerColor } from '../../helpers/i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import { hasPermission } from '../../helpers/security';
import { alertsKeys } from '../../queries/alerts';
import { Color, Device, Permission, PermissionRight, TonerMonitoring } from '../../queries/api/types';
import { useDeviceDetails, useDeviceList, useDeviceUpdate } from '../../queries/devices';
import AlertRemainingTonerLevels from '../alert/AlertRemainingTonerLevels';
import ApiResult from '../ApiResult';
import ListColorLevel from '../ColorLevel';
import DeviceAutoStatusFormItem from '../form/DeviceAutoStatusFormItem';
import DeviceEccStatusFormItem from '../form/DeviceEccStatusFormItem';
import NoData from '../NoData';

const formatInitialData = (data?: Device) => ({
    ...data,
    subscriptionReference: data?.subscription?.reference,
    doNotTrack: (Object.keys(data?.tonerMonitoring ?? []) as Array<keyof TonerMonitoring>).every(
        (key) => data?.tonerMonitoring[key] === 'silent'
    ),
});
interface DeviceDrawerDeviceProps {
    id?: Device['id'];
    serial?: Device['serial'];
    form: FormInstance;
    setHasFormChanged: Dispatch<SetStateAction<boolean>>;
    onCloseDrawer: () => void;
}

const DeviceDrawerDevice: VFC<DeviceDrawerDeviceProps> = ({ id, serial, form, setHasFormChanged, onCloseDrawer }) => {
    const queryClient = useQueryClient();
    const { organizationId } = useLayout();
    const { user } = useAuth();
    const canEdit = hasPermission(user, Permission.devices, PermissionRight.write);
    const {
        data: details,
        isLoading: isLoadingDetails,
        isError: isErrorDetails,
        error: errorDetails,
    } = useDeviceDetails(id, {
        enabled: !!id,
        onSuccess: (data) => form.setFieldsValue(formatInitialData(data)),
        refetchOnWindowFocus: false,
    });
    const {
        data: list,
        isLoading: isLoadingList,
        isError: isErrorList,
        error: errorList,
    } = useDeviceList(
        {
            serials: serial ? [serial] : undefined,
            organization: organizationId,
        },
        {
            enabled: !!serial,
            onSuccess: (data) => form.setFieldsValue(formatInitialData(data.items?.[0])),
            refetchOnWindowFocus: false,
        }
    );
    const { mutate: updateDevice } = useDeviceUpdate();
    const device = details || list?.items?.[0];
    const onSubmit: FormProps['onFinish'] = (values) => {
        const payload = {
            ...values,
            instruction: values.instruction || null,
            subscriptionReference: values.subscriptionReference || undefined,
        };

        delete payload.doNotTrack;

        if (!payload.hardCodedDeviceInfo.customer.mailTicketRecipient) {
            delete payload.hardCodedDeviceInfo;
        }

        if (Object.keys(payload.thresholdColorLevels).every((key) => !payload.thresholdColorLevels[key])) {
            delete payload.thresholdColorLevels;
        }

        updateDevice(
            {
                id: device?.id,
                ...payload,
            },
            {
                onSuccess: () => {
                    successMessage({ content: 'Machine mise à jour avec succès' });
                    onCloseDrawer();
                    queryClient.invalidateQueries(alertsKeys.lists());
                },
                onError: (error) => {
                    if (error?.response?.status === 404 && error?.response?.data?.data?.subscriptionNotFound) {
                        errorMessage({
                            content: "N° d'abonnement non trouvé",
                        });
                        form.setFields([
                            {
                                name: 'subscriptionReference',
                                errors: [
                                    "N° d'abonnement non trouvé, veuillez vérifier qu'il s'agit d'un numéro existant.",
                                ],
                            },
                        ]);
                    } else {
                        errorMessage({
                            content: `Une erreur est survenue pendant la mise à jour de la machine (${
                                error?.response?.status ?? 0
                            })`,
                        });
                    }
                },
            }
        );
    };

    if (isLoadingDetails || isLoadingList) {
        return <Skeleton active />;
    }

    if (isErrorDetails || isErrorList || !device) {
        return <ApiResult status={!device ? 404 : (errorDetails || errorList)?.response?.status} />;
    }

    return (
        <>
            <Typography.Title level={3} className="text-taupe">
                Consigne
            </Typography.Title>
            <Form
                form={form}
                onFinish={onSubmit}
                onReset={() => setHasFormChanged(false)}
                onChange={() => setHasFormChanged(true)}
                initialValues={formatInitialData(device)}
                scrollToFirstError
            >
                <Card className="mb-16">
                    <Form.Item name="instruction" noStyle>
                        <Input.TextArea placeholder="Saisir la consigne" autoSize />
                    </Form.Item>
                </Card>
                <Descriptions
                    title="Informations sur la machine"
                    size="small"
                    column={2}
                    className="mb-16"
                    labelStyle={{ width: 200 }}
                    contentStyle={{ width: 274 }}
                    bordered
                >
                    <Descriptions.Item label="N° de série">{device?.serial ?? <NoData />}</Descriptions.Item>
                    <Descriptions.Item label="Modèle">
                        {device?.hardCodedDeviceInfo?.model ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date première communication">
                        {formatDate(device?.firstCommunicationAt) === '-' ? (
                            <NoData />
                        ) : (
                            formatDate(device?.firstCommunicationAt)
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date dernière communication">
                        {formatDate(device?.lastCommunicationAt) === '-' ? (
                            <NoData />
                        ) : (
                            formatDate(device?.lastCommunicationAt)
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nombre de communications">
                        {formatNumber(device?.numberOfCommunication)}
                    </Descriptions.Item>
                    <Descriptions.Item label="N° d'abonnement">
                        <Form.Item name="subscriptionReference" className="mb-0">
                            <Input
                                placeholder="Saisir un numéro"
                                onChange={() => {
                                    form.setFields([
                                        {
                                            name: 'subscriptionReference',
                                            errors: [],
                                        },
                                    ]);
                                }}
                                disabled={!canEdit}
                            />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label="Machine en ECC">
                        <DeviceEccStatusFormItem
                            name="ecc"
                            noStyle
                            selectProps={{ className: 'w-full', onChange: () => setHasFormChanged(true) }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Machine en AUTO">
                        <DeviceAutoStatusFormItem
                            name="auto"
                            noStyle
                            selectProps={{ className: 'w-full', onChange: () => setHasFormChanged(true) }}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions
                    title="Informations sur le client"
                    size="small"
                    column={2}
                    className="mb-16"
                    labelStyle={{ width: 200 }}
                    contentStyle={{ width: 274 }}
                    bordered
                >
                    <Descriptions.Item label="Nom du client" span={2}>
                        {device?.organization?.name ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Organisation">
                        {device?.hardCodedDeviceInfo?.customer?.name ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone">
                        {device?.hardCodedDeviceInfo?.customer?.phone ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Addresse du client" span={2}>
                        {device?.hardCodedDeviceInfo?.customer?.address?.oneLineAddress ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email du client">
                        {device?.hardCodedDeviceInfo?.customer?.mail ?? <NoData />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Domaine">
                        {device?.hardCodedDeviceInfo?.customer?.mailTicketRecipient ||
                        device?.hardCodedDeviceInfo?.customer?.mail ? (
                            (
                                device?.hardCodedDeviceInfo?.customer?.mailTicketRecipient ||
                                device?.hardCodedDeviceInfo?.customer?.mail
                            )?.split('@')?.[1]
                        ) : (
                            <NoData />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email destinataire du ticket" span={2}>
                        <Form.Item
                            name={['hardCodedDeviceInfo', 'customer', 'mailTicketRecipient']}
                            rules={[{ type: 'email', message: 'Veuillez entrer une adresse email valide' }]}
                            className="mb-0"
                        >
                            <Input placeholder="Saisir une adresse email" disabled={!canEdit} allowClear />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <div className="flex justify-around mb-16">
                    <div>
                        <AlertRemainingTonerLevels device={device} />
                        <Typography.Paragraph className="text-center">Seuil TNE</Typography.Paragraph>
                        <div className="flex justify-center gap-24">
                            <Form.Item name={['thresholdColorLevels', 'yellow']} noStyle>
                                <InputNumber min={0} max={100} style={{ width: 56 }} disabled={!canEdit} />
                            </Form.Item>
                            <Form.Item name={['thresholdColorLevels', 'magenta']} noStyle>
                                <InputNumber min={0} max={100} style={{ width: 56 }} disabled={!canEdit} />
                            </Form.Item>
                            <Form.Item name={['thresholdColorLevels', 'cyan']} noStyle>
                                <InputNumber min={0} max={100} style={{ width: 56 }} disabled={!canEdit} />
                            </Form.Item>
                            <Form.Item name={['thresholdColorLevels', 'black']} noStyle>
                                <InputNumber min={0} max={100} style={{ width: 56 }} disabled={!canEdit} />
                            </Form.Item>
                            <Form.Item name={['thresholdColorLevels', 'erasableBlue']} noStyle>
                                <InputNumber min={0} max={100} style={{ width: 56 }} disabled={!canEdit} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ width: 482 }}>
                        <Typography.Paragraph className="text-center" style={{ width: 316, margin: '0 auto 1rem' }}>
                            Désactivation de la surveillance jusqu’au prochain changement de cartouche
                        </Typography.Paragraph>
                        <Form.Item dependencies={['doNotTrack']} noStyle>
                            {({ getFieldValue }) => (
                                <List
                                    size="small"
                                    className="mb-16"
                                    dataSource={
                                        device?.tonerMonitoring
                                            ? (Object.keys(device.tonerMonitoring) as Array<keyof TonerMonitoring>).map(
                                                  (key) => ({ color: key, value: device.tonerMonitoring[key] })
                                              )
                                            : []
                                    }
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div className="flex justify-between items-center w-full">
                                                <ListColorLevel
                                                    colorLevel={{ [item.color]: item.value }}
                                                    label={
                                                        <>
                                                            Ne pas contrôler{' '}
                                                            <span className="font-bold">
                                                                {translateTonerColor(item.color as Color)}
                                                            </span>
                                                        </>
                                                    }
                                                    large
                                                />
                                                <Form.Item
                                                    name={['tonerMonitoring', item.color]}
                                                    valuePropName="checked"
                                                    getValueProps={(value) => ({ checked: value === 'deactivate' })}
                                                    getValueFromEvent={(checked) => (checked ? 'deactivate' : 'active')}
                                                    noStyle
                                                >
                                                    <Switch
                                                        unCheckedChildren={<CloseOutlined className="font-12" />}
                                                        checkedChildren={<CheckOutlined className="font-12" />}
                                                        disabled={!canEdit || getFieldValue('doNotTrack')}
                                                        onChange={() => setHasFormChanged(true)}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </List.Item>
                                    )}
                                    bordered
                                />
                            )}
                        </Form.Item>
                        <Typography.Paragraph className="text-center">Interrompre la surveillance</Typography.Paragraph>
                        <Form.Item dependencies={['doNotTrack']} noStyle>
                            {({ getFieldValue }) => {
                                const isChecked = getFieldValue('doNotTrack');

                                return (
                                    <div
                                        className={classNames(
                                            'flex justify-between items-center w-full p-12 rounded border-1-red-5',
                                            isChecked && 'bg-dust-red'
                                        )}
                                    >
                                        <Typography.Text className={classNames(isChecked && 'text-red')}>
                                            Ne plus surveiller ce matériel
                                        </Typography.Text>
                                        <Form.Item name={['doNotTrack']} valuePropName="checked" noStyle>
                                            <Switch
                                                unCheckedChildren={<CloseOutlined className="font-12" />}
                                                checkedChildren={<CheckOutlined className="font-12" />}
                                                className="switch-primary"
                                                onChange={(checked) => {
                                                    setHasFormChanged(true);
                                                    form.setFieldsValue({
                                                        tonerMonitoring: (
                                                            Object.keys(device.tonerMonitoring) as Array<
                                                                keyof TonerMonitoring
                                                            >
                                                        ).reduce(
                                                            (result, key) => ({
                                                                ...result,
                                                                [key]: checked ? 'silent' : 'deactivate',
                                                            }),
                                                            {}
                                                        ),
                                                    });
                                                }}
                                                disabled={!canEdit}
                                            />
                                        </Form.Item>
                                    </div>
                                );
                            }}
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default DeviceDrawerDevice;

import api from './apiClient';
import { MailMessage } from './types';

// Controller Interface

export type MailMessageIdPayload = MailMessage['id'];

// Routes
export const details = async (id?: MailMessageIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<MailMessage>(`/mailMessages/${id}`).then((response) => response?.data);
};

import {
    ArrowLeftOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    LineChartOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { show } from '@ebay/nice-modal-react';
import { Button, Form, FormProps, Typography, Card, Input, Alert, Modal, Empty } from 'antd';
import { useCallback, useMemo, useState, VFC } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { SortingState } from '@tanstack/react-table';

import { CheckOne, DocSearchTwo } from '../../components/icons';
import NoData from '../../components/NoData';
import Seo from '../../components/Seo';
import TruncatedText from '../../components/TruncatedText';
import { useAuth } from '../../context/AuthContext';
import { useLayout } from '../../context/LayoutContext';
import { classNames, downloadBlobFile, isDeviceUsingErasableBlue, isDeviceUsingLowColor } from '../../helpers';
import { formatDate, formatNumber } from '../../helpers/i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import { hasPermission } from '../../helpers/security';
import { Customer, Device, Permission, PermissionRight, Technician } from '../../queries/api/types';
import {
    devicesKeys,
    useDeviceList,
    useDeviceRemove,
    useSelectedDevicesExport,
    useSelectedDevicesExportPDF,
} from '../../queries/devices';
import { getRoute, RoutePathName } from '../../routes';
import { deviceDrawerId } from '../../App';
import EccAutoStatusIcon from '../../components/EccAutoStatusIcon';
import ResizableTable, { ResizableTableColumn } from '../../components/ResizableTable';
import { FileType } from '../../queries/api';

const DeviceSearch: VFC = () => {
    const { organizationId } = useLayout();
    const { user } = useAuth();
    const queryClient = useQueryClient();
    const [devicesToDelete, setDevicesToDelete] = useState<Array<Device['id']>>();
    const [highlightedDevice, setHighlightedDevice] = useState<Device>();
    const [serialCount, setSerialCount] = useState(0);
    const [sort, setSort] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>();
    const [serials, setSerials] = useState<string[]>();
    const [isExcelExportEnabled, setIsExcelExportEnabled] = useState(false);
    const [isPDFExportEnabled, setIsPDFExportEnabled] = useState(false);
    const {
        data: devices,
        isLoading,
        isError,
        error,
    } = useDeviceList(
        {
            serials: serials ?? undefined,
            organization: organizationId,
            pageSize: 600,
            sort,
            sortOrder,
        },
        {
            enabled: !!serials?.length,
        }
    );

    const { isLoading: isExcelExporting } = useSelectedDevicesExport(
        {
            organization: organizationId,
            serials: serials ?? undefined,
            isFromSelectedDevices: true,
        },
        {
            enabled: isExcelExportEnabled,
            onSettled: () => {
                setIsExcelExportEnabled(false);
            },
            onSuccess: (data) => {
                const filename = data.headers['content-disposition'].split('"')[1];
                downloadBlobFile(filename, data.data, 'blob');
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );

    const { isLoading: isPDFExporting } = useSelectedDevicesExportPDF(
        {
            organization: organizationId,
            serials: serials ?? undefined,
            isFromSelectedDevices: true,
        },
        {
            enabled: isPDFExportEnabled,
            onSettled: () => {
                setIsPDFExportEnabled(false);
            },
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );

    const { mutateAsync: deleteDevice } = useDeviceRemove();
    const serialsNotFound =
        serials && devices?.items
            ? serials.filter((serial) => !devices.items.find((device) => device.serial === serial.toUpperCase()))
            : undefined;
    const onClickExport = async (type: FileType) => {
        if (type === FileType.EXCEL) {
            setIsExcelExportEnabled(true);
        } else if (type === FileType.PDF) {
            setIsPDFExportEnabled(true);
        }
    };
    const onSortChange = useCallback((sortingState: SortingState) => {
        setSort(sortingState[0]?.id);
        setSortOrder(sortingState[0] ? (sortingState[0].desc ? 'desc' : 'asc') : undefined);
    }, []);
    const [form] = Form.useForm();
    const canWrite = hasPermission(user, Permission.devices, PermissionRight.write);
    const onSubmit: FormProps['onFinish'] = ({ serials }) => {
        if (serials) {
            setSerials(serials.split('\n').filter(Boolean));
        }
    };
    const onTextareaChange = useCallback((e) => {
        const values = e.target.value.replaceAll(' ', '\r\n').split('\n').filter(Boolean);

        setSerialCount(values.length);
    }, []);
    const onFormReset = () => {
        setSerials(undefined);
        setHighlightedDevice(undefined);
        setSort(undefined);
        setSortOrder(undefined);
    };
    const onClickDelete = () => {
        const isMultiple = devicesToDelete!.length !== 1;
        Modal.confirm({
            width: 480,
            centered: true,
            title: 'Confirmation de suppression',
            icon: <ExclamationCircleOutlined className="text-warning" />,
            content: `Êtes-vous sûr de vouloir supprimer ${
                isMultiple ? 'les machines sélectionnées' : 'la machine sélectionnée'
            } ?`,
            okText: 'Supprimer',
            onOk: async () =>
                await Promise.allSettled(devicesToDelete!.map(async (deviceId) => await deleteDevice(deviceId))).then(
                    (results) => {
                        if (results.every((result) => result.status === 'fulfilled')) {
                            successMessage({
                                content: `${isMultiple ? 'Machines supprimées' : 'Machine supprimée'} avec succès`,
                            });
                            setDevicesToDelete(undefined);
                        } else {
                            queryClient.invalidateQueries(devicesKeys.lists());
                            errorMessage({
                                content: `Une erreur est survenue pendant la suppression ${
                                    isMultiple ? 'des machines' : 'de la machine'
                                }`,
                            });
                        }

                        const remainingDevicesToDelete = results
                            .map((result, index) =>
                                result.status === 'rejected' ? devicesToDelete![index] : undefined
                            )
                            .filter(Boolean) as string[];

                        setDevicesToDelete(remainingDevicesToDelete);
                    }
                ),
        });
    };
    const onRowHighlightChange = useCallback<(row: Device, index: number) => void>((row) => {
        setHighlightedDevice(row);
    }, []);
    const isRowHighlighted = useCallback<(row: Device, index: number) => boolean>(
        (row) => row.id === highlightedDevice?.id,
        [highlightedDevice?.id]
    );
    const columns = useMemo<Array<ResizableTableColumn<Device>>>(
        () => [
            {
                id: 'serial',
                header: 'N° de série',
                accessorKey: 'serial',
                cell: (info) => info.getValue() ?? <NoData />,
                enableSorting: true,
                fixed: 'left',
                size: 132,
            },
            {
                id: 'org',
                header: 'Org.',
                accessorKey: 'organization.code',
                cell: (info) => info.getValue() ?? <NoData />,
                size: 66,
            },

            {
                id: 'hardCodedDeviceInfoModel',
                header: 'Matériel',
                accessorKey: 'hardCodedDeviceInfo.model',
                cell: (info) => info.getValue() ?? <NoData />,
                size: 260,
            },
            {
                id: 'lastCommunicationAt',
                header: 'Dernier relevé',
                accessorKey: 'lastCommunicationAt',
                cell: (info) =>
                    info.getValue<Device['lastCommunicationAt']>() ? (
                        formatDate(info.getValue<Device['lastCommunicationAt']>())
                    ) : (
                        <NoData />
                    ),
                enableSorting: true,
                size: 142,
            },
            {
                id: 'lastCounter.counter.black',
                header: 'Total noir',
                accessorKey: 'lastCounter.counter.black',
                cell: (info) => formatNumber(info.getValue<number | null | undefined>()),
                enableSorting: true,
                size: 117,
            },
            {
                id: 'lastCounter.counter.color',
                header: 'Total couleurs',
                accessorKey: 'lastCounter.counter.color',
                cell: (info) => formatNumber(info.getValue<number | null | undefined>()),
                enableSorting: true,
                size: 160,
            },
            {
                id: 'lowColor',
                header: 'Marianne',
                accessorFn: (info) => info,
                cell: (info) =>
                    isDeviceUsingLowColor(info.getValue<Device>()) ? <CheckOne className="text-success" /> : <NoData />,
                size: 90,
            },
            {
                id: 'lastCounter.counter.lowColor',
                header: 'Total marianne',
                accessorFn: (info) => info,
                cell: (info) => {
                    const device = info.getValue<Device>();
                    return isDeviceUsingLowColor(device) ? (
                        formatNumber(device.lastCounter?.counter?.lowColor)
                    ) : (
                        <NoData />
                    );
                },
                enableSorting: true,
                size: 149,
            },
            {
                id: 'erasableBlue',
                header: 'Bleu effaçable',
                accessorFn: (info) => info,
                cell: (info) => {
                    const device = info.getValue<Device>();
                    return isDeviceUsingErasableBlue(device) ? <CheckOne className="text-success" /> : <NoData />;
                },
                size: 149,
            },
            {
                id: 'lastCounter.counter.erasableBlue',
                header: 'Total bleu effaç.',
                accessorKey: 'lastCounter.counter.erasableBlue',
                cell: (info) => formatNumber(info.getValue<number | null | undefined>()),
                enableSorting: true,
                size: 164,
            },
            {
                id: 'hardCodedDeviceInfoCustomerName',
                header: 'Nom du client',
                accessorKey: 'hardCodedDeviceInfo.customer.name',
                cell: (info) => info.getValue() ?? <NoData />,
                size: 275,
            },
            {
                id: 'ecc',
                header: 'ECC',
                accessorKey: 'ecc',
                cell: (info) => <EccAutoStatusIcon status={info.getValue<Device['ecc']>()} />,
                size: 51,
            },
            {
                id: 'auto',
                header: 'AUTO',
                accessorKey: 'auto',
                cell: (info) => <EccAutoStatusIcon status={info.getValue<Device['auto']>()} />,
                size: 64,
            },
            {
                id: 'contractReferences',
                header: 'N° contrat',
                accessorKey: 'erpDeviceInfo.customer.contractReferences',
                cell: (info) => info.getValue<Customer['contractReferences']>()?.join(', ') || <NoData />,
                size: 112,
            },
            {
                id: 'exportDate',
                header: 'Date export compteurs',
                accessorKey: 'exportDate',
                cell: (info) =>
                    info.getValue<Device['exportDate']>() ? (
                        formatDate(info.getValue<Device['exportDate']>())
                    ) : (
                        <NoData />
                    ),
                enableSorting: true,
                size: 205,
            },
            {
                id: 'technician',
                header: 'Nom technicien ERP',
                accessorKey: 'erpDeviceInfo.technician.lastName',
                cell: (info) => info.getValue<Technician['lastName']>() ?? <NoData />,
                size: 205,
            },
        ],
        []
    );

    return (
        <>
            <div className="-mt-16 -mx-16 p-16">
                <Seo title="Rechercher une liste de machines" />
                <div className="flex justify-between mb-14">
                    <Link
                        to={{
                            pathname: getRoute(RoutePathName.alerts),
                            state: { restoreQueryParams: true },
                        }}
                    >
                        <Button icon={<ArrowLeftOutlined />} type="link" className="pl-0 font-semibold">
                            Synthèse des alertes
                        </Button>
                    </Link>
                </div>
                <Typography.Title className="mb-24">Rechercher une liste de machines</Typography.Title>
                <Card>
                    <Form form={form} layout="vertical" onFinish={onSubmit} onReset={onFormReset}>
                        <Form.Item
                            name="serials"
                            label={
                                <div className="space-x-2">
                                    <span>Liste de N° de série</span>
                                    <span className={classNames(serialCount > 300 && 'text-red')}>
                                        ({formatNumber(serialCount)} / 300 max)
                                    </span>
                                </div>
                            }
                            normalize={(value) => value.replaceAll(' ', '\r\n')}
                            className="mb-12"
                        >
                            <Input.TextArea
                                rows={5}
                                placeholder="Saisir des N° de série de machines (un par ligne)"
                                onChange={onTextareaChange}
                            />
                        </Form.Item>
                        <div className="flex justify-center gap-8">
                            <Button htmlType="reset">Effacer la liste</Button>
                            <Button type="primary" htmlType="submit" disabled={serialCount > 300}>
                                Rechercher les machines
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
            {!!serials?.length && (
                <div className="bg-grey-darker -mx-16 -mb-16 p-16 space-y-4">
                    {!!serialsNotFound?.length && (
                        <Alert
                            message={
                                <TruncatedText maxHeight={100}>
                                    {serialsNotFound.length === 1
                                        ? 'Aucune machine trouvée pour le numéro de série suivant :'
                                        : 'Aucune machine trouvée pour les numéros de série suivants :'}{' '}
                                    {serialsNotFound.join(', ')}
                                </TruncatedText>
                            }
                            type="warning"
                        />
                    )}
                    <div className={classNames('flex justify-between', canWrite ? 'items-end' : 'items-center')}>
                        <div>
                            <Typography.Title level={2} className={canWrite ? 'mb-12' : 'mb-0'}>
                                Liste des machines trouvées ({formatNumber(devices?.totalCount)})
                            </Typography.Title>
                            {canWrite && (
                                <Button
                                    icon={<DeleteOutlined className="text-primary" />}
                                    disabled={!devicesToDelete?.length}
                                    onClick={onClickDelete}
                                >
                                    {devicesToDelete?.length === 1
                                        ? 'Supprimer la machine sélectionnée'
                                        : `Supprimer les ${devicesToDelete?.length || ''} machines sélectionnées`}
                                </Button>
                            )}
                        </div>
                        <div className="flex space-x-2">
                            <Button
                                icon={<UserOutlined className="text-primary" />}
                                disabled={!highlightedDevice?.id}
                                onClick={async () =>
                                    await show(deviceDrawerId, {
                                        id: highlightedDevice?.id,
                                        tab: 'artis',
                                    })
                                }
                            >
                                Fiche parc
                            </Button>
                            <Link
                                to={{
                                    pathname: getRoute(RoutePathName.alertHistory),
                                    search: new URLSearchParams({
                                        deviceSerial: highlightedDevice?.serial ?? '',
                                    }).toString(),
                                }}
                            >
                                <Button
                                    icon={<DocSearchTwo className="text-primary" />}
                                    disabled={!highlightedDevice?.serial}
                                >
                                    Historique alertes
                                </Button>
                            </Link>
                            <Link
                                to={{
                                    pathname: getRoute(RoutePathName.alertStats),
                                    search: new URLSearchParams({
                                        deviceSerial: highlightedDevice?.serial ?? '',
                                    }).toString(),
                                }}
                            >
                                <Button
                                    icon={<LineChartOutlined className="text-primary" />}
                                    disabled={!highlightedDevice?.serial}
                                >
                                    Compteurs
                                </Button>
                            </Link>
                            <Button
                                icon={<FileExcelOutlined className="text-primary" />}
                                onClick={async () => await onClickExport(FileType.EXCEL)}
                                loading={isExcelExporting}
                            >
                                Excel
                            </Button>
                            <Button
                                icon={<FilePdfOutlined className="text-primary" />}
                                onClick={async () => await onClickExport(FileType.PDF)}
                                loading={isPDFExporting}
                            >
                                PDF
                            </Button>
                        </div>
                    </div>
                    <ResizableTable<Device>
                        data={devices?.items}
                        columns={columns}
                        rowKey="id"
                        onSortChange={onSortChange}
                        onRowHighlightChange={onRowHighlightChange}
                        isRowHighlighted={isRowHighlighted}
                        selectedRowKeys={devicesToDelete}
                        onRowSelectionChange={setDevicesToDelete}
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        emptyContent={
                            <Empty
                                className="mb-32 text-taupe"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Aucun résultat"
                            />
                        }
                        enableColumnResizing
                        enableAutoSorting
                    />
                </div>
            )}
        </>
    );
};

export default DeviceSearch;

import { Tag, TagProps } from 'antd';
import { FC } from 'react';

import { classNames } from '../helpers';

const TagWhite: FC<TagProps> = ({ children, className, ...props }) => (
    <Tag {...props} className={classNames('tag-white', className)}>
        {children}
    </Tag>
);

export default TagWhite;
